
export default function getDisplay(display) {
  switch (display) {
    case "block":
      return "block";
    case "inline-block":
      return "inline-block";
    case "table-cell":
      return "table-cell";
    case "grid":
      return "grid";
    case "inline":
      return "inline-flex";
    case "row":
      return "flex flex-row";
    case "col":
      return "flex flex-col";
    case "row-reverse":
      return "flex flex-row-reverse";
    case "col-reverse":
      return "flex flex-col-reverse";
    case "none":
      return "flex-none";
    case "contents":
      return "contents";
    case "hidden":
      return "hidden";
    default:
      return "flex flex-col";
  }
}
