const values = {
  0: "lg:pl-0",
  0.5: "lg:pl-0.5",
  1: "lg:pl-1",
  1.5: "lg:pl-1.5",
  2: "lg:pl-2",
  2.5: "lg:pl-2.5",
  3: "lg:pl-3",
  3.5: "lg:pl-3.5",
  4: "lg:pl-4",
  5: "lg:pl-5",
  6: "lg:pl-6",
  7: "lg:pl-7",
  8: "lg:pl-8",
  9: "lg:pl-9",
  10: "lg:pl-10",
  12: "lg:pl-12",
  14: "lg:pl-14",
  16: "lg:pl-16",
  20: "lg:pl-20",
  24: "lg:pl-24",
  28: "lg:pl-28",
  32: "lg:pl-32",
  36: "lg:pl-36",
  40: "lg:pl-40",
  44: "lg:pl-44",
  48: "lg:pl-48",
  52: "lg:pl-52",
  56: "lg:pl-56",
  60: "lg:pl-60",
  64: "lg:pl-64",
  72: "lg:pl-72",
  80: "lg:pl-80",
  96: "lg:pl-96",
};

export default function getPaddingLeftLg(value) {
  return values[value];
}
