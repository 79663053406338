import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import formatDate from "./formatDate.js";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function formatTransaction(order, transaction, timeZone, dateFormat) {
  if (transaction) {
    return {
      id: transaction.stripePaymentId || transaction.id,
      amount: transaction.amount,
      option: transaction.option,
      operator: transaction.operator?.name,
      date: formatDate(transaction.createDate.date, dateFormat),
      time: dayjs.tz(`${transaction.createDate.date}T${transaction.createDate.time}`, timeZone).format("HH:mm"),
      balanceBefore: transaction.creditBefore,
      balanceAfter: transaction.creditAfter,
    };
  } else if (order?.payments) {
    // Find last payment
    const lastPayment = order?.payments?.find((payment) => {
      return !payment.pt && !order?.payments.find((other) => !other.pt && other.t > payment.t);
    });

    if (lastPayment) {
      const date = dayjs.unix(lastPayment.t).tz(timeZone);

      return {
        id: lastPayment.id,
        amount: lastPayment.v,
        option: lastPayment.m,
        operator: lastPayment.o,
        date: formatDate(date, dateFormat),
        time: date.format("HH:mm"),
        balanceAfter: lastPayment.balance,
      };
    }
  }
}
