const values = {
  0: "right-0",
  1: "right-1",
  2: "right-2",
  3: "right-3",
  4: "right-4",
  5: "right-5",
  6: "right-6",
  7: "right-7",
  8: "right-8",
  9: "right-9",
  10: "right-10",
  11: "right-11",
  12: "right-12",
  14: "right-14",
  16: "right-16",
  20: "right-20",
  "1/2": "right-1/2",
};

export default function getAbsoluteRight(value) {
  return values[value];
}
