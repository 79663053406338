import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast } from "@easybiz/shell";
import { CUSTOMER_ACTION_PROMO_CODE_CHECK, OPERATION_GROUP_CUSTOMER } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useCustomerPromoCodeCheck(callback) {
  const toast = useFeedbackToast();
  const intl = useIntl();

  return useActionCallback(
    ({ customerId, businessCode, code, serviceId, orderId }) => {
      if (!code) {
        return intl.formatMessage({
          id: "please.enter.promo.code",
          defaultMessage: "Please enter promo code",
        });
      }
      if (!customerId) {
        return intl.formatMessage({
          id: "please.select.customer.account.first",
          defaultMessage: "Please select customer account first",
        });
      }

      return [
        OPERATION_GROUP_CUSTOMER,
        CUSTOMER_ACTION_PROMO_CODE_CHECK,
        {
          customerId,
          serviceId,
          businessCode,
          promoCodeId: code,
          ...(orderId && { orderId }),
        },
      ];
    },
    callback,
    (request) =>
      toast.success(
        intl.formatMessage(
          {
            id: "promo.code.x.applied",
            defaultMessage: "Promo code {code} applied",
          },
          { code: request.promoCodeId }
        )
      )
  );
}

export default useCustomerPromoCodeCheck;
