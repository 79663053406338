"use client";

import { useSplashScreenStatus } from "@easybiz/hook";
import { clsx } from "clsx";
import { getAuth, signOut } from "firebase/auth";
import { FormattedMessage } from "react-intl";
import Button from "./Button";

export default function SplashScreen() {
  const { status, longerText, fallbackText } = useSplashScreenStatus();

  return (
    <div className="flex flex-col gap-4 h-screen justify-center items-center bg-gray-50">
      <img src="/images/platform_logo.webp" className="size-40 rounded-md animate-pulse drop-shadow-lg" />
      <p className="text-base text-gray-500 animate-pulse text-center whitespace-pre">{status}</p>
      {fallbackText || longerText ? (
        <p className={clsx("text-sm text-gray-500 text-center whitespace-pre", fallbackText ? "" : "animate-pulse")}>
          ({fallbackText || longerText})
        </p>
      ) : (
        <br />
      )}
      {fallbackText ? (
        <div className="grid grid-cols-2 gap-4 mt-6">
          <Button danger onClick={() => signOut(getAuth())}>
            <FormattedMessage id="sign.out" defaultMessage="Sign out" />
          </Button>
          <Button onClick={() => window.location.reload(true)}>
            <FormattedMessage id="reload" defaultMessage="Reload" />
          </Button>
        </div>
      ) : (
        <>
          <br />
          <br />
        </>
      )}
    </div>
  );
}
