export const PLATFORM_PRODUCT_ID_POS = "POS";
export const PLATFORM_PRODUCT_ID_ONLINE = "ONLINE";
export const PLATFORM_PRODUCT_ID_APP = "APP";
export const PLATFORM_PRODUCT_ID_WORKFLOW = "WORKFLOW";
export const PLATFORM_PRODUCT_ID_B2B = "FACTORY";

export const PRICE_STATUS_PUBLIC = "public";
export const PRICE_STATUS_PRIVATE = "private";

export const CLIENT_POS = "pos";
export const CLIENT_MOBILE = "mobile";
export const CLIENT_CONSOLE = "console";
export const CLIENT_CUSTOMER_APP = "customer_app";
export const CLIENT_CUSTOMER_WEBSITE = "customer_website";
export const CLIENT_PLATFORM_DASHBOARD = "dashboard";
export const CLIENT_WHATSAPP = "whatsapp";
export const CLIENT_EMAIL = "email";

export const PLATFORM_WEB = "web";
export const PLATFORM_IOS = "ios";
export const PLATFORM_ANDROID = "android";

export const DEVICE_MAC = "mac";
export const DEVICE_WINDOWS = "windows";
export const DEVICE_LINUX = "linux";
export const DEVICE_IPHONE = "iphone";
export const DEVICE_IPAD = "ipad";
export const DEVICE_ANDROID = "android";
export const DEVICE_NONE = "none";
export const MODE_COMPONENTS = "components";

export const PLATFORM_ACTION_ERROR_REPORT = "error_report";
export const PLATFORM_ACTION_NEW_NOTICE = 'new_notice';
export const PLATFORM_ACTION_STORAGE_REQUEST = 'storage_request';
export const PLATFORM_ACTION_STORAGE_COMPLETE = 'storage_complete';
export const PLATFORM_ACTION_STORAGE_DELETE = 'storage_delete';

export const SUBSCRIPTION_ACTION_AUTO_ASSIGN_DOMAIN_CHECK = "auto_assign_domain_check";
export const SUBSCRIPTION_ACTION_SIGN_IN_EMAIL_CHECK = "sign_in_email_check";
export const SUBSCRIPTION_ACTION_EMAIL_OTP_SEND = 'email_otp_send';
export const SUBSCRIPTION_ACTION_START = 'create_account';
export const SUBSCRIPTION_ACTION_PASSWORD_FORGOT = "password_forgot";
export const SUBSCRIPTION_ACTION_PASSWORD_RESET = "password_reset";
export const SUBSCRIPTION_ACTION_EMAIL_SEND_VERIFY_LINK = "email_send_verify_link";
export const SUBSCRIPTION_ACTION_EMAIL_VERIFY = "email_verify";

export const PLATFORM_OPERATION_DELETE_ACCOUNT = "delete account";

export const OPERATOR_SYSTEM = {
  id: 'system',
  name: 'System'
};

export const BUG_STATUS_OPEN = 'open';
export const BUG_STATUS_ATTENDING = 'attending';
export const BUG_STATUS_SOLVED = 'solved';

export const TEMPLATE_REALM_ID = 'tGMa7fl6xnX2U6Z8Z0sR';

export const PLATFORM_APP_THEME = {
  name: "teal",
  value: 600,
  hex: "#319795",
  error: `#B3261E`,
  // Material UI
  primaryContainer: `#8af7c5`,
  secondary: `#4d6357`,
  secondaryContainer: `#cfe9d9`,
  surface: `#dbe5dd`,
  background: `#fbfdf8`,
  errorContainer: `#F9DEDC`,
  tertiaryContainer: `#c1e9fb`,
  tertiary: `#3d6373`,
  disabled: `#B9BDC1`,
};