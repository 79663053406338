import { NotificationOutlined } from "@ant-design/icons";
import { Dialog } from "@easybiz/web-admin";
import { DateDisplay } from "@easybiz/component";
import { usePOSCloseOfDayWithdraw } from "@easybiz/api-pos";
import { Alert, Input, Space } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function ({ open, onCancel, date }) {
  const [note, setNote] = useState();

  const intl = useIntl();
  const { onSubmit, loading } = usePOSCloseOfDayWithdraw((succeed) => {
    if (succeed) {
      onCancel();
    }
  });

  return (
    <Dialog
      confirmLoading={loading}
      title={
        <FormattedMessage
          defaultMessage={"Withdraw Close of Day Report of {date}?"}
          values={{ date: <DateDisplay date={date} /> }}
        />
      }
      open={open}
      onCancel={onCancel}
      okText={<FormattedMessage tagName={"span"} defaultMessage="Withdraw" />}
      okDanger
      onOk={() => {
        onSubmit({
          note,
          date,
        });
      }}
    >
      <Space direction="vertical" className="full-width">
        <Alert
          type="info"
          showIcon
          icon={<NotificationOutlined />}
          message={<FormattedMessage defaultMessage={"A notification with your note will be sent to the team"} />}
        />
        <Input.TextArea
          disabled={loading}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder={intl.formatMessage({
            defaultMessage: "Reason of withdraw",
          })}
        />
      </Space>
    </Dialog>
  );
}
