import translate from "./translate.js";

export default function (doc, stateClient) {
  return {
    id: doc.id,
    option: doc.id, // TODO:
    title: translate(doc.get("title")),
    ...(doc.get("instructions") && { instructions: doc.get("instructions") }),
    ...(doc.get("photoId") && { photoId: doc.get("photoId") }),
    ...(doc.get("customized") && { customized: doc.get("customized") }),
    ...(doc.get("noteRequired") && { noteRequired: true }),
    ...(doc.get("refundable") && { refundable: true }),
    ...(stateClient &&
      Array.isArray(doc.get("enabledClients")) && {
        enabled: doc.get("enabledClients").includes(stateClient),
      }),
  };
}
