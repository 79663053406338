export default function getGapYLg(value) {
  switch (value) {
    case 0.5:
      return "lg:gap-y-0.5";
    case 1:
      return "lg:gap-y-1";
    case 2:
      return "lg:gap-y-2";
    case 3:
      return "lg:gap-y-3";
    case 4:
      return "lg:gap-y-4";
    case 5:
      return "lg:gap-y-5";
    case 6:
      return "lg:gap-y-6";
    case 7:
      return "lg:gap-y-7";
    case 8:
      return "lg:gap-y-8";
    case 9:
      return "lg:gap-y-9";
    case 10:
      return "lg:gap-y-10";
    case 12:
      return "lg:gap-y-12";
    case 14:
      return "lg:gap-y-14";
    case 16:
      return "lg:gap-y-16";
    case 20:
      return "lg:gap-y-20";
    case 24:
      return "lg:gap-y-24";
    case 28:
      return "lg:gap-y-28";
    case 32:
      return "lg:gap-y-32";
    case 36:
      return "lg:gap-y-36";
    case 40:
      return "lg:gap-y-40";
    case 44:
      return "lg:gap-y-44";
  }
}
