"use client";

import { useRealmToDisplayName } from "@easybiz/shell";

function OrderNoDisplay({ business, businessCode, orderNo, agent }) {
  const toDisplayName = useRealmToDisplayName();

  if (agent) {
    return `#${orderNo} (${agent.name})`;
  } else {
    const businessName = toDisplayName(businessCode || business?.id);

    return `#${orderNo}${businessName ? ` (${businessName})` : ""}`;
  }
}

export default OrderNoDisplay;
