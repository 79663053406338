"use client";

import { useCallback, useEffect, useState } from "react";

function useServiceWorkerUpdate() {
  const [newVersion, setNewVersion] = useState(false);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration && registration.waiting) {
          setNewVersion(true);
        }
      });
    }

    const onUpdate = (e) => {
      if (e.data.updateAvailable) {
        setNewVersion(true);
      }
    };

    window.addEventListener("message", onUpdate);

    return () => window.removeEventListener("message", onUpdate);
  }, []);

  const onUpdate = useCallback(() => {
    const watchDog = setTimeout(() => {
      window.location.reload(true);
    }, 10000);

    navigator.serviceWorker.getRegistration().then((registration) => {
      const waitingWorker = registration && registration.waiting;
      if (waitingWorker) {
        waitingWorker.onstatechange = () => {
          if (waitingWorker.state === "activated") {
            clearTimeout(watchDog);
            window.location.reload(true);
          }
        };
        waitingWorker.postMessage({ type: "SKIP_WAITING" });
      } else {
        registration && registration.update();
      }
    });
  }, []);

  if (newVersion) {
    return onUpdate;
  } else {
    return null;
  }
}

export default useServiceWorkerUpdate;
