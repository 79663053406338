const options = {
  5: "bg-green-500/5",
  10: "bg-green-500/10",
  25: "bg-green-500/25",
  50: "bg-green-500/50",
  75: "bg-green-500/75",
  90: "bg-green-500/90",
  100: "bg-green-500",
};

const from = {
  5: "from-green-500/5",
  10: "from-green-500/10",
  25: "from-green-500/25",
  50: "from-green-500/50",
  75: "from-green-500/75",
  90: "from-green-500/90",
  100: "from-green-500",
};

export default function getBackgroundGreen500(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
