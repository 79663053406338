import clsx from "clsx";
import { PADDINGS } from "../constants/attrs";
import getPaddingBottom from "./getPaddingBottom";
import getPaddingBottomLg from "./getPaddingBottomLg";
import getPaddingBottomSm from "./getPaddingBottomSm";
import getPaddingLeft from "./getPaddingLeft";
import getPaddingLeftLg from "./getPaddingLeftLg";
import getPaddingLeftSm from "./getPaddingLeftSm";
import getPaddingRight from "./getPaddingRight";
import getPaddingRightLg from "./getPaddingRightLg";
import getPaddingRightSm from "./getPaddingRightSm";
import getPaddingTop from "./getPaddingTop";
import getPaddingTopLg from "./getPaddingTopLg";
import getPaddingTopSm from "./getPaddingTopSm";

export default function getPaddings(attrs) {
  const { pt, pr, pb, pl, ptLg, prLg, pbLg, plLg, ptSm, prSm, pbSm, plSm, pAuto } = attrs || {};

  return clsx(
    getPaddingLeft(pl),
    getPaddingRight(pr),
    getPaddingTop(pt),
    getPaddingBottom(pb),
    ...(pAuto
      ? [
          ...(pl && PADDINGS.includes(pl)
            ? [getPaddingLeftLg(PADDINGS[Math.min(PADDINGS.indexOf(pl) + 2, PADDINGS.length - 1)])]
            : []),
          ...(pr && PADDINGS.includes(pr)
            ? [getPaddingRightLg(PADDINGS[Math.min(PADDINGS.indexOf(pr) + 2, PADDINGS.length - 1)])]
            : []),
          ...(pt && PADDINGS.includes(pt)
            ? [getPaddingTopLg(PADDINGS[Math.min(PADDINGS.indexOf(pt) + 2, PADDINGS.length - 1)])]
            : []),
          ...(pb && PADDINGS.includes(pb)
            ? [getPaddingBottomLg(PADDINGS[Math.min(PADDINGS.indexOf(pb) + 2, PADDINGS.length - 1)])]
            : []),
        ]
      : [
          getPaddingLeftSm(plSm),
          getPaddingRightSm(prSm),
          getPaddingTopSm(ptSm),
          getPaddingBottomSm(pbSm),
          getPaddingLeftLg(plLg),
          getPaddingRightLg(prLg),
          getPaddingTopLg(ptLg),
          getPaddingBottomLg(pbLg),
        ])
  );
}
