"use client";

import { findPrinter } from "@easybiz/utils";
import { useCallback } from "react";

function useUsbIO() {
  const onPrint = useCallback(async (printer, commands) => {
    // Find real printer
    let device;

    if (printer) {
      const devices = await navigator.usb.getDevices();
      device = devices?.find((device) => findPrinter(device, printer));
    }

    if (!device) {
      throw new Error(`Device not paired`);
    }

    // USB
    if (!device.opened) {
      await device.open();
    }

    let configuration = device.configuration;

    if (!configuration) {
      for (const config of device.configurations) {
        await device.selectConfiguration(config.configurationValue);
        configuration = config;
        break;
      }
    }

    if (!configuration) {
      throw new Error(`USB Printer configuration not found`);
    }

    let claimedInterface;

    for (const inter of configuration.interfaces) {
      if (inter.claimed) {
        claimedInterface = inter;
        break;
      }

      await device.claimInterface(inter.interfaceNumber);
      claimedInterface = inter;
      break;
    }

    if (!claimedInterface) {
      throw new Error(`USB Printer interface not found`);
    }

    let outEndPoint;

    if (claimedInterface.alternate && Array.isArray(claimedInterface.alternate.endpoints)) {
      outEndPoint = claimedInterface.alternate.endpoints.find((endPoint) => endPoint.direction === "out");
    }

    if (!outEndPoint) {
      throw new Error(`USB Printer out endpoint not found`);
    }

    for (let content of commands) {
      if (typeof content === "string") {
        const iconv = await import("iconv-lite");
        content = iconv.encode(content, "GB18030");
      }

      await device.transferOut(outEndPoint.endpointNumber, content);
    }
  }, []);

  if (typeof navigator !== "undefined" && navigator.usb) {
    return onPrint;
  } else {
    return null;
  }
}

export default useUsbIO;
