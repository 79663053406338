const options = {
  5: "bg-orange-500/5",
  10: "bg-orange-500/10",
  25: "bg-orange-500/25",
  50: "bg-orange-500/50",
  75: "bg-orange-500/75",
  90: "bg-orange-500/90",
  100: "bg-orange-500",
};

const from = {
  5: "from-orange-500/5",
  10: "from-orange-500/10",
  25: "from-orange-500/25",
  50: "from-orange-500/50",
  75: "from-orange-500/75",
  90: "from-orange-500/90",
  100: "from-orange-500",
};

export default function getBackgroundOrange500(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
