import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_POS, POS_ACTION_DEVICE_PAIRING_TOKEN } from "@easybiz/utils";
import { useCallback } from "react";

function usePOSDevicePairingToken(callback) {
  const onSubmit = useCallback(({ pairingMode }) => {
    return [OPERATION_GROUP_POS, POS_ACTION_DEVICE_PAIRING_TOKEN, { pairingMode }];
  }, []);

  return useActionCallback(onSubmit, callback);
}

export default usePOSDevicePairingToken;
