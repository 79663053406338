const values = {
  0: "lg:pr-0",
  0.5: "lg:pr-0.5",
  1: "lg:pr-1",
  1.5: "lg:pr-1.5",
  2: "lg:pr-2",
  2.5: "lg:pr-2.5",
  3: "lg:pr-3",
  3.5: "lg:pr-3.5",
  4: "lg:pr-4",
  5: "lg:pr-5",
  6: "lg:pr-6",
  7: "lg:pr-7",
  8: "lg:pr-8",
  9: "lg:pr-9",
  10: "lg:pr-10",
  12: "lg:pr-12",
  14: "lg:pr-14",
  16: "lg:pr-16",
  20: "lg:pr-20",
  24: "lg:pr-24",
  28: "lg:pr-28",
  32: "lg:pr-32",
  36: "lg:pr-36",
  40: "lg:pr-40",
  44: "lg:pr-44",
  48: "lg:pr-48",
  52: "lg:pr-52",
  56: "lg:pr-56",
  60: "lg:pr-60",
  64: "lg:pr-64",
  72: "lg:pr-72",
  80: "lg:pr-80",
  96: "lg:pr-96",
};

export default function getPaddingRightLg(value) {
  return values[value];
}
