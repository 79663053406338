import {
  COMPONENT_CONTAINER,
  COMPONENT_PAGE,
  COMPONENT_SECTION,
  COMPONENT_TEXT,
  COMPONENT_TEXT_FIELD,
  getBackgroundColor,
  getFocusRingColor,
} from "@easybiz/utils";
import clsx from "clsx";
import getAbsoluteBottom from "./getAbsoluteBottom";
import getAbsoluteBottomLg from "./getAbsoluteBottomLg";
import getAbsoluteLeft from "./getAbsoluteLeft";
import getAbsoluteLeftLg from "./getAbsoluteLeftLg";
import getAbsoluteLeftSm from "./getAbsoluteLeftSm";
import getAbsoluteRight from "./getAbsoluteRight";
import getAbsoluteRightLg from "./getAbsoluteRightLg";
import getAbsoluteTop from "./getAbsoluteTop";
import getAbsoluteTopLg from "./getAbsoluteTopLg";
import getAbsoluteTopSm from "./getAbsoluteTopSm";
import getAlignItems from "./getAlignItems";
import getAlignItemsLg from "./getAlignItemsLg";
import getAlignItemsMd from "./getAlignItemsMd";
import getAlignItemsSm from "./getAlignItemsSm";
import getAlignItemsXl from "./getAlignItemsXl";
import getAlignSelf from "./getAlignSelf";
import getAlignSelfLg from "./getAlignSelfLg";
import getAlignSelfSm from "./getAlignSelfSm";
import getAlignSelfXl from "./getAlignSelfXl";
import getTextAlign from "./getTextAlign";
import getTextAlignLg from "./getTextAlignLg";
import getTextAlignMd from "./getTextAlignMd";
import getTextAlignSm from "./getTextAlignSm";
import getTextAlignXl from "./getTextAlignXl";
import getAspectRatio from "./getAspectRatio";
import getAspectRatioLg from "./getAspectRatioLg";
import getAspectRatioMd from "./getAspectRatioMd";
import getAspectRatioSm from "./getAspectRatioSm";
import getAspectRatioXl from "./getAspectRatioXl";
import getAttr from "./getAttr";
import getBackgroundBlend from "./getBackgroundBlend";
import getBackgroundClip from "./getBackgroundClip";
import getBgImagePosition from "./getBgImagePosition";
import getBgImageRepeat from "./getBgImageRepeat";
import getBgImageSize from "./getBgImageSize";
import getBorders from "./getBorders";
import getColumns from "./getColumns";
import getColumnsLg from "./getColumnsLg";
import getColumnsSm from "./getColumnsSm";
import getDisplay from "./getDisplay";
import getDisplayLg from "./getDisplayLg";
import getDisplayMd from "./getDisplayMd";
import getDisplaySm from "./getDisplaySm";
import getDisplayXl from "./getDisplayXl";
import getFlexShrink from "./getFlexShrink";
import getFlexBasis from "./getFlexBasis";
import getFlexShrinkLg from "./getFlexShrinkLg";
import getFlexWrap from "./getFlexWrap";
import getGaps from "./getGaps";
import getGridColEnd from "./getGridColEnd";
import getGridColEndXl from "./getGridColEndXl";
import getGridColSpan from "./getGridColSpan";
import getGridColSpanLg from "./getGridColSpanLg";
import getGridColSpanMd from "./getGridColSpanMd";
import getGridColSpanSm from "./getGridColSpanSm";
import getGridColSpanXl from "./getGridColSpanXl";
import getGridColumnStart from "./getGridColumnStart";
import getGridColumnStartLg from "./getGridColumnStartLg";
import getGridColumns from "./getGridColumns";
import getGridColumnsLg from "./getGridColumnsLg";
import getGridColumnsMd from "./getGridColumnsMd";
import getGridColumnsSm from "./getGridColumnsSm";
import getGridColumnsXl from "./getGridColumnsXl";
import getGridRowEndLg from "./getGridRowEndLg";
import getGridRowEndXl from "./getGridRowEndXl";
import getGridRowSpan from "./getGridRowSpan";
import getGridRowSpanLg from "./getGridRowSpanLg";
import getGridRowSpanXl from "./getGridRowSpanXl";
import getGridRowStartLg from "./getGridRowStartLg";
import getGridRowStartXl from "./getGridRowStartXl";
import getGridRows from "./getGridRows";
import getGridRowsXl from "./getGridRowsXl";
import getHeight from "./getHeight";
import getHeightLg from "./getHeightLg";
import getHeightMd from "./getHeightMd";
import getHeightSm from "./getHeightSm";
import getHeightXl from "./getHeightXl";
import getHoverBgColor from "./getHoverBgColor";
import getJustifyItems from "./getJustifyItems";
import getJustifyItemsLg from "./getJustifyItemsLg";
import getJustifyItemsMd from "./getJustifyItemsMd";
import getJustifyItemsSm from "./getJustifyItemsSm";
import getJustifyItemsXl from "./getJustifyItemsXl";
import getJustifySelf from "./getJustifySelf";
import getJustifySelfLg from "./getJustifySelfLg";
import getJustifySelfSm from "./getJustifySelfSm";
import getMargins from "./getMargins";
import getMaxHeight from "./getMaxHeight";
import getMaxHeightLg from "./getMaxHeightLg";
import getMaxHeightMd from "./getMaxHeightMd";
import getMaxHeightSm from "./getMaxHeightSm";
import getMaxHeightXl from "./getMaxHeightXl";
import getMaxWidths from "./getMaxWidths";
import getOrder from "./getOrder";
import getOverflow from "./getOverflow";
import getPaddings from "./getPaddings";
import getPointerEvent from "./getPointerEvent";
import getPosition from "./getPosition";
import getPositionLg from "./getPositionLg";
import getPositionSm from "./getPositionSm";
import getRing from "./getRing";
import getRounded from "./getRounded";
import getShadow from "./getShadow";
import getTranslateX from "./getTranslateX";
import getTranslateXLg from "./getTranslateXLg";
import getTranslateXSm from "./getTranslateXSm";
import getTranslateYLg from "./getTranslateYLg";
import getWidth from "./getWidth";
import getWidthLg from "./getWidthLg";
import getWidthMd from "./getWidthMd";
import getWidthSm from "./getWidthSm";
import getWidthXl from "./getWidthXl";
import getWordBreak from "./getWordBreak";
import getWrap from "./getWrap";
import getZIndex from "./getZIndex";

export default function getNodeClassName({ className, context, attrs, content, draft, type, as }) {
  const isContainer = type === COMPONENT_CONTAINER;
  const isText = type === COMPONENT_TEXT;
  const isTextField = type === COMPONENT_TEXT_FIELD;
  const aspectRatio = getAspectRatio(attrs);
  const aspectRatioSm = getAspectRatioSm(attrs);
  const aspectRatioMd = getAspectRatioMd(attrs);
  const aspectRatioLg = getAspectRatioLg(attrs);
  const aspectRatioXl = getAspectRatioXl(attrs);

  // Parent
  const parentDisplay = getAttr(context?.parent?.attrs, "display");
  const parentDisplaySm = getAttr(context?.parent?.attrs, "display", "Sm");
  const parentDisplayMd = getAttr(context?.parent?.attrs, "display", "Md");
  const parentDisplayLg = getAttr(context?.parent?.attrs, "display", "Lg");
  const parentDisplayXl = getAttr(context?.parent?.attrs, "display", "Xl");

  const maxWidths = getMaxWidths(attrs);

  return clsx(
    className,
    draft?.className,
    (context?.parent?.type === COMPONENT_PAGE || attrs?.subType === COMPONENT_SECTION) && maxWidths ? "mx-auto" : "",
    draft ? "" : "focus:outline-none",
    content?.link ? "cursor-pointer hover:opacity-75" : "",
    content?.condition ? "opacity-50" : "",
    as === "ul" ? "list-disc" : as === "ol" ? "list-decimal" : "",
    isTextField ? "placeholder:text-gray-400 focus:ring-2 focus:ring-inset border-0" : "",
    isTextField ? getFocusRingColor(context?.theme) : "",
    getOverflow(attrs),
    getPointerEvent(attrs?.pointer),
    getPosition(attrs?.position || (isContainer || content?.link || draft ? "relative" : "")),
    getPositionSm(attrs?.positionSm),
    getPositionLg(attrs?.positionLg),
    getAbsoluteTop(attrs?.top),
    getAbsoluteLeft(attrs?.left),
    getAbsoluteLeftSm(attrs?.leftSm),
    getAbsoluteLeftLg(attrs?.leftLg),
    getAbsoluteBottom(attrs?.bottom),
    getAbsoluteRight(attrs?.right),
    getAbsoluteRightLg(attrs?.rightLg),
    getAbsoluteTopSm(attrs?.topSm),
    getAbsoluteTopLg(attrs?.topLg),
    getAbsoluteBottomLg(attrs?.bottomLg),
    getTranslateX(attrs?.translateX),
    getTranslateXSm(attrs?.translateXSm),
    getTranslateXLg(attrs?.translateXLg),
    getTranslateYLg(attrs?.translateYLg),
    getZIndex(attrs?.zIndex),
    getColumns(attrs?.columns),
    getColumnsSm(attrs?.columnsSm),
    getColumnsLg(attrs?.columnsLg),
    getFlexShrink(attrs?.shrink),
    getFlexBasis(attrs?.basis),
    getFlexShrinkLg(attrs?.shrinkLg),
    getWrap(attrs?.wrap),
    isText ? "" : getDisplay(attrs?.display),
    getDisplaySm(attrs?.displaySm),
    getDisplayMd(attrs?.displayMd),
    getDisplayLg(attrs?.displayLg),
    getDisplayXl(attrs?.displayXl),
    getJustifyItems(attrs?.justifyItems),
    getJustifyItemsSm(attrs?.justifyItemsSm),
    getJustifyItemsMd(attrs?.justifyItemsMd),
    getJustifyItemsLg(attrs?.justifyItemsLg),
    getJustifyItemsXl(attrs?.justifyItemsXl),
    getWidth(attrs?.width, parentDisplay),
    getWidthSm(attrs?.widthSm, parentDisplaySm),
    getWidthMd(attrs?.widthMd, parentDisplayMd),
    getWidthLg(attrs?.widthLg, parentDisplayLg),
    getWidthXl(attrs?.widthXl, parentDisplayXl),
    getHeight(attrs?.height, parentDisplay),
    getHeightSm(attrs?.heightSm, parentDisplaySm),
    getHeightMd(attrs?.heightMd, parentDisplayMd),
    getHeightLg(attrs?.heightLg, parentDisplayLg),
    getHeightXl(attrs?.heightXl, parentDisplayXl),
    getShadow(attrs),
    getRounded(attrs),
    getPaddings(attrs),
    getMargins(attrs),
    getBorders(attrs, context?.theme),
    getRing(attrs, context?.bgColor, context?.theme),
    getAlignSelf(attrs?.alignSelf),
    getAlignSelfSm(attrs?.alignSelfSm),
    getAlignSelfLg(attrs?.alignSelfLg),
    getAlignSelfXl(attrs?.alignSelfXl),
    getJustifySelf(attrs?.justifySelf),
    getJustifySelfSm(attrs?.justifySelfSm),
    getJustifySelfLg(attrs?.justifySelfLg),
    getGaps(attrs),
    getOrder(attrs),
    getGridRows(attrs?.row),
    getGridRowsXl(attrs?.rowXl),
    getGridColumnStart(attrs?.colStart),
    getGridColumnStartLg(attrs?.colStartLg),
    getGridColumns(attrs?.col || (attrs?.display === "grid" ? 1 : null)),
    getGridColumnsSm(attrs?.colSm),
    getGridColumnsMd(attrs?.colMd),
    getGridColumnsLg(attrs?.colLg),
    getGridColumnsXl(attrs?.colXl),
    getGridRowSpan(attrs?.rowSpan),
    getGridRowSpanLg(attrs?.rowSpanLg),
    getGridRowSpanXl(attrs?.rowSpanXl),
    getGridRowStartLg(attrs?.rowStartLg),
    getGridRowStartXl(attrs?.rowStartXl),
    getGridRowEndLg(attrs?.rowEndLg),
    getGridRowEndXl(attrs?.rowEndXl),
    getGridColSpan(attrs?.colSpan),
    getGridColSpanSm(attrs?.colSpanSm),
    getGridColSpanMd(attrs?.colSpanMd),
    getGridColSpanLg(attrs?.colSpanLg),
    getGridColSpanXl(attrs?.colSpanXl),
    getGridColEnd(attrs?.colEnd),
    getGridColEndXl(attrs?.colEndXl),
    getMaxHeight(attrs?.maxH),
    getMaxHeightSm(attrs?.maxHSm),
    getMaxHeightMd(attrs?.maxHMd),
    getMaxHeightLg(attrs?.maxHLg),
    getMaxHeightXl(attrs?.maxHXl),
    getTextAlign(attrs?.alignText),
    getTextAlignSm(attrs?.alignTextSm),
    getTextAlignMd(attrs?.alignTextMd),
    getTextAlignLg(attrs?.alignTextLg),
    getTextAlignXl(attrs?.alignTextXl),
    getWordBreak(attrs?.break),
    getHoverBgColor(attrs?.hoverBgColor),
    getFlexWrap(attrs?.wrap),
    maxWidths,
    getBackgroundClip(attrs?.bgClip),
    ...(isText
      ? []
      : [
          getAlignItems(attrs?.alignItems),
          getAlignItemsSm(attrs?.alignItemsSm),
          getAlignItemsMd(attrs?.alignItemsMd),
          getAlignItemsLg(attrs?.alignItemsLg),
          getAlignItemsXl(attrs?.alignItemsXl),
        ]),
    aspectRatio,
    aspectRatioSm,
    aspectRatioMd,
    aspectRatioLg,
    aspectRatioXl,
    getBackgroundColor(attrs?.bgColor, context?.theme, attrs?.bgOpacity) || draft?.afterBgClassName,
    ...(attrs?.bgImage
      ? [
          getBackgroundBlend(attrs?.bgBlend),
          getBgImageSize(attrs?.bgSize),
          getBgImageRepeat(attrs?.bgRepeat),
          getBgImagePosition(attrs?.bgPosition),
        ]
      : [])
  );
}
