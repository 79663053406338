import { EditOutlined } from "@ant-design/icons";
import { usePOSCashDrawerEnd } from "@easybiz/api-pos";
import { AmountDisplay } from "@easybiz/component";
import { useClientTheme, useFeedbackToast } from "@easybiz/shell";
import { formatPrice } from "@easybiz/utils";
import { Dialog, NumericUpdatePanel, Title } from "@easybiz/web-admin";
import { Alert, Button, List, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default ({ cashDrawer, open, onCancel, onEnded }) => {
  const errorBgcolor = useClientTheme()?.errorContainer;
  const [manualAmount, setManualAmount] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const toast = useFeedbackToast();
  const intl = useIntl();
  const { onSubmit, loading } = usePOSCashDrawerEnd((succeed) => {
    if (succeed) {
      onEnded && onEnded();
    }
  });
  const actualAmount = manualAmount ?? cashDrawer?.balance;

  useEffect(() => {
    if (!open) {
      setManualAmount(null);
      setEditMode(false);
    }
  }, [open]);

  const difference = formatPrice(
    (actualAmount ?? cashDrawer?.balance ?? 0) - (cashDrawer?.balance || 0),
    true
  );

  return (
    <Dialog
      title={
        editMode ? (
          <FormattedMessage defaultMessage="Actual in Drawer Amount" />
        ) : (
          <FormattedMessage defaultMessage={"End Drawer"} />
        )
      }
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
      okType={editMode ? "default" : "primary"}
      okText={
        editMode ? (
          <FormattedMessage defaultMessage={"Back"} />
        ) : (
          <FormattedMessage defaultMessage={"Confirm end drawer"} />
        )
      }
      onOk={() => {
        if (editMode) {
          setEditMode(false);
        } else {
          onSubmit({
            actualAmount: cashDrawer?.balance ?? 0,
          });
        }
      }}
    >
      {editMode ? (
        <NumericUpdatePanel
          isCurrency
          onDone={(amount) => {
            if (typeof amount !== "number") {
              return toast.info(
                intl.formatMessage({
                  defaultMessage: "Please enter actual in drawer amount",
                })
              );
            }

            setManualAmount(amount);
            setEditMode(false);
          }}
        />
      ) : (
        <List
          dataSource={[
            {
              title: <FormattedMessage defaultMessage="Expected in Drawer" />,
              amount: (
                <Title>
                  <AmountDisplay value={cashDrawer?.balance || 0} />
                </Title>
              ),
            },
            {
              title: <FormattedMessage defaultMessage="Actual in Drawer" />,
              amount: (
                <Title>
                  <AmountDisplay value={actualAmount} />
                </Title>
              ),
              description: (
                <Alert
                  type="info"
                  banner
                  message={
                    <FormattedMessage defaultMessage="Check your cash drawer & confirm the actual amount, edit if not match." />
                  }
                />
              ),
              button: (
                <Button size="large" icon={<EditOutlined />} onClick={() => setEditMode(true)}>
                  <FormattedMessage tagName={"span"} defaultMessage={"Edit"} />
                </Button>
              ),
            },
            {
              title: <FormattedMessage defaultMessage="Difference" />,
              amount:
                difference > 0 ? (
                  <Title type={"success"}>
                    <FormattedMessage
                      defaultMessage={"{amount} over"}
                      values={{
                        amount: <AmountDisplay value={difference} />,
                      }}
                    />
                  </Title>
                ) : difference < 0 ? (
                  <Title type={"danger"}>
                    <FormattedMessage
                      defaultMessage={"{amount} short"}
                      values={{
                        amount: <AmountDisplay value={Math.abs(difference)} />,
                      }}
                    />
                  </Title>
                ) : (
                  <Title>
                    <AmountDisplay value={difference} />
                  </Title>
                ),
              backgroundColor: difference !== 0 ? errorBgcolor : null,
            },
          ]}
          renderItem={({ title, amount, backgroundColor, description, button }) => {
            return (
              <List.Item
                style={{ backgroundColor }}
                actions={[
                  <Space key={"actions"} direction="vertical">
                    {amount}
                    {button}
                  </Space>,
                ]}
              >
                <List.Item.Meta title={title} description={description} />
              </List.Item>
            );
          }}
        />
      )}
    </Dialog>
  );
};
