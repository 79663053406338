export default function getMarginTop(value) {
  switch (value) {
    case -44:
      return "-mt-44";
    case -40:
      return "-mt-40";
    case -36:
      return "-mt-36";
    case -32:
      return "-mt-32";
    case -28:
      return "-mt-28";
    case -24:
      return "-mt-24";
    case -20:
      return "-mt-20";
    case -16:
      return "-mt-16";
    case -12:
      return "-mt-12";
    case -8:
      return "-mt-8";
    case -4:
      return "-mt-4";
    case 0:
      return "mt-0";
    case 1:
      return "mt-1";
    case 2:
      return "mt-2";
    case 3:
      return "mt-3";
    case 4:
      return "mt-4";
    case 5:
      return "mt-5";
    case 6:
      return "mt-6";
    case 7:
      return "mt-7";
    case 8:
      return "mt-8";
    case 9:
      return "mt-9";
    case 10:
      return "mt-10";
    case 12:
      return "mt-12";
    case 14:
      return "mt-14";
    case 16:
      return "mt-16";
    case 20:
      return "mt-20";
    case 24:
      return "mt-24";
    case 28:
      return "mt-28";
    case 32:
      return "mt-32";
    case 36:
      return "mt-36";
    case 40:
      return "mt-40";
    case 44:
      return "mt-44";
    case 64:
      return "mt-64";
    default:
      return "";
  }
}
