const options = {
  5: "bg-amber-700/5",
  10: "bg-amber-700/10",
  25: "bg-amber-700/25",
  50: "bg-amber-700/50",
  75: "bg-amber-700/75",
  90: "bg-amber-700/90",
  100: "bg-amber-700",
};

const from = {
  5: "from-amber-700/5",
  10: "from-amber-700/10",
  25: "from-amber-700/25",
  50: "from-amber-700/50",
  75: "from-amber-700/75",
  90: "from-amber-700/90",
  100: "from-amber-700",
};

export default function getBackgroundAmber700(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
