"use client";

import { formatGoogleAddress } from "@easybiz/utils";
import { getGeocode } from "use-places-autocomplete";

async function fetchGooglePlaceAddresses(placeId, requirePostalCode) {
  const geoCodeResults = await getGeocode({
    placeId,
  });

  const addresses = geoCodeResults.map(formatGoogleAddress);

  if (requirePostalCode) {
    return addresses.filter((address) => address.postalCode);
  } else {
    return addresses;
  }
}

export default fetchGooglePlaceAddresses;
