import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_JOB_ADD } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function useOrderAddJob(callback) {
  const intl = useIntl();

  const onSubmit = useCallback(({ address, schedule, orderId, worker, note, scheduleType }) => {
    if (!address) {
      return intl.formatMessage({
        id: "please.select.address",
        defaultMessage: "Please select address",
      });
    }
    if (!schedule?.date) {
      return intl.formatMessage({
        id: "please.select.schedule",
        defaultMessage: "Please select schedule",
      });
    }

    return [
      OPERATION_GROUP_ORDER,
      ORDER_ACTION_JOB_ADD,
      {
        schedule,
        ...(worker && { worker }),
        ...(note && { note }),
        address,
        orderId,
        scheduleType,
      },
    ];
  }, []);

  return useActionCallback(
    onSubmit,
    callback,
    intl.formatMessage({
      id: "job.scheduled",
      defaultMessage: "Job scheduled",
    })
  );
}

export default useOrderAddJob;
