const options = {
  5: "bg-emerald-600/5",
  10: "bg-emerald-600/10",
  25: "bg-emerald-600/25",
  50: "bg-emerald-600/50",
  75: "bg-emerald-600/75",
  90: "bg-emerald-600/90",
  100: "bg-emerald-600",
};

const from = {
  5: "from-emerald-600/5",
  10: "from-emerald-600/10",
  25: "from-emerald-600/25",
  50: "from-emerald-600/50",
  75: "from-emerald-600/75",
  90: "from-emerald-600/90",
  100: "from-emerald-600",
};

export default function getBackgroundEmerald600(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
