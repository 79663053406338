import { TAX_TYPE_GST, TAX_TYPE_SALES_TAX, TAX_TYPE_VAT } from "./constants/index.js";

export default function toDisplayTaxType(taxType) {
  switch (taxType) {
    case TAX_TYPE_GST:
      return "GST";
    case TAX_TYPE_VAT:
      return "VAT";
    case TAX_TYPE_SALES_TAX:
      return "Sales Tax";
    default:
      return taxType;
  }
}
