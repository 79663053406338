export default function getLabelFontWeight(density) {
  switch (density) {
    case 15:
    case 14:
      return {
        fontWeight: 900,
      };
    case 13:
    case 12:
      return {
        fontWeight: 800,
      };
    case 11:
    case 10:
      return {
        fontWeight: 700,
      };
    case 9:
      return {
        fontWeight: 600,
      };
    case 8:
      return {
        fontWeight: 500,
      };
    case 7:
      return {
        fontWeight: 400,
      };
    case 6:
    case 5:
      return {
        fontWeight: 300,
      };
    case 4:
    case 3:
      return {
        fontWeight: 200,
      };
    case 2:
    case 1:
      return {
        fontWeight: 100,
      };
  }
}
