import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_POS, POS_ACTION_ORDER_HANDOVER } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function usePOSBatchHandover(callback) {
  const intl = useIntl();

  const onSubmit = useCallback(({ procedure, serviceId, orderIds }) => {
    if (!Array.isArray(orderIds) || orderIds.length === 0)
      return intl.formatMessage({ defaultMessage: "Please select order" });
    if (orderIds.length > 500)
      return intl.formatMessage({
        defaultMessage: `You can only proceed maxinum 500 orders at a time`,
      });

    return [
      OPERATION_GROUP_POS,
      POS_ACTION_ORDER_HANDOVER,
      {
        procedure,
        orderIds,
        serviceId,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Handover succeed" }));
}

export default usePOSBatchHandover;
