import { CreditCardOutlined, CrownOutlined, GiftOutlined, SearchOutlined, WalletOutlined } from "@ant-design/icons";
import { useCheckoutCustomer, useCheckoutCustomerSetter, useCheckoutParams, useClientState, useClientStateSetter, useClientTheme } from "@easybiz/shell";
import {
  TAB_ADVANCE_CREDIT,
  TAB_MEMBER,
  TAB_NEW_ORDER,
  TAB_ORDER_HISTORY,
  TAB_OUTSTANDING,
  TAB_POINTS,
} from "@easybiz/utils";
import { useCustomerDoc } from "@easybiz/web-firebase";
import { Badge, Tabs } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import CustomerSearchList from "./CustomerSearchList";
import CustomerSelectBanner from "./CustomerSelectBanner";
import MainActionAdvanceCreditPanel from "./MainActionAdvanceCreditPanel";
import MainActionGiftPointsPanel from "./MainActionGiftPointsPanel";
import MainActionMembershipPanel from "./MainActionMembershipPanel";
import MainActionNewOrderPanel from "./MainActionNewOrderPanel";
import MainActionOrderHistoryPanel from "./MainActionOrderHistoryPanel";
import MainActionUnpaidOrdersPanel from "./MainActionUnpaidOrdersPanel";

export default function MainActionTabs() {
  const customer = useCheckoutCustomer();
  const customerDoc = useCustomerDoc(customer?.id);
  const [search, setSearch] = useState();
  const [activeKey, setActiveKey] = useState(TAB_NEW_ORDER);
  const setCustomer = useCheckoutCustomerSetter();
  const backgroundColor = useClientTheme()?.background;
  const secondaryContainer = useClientTheme()?.tertiary;
  const outstandingCount = customerDoc?.get("outstandingCount");
  const { displayMode } = useClientState();
  const setState = useClientStateSetter();
  const checkoutParams = useCheckoutParams();
  const orderCount = customerDoc?.get("orderCount");
  const haveCredit = customerDoc?.get("credit") > 0;
  const havePoints = customerDoc?.get("points") > 0;
  const isMember = customerDoc?.get("members")?.length > 0;

  useEffect(() => {
    if (typeof search === "string") {
      setSearch(undefined);
    }
  }, [checkoutParams]);

  useEffect(() => {
    if (displayMode?.mainActionTab) {
      setActiveKey(displayMode?.mainActionTab);
    }
  }, [displayMode]);

  const items = useMemo(() => {
    return [
      {
        key: TAB_NEW_ORDER,
        label: <FormattedMessage defaultMessage={"New order"} />,
      },
      ...(outstandingCount > 0 || activeKey === TAB_OUTSTANDING
        ? [
            {
              key: TAB_OUTSTANDING,
              label: (
                <Badge count={outstandingCount}>
                  <CreditCardOutlined />
                </Badge>
              ),
            },
          ]
        : []),
      {
        key: TAB_ORDER_HISTORY,
        label: (
          <Badge count={orderCount} style={{ backgroundColor: secondaryContainer }}>
            <SearchOutlined />
          </Badge>
        ),
      },
      {
        key: TAB_ADVANCE_CREDIT,
        label: (
          <Badge dot={haveCredit} status="success">
            <WalletOutlined />
          </Badge>
        ),
      },
      {
        key: TAB_POINTS,
        label: (
          <Badge dot={havePoints} status="success">
            <GiftOutlined />
          </Badge>
        ),
      },
      {
        key: TAB_MEMBER,
        label: (
          <Badge dot={isMember} status="success">
            <CrownOutlined />
          </Badge>
        ),
      },
    ];
  }, [outstandingCount, orderCount, haveCredit, havePoints, isMember, secondaryContainer]);

  useEffect(() => {
    if (activeKey && !items.find((item) => item.key === activeKey)) {
      setActiveKey(items[0].key);
    }
  }, [activeKey, items]);

  let children;

  switch (activeKey) {
    case TAB_NEW_ORDER:
      children = (
        <MainActionNewOrderPanel
          displayMode={displayMode}
          setDisplayMode={(displayMode) => setState({ displayMode })}
        />
      );
      break;
    case TAB_OUTSTANDING:
      children = customer ? (
        <MainActionUnpaidOrdersPanel
          customer={customer}
          outstandingCount={outstandingCount}
          onPreviewInvoice={(invoice) => setState({ displayMode: { ...displayMode, invoice } })}
        />
      ) : null;
      break;
    case TAB_ORDER_HISTORY:
      children = <MainActionOrderHistoryPanel search={search} orderCount={orderCount} />;
      break;
    case TAB_ADVANCE_CREDIT:
      children = <MainActionAdvanceCreditPanel />;
      break;
    case TAB_POINTS:
      children = <MainActionGiftPointsPanel />;
      break;
    case TAB_MEMBER:
      children = <MainActionMembershipPanel customerDoc={customerDoc} />;
      break;
    default:
      children = null;
  }

  return (
    <div className="flex flex-fill scroll-container">
      <CustomerSelectBanner search={search} customer={customer} onSelect={setCustomer} onSearch={setSearch} />
      <Tabs type="card" activeKey={activeKey} onChange={setActiveKey} tabBarStyle={{ margin: 0 }} items={items} />
      <div className="flex flex-fill scroll-body" style={{ backgroundColor }}>
        {typeof search === "string" && activeKey !== TAB_ORDER_HISTORY ? (
          <CustomerSearchList search={search} onSearch={setSearch} />
        ) : (
          children
        )}
      </div>
    </div>
  );
}
