const options = {
  "1/2": "basis-1/2",
  "1/3": "basis-1/3",
  "2/3": "basis-2/3",
  "1/4": "basis-1/4",
  "2/4": "basis-2/4",
  "3/4": "basis-3/4",
  "1/5": "basis-1/5",
  "2/5": "basis-2/5",
  "3/5": "basis-3/5",
  "4/5": "basis-4/5",
  "1/6": "basis-1/6",
  "2/6": "basis-2/6",
  "3/6": "basis-3/6",
  "4/6": "basis-4/6",
  "5/6": "basis-5/6",
};

export default function getFlexBasis(wrap) {
  return options[wrap];
}
