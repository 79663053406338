// TODO: DELETE ALL
export const OLD_PLACEHOLDER_BUSINESS_CODE = "BUSINESS_CODE";
export const OLD_PLACEHOLDER_ORDER_NO = "ORDER_NO";
export const OLD_PLACEHOLDER_ORDER_TOTAL_UNIT = "ORDER_TOTAL_PCS";
export const OLD_PLACEHOLDER_ORDER_UNIT_INDEX = "ORDER_PCS_INDEX";
export const OLD_PLACEHOLDER_SET_TOTAL_PCS = "SET_TOTAL_PCS";
export const OLD_PLACEHOLDER_SET_PCS_INDEX = "SET_PCS_INDEX";
export const OLD_PLACEHOLDER_CUSTOMER_NAME = "CUSTOMER_NAME";
export const OLD_PLACEHOLDER_CUSTOMER_CONTACT = "CUSTOMER_CONTACT";
export const OLD_PLACEHOLDER_AGENT_NAME = "AGENT_NAME";
export const OLD_PLACEHOLDER_ITEM_NAME = "ITEM_NAME";
export const OLD_PLACEHOLDER_ITEM_DEPARTMENT = "ITEM_DEPARTMENT";
export const OLD_PLACEHOLDER_SERVICE_OPTION = "SERVICE_OPTION";
export const OLD_PLACEHOLDER_RECEVING_DATE = "RECEIVING_DATE";
export const OLD_PLACEHOLDER_PACKING_DATE = "PACKING_DATE";
export const OLD_PLACEHOLDER_RECEVING_WEEK_DAY = "RECEVING_WEEK_DAY";
export const OLD_PLACEHOLDER_DELIVERY_DATE = "DELIVERY_DATE";
export const OLD_PLACEHOLDER_DELIVERY_WEEK_DAY = "DELIVERY_WEEK_DAY";
export const OLD_PLACEHOLDER_BALANCE_AMOUNT = "BALANCE_AMOUNT";
export const OLD_PLACEHOLDER_ITEM_REMARK = "ITEM_REMARK";
export const OLD_PLACEHOLDER_ITEM_DETAILS = "ITEM_DETAILS";
export const OLD_PLACEHOLDER_ICONS = "ICONS";
export const OLD_PLACEHOLDER_TOTAL_PACKAGE = "TOTAL_PACKAGES";
export const OLD_PLACEHOLDER_PACKAGE_INDEX = "PACKAGE_INDEX";
export const OLD_PLACEHOLDER_DEPARTMENT = "DEPARTMENT";
export const OLD_PLACEHOLDER_PACKING_PCS = "PACKING_PCS";
export const OLD_PLACEHOLDER_COLLECTION_PLACE = "COLLECTION_PLACE";

export const LABEL_AREA_LEFT = "left";
export const LABEL_AREA_RIGHT = "right";
export const LABEL_AREA_SIDE = "side";

export const LABEL_PLACEHOLDERS = [
  OLD_PLACEHOLDER_BUSINESS_CODE,
  OLD_PLACEHOLDER_ORDER_NO,
  OLD_PLACEHOLDER_ORDER_TOTAL_UNIT,
  OLD_PLACEHOLDER_ORDER_UNIT_INDEX,
  OLD_PLACEHOLDER_SET_TOTAL_PCS,
  OLD_PLACEHOLDER_SET_PCS_INDEX,
  OLD_PLACEHOLDER_CUSTOMER_NAME,
  OLD_PLACEHOLDER_CUSTOMER_CONTACT,
  OLD_PLACEHOLDER_ITEM_NAME,
  OLD_PLACEHOLDER_ITEM_DEPARTMENT,
  OLD_PLACEHOLDER_SERVICE_OPTION,
  OLD_PLACEHOLDER_RECEVING_DATE,
  OLD_PLACEHOLDER_RECEVING_WEEK_DAY,
  OLD_PLACEHOLDER_DELIVERY_DATE,
  OLD_PLACEHOLDER_DELIVERY_WEEK_DAY,
  OLD_PLACEHOLDER_BALANCE_AMOUNT,
  OLD_PLACEHOLDER_ITEM_REMARK,
  OLD_PLACEHOLDER_ITEM_DETAILS,
  OLD_PLACEHOLDER_ICONS,
  OLD_PLACEHOLDER_COLLECTION_PLACE,
  // Package
  OLD_PLACEHOLDER_AGENT_NAME,
  OLD_PLACEHOLDER_PACKING_DATE,
  OLD_PLACEHOLDER_PACKAGE_INDEX,
  OLD_PLACEHOLDER_TOTAL_PACKAGE,
  OLD_PLACEHOLDER_DEPARTMENT,
  OLD_PLACEHOLDER_PACKING_PCS,
];

export const PACK_PLACEHOLDERS = [
  OLD_PLACEHOLDER_BUSINESS_CODE,
  OLD_PLACEHOLDER_ORDER_NO,
  OLD_PLACEHOLDER_ORDER_TOTAL_UNIT,
  OLD_PLACEHOLDER_CUSTOMER_NAME,
  OLD_PLACEHOLDER_CUSTOMER_CONTACT,
  OLD_PLACEHOLDER_AGENT_NAME,
  OLD_PLACEHOLDER_PACKING_DATE,
  OLD_PLACEHOLDER_PACKAGE_INDEX,
  OLD_PLACEHOLDER_TOTAL_PACKAGE,
  OLD_PLACEHOLDER_DEPARTMENT,
  OLD_PLACEHOLDER_PACKING_PCS,
];

export const RECEIVING_DAY_INDEX = "RECEIVING_DAY_INDEX";
export const AGENT_SORTING_CODE = "AGENT_SORTING_CODE";
export const INTERNAL_NAME = "INTERNAL_NAME";

export const QR_ECC_LEVEL_L = "L";
export const QR_ECC_LEVEL_M = "M";
export const QR_ECC_LEVEL_Q = "Q";
export const QR_ECC_LEVEL_H = "H";
