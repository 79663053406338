import { ShopFilled } from "@ant-design/icons";
import { NameDisplay } from "@easybiz/component";
import { useClientOTAUpdate, useLicenseTrailEndTimestamp, useUserBusinessCode } from "@easybiz/shell";
import { Badge, Button } from "antd";
import dayjs from "dayjs";
import { useIntl } from "react-intl";

export default function ({ onClick }) {
  const businessCode = useUserBusinessCode();
  const haveUpdate = useClientOTAUpdate();
  const trialEndTimestamp = useLicenseTrailEndTimestamp();
  const intl = useIntl();

  return (
    <Badge
      color={haveUpdate ? undefined : "#2563eb"}
      style={{ zIndex: 1 }}
      count={
        haveUpdate
          ? intl.formatMessage({
              defaultMessage: "New version",
            })
          : trialEndTimestamp
          ? intl.formatMessage(
              {
                id: "trialEnd",
                defaultMessage: "[Trialing] Ends in {count} days",
              },
              {
                count: Math.max(0, dayjs.unix(trialEndTimestamp).diff(dayjs(), "days"))
              }
            )
          : null
      }
    >
      <Button icon={<ShopFilled />} onClick={onClick}>
        <span>
          <NameDisplay nameKey={businessCode} />
        </span>
      </Button>
    </Badge>
  );
}
