const options = {
  5: "bg-fuchsia-800/5",
  10: "bg-fuchsia-800/10",
  25: "bg-fuchsia-800/25",
  50: "bg-fuchsia-800/50",
  75: "bg-fuchsia-800/75",
  90: "bg-fuchsia-800/90",
  100: "bg-fuchsia-800",
};

const from = {
  5: "from-fuchsia-800/5",
  10: "from-fuchsia-800/10",
  25: "from-fuchsia-800/25",
  50: "from-fuchsia-800/50",
  75: "from-fuchsia-800/75",
  90: "from-fuchsia-800/90",
  100: "from-fuchsia-800",
};

export default function getBackgroundFuchsia800(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
