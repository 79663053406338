const values = {
  center: "lg:self-center",
  end: "lg:self-end",
  start: "lg:self-start",
  none: "lg:self-auto",
};

export default function getAlignSelfLg(align) {
  return values[align];
}
