const values = {
  first: "lg:order-first",
  last: "lg:order-last",
  none: "lg:order-none",
  1: "lg:order-1",
  2: "lg:order-2",
  3: "lg:order-3",
  4: "lg:order-4",
  5: "lg:order-5",
  6: "lg:order-6",
  7: "lg:order-7",
  8: "lg:order-8",
  9: "lg:order-9",
  10: "lg:order-10",
  11: "lg:order-11",
  12: "lg:order-12",
};

export default function getOrderLg(order) {
  return values[order];
}
