"use client";

import { useJobReschedule } from "@easybiz/api-job";
import { useOrderAddJob, useOrderCollectionUpdate } from "@easybiz/api-order";
import { useUserBusinessCode } from "@easybiz/shell";
import { translate } from "@easybiz/utils";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import AddressDisplay from "../AddressDisplay";
import DateDisplay from "../DateDisplay";
import FlatPercentageDisplay from "../FlatPercentageDisplay";
import JobTitle from "../JobTitle";
import NameDisplay from "../NameDisplay";

function useOrderRescheduleConfirmDialog(order, initSchedule, onCancel) {
  const businessCode = useUserBusinessCode();
  const [editSchedule, setEditSchedule] = useState(null);
  const [note, setNote] = useState("");
  const { onSubmit: onCollectionReschedule, loading: collectionLoading } = useOrderCollectionUpdate(
    (success) => success && onCancel()
  );
  const { onSubmit: onJobReschedule, loading: jobLoading } = useJobReschedule((success) => success && onCancel());
  const { onSubmit: onNewJob, loading: newJobLoading } = useOrderAddJob((success) => success && onCancel());
  const newSchedule = editSchedule || initSchedule;

  useEffect(() => {
    if (!order) {
      setNote("");
      setEditSchedule(null);
    }
  }, [Boolean(order)]);

  return {
    title: newSchedule?.taskId ? (
      <FormattedMessage id="job.reschedule" defaultMessage={"Job Reschedule"} />
    ) : newSchedule?.scheduleType ? (
      <FormattedMessage
        id="new.job.x"
        defaultMessage={"New {jobType}"}
        values={{
          jobType: <JobTitle type={newSchedule?.scheduleType} />,
        }}
      />
    ) : (
      <FormattedMessage id="reschedule.collection.?" defaultMessage={"Reschedule Collection?"} />
    ),
    okText: <FormattedMessage id="submit" defaultMessage="Submit" />,
    onOk: () => {
      if (newSchedule?.taskId) {
        // Reschedule
        onJobReschedule(newSchedule);
      } else if (newSchedule?.scheduleType) {
        // New schedule
        onNewJob({
          ...newSchedule,
          orderId: order.id,
        });
      } else {
        onCollectionReschedule({
          orderId: order.id,
          note,
          collectionPlace: newSchedule.collectionPlace || order.get("collectionPlace") || businessCode,
          date: newSchedule?.date,
          option: newSchedule?.option || null,
        });
      }
    },
    loading: collectionLoading || jobLoading || newJobLoading,
    note,
    onNoteChange: setNote,
    newSchedule,
    onScheduleChange: setEditSchedule,
    descriptions: [
      ...(newSchedule?.collectionPlace
        ? [
            {
              label: <FormattedMessage id="collection.place" defaultMessage={"Collection place"} />,
              content: <NameDisplay nameKey={newSchedule?.collectionPlace} showKey />,
            },
          ]
        : []),
      ...(newSchedule?.address
        ? [
            {
              label: <FormattedMessage id="address" defaultMessage={"Address"} />,
              content: <AddressDisplay address={newSchedule?.address} />,
            },
          ]
        : []),
      ...(newSchedule?.schedule || newSchedule?.date
        ? [
            {
              label: <FormattedMessage id="date" defaultMessage={"Date"} />,
              content: (newSchedule?.schedule || newSchedule?.date) && (
                <DateDisplay date={newSchedule?.date} {...newSchedule?.schedule} />
              ),
            },
            {
              label: <FormattedMessage id="option" defaultMessage={"Option"} />,
              content: (
                <>
                  {translate(newSchedule?.option?.title)}
                  {` `}
                  {newSchedule?.option?.surcharge ? (
                    <FormattedMessage
                      id="x.amount.surcharge"
                      defaultMessage={"{amount} surcharge"}
                      values={{
                        amount: <FlatPercentageDisplay {...newSchedule.option.surcharge} />,
                      }}
                    />
                  ) : (
                    <FormattedMessage id="no.surcharge" defaultMessage={"No surcharge"} />
                  )}
                </>
              ),
              isSurcharge: true,
            },
          ]
        : []),
    ],
  };
}

export default useOrderRescheduleConfirmDialog;
