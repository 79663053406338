export const PAYMENT_GATEWAY_IN_HOUSE = "in_house";
export const PAYMENT_GATEWAY_STRIPE = "stripe";

export const PAYMENT_STATUS_PENDING = "pending";
export const PAYMENT_STATUS_SUCCEED = "succeed";
export const PAYMENT_STATUS_FAILED = "failed";
export const PAYMENT_STATUS_CANCELLED = "cancelled";

export const PAYMENT_ID_CASH = "CASH";
export const PAYMENT_ID_PAYNOW = "PAYNOW";
export const PAYMENT_ID_PAYNOW_STRIPE = "PAYNOW_STRIPE";
export const PAYMENT_ID_CREDIT = "CREDIT";
export const PAYMENT_ID_POINTS = "POINTS";
export const PAYMENT_ID_STRIPE = "STRIPE";
export const PAYMENT_ID_GRABPAY = "GRABPAY";
export const PAYMENT_ID_WECHATPAY = "WECHATPAY";
export const PAYMENT_ID_ALIPAY = "ALIPAY";
export const PAYMENT_ID_GIFT = 'GIFT';
export const PAYMENT_ID_FPX = "fpx";
export const PAYMENT_ID_BANK_DEPOSIT = "bank_deposit";
export const PAYMENT_ID_CUSTOM = "custom";

export const STRIPE_PAYMENT_METHOD_TYPE_ACSS_DEBIT = "acss_debit";
export const STRIPE_PAYMENT_METHOD_TYPE_AFFIRM = "affirm";
export const STRIPE_PAYMENT_METHOD_TYPE_AFTERPAY_CLEARPAY = "afterpay_clearpay";
export const STRIPE_PAYMENT_METHOD_TYPE_ALIPAY = "alipay";
export const STRIPE_PAYMENT_METHOD_TYPE_AU_BECS_DEBIT = "au_becs_debit";
export const STRIPE_PAYMENT_METHOD_TYPE_BACS_DEBIT = "bacs_debit";
export const STRIPE_PAYMENT_METHOD_TYPE_BANCONTACT = "bancontact";
export const STRIPE_PAYMENT_METHOD_TYPE_BLIK = "blik";
export const STRIPE_PAYMENT_METHOD_TYPE_BOLETO = "boleto";
export const STRIPE_PAYMENT_METHOD_TYPE_CARD = "card";
export const STRIPE_PAYMENT_METHOD_TYPE_CARD_PRESENT = "card_present";
export const STRIPE_PAYMENT_METHOD_TYPE_CASHAPP = "cashapp";
export const STRIPE_PAYMENT_METHOD_TYPE_CARTES_BANCAIRES = "cartes_bancaires";
export const STRIPE_PAYMENT_METHOD_TYPE_EPS = "eps";
export const STRIPE_PAYMENT_METHOD_TYPE_FPX = "fpx";
export const STRIPE_PAYMENT_METHOD_TYPE_GIROPAY = "giropay";
export const STRIPE_PAYMENT_METHOD_TYPE_GRABPAY = "grabpay";
export const STRIPE_PAYMENT_METHOD_TYPE_IDEAL = "ideal";
export const STRIPE_PAYMENT_METHOD_TYPE_INTERAC_PRESENT = "interac_present";
export const STRIPE_PAYMENT_METHOD_TYPE_KLARNA = "klarna";
export const STRIPE_PAYMENT_METHOD_TYPE_KONBINI = "konbini";
export const STRIPE_PAYMENT_METHOD_TYPE_LINK = "link";
export const STRIPE_PAYMENT_METHOD_TYPE_OXXO = "oxxo";
export const STRIPE_PAYMENT_METHOD_TYPE_P24 = "p24";
export const STRIPE_PAYMENT_METHOD_TYPE_PAYNOW = "paynow";
export const STRIPE_PAYMENT_METHOD_TYPE_PIX = "pix";
export const STRIPE_PAYMENT_METHOD_TYPE_PROMTPAY = "promptpay";
export const STRIPE_PAYMENT_METHOD_TYPE_SEPA_DEBIT = "sepa_debit";
export const STRIPE_PAYMENT_METHOD_TYPE_SOFORT = "sofort";
export const STRIPE_PAYMENT_METHOD_TYPE_US_BANK_ACCOUNT = "us_bank_account";
export const STRIPE_PAYMENT_METHOD_TYPE_WECHAT_PAY = "wechat_pay";

export const PAYMENT_OVERVIEW_LINE_TOTAL = "total";
export const PAYMENT_OVERVIEW_LINE_USE_POINTS = "use_points";
export const PAYMENT_OVERVIEW_LINE_PAYMENT_METHOD = "payment_method";
export const PAYMENT_OVERVIEW_LINE_PAYMENT_AMOUNT = "payment_amount";
export const PAYMENT_OVERVIEW_LINE_OUTSTANDING = "outstanding";

export const PAYMENT_TYPE_CHECKOUT = "checkout";
export const PAYMENT_TYPE_ORDER = "order";
export const PAYMENT_TYPE_TOP_UP = "top_up";
export const PAYMENT_TYPE_MEMBERSHIP = "membership";

export const CHECKOUT_PAYMENT_MODE_INTENT = "intent_payment_method";
export const CHECKOUT_PAYMENT_MODE_ADD_CARD = "add_card";
export const CHECKOUT_PAYMENT_MODE_FULL = "full_payment";
export const CHECKOUT_PAYMENT_MODE_DEPOSIT = "pay_deposit";
export const CHECKOUT_PAYMENT_MODE_MINIMUM = "pay_minimum";