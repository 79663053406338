"use client";

import { motion, useReducedMotion } from "framer-motion";
import { createContext, useContext } from "react";

const FadeInStaggerContext = createContext(false);

const viewport = { once: true, margin: "0px 0px -200px" };

export function FadeIn({ id, as, headless, draftRef, ...props }) {
  let shouldReduceMotion = useReducedMotion();
  let isInStaggerGroup = useContext(FadeInStaggerContext);

  const motionProps = {
    id,
    variants: {
      hidden: { opacity: 0, y: shouldReduceMotion ? 0 : 24 },
      visible: { opacity: 1, y: 0 },
    },
    transition: { duration: 0.5 },
    ...(draftRef && { ref: draftRef }),
    ...(isInStaggerGroup
      ? {}
      : {
          initial: "hidden",
          whileInView: "visible",
          viewport,
        }),
    ...props,
  };

  if (headless) {
    return <div className={props.className}>{props.children}</div>;
  } else {
    switch (as) {
      case "input":
        return <motion.input {...motionProps} />;
      case "textarea":
        return <motion.textarea {...motionProps} />;
      case "iframe":
        return <motion.iframe {...motionProps} />;
      case "li":
        return <motion.li {...motionProps} />;
      case "p":
        return <motion.p {...motionProps} />;
      case "a":
        return <motion.a {...motionProps} />;
      case "span":
        return <motion.span {...motionProps} />;
      case "button":
        return <motion.button {...motionProps} />;
      case "select":
        return <motion.select {...motionProps} />;
      case "img":
        return <motion.img {...motionProps} />;
      case "h1":
        return <motion.h1 {...motionProps} />;
      case "h2":
        return <motion.h2 {...motionProps} />;
      case "h3":
        return <motion.h3 {...motionProps} />;
      case "h4":
        return <motion.h4 {...motionProps} />;
      case "h5":
        return <motion.h5 {...motionProps} />;
      case "h6":
        return <motion.h6 {...motionProps} />;
      default:
        return <motion.div {...motionProps} />;
    }
  }
}

export function FadeInStagger({ id, as, faster = false, disabled, tagName, headless, draftRef, ...props }) {
  if (headless) {
    return props.className ? (
      tagName ? (
        <tagName className={props.className}>{props.children}</tagName>
      ) : (
        <div className={props.className}>{props.children}</div>
      )
    ) : (
      props.children
    );
  } else {
    const motionProps = {
      id,
      ...props,
      initial: "hidden",
      whileInView: "visible",
      viewport,
      transition: { staggerChildren: faster ? 0.12 : 0.2 },
      ...(draftRef && { ref: draftRef }),
    };

    return (
      <FadeInStaggerContext.Provider value={true}>
        {as == "dl" ? (
          <motion.dl {...motionProps} />
        ) : as === "ul" ? (
          <motion.ul {...motionProps} />
        ) : (
          <motion.div {...motionProps} />
        )}
      </FadeInStaggerContext.Provider>
    );
  }
}
