import {
  DECORATION_COLOR_GRADIENT_BETWEEN,
  DECORATION_COLOR_GRADIENT_BOTTOM_CENTER,
  DECORATION_COLOR_GRADIENT_BOTTOM_TOP_RIGHT,
  DECORATION_COLOR_GRADIENT_CENTER_1,
  DECORATION_COLOR_GRADIENT_CENTER_2,
  DECORATION_COLOR_GRADIENT_CENTER_3,
  DECORATION_COLOR_GRADIENT_CENTER_4,
  DECORATION_COLOR_GRADIENT_FAR_RIGHT,
  DECORATION_COLOR_GRADIENT_TOP_LEFT,
  DECORATION_COLOR_GRADIENT_TOP_LEFT_TO_BOTTOM_RIGHT,
  DECORATION_GRID_FROM_BOTTOM_CENTER,
  DECORATION_GRID_FROM_TOP_RIGHT,
  DECORATION_GRID_FROM_TOP_RIGHT_WITH_COLOR_TILES,
  DECORATION_SHADOW_SLASH_FULL,
  DECORATION_SHADOW_SLASH_MIDDLE,
  DECORATION_SHADOW_SLASH_ON_LEFT,
  DECORATION_WAVE,
} from "@easybiz/utils";
import clsx from "clsx";
import getAbsoluteBottom from "../utils/getAbsoluteBottom";
import getAbsoluteLeft from "../utils/getAbsoluteLeft";
import getAbsoluteRight from "../utils/getAbsoluteRight";
import getAbsoluteTop from "../utils/getAbsoluteTop";
import getPosition from "../utils/getPosition";
import BackgroundGradient from "./BackgroundGradient";
import BackgroundGradientBetween from "./BackgroundGradientBetween";
import BackgroundGradientBottomCenter from "./BackgroundGradientBottomCenter";
import BackgroundGradientBottomRight from "./BackgroundGradientBottomRight";
import BackgroundGradientBottomTopRight from "./BackgroundGradientBottomTopRight";
import BackgroundGradientCenter from "./BackgroundGradientCenter";
import BackgroundGradientCenter2 from "./BackgroundGradientCenter2";
import BackgroundGradientCenter3 from "./BackgroundGradientCenter3";
import BackgroundGradientRight from "./BackgroundGradientRight";
import BackgroundGradientTopLeft from "./BackgroundGradientTopLeft";
import BackgroundGridFromBottomCenter from "./BackgroundGridFromBottomCenter";
import BackgroundGridFromTopLeft from "./BackgroundGridFromTopLeft";
import BackgroundGridFromTopLeftWithColorTiles from "./BackgroundGridFromTopLeftWithColorTiles";
import BackgroundShadowSlashFull from "./BackgroundShadowSlashFull";
import BackgroundShadowSlashLeft from "./BackgroundShadowSlashLeft";
import BackgroundShadowSlashMiddle from "./BackgroundShadowSlashMiddle";
import Wave from "./Wave";

export default function BackgroundDecorations({ decorations, bgColor, theme }) {
  return decorations?.map((decoration, index) => {
    const { id, attrs, content } = typeof decoration === "string" ? { id: decoration } : decoration;
    const className =
      attrs &&
      clsx(
        getPosition(attrs.position),
        getAbsoluteTop(attrs?.top),
        getAbsoluteLeft(attrs?.left),
        getAbsoluteBottom(attrs?.bottom),
        getAbsoluteRight(attrs?.right)
      );

    switch (id) {
      case DECORATION_GRID_FROM_TOP_RIGHT:
        return <BackgroundGridFromTopLeft key={id} bgColor={bgColor} theme={theme} />;
      case DECORATION_GRID_FROM_TOP_RIGHT_WITH_COLOR_TILES:
        return <BackgroundGridFromTopLeftWithColorTiles key={id} bgColor={bgColor} theme={theme} />;
      case DECORATION_GRID_FROM_BOTTOM_CENTER:
        return <BackgroundGridFromBottomCenter key={id} bgColor={bgColor} theme={theme} />;
      case DECORATION_SHADOW_SLASH_MIDDLE:
        return <BackgroundShadowSlashMiddle key={id} bgColor={bgColor} theme={theme} />;
      case DECORATION_SHADOW_SLASH_ON_LEFT:
        return <BackgroundShadowSlashLeft key={id} bgColor={bgColor} theme={theme} />;
      case DECORATION_SHADOW_SLASH_FULL:
        return <BackgroundShadowSlashFull key={id} bgColor={bgColor} theme={theme} />;
      case DECORATION_COLOR_GRADIENT_TOP_LEFT_TO_BOTTOM_RIGHT:
        return <BackgroundGradient key={id} />;
      case DECORATION_COLOR_GRADIENT_TOP_LEFT:
        return <BackgroundGradientTopLeft key={id} />;
      case DECORATION_COLOR_GRADIENT_FAR_RIGHT:
        return <BackgroundGradientRight key={id} />;
      case DECORATION_COLOR_GRADIENT_BOTTOM_TOP_RIGHT:
        return <BackgroundGradientBottomTopRight key={id} />;
      case DECORATION_COLOR_GRADIENT_BOTTOM_CENTER:
        return <BackgroundGradientBottomCenter key={id} />;
      case DECORATION_COLOR_GRADIENT_BETWEEN:
        return <BackgroundGradientBetween key={id} />;
      case DECORATION_COLOR_GRADIENT_CENTER_1:
        return <BackgroundGradientBottomRight key={id} />;
      case DECORATION_COLOR_GRADIENT_CENTER_4:
        return <BackgroundGradientCenter key={id} />;
      case DECORATION_COLOR_GRADIENT_CENTER_2:
        return <BackgroundGradientCenter2 key={id} />;
      case DECORATION_COLOR_GRADIENT_CENTER_3:
        return <BackgroundGradientCenter3 key={id} />;
      case DECORATION_WAVE:
        return (
          <Wave
            key={`${id}-${index}`}
            className={className}
            theme={theme}
            color={attrs?.color}
            bgColor={bgColor}
            content={content}
          />
        );
      default:
        return null;
    }
  });
}
