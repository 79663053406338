import {
  PLACEHOLDER_JOB_ID,
  PLACEHOLDER_ORDER_BUSINESS_UNIT_ID,
  PLACEHOLDER_ORDER_ID,
  PLACEHOLDER_SALES_CHANNEL_DOMAIN,
} from "./constants/content.js";
import {
  PAGE_ID_CASHIER,
  PAGE_ID_CHECKOUT,
  PAGE_ID_HOME,
  PAGE_ID_ORDER_DETAIL,
  PAGE_ID_PRICING,
  PAGE_ID_RESCHEDULE,
  PAGE_ID_TRACKING,
} from "./constants/template.js";
import isStandardPage from "./isStandardPage.js";

export default function getPageHref({ lang, pageId, serviceId, isEmbedMode, siteMap, hash, params }, renderParams) {
  let pathname = "";

  switch (pageId) {
    case PAGE_ID_HOME:
      break;
    case PAGE_ID_CHECKOUT:
    case PAGE_ID_PRICING:
      pathname = `${pageId}${serviceId ? `/${serviceId}` : ""}`;
      break;
    case PAGE_ID_RESCHEDULE:
      if (renderParams?.[PLACEHOLDER_ORDER_BUSINESS_UNIT_ID] && renderParams?.[PLACEHOLDER_JOB_ID]) {
        pathname = `checkout/${renderParams[PLACEHOLDER_ORDER_BUSINESS_UNIT_ID]}?reschedule=${renderParams[PLACEHOLDER_JOB_ID]}#date_time`;
      }
      break;
    default:
      if (isStandardPage(pageId)) {
        pathname = pageId;

        const orderId = renderParams?.[PLACEHOLDER_ORDER_ID];

        if (orderId) {
          switch (pageId) {
            case PAGE_ID_CASHIER:
              // BACKWARD COMPATIBILITY
              pathname = "payments";
              params = { ...params, orderId };
              break;
            case PAGE_ID_ORDER_DETAIL:
              params = { ...params, order_id: orderId };
              break;
            case PAGE_ID_TRACKING:
              params = { ...params, tracking_id: orderId };
              break;
          }
        }
      } else if (isEmbedMode) {
        pathname = `_embed/${pageId}`;
      } else {
        const slug = siteMap?.[pageId];
        pathname = slug ? `pages/${slug}` : "";
      }
  }

  return `${
    renderParams?.[PLACEHOLDER_SALES_CHANNEL_DOMAIN]
      ? `https://${renderParams[PLACEHOLDER_SALES_CHANNEL_DOMAIN]}`
      : ""
  }/${lang ? `${lang}/` : ""}${pathname}${
    params
      ? `?${
          renderParams?.skipEncode
            ? Object.keys(params)
                .map((key) => `${key}=${params[key]}`)
                .join("&")
            : new URLSearchParams(params).toString()
        }`
      : ""
  }${hash ? `#${hash}` : ""}`;
}
