const values = {
  first: "md:order-first",
  last: "md:order-last",
  none: "md:order-none",
  1: "md:order-1",
  2: "md:order-2",
  3: "md:order-3",
  4: "md:order-4",
  5: "md:order-5",
  6: "md:order-6",
  7: "md:order-7",
  8: "md:order-8",
  9: "md:order-9",
  10: "md:order-10",
  11: "md:order-11",
  12: "md:order-12",
};

export default function getOrderMd(order) {
  return values[order];
}
