"use client";

import { JOB_TYPE_DELIVERY, JOB_TYPE_PICKUP, JOB_TYPE_SERVICE } from "@easybiz/utils";
import { useIntl } from "react-intl";

function JobTitle({ type, recurring }) {
  const intl = useIntl();

  let title;

  switch (type) {
    case JOB_TYPE_PICKUP:
      title = intl.formatMessage({
        id: "pickup",
        defaultMessage: "Pick up",
      });
      break;
    case JOB_TYPE_DELIVERY:
      title = intl.formatMessage({
        id: "delivery",
        defaultMessage: "Delivery",
      });
      break;
    case JOB_TYPE_SERVICE:
      title = intl.formatMessage({
        id: "service",
        defaultMessage: "Service",
      });
      break;
    default:
      return null;
  }

  return `${
    recurring
      ? `${intl.formatMessage({
          id: "recurring",
          defaultMessage: "Recurring",
        })}(${recurring.index + 1}/${recurring.total}) `
      : ""
  }${title || ""}`;
}

export default JobTitle;
