import { EditOutlined } from "@ant-design/icons";
import { PROMO_TYPE_FLAT, PROMO_TYPE_PERCENT } from "@easybiz/utils";
import React, { useEffect, useState } from "react";
import FlatPercentRadioGroup from "../FlatPercentRadioGroup";
import IconButton from "../IconButton";
import QtyInputPopover from "../QtyInputPopover";
import { useUserCurrency } from "@easybiz/shell";

export default function SurchargeEditButton({ disabled, surcharge, onChange }) {
  const currency = useUserCurrency();
  const [open, setVisible] = useState(false);
  const [type, setType] = useState();

  useEffect(() => {
    if (open) {
      setType(surcharge?.type || PROMO_TYPE_PERCENT);
    }
  }, [open]);

  return (
    <QtyInputPopover
      removeable
      open={open}
      onOpenChange={setVisible}
      value={surcharge?.value}
      prefix={type === PROMO_TYPE_FLAT ? currency : null}
      suffix={type === PROMO_TYPE_PERCENT ? `%` : null}
      decimal
      extra={<FlatPercentRadioGroup value={type} onChange={setType} />}
      onDone={(amount) => {
        if (typeof amount === "number" && amount > 0) {
          setVisible(false);
          onChange({ type, value: amount });
        } else {
          setVisible(false);
          onChange(null);
        }
      }}
    >
      <IconButton icon={<EditOutlined />} disabled={disabled} onClick={() => setVisible(true)} />
    </QtyInputPopover>
  );
}
