import clsx from "clsx";
import getOrderLg from "./getOrderLg";
import getOrderMd from "./getOrderMd";
import getOrderSm from "./getOrderSm";
import getOrderXl from "./getOrderXl";

const values = {
  first: "order-first",
  last: "order-last",
  none: "order-none",
  1: "order-1",
  2: "order-2",
  3: "order-3",
  4: "order-4",
  5: "order-5",
  6: "order-6",
  7: "order-7",
  8: "order-8",
  9: "order-9",
  10: "order-10",
  11: "order-11",
  12: "order-12",
};

export default function getOrder(attrs) {
  return clsx(
    values[attrs?.order],
    getOrderSm(attrs?.orderSm),
    getOrderMd(attrs?.orderMd),
    getOrderLg(attrs?.orderLg),
    getOrderXl(attrs?.orderXl)
  );
}
