const options = {
  1: "row-span-1",
  2: "row-span-2",
  3: "row-span-3",
  4: "row-span-4",
};

export default function getGridRowSpan(span) {
  return options[span];
}
