const COLOR_MAP = {
  slate: "colors.slate.100",
  gray: "colors.gray.100",
  zinc: "colors.zinc.100",
  neutral: "colors.neutral.100",
  stone: "colors.stone.100",
  red: "colors.red.100",
  orange: "colors.orange.100",
  amber: "colors.amber.100",
  yellow: "colors.yellow.100",
  lime: "colors.lime.100",
  green: "colors.green.100",
  emerald: "colors.emerald.100",
  teal: "colors.teal.100",
  cyan: "colors.cyan.100",
  sky: "colors.sky.100",
  blue: "colors.blue.100",
  indigo: "colors.indigo.100",
  violet: "colors.violet.100",
  purple: "colors.purple.100",
  fuchsia: "colors.fuchsia.100",
  pink: "colors.pink.100",
  rose: "colors.rose.100",
};

export default function getColorParams100(theme) {
  const { name = "teal" } = theme || {};
  return COLOR_MAP[name];
}


// colors.indigo.100