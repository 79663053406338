export default function (customer) {
  if (!customer) return null;

  const { name, index, email, tels, birthday, address, corporate, orderDate } =
    (typeof customer?.data === "function" && customer.data()) || customer || {};

  return {
    id: customer.id,
    name: `${name || ""}`.trim(),
    ...(index && { index }),
    ...(email && { email }),
    ...(!orderDate && { firstTime: true }),
    ...(Array.isArray(tels) && { tels }),
    ...(address && { address }),
    ...(corporate && { corporate }),
    ...(birthday && { birthday }),
  };
}
