"use client";

import { useClientType, useUserRealmId } from "@easybiz/shell";
import { formatPaymentMethod } from "@easybiz/utils";
import { collection, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export default function useOfflinePaymentMethodQuery(isRefund) {
  const realmId = useUserRealmId();
  const client = useClientType();
  const [paymentMethods, setPaymentMethods] = useState(null);

  useEffect(() => {
    setPaymentMethods(null);
    if (realmId) {
      let q = query(
        collection(getFirestore(), `realms/${realmId}/payment_methods`),
        where("automatic", "==", false) // TODO: remove this after 3.0 release
      );

      if (client) {
        q = query(q, where("enabledClients", "array-contains", client));
      }

      if (isRefund) {
        q = query(q, where("refundable", "==", true));
      }

      return onSnapshot(q, (result) => {
        setPaymentMethods(
          result.docs
            .sort((a, b) => {
              return a.get("sortIndex") - b.get("sortIndex");
            })
            .map((doc) => formatPaymentMethod(doc))
        );
      });
    }
  }, [realmId, client, isRefund]);

  return paymentMethods;
}
