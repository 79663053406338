const values = {
  first: "xl:order-first",
  last: "xl:order-last",
  none: "xl:order-none",
  1: "xl:order-1",
  2: "xl:order-2",
  3: "xl:order-3",
  4: "xl:order-4",
  5: "xl:order-5",
  6: "xl:order-6",
  7: "xl:order-7",
  8: "xl:order-8",
  9: "xl:order-9",
  10: "xl:order-10",
  11: "xl:order-11",
  12: "xl:order-12",
};

export default function getOrderXl(order) {
  return values[order];
}
