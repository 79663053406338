import { TAILWIND_COLORS } from "./constants/tailwindColors.js";
import { COLOR_BLACK, COLOR_TRANSPARENT, COLOR_WHITE } from "./constants/template.js";
import isDarkTone from "./isDarkTone.js";
import parseColorNameValue from "./parseColorNameValue.js";

export default function getColorHex(colorCode, theme, bgColor) {
  if (`${colorCode}`.startsWith("#")) {
    return colorCode;
  } else {
    const [name, value] = parseColorNameValue(colorCode, theme, isDarkTone(bgColor, theme));

    switch (name) {
      case COLOR_WHITE:
        return "#ffffff";
      case COLOR_BLACK:
        return "#000000";
      case COLOR_TRANSPARENT:
        return "#ffffff00";
      default:
        return TAILWIND_COLORS[name]?.[value];
    }
  }
}
