import clsx from "clsx";
import getBorderBottom from "./getBorderBottom";
import getBorderBottomLg from "./getBorderBottomLg";
import getBorderBottomSm from "./getBorderBottomSm";
import getBorderLeft from "./getBorderLeft";
import getBorderLeftLg from "./getBorderLeftLg";
import getBorderLeftSm from "./getBorderLeftSm";
import getBorderRight from "./getBorderRight";
import getBorderRightLg from "./getBorderRightLg";
import getBorderRightSm from "./getBorderRightSm";
import getBorderTop from "./getBorderTop";
import getBorderTopLg from "./getBorderTopLg";
import getBorderTopSm from "./getBorderTopSm";
import { getBorderColor } from "@easybiz/utils";

export default function getBorders(attrs, theme) {
  const {
    borderT,
    borderTSm,
    borderTLg,
    borderR,
    borderRSm,
    borderRLg,
    borderB,
    borderBSm,
    borderBLg,
    borderL,
    borderLSm,
    borderLLg,
    borderWidth,
    borderWidthSm,
    borderWidthLg,
    borderColor,
    borderOpacity,
  } = attrs || {};

  if (
    borderT ||
    borderT === 0 ||
    borderR ||
    borderR === 0 ||
    borderB ||
    borderB === 0 ||
    borderL ||
    borderL === 0 ||
    borderTSm ||
    borderTSm === 0 ||
    borderRSm ||
    borderRSm === 0 ||
    borderBSm ||
    borderBSm === 0 ||
    borderLSm ||
    borderLSm === 0 ||
    borderTLg ||
    borderTLg === 0 ||
    borderRLg ||
    borderRLg === 0 ||
    borderBLg ||
    borderBLg === 0 ||
    borderLLg ||
    borderLLg === 0
  ) {
    return clsx(
      borderT || borderT === 0 ? getBorderTop(Number.isInteger(borderT) ? borderT : borderWidth ?? 1) : "",
      borderR || borderR === 0 ? getBorderRight(Number.isInteger(borderR) ? borderR : borderWidth ?? 1) : "",
      borderB || borderB === 0 ? getBorderBottom(Number.isInteger(borderB) ? borderB : borderWidth ?? 1) : "",
      borderL || borderL === 0 ? getBorderLeft(Number.isInteger(borderL) ? borderL : borderWidth ?? 1) : "",
      borderTSm || borderTSm === 0 ? getBorderTopSm(Number.isInteger(borderTSm) ? borderTSm : borderWidthSm ?? 1) : "",
      borderRSm || borderRSm === 0
        ? getBorderRightSm(Number.isInteger(borderRSm) ? borderRSm : borderWidthSm ?? 1)
        : "",
      borderBSm || borderBSm === 0
        ? getBorderBottomSm(Number.isInteger(borderBSm) ? borderBSm : borderWidthSm ?? 1)
        : "",
      borderLSm || borderLSm === 0 ? getBorderLeftSm(Number.isInteger(borderLSm) ? borderLSm : borderWidthSm ?? 1) : "",
      borderTLg || borderTLg === 0 ? getBorderTopLg(Number.isInteger(borderTLg) ? borderTLg : borderWidthLg ?? 1) : "",
      borderRLg || borderRLg === 0
        ? getBorderRightLg(Number.isInteger(borderRLg) ? borderRLg : borderWidthLg ?? 1)
        : "",
      borderBLg || borderBLg === 0
        ? getBorderBottomLg(Number.isInteger(borderBLg) ? borderBLg : borderWidthLg ?? 1)
        : "",
      borderLLg || borderLLg === 0 ? getBorderLeftLg(Number.isInteger(borderLLg) ? borderLLg : borderWidthLg ?? 1) : "",
      getBorderColor(borderColor, theme, borderOpacity) || "border-gray-200"
    );
  }
}
