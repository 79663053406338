"use client";

import { useUser } from "@easybiz/shell";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

export default function useSplashScreenStatus() {
  const [start] = useState(Date.now());
  const [current, setCurrent] = useState(Date.now());
  const user = useUser();
  const spanSeconds = Math.floor((current - start) / 1000);

  useEffect(() => {
    const handler = setInterval(() => {
      setCurrent(Date.now());
    }, 5000);

    return () => {
      clearInterval(handler);
    };
  }, []);

  return {
    status: user ? (
      <FormattedMessage id="Registering device…" defaultMessage="Registering device…" />
    ) : (
      <FormattedMessage id="authenticating…" defaultMessage="Authenticating…" />
    ),
    longerText: spanSeconds >= 10 && (
      <FormattedMessage
        id="splash.screen.long.time.help"
        defaultMessage="Sorry, this is taking longer than usual. Please bear with us."
      />
    ),
    fallbackText: spanSeconds >= 30 && (
      <FormattedMessage
        id="splash.screen.fallback.help"
        defaultMessage="Sorry, we are having trouble connecting. Please check your internet connection."
      />
    ),
  };
}
