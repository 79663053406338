import { EditOutlined } from "@ant-design/icons";
import { useOrderLabelListItems } from "@easybiz/component";
import { PROCEDURE_PLANT_LABELING } from "@easybiz/utils";
import { Button, Drawer, Empty, List } from "antd";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import EditNoteDrawer from "../EditNoteDrawer";
import LabelListItem from "../LabelListItem";
import PhotoTakingPanel from "../PhotoTakingPanel";
import PrintLabelButton from "../PrintLabelButton";

export default ({ placement, order, permissions }) => {
  const [noteLabel, setNoteLabel] = useState();
  const [photoIndex, setPhotoIndex] = useState();
  const [noteAll, setNoteAll] = useState(false);
  const listItems = useOrderLabelListItems(order);

  const renderListItem = useCallback(
    (item) => {
      return (
        <LabelListItem
          order={order}
          item={item}
          onNote={permissions?.canNote ? setNoteLabel : null}
          onPhoto={permissions?.canNote ? setPhotoIndex : null}
        />
      );
    },
    [order]
  );

  return (
    <React.Fragment>
      <div className="flex flex-row flex-space-between">
        <PrintLabelButton type="primary" printingParams={{ labelOrder: order }} />
        <Button onClick={() => setNoteAll(true)} disabled={!permissions?.canNote} icon={<EditOutlined />}>
          <FormattedMessage tagName="span" defaultMessage="Label Note" />
        </Button>
      </div>
      <List
        loading={!order}
        dataSource={listItems}
        pagination={listItems.length > 25 ? { position: "top", pageSize: 25, total: listItems.length } : false}
        renderItem={renderListItem}
        locale={{
          emptyText: <Empty description={<FormattedMessage defaultMessage={"No label generated yet"} />} />,
        }}
      />
      <br />
      <br />
      <br />
      <EditNoteDrawer
        open={Boolean(noteLabel) || noteAll}
        allItems={noteAll}
        noteLabel={noteLabel}
        order={order}
        onCancel={() => {
          setNoteLabel(null);
          setNoteAll(false);
        }}
        placement={placement}
      />
      <Drawer
        title={
          <span>
            <FormattedMessage tagName="span" defaultMessage="Taking photo" /> <b>#{photoIndex}</b>
          </span>
        }
        open={Boolean(photoIndex)}
        onClose={() => setPhotoIndex(null)}
        placement={placement}
        width={470}
      >
        {photoIndex && (
          <PhotoTakingPanel
            procedure={PROCEDURE_PLANT_LABELING}
            order={order}
            sortIndex={photoIndex}
            videoWidth={720}
            permissions={permissions}
          />
        )}
      </Drawer>
    </React.Fragment>
  );
};
