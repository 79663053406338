const options = {
  visible: "xl:overflow-x-visible",
  hidden: "xl:overflow-x-hidden",
  auto: "xl:overflow-x-auto",
  clip: "xl:overflow-x-clip",
};

export default function getOverflowXXl(value) {
  return options[value];
}
