"use client";

import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useUserBusinessCode } from "./UserContext";

const KVStorageContext = createContext();

const KEY_SCANNER = "scanner";
const KEY_PRODUCTION_STORE = "productionStore";
const KEY_AUTO_PRINT_RECEIPT = "auto_receipt";
const KEY_AUTO_PRINT_INTERNAL_RECEIPT = "auto_internal_receipt";
const KEY_AUTO_PRINT_LAEBL = "auto_label";
const KEY_AUTO_PRINT_STICKER = "auto_sticker";
const KEY_JOB_MAP_MODE = "job_map_mode";
const KEY_DEFAULT_DEPARTMENT = "DEFAULT_SERVICE_MERK";
const KEY_COMPLETE_SHOW_LABEL = "complete_show_label";
const KEY_PHOTO_CAPTURE = "photo_capture";
const KEY_SHOW_PHOTO = "show_photo";
const KEY_SEARCH_TABS = "search_tabs";
const KEY_PROXY_UPLOAD = "proxy_upload";
const KEY_CAMERA_SHUTTER = "shutter";
const KEY_CAMERA_FLASH = "flash";
const KEY_YES = "YES";
const KEY_WORKING_PLACE = "working_place";

export function KVStorageProvider({ localStorage, children }) {
  const [localSettings, setLocalSettings] = useState();

  useEffect(() => {
    if (localStorage) {
      Promise.all([
        localStorage.getItem(KEY_SCANNER),
        localStorage.getItem(KEY_PRODUCTION_STORE),
        localStorage.getItem(KEY_WORKING_PLACE),
        localStorage.getItem(KEY_AUTO_PRINT_RECEIPT),
        localStorage.getItem(KEY_AUTO_PRINT_INTERNAL_RECEIPT),
        localStorage.getItem(KEY_AUTO_PRINT_LAEBL),
        localStorage.getItem(KEY_AUTO_PRINT_STICKER),
        localStorage.getItem(KEY_JOB_MAP_MODE),
        localStorage.getItem(KEY_DEFAULT_DEPARTMENT),
        localStorage.getItem(KEY_COMPLETE_SHOW_LABEL),
        localStorage.getItem(KEY_PHOTO_CAPTURE),
        localStorage.getItem(KEY_SHOW_PHOTO),
        localStorage.getItem(KEY_SEARCH_TABS),
        localStorage.getItem(KEY_PROXY_UPLOAD),
        localStorage.getItem(KEY_CAMERA_SHUTTER),
        localStorage.getItem(KEY_CAMERA_FLASH),
      ]).then((reuslts) => {
        let [
          scannerOpen,
          productionStore,
          workingPlace,
          autoCusReceipt,
          autoInternalReceipt,
          autoLabel,
          autoSticker,
          jobMapMode,
          defaultDepartment,
          showLabel,
          photoCapture,
          showPhoto,
          searchTabs,
          proxyUpload,
          shutter,
          flash,
        ] = reuslts;

        if (searchTabs) {
          try {
            searchTabs = JSON.parse(searchTabs);
          } catch (e) {
            // Ignore
          }
        }

        setLocalSettings({
          scannerOpen: scannerOpen !== "disabled",
          workingPlace: workingPlace ? JSON.parse(workingPlace) : null,
          productionStore,
          autoCusReceipt:
            autoCusReceipt && !isNaN(autoCusReceipt) ? parseInt(autoCusReceipt) : autoCusReceipt === KEY_YES ? 1 : 0,
          autoInternalReceipt:
            autoInternalReceipt && !isNaN(autoInternalReceipt)
              ? parseInt(autoInternalReceipt)
              : autoInternalReceipt === KEY_YES
              ? 1
              : 0,
          autoLabel: autoLabel === KEY_YES,
          autoSticker: autoSticker === KEY_YES,
          jobMapMode: jobMapMode === KEY_YES,
          defaultDepartment: defaultDepartment || null,
          showLabel: Boolean(showLabel) ?? false,
          photoCapture: Boolean(photoCapture),
          showPhoto: Boolean(showPhoto) ?? true,
          proxyUpload: Boolean(proxyUpload),
          shutter: (shutter || KEY_YES) === KEY_YES,
          flash,
          ...(Array.isArray(searchTabs) && { searchTabs }),
        });
      });
    }
  }, []);

  const context = useMemo(() => {
    function localSave(key, value) {
      if (localStorage) {
        if (value) {
          localStorage.setItem(key, value);
        } else {
          localStorage.removeItem(key);
        }
      }
    }

    return {
      ...localSettings,
      setScannerOpen: (open) => {
        setLocalSettings((localSettings) => ({ ...localSettings, scannerOpen: Boolean(open) }));
        localSave(KEY_SCANNER, open ? null : "disabled");
      },
      setProductionStore: (store) => {
        setLocalSettings((localSettings) => ({ ...localSettings, productionStore: store }));
        localSave(KEY_PRODUCTION_STORE, store);
      },
      setWorkingPlace: (place) => {
        setLocalSettings((localSettings) => ({ ...localSettings, workingPlace: place }));
        localSave(KEY_WORKING_PLACE, place ? JSON.stringify(place) : null);
      },
      setDefaultDepartment: (department) => {
        setLocalSettings((localSettings) => ({ ...localSettings, defaultDepartment: department }));
        localSave(KEY_DEFAULT_DEPARTMENT, department);
      },
      setAutoCusReceipt: (data) => {
        if (Number.isInteger(data)) {
          setLocalSettings((localSettings) => ({ ...localSettings, autoCusReceipt: data }));
          localSave(KEY_AUTO_PRINT_RECEIPT, data > 0 ? `${data}` : null);
        }
      },
      setAutoInternalReceipt: (data) => {
        if (Number.isInteger(data)) {
          setLocalSettings((localSettings) => ({ ...localSettings, autoInternalReceipt: data }));
          localSave(KEY_AUTO_PRINT_INTERNAL_RECEIPT, data > 0 ? `${data}` : null);
        }
      },
      setAutoLabel: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, autoLabel: Boolean(data) }));
        localSave(KEY_AUTO_PRINT_LAEBL, data ? KEY_YES : null);
      },
      setAutoSticker: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, autoSticker: Boolean(data) }));
        localSave(KEY_AUTO_PRINT_STICKER, data ? KEY_YES : null);
      },
      setJobMapMode: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, jobMapMode: Boolean(data) }));
        localSave(KEY_JOB_MAP_MODE, data ? KEY_YES : null);
      },
      setShowLabel: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, showLabel: Boolean(data) }));
        localSave(KEY_COMPLETE_SHOW_LABEL, data ? KEY_YES : null);
      },
      setPhotoCapture: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, photoCapture: Boolean(data) }));
        localSave(KEY_PHOTO_CAPTURE, data ? KEY_YES : null);
      },
      setShowPhoto: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, showPhoto: Boolean(data) }));
        localSave(KEY_SHOW_PHOTO, data ? KEY_YES : null);
      },
      setSearchTabs: (data) => {
        if (Array.isArray(data)) {
          setLocalSettings((localSettings) => ({ ...localSettings, searchTabs: data }));
          localSave(KEY_SEARCH_TABS, JSON.stringify(data));
        }
      },
      setProxyUpload: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, proxyUpload: Boolean(data) }));
        localSave(KEY_PROXY_UPLOAD, data ? KEY_YES : null);
      },
      setCameraShutter: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, shutter: Boolean(data) }));
        localSave(KEY_CAMERA_SHUTTER, data ? KEY_YES : "disabled");
      },
      setCameraFlash: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, flash: data }));
        localSave(KEY_CAMERA_FLASH, data);
      },
    };
  }, [localSettings]);

  return <KVStorageContext.Provider value={context}>{children}</KVStorageContext.Provider>;
}

export const useKVStorageScannerOpen = () => useContext(KVStorageContext).scannerOpen;
export const useKVStorageScannerOpenSetter = () => useContext(KVStorageContext).setScannerOpen;

export const useKVStorageWorkingStore = () => {
  const { productionStore } = useContext(KVStorageContext);
  const businessCode = useUserBusinessCode();

  return productionStore || businessCode;
};
export const useKVStorageWorkingStoreSetter = () => useContext(KVStorageContext).setProductionStore;

export const useKVStorageWorkingPlace = () => useContext(KVStorageContext).workingPlace;
export const useKVStorageWorkingPlaceSetter = () => useContext(KVStorageContext).setWorkingPlace;

export const useKVStorageAutoCusReceipt = () => useContext(KVStorageContext).autoCusReceipt;
export const useKVStorageAutoCusReceiptSetter = () => useContext(KVStorageContext).setAutoCusReceipt;

export const useKVStorageAutoInternalReceipt = () => useContext(KVStorageContext).autoInternalReceipt;
export const useKVStorageAutoInternalReceiptSetter = () => useContext(KVStorageContext).setAutoInternalReceipt;

export const useKVStorageAutoLabel = () => useContext(KVStorageContext).autoLabel;
export const useKVStorageAutoLabelSetter = () => useContext(KVStorageContext).setAutoLabel;

export const useKVStorageAutoSticker = () => useContext(KVStorageContext).autoSticker;
export const useKVStorageAutoStickerSetter = () => useContext(KVStorageContext).setAutoSticker;

export const useKVStorageJobMapMode = () => useContext(KVStorageContext).jobMapMode;
export const useKVStorageJobMapModeSetter = () => useContext(KVStorageContext).setJobMapMode;

export const useKVStorageProcessingDepartment = () => useContext(KVStorageContext).defaultDepartment;
export const useKVStorageProcessingDepartmentSetter = () => useContext(KVStorageContext).setDefaultDepartment;

export const useKVStorageShowLabel = () => useContext(KVStorageContext).showLabel;
export const useKVStorageShowLabelSetter = () => useContext(KVStorageContext).setShowLabel;

export const useKVStoragePhotoCapture = () => useContext(KVStorageContext).photoCapture;
export const useKVStoragePhotoCaptureSetter = () => useContext(KVStorageContext).setPhotoCapture;

export const useKVStorageShowPhoto = () => useContext(KVStorageContext).showPhoto;
export const useKVStorageShowPhotoSetter = () => useContext(KVStorageContext).setShowPhoto;

export const useKVStorageSearchTabs = () => useContext(KVStorageContext).searchTabs;
export const useKVStorageSearchTabsSetter = () => useContext(KVStorageContext).setSearchTabs;

export const useKVStorageCameraShutter = () => useContext(KVStorageContext).shutter;
export const useKVStorageCameraShutterSetter = () => useContext(KVStorageContext).setCameraShutter;

export const useKVStorageCameraFlash = () => useContext(KVStorageContext).flash;
export const useKVStorageCameraFlashSetter = () => useContext(KVStorageContext).setCameraFlash;
