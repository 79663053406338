"use client";

import React, { createContext, useContext, useMemo, useRef, useState } from "react";

const AsyncUploadContext = createContext();

export function AsyncUploadProvider({ children, uploadStorage }) {
  const uploadRef = useRef([]);

  const [refresh, setRefresh] = useState();

  const context = useMemo(() => {
    return {
      uploadStorage,
      deletePhoto: (index) => {
        if (Number.isInteger(index)) {
          if (Array.isArray(uploadRef.current) && uploadRef.current[index]) {
            uploadRef.current = [...uploadRef.current.slice(0, index), ...uploadRef.current.slice(index + 1)];
            setRefresh(Date.now());
          }
        } else if (typeof index === "string") {
          const realIndex = Array.isArray(uploadRef.current)
            ? uploadRef.current.findIndex((photo) => photo.id === index)
            : -1;

          if (realIndex >= 0) {
            uploadRef.current = [...uploadRef.current.slice(0, realIndex), ...uploadRef.current.slice(realIndex + 1)];
            setRefresh(Date.now());
          }
        } else {
          // Delete all
          uploadRef.current = [];
          setRefresh(Date.now());
        }
      },
      getUploads: ({ groupKey, orderId, itemIndex }) => {
        return (uploadRef.current || []).filter((data) => {
          if (groupKey) {
            return data.groupKey === groupKey;
          } else if (itemIndex) {
            return data.orderId === orderId && `${data.itemIndex}` === `${itemIndex}`;
          } else {
            return data.orderId === orderId;
          }
        });
      },
      asyncUpload: (uploads) => {
        (Array.isArray(uploads) ? uploads : [uploads]).forEach(({ uri, ...metadata }) => {
          const photoId = uploadStorage(
            uri,
            metadata,
            (photoId) => {
              // Upload completed remove
              const index = (uploadRef.current || []).findIndex((data) => data.id === photoId);

              if (index >= 0) {
                if (metadata.groupKey) {
                  delete uploadRef.current[index].uri;
                } else {
                  uploadRef.current.splice(index, 1);
                }

                setRefresh(Date.now());
              }
            }
          );

          uploadRef.current.unshift({ ...metadata, uri, id: photoId });
        });

        setRefresh(Date.now());
      },
    };
  }, [refresh, uploadStorage]);

  return <AsyncUploadContext.Provider value={context}>{children}</AsyncUploadContext.Provider>;
}

export const useAsyncUpload = () => useContext(AsyncUploadContext).asyncUpload;
export const useAsyncUploadItems = (params) => useContext(AsyncUploadContext).getUploads(params);
export const useAsyncUploadDelete = () => useContext(AsyncUploadContext).deletePhoto;
export const useAsyncUploadSync = () => useContext(AsyncUploadContext).uploadStorage;
