import {
  useClientPlatform,
  useClientRegisterDevice,
  usePrintingBleLabelPrinter,
  usePrintingBleReceiptPrinter,
} from "@easybiz/shell";
import { PLATFORM_WEB, PRINTER_IO_BLUETOOTH, PRINTER_TYPE_LABEL, PRINTER_TYPE_RECEIPT } from "@easybiz/utils";

export default function useConnectedPrinter(printerType) {
  const platform = useClientPlatform();
  const bleReceiptPrinter = usePrintingBleReceiptPrinter();
  const bleLabelPrinter = usePrintingBleLabelPrinter();
  const deviceDoc = useClientRegisterDevice();
  const printer = deviceDoc?.get(`${printerType}Printer`);

  if (platform === PLATFORM_WEB && printer?.io === PRINTER_IO_BLUETOOTH) {
    if (printerType === PRINTER_TYPE_RECEIPT && !bleReceiptPrinter) {
      return null;
    } else if (printerType === PRINTER_TYPE_LABEL && !bleLabelPrinter) {
      return null;
    }
  }

  return printer;
}
