const COLOR_MAP = {
  slate: "shadow-slate-600/10",
  gray: "shadow-gray-600/10",
  zinc: "shadow-zinc-600/10",
  neutral: "shadow-neutral-600/10",
  stone: "shadow-stone-600/10",
  red: "shadow-red-600/10",
  orange: "shadow-orange-600/10",
  amber: "shadow-amber-600/10",
  yellow: "shadow-yellow-600/10",
  lime: "shadow-lime-600/10",
  green: "shadow-green-600/10",
  emerald: "shadow-emerald-600/10",
  teal: "shadow-teal-600/10",
  cyan: "shadow-cyan-600/10",
  sky: "shadow-sky-600/10",
  blue: "shadow-blue-600/10",
  indigo: "shadow-indigo-600/10",
  violet: "shadow-violet-600/10",
  purple: "shadow-purple-600/10",
  fuchsia: "shadow-fuchsia-600/10",
  pink: "shadow-pink-600/10",
  rose: "shadow-rose-600/10",
};

export default function getShadowColor(theme) {
  const { name = "teal" } = theme || {};

  return COLOR_MAP[name];
}
