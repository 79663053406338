import { Drawer } from "antd";
import React, { createContext, useContext, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

const HelpCenterContext = createContext();

export default function HelpCenterProvider({ children }) {
  const [pageId, setPageId] = useState(null);

  const context = useMemo(() => {
    return {
      setPageId,
    };
  }, []);

  return (
    <HelpCenterContext.Provider value={context}>
      {children}
      <Drawer
        title={<FormattedMessage defaultMessage="Help center" />}
        placement="bottom"
        destroyOnClose
        onClose={() => setPageId(null)}
        open={Boolean(pageId)}
        height="100%"
        bodyStyle={{
          padding: 0,
          backgroundColor: "white",
        }}
      >
        {pageId && (
          <iframe
            title="help_center"
            src={`https://help.easybizcloud.com/en/_embed/${pageId}`}
            style={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
            }}
          />
        )}
      </Drawer>
    </HelpCenterContext.Provider>
  );
}

export const useHelpCenterOpen = () => useContext(HelpCenterContext).setPageId;
