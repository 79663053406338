const values = {
  static: "sm:static",
  relative: "sm:relative",
  fixed: "sm:fixed",
  absolute: "sm:absolute",
  sticky: "sm:sticky",
};

export default function getPositionSm(positionSm) {
  return values[positionSm];
}
