"use client";

import {
  EMAIL_STATUS_BOUNCE,
  EMAIL_STATUS_CLICK,
  EMAIL_STATUS_DEFERRED,
  EMAIL_STATUS_DELIVERED,
  EMAIL_STATUS_DROPPED,
  EMAIL_STATUS_ERROR,
  EMAIL_STATUS_OPEN,
  EMAIL_STATUS_SENT,
  EMAIL_STATUS_SPAM,
} from "@easybiz/utils";
import { useIntl } from "react-intl";

function useEmailStatusMessage(email) {
  const intl = useIntl();

  switch (email?.get("status")) {
    case EMAIL_STATUS_SENT:
      return intl.formatMessage(
        {
          id: "email.sent.to.x.checking.delivery.status",
          defaultMessage: "Email sent to {address}, checking delivery status...(click to dismiss)",
        },
        { address: email.get("to") }
      );
    case EMAIL_STATUS_DELIVERED:
    case EMAIL_STATUS_OPEN:
    case EMAIL_STATUS_CLICK:
      return intl.formatMessage(
        {
          id: "email.delivered.to.x",
          defaultMessage: "Email delivered to {address}",
        },
        { address: email.get("to") }
      );
    case EMAIL_STATUS_BOUNCE:
      return intl.formatMessage(
        {
          id: "email.bounced.to.x",
          defaultMessage: "Failed send email to {address}, email bounced",
        },
        { address: email.get("to") }
      );
    case EMAIL_STATUS_DROPPED:
      return intl.formatMessage(
        {
          id: "email.dropped.to.x",
          defaultMessage: "Failed send email to {address}, email dropped by mail server",
        },
        { address: email.get("to") }
      );
    case EMAIL_STATUS_DEFERRED:
      return intl.formatMessage(
        {
          id: "email.deferred.to.x",
          defaultMessage: "Failed send email to {address}, email deferred, system will try again later",
        },
        { address: email.get("to") }
      );
    case EMAIL_STATUS_SPAM:
      return intl.formatMessage(
        {
          id: "email.spam.to.x",
          defaultMessage: "Failed send email to {address}, spam",
        },
        { address: email.get("to") }
      );
    case EMAIL_STATUS_ERROR:
      return intl.formatMessage(
        {
          id: "email.error.to.x",
          defaultMessage: "Failed send email to {address}, unknown error",
        },
        { address: email.get("to") }
      );
    default:
      if (email) {
        return intl.formatMessage({
          id: "sending.email.to.x",
          defaultMessage: "Sending email...",
        });
      }
  }
}

export default useEmailStatusMessage;
