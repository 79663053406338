import { AmountDisplay, UnitDisplay } from "@easybiz/component";
import { Button, message, Popover } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import NumericUpdatePanel from "../NumericUpdatePanel";
import Title from "../Title";
import { usePOSPermission } from "@easybiz/hook";
import { PERMISSION_LEVEL_FULL, PERMISSION_PRICE_OVERWRITE } from "@easybiz/constants";

export default ({ unit, unitPrice, onChange }) => {
  const [open, setVisible] = useState(false);
  const intl = useIntl();
  const { level } = usePOSPermission(PERMISSION_PRICE_OVERWRITE);
  const disabled = (unitPrice > 0) && (level !== PERMISSION_LEVEL_FULL);

  return (
    <Popover
      trigger={"click"}
      destroyTooltipOnHide
      placement={"right"}
      title={
        <Title>
          <FormattedMessage defaultMessage={"Unit Price"} />
        </Title>
      }
      open={disabled ? false : open}
      onOpenChange={setVisible}
      overlayStyle={{ width: 300 }}
      content={
        <NumericUpdatePanel
          open={open}
          isCurrency
          value={unitPrice}
          suffix={
            unit ? (
              <span>
                /<UnitDisplay unit={unit} />
              </span>
            ) : null
          }
          onDone={(unitPrice) => {
            if (typeof unitPrice !== "number") {
              return message.info(
                intl.formatMessage({
                  defaultMessage: "Please enter unit price",
                })
              );
            }

            setVisible(false);
            onChange(unitPrice);
          }}
        />
      }
    >
      <Button disabled={disabled}>
        <b>
          <AmountDisplay value={unitPrice} />
        </b>
      </Button>
    </Popover>
  );
};
