export default function getMarginRight(value) {
  switch (value) {
    case -44:
      return "-mr-44";
    case -40:
      return "-mr-40";
    case -36:
      return "-mr-36";
    case -32:
      return "-mr-32";
    case -28:
      return "-mr-28";
    case -24:
      return "-mr-24";
    case -20:
      return "-mr-20";
    case -16:
      return "-mr-16";
    case -12:
      return "-mr-12";
    case -8:
      return "-mr-8";
    case -4:
      return "-mr-4";
    case -3:
      return "-mr-3";
    case -2:
      return "-mr-2";
    case -1:
      return "-mr-1";
    case 0:
      return "mr-0";
    case 1:
      return "mr-1";
    case 2:
      return "mr-2";
    case 3:
      return "mr-3";
    case 4:
      return "mr-4";
    case 5:
      return "mr-5";
    case 6:
      return "mr-6";
    case 7:
      return "mr-7";
    case 8:
      return "mr-8";
    case 9:
      return "mr-9";
    case 10:
      return "mr-10";
    case 12:
      return "mr-12";
    case 14:
      return "mr-14";
    case 16:
      return "mr-16";
    case 20:
      return "mr-20";
    case 24:
      return "mr-24";
    default:
      return "";
  }
}
