import {
  COLOR_50, COLOR_700
} from "@easybiz/utils";

export default function getHoverBgColor(color) {
  switch (color) {
    case COLOR_50:
      return "hover:bg-gray-50";
    case COLOR_700:
      return "hover:bg-gray-700";
  }
}
