// Settings
export const PERMISSION_MANAGE_BILLING = "manage_billing";
export const PERMISSION_MANAGE_SALES_CHANNEL_POS = "sales_channel_pos";
export const PERMISSION_SETTING_PRODUCTS_PRICING = "setting_products_pricing";
export const PERMISSION_SETTING_BOOKINGS = "setting_bookings";
export const PERMISSION_SETTING_TEMPLATES = "setting_templates";
export const PERMISSION_SETTING_OPERATIONS = "setting_operations";
export const PERMISSION_SETTING_BUSINESS_UNITS = "setting_business_units";
export const PERMISSION_SETTING_BUSINESS_PORTFOLIO = "setting_business_portfolio";

// Data access
export const PERMISSION_ACCESS_CUSTOMER_DATABASE = "access_customer_database";
export const PERMISSION_ACCESS_ORDER_DATABASE = "access_order_database";
export const PERMISSION_ACCESS_SALES_AND_REPORTS = "access_sales_and_reports";

// Actions
export const PERMISSION_CANCEL_ORDER = "cancel_order";
export const PERMISSION_CANCEL_PAYMENT = "cancel_payment";
export const PERMISSION_PRICE_OVERWRITE = "price_overwrite";
export const PERMISSION_SALES_RETURN = "sales_return";
export const PERMISSION_APPLY_DISCOUNT = "apply_discount";
export const PERMISSION_OPEN_CASHIER = "open_cashier";
export const PERMISSION_CUSTOMER_DATABASE_ACCESS = "customer_database_access";
export const PERMISSION_ITEM_DISCHARGE = "item_discharge";

export const PERMISSION_LEVEL_FULL = "full";
export const PERMISSION_LEVEL_REQUIRES_APPROVAL = "requires_approval";
export const PERMISSION_LEVEL_PAID_ONLY = "paid_only";
export const PERMISSION_LEVEL_UNPAID_ONLY = "unpaid_only";
export const PERMISSION_LEVEL_TIME_LIMITED = "time_limited";
export const PERMISSION_LEVEL_AMOUNT_LIMITED = "amount_limited";

export const PERMISSION_TIME_SAME_DAY = "same_day";
export const PERMISSION_TIME_WITHIN_X_DAY = "within_x_day";