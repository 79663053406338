const options = {
  5: "bg-sky-100/5",
  10: "bg-sky-100/10",
  25: "bg-sky-100/25",
  50: "bg-sky-100/50",
  75: "bg-sky-100/75",
  90: "bg-sky-100/90",
  100: "bg-sky-100",
};

const from = {
  5: "from-sky-100/5",
  10: "from-sky-100/10",
  25: "from-sky-100/25",
  50: "from-sky-100/50",
  75: "from-sky-100/75",
  90: "from-sky-100/90",
  100: "from-sky-100",
};

export default function getBackgroundSky100(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
