"use client";

import {
  browserName,
  browserVersion,
  isWindows,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from "react-device-detect";

function getDeviceInfo() {
  return {
    deviceName: osName || null,
    os: `${browserName || ""} ${browserVersion || ""}`,
    ...(isWindows && { isWindows }),
    ...(osName && { osName }),
    ...(osVersion && { osVersion }),
    ...(mobileVendor && { vendor: mobileVendor }),
    ...(mobileModel && { model: mobileModel }),
    ...(browserName && { browser: browserName }),
  };
}

export default getDeviceInfo;
