import { ReloadOutlined } from "@ant-design/icons";
import { useFeedbackToast } from "@easybiz/shell";
import { useQRSignInPanel } from "@easybiz/pos";
import { AppUpdateButton } from "@easybiz/web-admin";
import { getDeviceInfo } from "@easybiz/web-component";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { Button, Card, Divider, QRCode, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

function MobileAppLink({ children }) {
  return (
    <a href={process.env.REACT_APP_MOBILE_LINK} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}

export default () => {
  const [signingIn, setSigningIn] = useState(false);
  const { title, subTitle, qrTitle, qrSubTitle, loading, otp, displayOtp, onRetry } = useQRSignInPanel(
    MobileAppLink,
    getDeviceInfo
  );
  const toast = useFeedbackToast();

  useEffect(() => {
    if (otp) {
      return onSnapshot(doc(getFirestore(), `devices/${otp}`), (otp) => {
        if (!otp.exists()) {
          onRetry();
        } else if (otp.get("token")) {
          setSigningIn(true);
          signInWithCustomToken(getAuth(), otp.get("token"))
            .catch((error) => toast.error(error.message))
            .finally(() => setSigningIn(false));
        }
      });
    }
  }, [otp]);

  return (
    <>
      <Typography.Title level={2}>{title}</Typography.Title>
      <Typography>{subTitle}</Typography>
      <br />
      <Card hoverable>
        <Card.Meta
          title={
            signingIn || loading ? (
              <Skeleton paragraph={false} />
            ) : (
              <Typography style={{ fontSize: 30, textAlign: "center" }}>{displayOtp}</Typography>
            )
          }
        />
      </Card>
      <Divider>
        <FormattedMessage defaultMessage={"Or"} />
      </Divider>
      <Card hoverable>
        <div className="flex flex-row flex-align-center">
          <div className={"flex-fill padding-right"}>
            <Typography.Title level={2}>{qrTitle}</Typography.Title>
            <Typography>{qrSubTitle}</Typography>
          </div>
          <QRCode
            size={150}
            value={otp || ``}
            status={loading || signingIn ? "loading" : otp ? "active" : "expired"}
            onRefresh={onRetry}
          />
        </div>
      </Card>
      <br />
      <div className="flex flex-row flex-space-between">
        <AppUpdateButton showVersion type="text" />
        <Button icon={<ReloadOutlined />} type="link" disabled={signingIn || loading} onClick={onRetry}>
          <FormattedMessage tagName={"span"} defaultMessage={"Reload"} />
        </Button>
      </div>
    </>
  );
};
