const options = {
  0: "sm:border-r-0",
  1: "sm:border-r",
  2: "sm:border-r-2",
  4: "sm:border-r-4",
  8: "sm:border-r-8",
};

export default function getBorderRightSm(width) {
  return options[width];
}
