"use client";

import { OWNER_LICENSE_ID } from "@easybiz/constants";
import { createContext, useContext, useMemo, useState } from "react";
import { useRealmContext } from "./RealmContext";
import { useUser } from "./UserContext";

const AccessContext = createContext();

export function AccessProvider({ children }) {
  const user = useUser();
  const realm = useRealmContext();
  const [mockUser, setMockUser] = useState();

  const context = useMemo(() => {
    const onlineLicenses = realm?.onlineLicences;
    const storeLicenses = realm?.storeLicences;
    const plantLicense = realm?.plantLicence;
    const { permissions, limitBusinesses, procedures, limitAgents, storePlants, specialists, licenseId } =
      mockUser || user || {};
    const businessLimited = Array.isArray(limitBusinesses) && limitBusinesses.length > 0;

    return {
      permissions,
      licenseId,
      limitBusinesses,
      limitProcedures: procedures,
      limitAgents,
      storePlants,
      agentId: Array.isArray(specialists) ? user?.uid : null,
      ...(realm && {
        onlineLicenses: businessLimited
          ? (onlineLicenses || []).filter((code) => limitBusinesses.indexOf(code) >= 0)
          : onlineLicenses || [],
      }),
      ...(realm && {
        storeLicenses: businessLimited
          ? (storeLicenses || []).filter((code) => limitBusinesses.indexOf(code) >= 0)
          : storeLicenses || [],
      }),
      plantLicense,
      mockUser,
      setMockUser,
    };
  }, [user, realm, mockUser]);

  return <AccessContext.Provider value={context}>{children}</AccessContext.Provider>;
}

export const useAccess = (...checkingKeys) => {
  const { licenseId, permissions } = useContext(AccessContext);

  if (licenseId === OWNER_LICENSE_ID) {
    return true;
  }

  if (checkingKeys.length === 0) {
    return false;
  } else {
    return !checkingKeys.some((key) => !permissions?.some((p) => p.id === key));
  }
};
export const useAccessMain = () => useContext(AccessContext).licenseId === OWNER_LICENSE_ID;
export const useAccessOnlineIds = () => useContext(AccessContext).onlineLicenses;
export const useAccessStoreIds = () => useContext(AccessContext).storeLicenses;
export const useAccessB2B = () => useContext(AccessContext).plantLicense;
export const useAccessLimitBusinessIds = () => useContext(AccessContext).limitBusinesses;
export const useAccessLimitProcedures = () => useContext(AccessContext).limitProcedures;
export const useAccessLimitAgents = () => useContext(AccessContext).limitAgents;
export const useAccessStorePlants = () => useContext(AccessContext).storePlants;
export const useAccessMockUser = () => useContext(AccessContext).mockUser;
export const useAccessMockUserSetter = () => useContext(AccessContext).setMockUser;
// TODO: IMPROVE
export const useAccessSelfAgentId = () => useContext(AccessContext).agentId;
