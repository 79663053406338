import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast } from "@easybiz/shell";
import {
  ADMIN_ACTION_EVENT_TEMPLATE_SEND,
  isLogisticEvent,
  isOrderEvent,
  isPaymentEvent,
  isValidEmail,
  OPERATION_GROUP_ADMIN,
} from "@easybiz/utils";
import { useIntl } from "react-intl";

function useAdminEventTemplateSend(callback, skipSuccessNotice) {
  const toast = useFeedbackToast();
  const intl = useIntl();

  return useActionCallback(
    ({
      eventType,
      email,
      notification,
      internalNotification,
      orderId,
      customerId,
      transactionId,
      taskId,
      payment,
      collectionPlace,
      creditNote,
      templateName,
      sendTo,
      admin,
    }) => {
      if (isOrderEvent(eventType)) {
        if (!orderId) {
          return intl.formatMessage({ defaultMessage: "Please select test order" });
        }
      } else if (isLogisticEvent(eventType)) {
        if (!taskId) {
          return intl.formatMessage({ defaultMessage: "Please select test job" });
        }
      } else if (isPaymentEvent(eventType)) {
        if (!orderId || !payment) {
          return intl.formatMessage({ defaultMessage: "Please select test payment" });
        }
      } else {
        if (!customerId && !transactionId) {
          return intl.formatMessage({
            defaultMessage: "Please select test customer",
          });
        }
      }

      if (email) {
        if (!sendTo || !isValidEmail(`${sendTo}`.trim())) {
          return intl.formatMessage({
            defaultMessage: "Please enter a valid email address",
          });
        }
      } else if (internalNotification) {
        if (!sendTo?.id) {
          return intl.formatMessage({
            defaultMessage: "Please select customer account",
          });
        }
      }

      return [
        OPERATION_GROUP_ADMIN,
        ADMIN_ACTION_EVENT_TEMPLATE_SEND,
        {
          eventType,
          ...(orderId && { orderId }),
          ...(taskId && { taskId }),
          ...(customerId && { customerId }),
          ...(transactionId && { transactionId }),
          ...(collectionPlace && { collectionPlace }),
          ...(creditNote && { creditNote }),
          ...(payment && { payment }),
          ...(email && { email }),
          ...(notification && { notification }),
          ...(internalNotification && { internalNotification }),
          ...(sendTo && { sendTo }),
          ...(admin && { admin }),
          ...(templateName && { templateName })
        },
      ];
    },
    callback,
    skipSuccessNotice
      ? null
      : (request) =>
          toast.success(
            intl.formatMessage(
              { defaultMessage: "Sent to {recipint}" },
              {
                recipint: typeof request.sendTo === "string" ? request.sendTo : request.sendTo?.name,
              }
            )
          )
  );
}

export default useAdminEventTemplateSend;
