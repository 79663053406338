import { useRealmServices, useUserImageHost } from "@easybiz/shell";
import { Avatar } from "antd";
import React from "react";

export default function ({ serviceId, serviceName }) {
  const imageHost = useUserImageHost();
  const services = useRealmServices();
  const found = services?.find((service) => service.id === serviceId);

  return (
    <Avatar shape="square" src={found?.photoId ? `${imageHost}/${found?.photoId}` : null}>
      {`${found?.title || serviceName || ""}`.slice(0, 2)}
    </Avatar>
  );
}
