const OPTIONS = {
  8: "sm:max-h-8",
  12: "sm:max-h-12",
  16: "sm:max-h-16",
  20: "sm:max-h-20",
  24: "sm:max-h-24",
  28: "sm:max-h-28",
  32: "sm:max-h-32",
  36: "sm:max-h-36",
  40: "sm:max-h-40",
  44: "sm:max-h-44",
  48: "sm:max-h-48",
  52: "sm:max-h-52",
  56: "sm:max-h-56",
  60: "sm:max-h-60",
  64: "sm:max-h-64",
  72: "sm:max-h-72",
  80: "sm:max-h-80",
  96: "sm:max-h-96",
};

export default function getMaxHeightSm(height) {
  return OPTIONS[height];
}
