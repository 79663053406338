const options = {
  5: "bg-teal-200/5",
  10: "bg-teal-200/10",
  25: "bg-teal-200/25",
  50: "bg-teal-200/50",
  75: "bg-teal-200/75",
  90: "bg-teal-200/90",
  100: "bg-teal-200",
};

const from = {
  5: "from-teal-200/5",
  10: "from-teal-200/10",
  25: "from-teal-200/25",
  50: "from-teal-200/50",
  75: "from-teal-200/75",
  90: "from-teal-200/90",
  100: "from-teal-200",
};

export default function getBackgroundTeal200(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
