import { ApiOutlined } from "@ant-design/icons";
import { useFeedbackToast } from "@easybiz/shell";
import { useAdminPrinterRegister } from "@easybiz/api-admin";
import { PRINTER_IO_USB } from "@easybiz/utils";
import { useUsbIO } from "@easybiz/web-component";
import { Button } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

export default function ({ device, commands, printerType }) {
  const [testPrinting, setTestPrinting] = useState(false);
  const { onSubmit, loading } = useAdminPrinterRegister();
  const toast = useFeedbackToast();
  const usbIO = useUsbIO();

  const onSetPrinter = async () => {
    try {
      setTestPrinting(true);

      let error;

      if (commands) {
        // Try continue printing before set printer
        error = await usbIO(device, commands, printerType);
      }

      if (error) {
        toast.error(error.message);
      } else {
        onSubmit({
          printer: device,
          printerType,
          io: PRINTER_IO_USB,
        });
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setTestPrinting(false);
    }
  };

  return (
    <Button icon={<ApiOutlined />} onClick={onSetPrinter} loading={loading}>
      {testPrinting ? (
        <FormattedMessage tagName={"span"} defaultMessage={"Printing"} />
      ) : (
        <FormattedMessage tagName={"span"} defaultMessage={"Connect"} />
      )}
    </Button>
  );
}
