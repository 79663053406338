const options = {
  left: "xl:text-left",
  center: "xl:text-center",
  right: "xl:text-right",
  justify: "xl:text-justify",
};

export default function getTextAlignXl(align) {
  return options[align];
}
