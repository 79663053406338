import dayjs from "dayjs";

export default function formatDate(date, format, offset) {
  const dateObj = dayjs.isDayjs(date) ? date : date ? dayjs(date) : null;

  if (dateObj?.isValid()) {
    if (Number.isInteger(offset)) {
      return dateObj.add(offset, "days").format(format || "DD/MM/YYYY");
    } else {
      return dateObj.format(format || "DD/MM/YYYY");
    }
  }
}
