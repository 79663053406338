const values = {
  center: "lg:justify-self-center",
  end: "lg:justify-self-end",
  start: "lg:justify-self-start",
  stretch: "lg:justify-self-stretch",
  auto: "lg:justify-self-auto",
};

export default function getJustifySelfLg(align) {
  return values[align];
}
