const options = {
  1: "md:w-1",
  2: "md:w-2",
  3: "md:w-3",
  4: "md:w-4",
  5: "md:w-5",
  6: "md:w-6",
  7: "md:w-7",
  8: "md:w-8",
  9: "md:w-9",
  10: "md:w-10",
  11: "md:w-11",
  12: "md:w-12",
  14: "md:w-14",
  16: "md:w-16",
  20: "md:w-20",
  24: "md:w-24",
  28: "md:w-28",
  32: "md:w-32",
  36: "md:w-36",
  40: "md:w-40",
  44: "md:w-44",
  48: "md:w-48",
  52: "md:w-52",
  56: "md:w-56",
  60: "md:w-60",
  64: "md:w-64",
  72: "md:w-72",
  80: "md:w-80",
  96: "md:w-96",
  112: "md:w-[28rem]",
  128: "md:w-[32rem]",
  160: "md:w-[40rem]",
  192: "md:w-[48rem]",
  "1/2": "md:w-1/2",
  "1/3": "md:w-1/3",
  "2/3": "md:w-2/3",
  "1/5": "md:w-1/5",
  "2/5": "md:w-2/5",
  "3/5": "md:w-3/5",
  "4/5": "md:w-4/5",
  px: "md:w-px",
  screen: "md:w-screen",
  grow: "md:grow",
  fill: "md:flex-1",
  full: "md:w-full",
  ratio: "md:w-auto",
  auto: "md:w-auto",
};

export default function getWidthMd(size, parentDisplay) {
  return size === "fill" && !["row", "row-reverse"].includes(parentDisplay) ? "" : options[size];
}
