const options = {
  normal: "lg:justify-normal",
  center: "lg:justify-center",
  end: "lg:justify-end",
  start: "lg:justify-start",
  between: "lg:justify-between",
  around: "lg:justify-around",
  evenly: "lg:justify-evenly",
};

export default function getJustifyItemsLg(justify) {
  return options[justify];
}
