import getComponentProps from "./getComponentProps.js";

export default function getChildProps(props, childKey) {
  // Merge shared component
  const sharedId = props.child?.[childKey]?.attrs?.sharedId;
  const sharedComponent = sharedId && props.context?.sharedComponentMap?.[sharedId];

  if (sharedComponent || props.child?.[childKey]) {
    return getComponentProps(
      sharedComponent && props.child?.[childKey]
        ? {
            ...props.child?.[childKey],
            ...sharedComponent,
            attrs: {
              ...props.child?.[childKey]?.attrs,
              ...sharedComponent.attrs,
            },
            content: {
              ...props.child?.[childKey]?.content,
              ...sharedComponent.content,
            },
          }
        : sharedComponent || props.child?.[childKey],
      props.intl,
      props.context,
      props
    );
  }
}
