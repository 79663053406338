"use client";

import {
  TRANSACTION_TYPE_BONUS,
  TRANSACTION_TYPE_CASH_BACK,
  TRANSACTION_TYPE_COMPENSATION,
  TRANSACTION_TYPE_GIFT,
  TRANSACTION_TYPE_REDEEM,
  TRANSACTION_TYPE_REFUND,
  TRANSACTION_TYPE_SPEND,
  TRANSACTION_TYPE_TOP_UP,
  TRANSACTION_TYPE_VOID,
} from "@easybiz/utils";
import { FormattedMessage } from "react-intl";

function TransactionTypeDisplay({ type }) {
  switch (type) {
    case TRANSACTION_TYPE_TOP_UP:
      return <FormattedMessage id="top.up" defaultMessage="Top Up" />;
    case TRANSACTION_TYPE_GIFT:
      return <FormattedMessage id="gift" defaultMessage="Gift" />;
    case TRANSACTION_TYPE_COMPENSATION:
      return <FormattedMessage id="compensation" defaultMessage="Compensation" />;
    case TRANSACTION_TYPE_SPEND:
      return <FormattedMessage id="payment" defaultMessage="Payment" />;
    case TRANSACTION_TYPE_VOID:
      return <FormattedMessage id="void" defaultMessage="Void" />;
    case TRANSACTION_TYPE_CASH_BACK:
      return <FormattedMessage id="cash.back" defaultMessage="Cash back" />;
    case TRANSACTION_TYPE_BONUS:
      return <FormattedMessage id="bonus" defaultMessage="Bonus" />;
    case TRANSACTION_TYPE_REFUND:
      return <FormattedMessage id="refund" defaultMessage="Refund" />;
    case TRANSACTION_TYPE_REDEEM:
      return <FormattedMessage id="redeem" defaultMessage="Redeem" />;
    default:
      return type || null;
  }
}

export default TransactionTypeDisplay;
