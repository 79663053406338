import formatLabeLines from "./formatLabeLines.js";

export default function ({ title, content, qrcode, appendix, homeDelivery }, settings) {
  const { offsetVertical, stickerGap, mmGap, density } = settings || {};

  const titleLines = [].concat.apply(
    [],
    (title || "")
      .split("\n")
      .filter((seg) => seg)
      .map((seg) => {
        return formatLabeLines(seg, 9);
      })
  );
  const contentLines = [].concat.apply(
    [],
    (content || "")
      .split("\n")
      .filter((seg) => seg)
      .map((seg) => {
        return formatLabeLines(seg, 12);
      })
  );
  const appendixLines = [].concat.apply(
    [],
    (appendix || "")
      .split("\n")
      .filter((seg) => seg)
      .map((seg) => {
        return formatLabeLines(seg, 10);
      })
  );

  return `
    SIZE 60 mm, 40mm
    GAP ${Number.isInteger(mmGap ?? stickerGap) ? mmGap ?? stickerGap : 2} mm, 0 mm
    SPEED 6
    DENSITY ${density || 15}
    SHIFT ${typeof offsetVertical === "number" ? offsetVertical : 0}
    CLS
    ${titleLines.map((line, index) => `TEXT 10,${40 + index * 60},"5",0,1,1,"${line}"`).join("\r\n")}
    ${homeDelivery ? `REVERSE 0,32,280,68` : ""}
    ${contentLines
      .map((word, index) => `TEXT 10,${50 + titleLines.length * 60 + index * 40},"4",0,1,1,"${word}"`)
      .join("\r\n")}
    QRCODE 300,30,L,5,M,0,1,1,"${qrcode}"
    ${appendixLines
      .map((word, index) => `TEXT ${350 - `${word}`.length * 10},${180 + index * 60},"5",0,1,1,"${word}"`)
      .join("\r\n")}
    PRINT 1,1
    CLS
  `;
}
