const options = {
  0: "lg:border-r-0",
  1: "lg:border-r",
  2: "lg:border-r-2",
  4: "lg:border-r-4",
  8: "lg:border-r-8",
};

export default function getBorderRightLg(width) {
  return options[width];
}
