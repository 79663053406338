const SUPPORT_CURRENCIES = {
  us_bank_account: ["USD"],
  bacs_debit: ["GBP"],
  au_becs_debit: ["AUD"],
  acss_debit: ["CAD", "USD"],
  sepa_debit: ["EUR"],
  bancontact: ["EUR"],
  customer_balance: ["USD", "EUR", "GBP", "JPY", "MXN"],
  blik: ["PLN"],
  eps: ["EUR"],
  fpx: ["MYR"],
  giropay: ["EUR"],
  ideal: ["EUR"],
  p24: ["EUR", "PLN"],
  paypal: ["AUD", "CAD", "CHF", "CZK", "DKK", "EUR", "GBP", "HKD", "NOK", "NZD", "PLN", "SEK", "SGD", "USD"],
  sofort: ["EUR"],
  affirm: ["USD", "CAD"],
  afterpay_clearpay: ["AUD", "CAD", "CHF", "CZK", "DKK", "EUR", "GBP", "NOK", "NZD", "PLN", "SEK", "USD"],
  klarna: ["AUD", "CAD", "CHF", "CZK", "DKK", "EUR", "GBP", "NOK", "NZD", "PLN", "SEK", "USD"],
  card: true,
  // link: true,
  paynow: ["SGD"],
  promptpay: ["THB"],
  boleto: ["BRL"],
  konbini: ["JPY"],
  oxxo: ["MXN"],
  alipay: ["AUD", "CAD", "CNY", "EUR", "GBP", "HKD", "JPY", "MYR", "NZD", "SGD", "USD"],
  cashapp: ["USD"],
  grabpay: ["SGD", "MYR"],
  mobilepay: ["DKK", "EUR", "NOK", "SEK"],
  wechat_pay: ["AUD", "CAD", "CNY", "EUR", "GBP", "HKD", "JPY", "SGD", "USD", "DKK", "NOK", "SEK", "CHF"],
  zip: ["AUD", "NZD"],
};

export default function isPaymentTypeSupported(type, currency) {
  return SUPPORT_CURRENCIES[type] === true || SUPPORT_CURRENCIES[type]?.includes(currency);
}
