export const PLATFORM_STATE_REALM_INDEX = "customer_id";
export const PLATFORM_TEMPLATE_REALM_ID = "template";

export const PLATFORM_PRODUCT_ID_POS = "POS";
export const PLATFORM_PRODUCT_ID_ONLINE = "ONLINE";
export const PLATFORM_PRODUCT_ID_APP = "APP";
export const PLATFORM_PRODUCT_ID_WORKFLOW = "WORKFLOW";
export const PLATFORM_PRODUCT_ID_B2B = "FACTORY";

export const PLATFORM_FREE_TRIAL_PLAN = "free_trial";

export const STATUS_INFO = "info";
export const STATUS_SUCCESS = "success";
export const STATUS_WARNING = "warning";
export const STATUS_ERROR = "error";

export const SALES_CHANNEL_TYPE_STORE = "shop";
export const SALES_CHANNEL_TYPE_ONLINE = "online";
export const SALES_CHANNEL_TYPE_AGENT = "factory";

export const CLIENT_POS = "pos";
export const CLIENT_MOBILE = "mobile";
export const CLIENT_CONSOLE = "console";
export const CLIENT_CUSTOMER_APP = "customer_app";
export const CLIENT_CUSTOMER_WEBSITE = "customer_website";
export const CLIENT_PLATFORM_DASHBOARD = "dashboard";
export const CLIENT_WHATSAPP = "whatsapp";
export const CLIENT_EMAIL = "email";

// Special Payment ID
export const PAYMENT_ID_CASH = "CASH";

export const LANGUAGE_EN = "en";
export const LANGUAGE_ZH = "zh";

export const SYSTEM_OPERATOR = {
  id: "system",
  name: "System",
};
