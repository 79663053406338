export default function formatMMPaddings(attrs, dotsPerMM) {
  const { mmPl, mmPr, mmPt, mmPb, rotation } = attrs;

  let paddingLeft = parseInt((typeof mmPl === "number" ? mmPl : 0) * (dotsPerMM || 8));
  let paddingRight = parseInt((typeof mmPr === "number" ? mmPr : 0) * (dotsPerMM || 8));
  let paddingTop = parseInt((typeof mmPt === "number" ? mmPt : 0) * (dotsPerMM || 8));
  let paddingBottom = parseInt((typeof mmPb === "number" ? mmPb : 0) * (dotsPerMM || 8));

  if (rotation === 90) {
    const temp = paddingBottom;
    paddingBottom = paddingLeft;
    paddingLeft = paddingTop;
    paddingTop = paddingRight;
    paddingRight = temp;
  }

  return {
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
  };
}
