import { Dialog } from "@easybiz/web-admin";
import { usePOSPointsRedeem } from "@easybiz/api-pos";
import { translate } from "@easybiz/utils";
import { Statistic } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ customer, quantity, recycleItem, open, onClose, onSucceed }) => {
  const { onSubmit, loading } = usePOSPointsRedeem((succeed, resposne) => {
    if (succeed) {
      onSucceed(resposne);
    }
  });

  return (
    <Dialog
      title={
        <FormattedMessage
          tagName="span"
          defaultMessage="Received {count} {itemName} from customer {customerName}?"
          values={{
            count: quantity,
            itemName: translate(recycleItem?.get("title")),
            customerName: customer.name,
          }}
        />
      }
      open={open}
      onCancel={onClose}
      confirmLoading={loading}
      onOk={() =>
        onSubmit({
          count: quantity,
          redeemItemId: recycleItem.id,
          customerId: customer.id,
        })
      }
    >
      {recycleItem && typeof recycleItem.get("point") === "number" && typeof returnCount === "number" && (
        <Statistic
          prefix={
            <span>
              {quantity} {translate(recycleItem.get("title"))} =
            </span>
          }
          value={recycleItem.get("point") * quantity}
          suffix={
            <span>
              <FormattedMessage tagName="span" defaultMessage="Points" />
            </span>
          }
        />
      )}
    </Dialog>
  );
};
