export default function getDisplaySm(display) {
  switch (display) {
    case "block":
      return "sm:block";
    case "inline-block":
      return "sm:inline-block";
    case "grid":
      return "sm:grid";
    case "inline":
      return "sm:inline-flex";
    case "row":
      return "sm:flex sm:flex-row";
    case "col":
      return "sm:flex sm:flex-col";
    case "row-reverse":
      return "sm:flex sm:flex-row-reverse";
    case "col-reverse":
      return "sm:flex sm:flex-col-reverse";
    case "none":
      return "sm:flex-none";
    case "contents":
      return "sm:contents";
    case "hidden":
      return "sm:hidden";
    default:
      return "";
  }
}
