import { SaveOutlined } from "@ant-design/icons";
import { useOrderLineItemEdit } from "@easybiz/api-order";
import { AmountDisplay } from "@easybiz/component";
import { useCheckoutEditItemSetter, useCheckoutItems, useCheckoutLineItemsSteps, useCheckoutPricing, useRealmToDisplayName, useUserBusinessCode } from "@easybiz/shell";
import { checkMissingStep } from "@easybiz/utils";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import PopConfirm from "../PopConfirm";

export default function ({ order, disabled, onSubmitted, confirmed }) {
  const businessCode = useUserBusinessCode();
  const lineItemsSteps = useCheckoutLineItemsSteps();
  const newPricing = useCheckoutPricing();
  const items = useCheckoutItems();
  const setEditItem = useCheckoutEditItemSetter();
  const toDisplayName = useRealmToDisplayName();
  const { onSubmit, loading } = useOrderLineItemEdit((success, ...args) => {
    if (success) {
      onSubmitted(...args);
    }
  });

  const onConfirm = () => {
    const missing = checkMissingStep(order?.get("service.sector"), lineItemsSteps, items);

    if (missing) {
      return setEditItem(missing);
    }

    onSubmit(
      {
        confirmed,
        orderId: order.id,
        newPricing,
        ...(businessCode && {
          supplier: { id: businessCode, name: toDisplayName(businessCode) },
        }),
      },
      true
    );
  };

  const button = (
    <Button
      icon={confirmed ? null : <SaveOutlined />}
      disabled={disabled || loading}
      type={confirmed ? "primary" : "default"}
      block
      loading={loading}
      onClick={confirmed ? null : onConfirm}
    >
      {confirmed ? (
        <FormattedMessage tagName={"span"} defaultMessage={"Save & Confirm"} />
      ) : (
        <FormattedMessage tagName="span" defaultMessage="Save" />
      )}
    </Button>
  );

  return confirmed ? (
    <PopConfirm
      title={
        <FormattedMessage
          defaultMessage={"Confirm order total {amount}?"}
          values={{
            amount: <AmountDisplay value={newPricing?.netSales} />,
          }}
        />
      }
      loading={loading}
      onConfirm={onConfirm}
    >
      {button}
    </PopConfirm>
  ) : (
    button
  );
}
