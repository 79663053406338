export default function calculateQRCodeWidth(content, errorCorrectionLevel, cellWidth) {
  // QR Code capacity table for Byte mode (as TSPL uses Byte mode for most data)
  const capacityTable = {
    1: { L: 17, M: 14, Q: 11, H: 7 },
    2: { L: 32, M: 26, Q: 20, H: 14 },
    3: { L: 53, M: 42, Q: 32, H: 24 },
    4: { L: 78, M: 62, Q: 46, H: 34 },
    5: { L: 106, M: 84, Q: 60, H: 44 },
    6: { L: 134, M: 106, Q: 74, H: 58 },
    7: { L: 154, M: 122, Q: 86, H: 64 },
    8: { L: 192, M: 152, Q: 108, H: 84 },
    9: { L: 230, M: 180, Q: 130, H: 98 },
    10: { L: 271, M: 213, Q: 151, H: 119 },
    // ... You can extend this table up to version 40 if needed
  };

  // Validate error correction level
  const errorLevels = ["L", "M", "Q", "H"];
  errorCorrectionLevel = errorCorrectionLevel.toUpperCase();
  if (!errorLevels.includes(errorCorrectionLevel)) {
    throw new Error("Invalid error correction level. Choose from 'L', 'M', 'Q', 'H'.");
  }

  // Calculate the length of the content in bytes (UTF-8 encoding)
  const encoder = new TextEncoder();
  const contentBytes = encoder.encode(content);
  const contentLength = contentBytes.length;

  // Determine the minimum version needed
  let version = null;
  for (let v = 1; v <= 40; v++) {
    if (capacityTable[v] && capacityTable[v][errorCorrectionLevel] >= contentLength) {
      version = v;
      break;
    }
  }

  if (version === null) {
    throw new Error("The content is too long to encode in a QR code.");
  }

  // Calculate the module size (number of modules per side)
  const moduleSize = 17 + 4 * version;

  // Calculate the total width (and height, since it's square)
  const totalWidth = moduleSize * cellWidth;

  return totalWidth;
}
