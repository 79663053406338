import {
  LINK_TYPE_APP_STORE,
  LINK_TYPE_EMAIL,
  LINK_TYPE_GOOGLE_PLAY,
  LINK_TYPE_HTTP,
  LINK_TYPE_LOCATION,
  LINK_TYPE_TEL,
  LINK_TYPE_WHATSAPP,
} from "./constants/template.js";

export default function getGeneralHref(link) {
  const { type, href, text, tel, email, subject, appId } = link || {};

  switch (type) {
    case LINK_TYPE_HTTP:
      return href;
    case LINK_TYPE_LOCATION:
      return href;
    case LINK_TYPE_GOOGLE_PLAY:
      return `https://play.google.com/store/apps/details?id=${appId || ""}`;
    case LINK_TYPE_APP_STORE:
      return `https://apps.apple.com/app/id${appId || ""}`;
    case LINK_TYPE_WHATSAPP:
      return `https://wa.me/${tel?.dialCode}${tel?.number}?text=${encodeURIComponent(text || "")}`;
    case LINK_TYPE_EMAIL:
      return `mailto:${email}${subject ? `?subject=${encodeURIComponent(subject)}` : ""}`;
    case LINK_TYPE_TEL:
      return tel?.uri || "#";
    default:
      return href;
  }
}
