const COLOR_500 = {
  teal: "focus:ring-teal-500",
  red: "focus:ring-red-500",
  yellow: "focus:ring-yellow-500",
  green: "focus:ring-green-500",
  blue: "focus:ring-blue-500",
  indigo: "focus:ring-indigo-500",
  purple: "focus:ring-purple-500",
  pink: "focus:ring-pink-500",
  fuchsia: "focus:ring-fuchsia-500",
  gray: "focus:ring-gray-500",
  orange: "focus:ring-orange-500",
  rose: "focus:ring-rose-500",
  lime: "focus:ring-lime-500",
  amber: "focus:ring-amber-500",
  cyan: "focus:ring-cyan-500",
  sky: "focus:ring-sky-500",
  violet: "focus:ring-violet-500",
  emerald: "focus:ring-emerald-500",
  lightBlue: "focus:ring-lightBlue-500",
};

const COLOR_600 = {
  teal: "focus:ring-teal-600",
  red: "focus:ring-red-600",
  yellow: "focus:ring-yellow-600",
  green: "focus:ring-green-600",
  blue: "focus:ring-blue-600",
  indigo: "focus:ring-indigo-600",
  purple: "focus:ring-purple-600",
  pink: "focus:ring-pink-600",
  fuchsia: "focus:ring-fuchsia-600",
  gray: "focus:ring-gray-600",
  orange: "focus:ring-orange-600",
  rose: "focus:ring-rose-600",
  lime: "focus:ring-lime-600",
  amber: "focus:ring-amber-600",
  cyan: "focus:ring-cyan-600",
  sky: "focus:ring-sky-600",
  violet: "focus:ring-violet-600",
  emerald: "focus:ring-emerald-600",
  lightBlue: "focus:ring-lightBlue-600",
};

const COLOR_700 = {
  teal: "focus:ring-teal-700",
  red: "focus:ring-red-700",
  yellow: "focus:ring-yellow-700",
  green: "focus:ring-green-700",
  blue: "focus:ring-blue-700",
  indigo: "focus:ring-indigo-700",
  purple: "focus:ring-purple-700",
  pink: "focus:ring-pink-700",
  fuchsia: "focus:ring-fuchsia-700",
  gray: "focus:ring-gray-700",
  orange: "focus:ring-orange-700",
  rose: "focus:ring-rose-700",
  lime: "focus:ring-lime-700",
  amber: "focus:ring-amber-700",
  cyan: "focus:ring-cyan-700",
  sky: "focus:ring-sky-700",
  violet: "focus:ring-violet-700",
  emerald: "focus:ring-emerald-700",
  lightBlue: "focus:ring-lightBlue-700",
};

const COLOR_800 = {
  teal: "focus:ring-teal-800",
  red: "focus:ring-red-800",
  yellow: "focus:ring-yellow-800",
  green: "focus:ring-green-800",
  blue: "focus:ring-blue-800",
  indigo: "focus:ring-indigo-800",
  purple: "focus:ring-purple-800",
  pink: "focus:ring-pink-800",
  fuchsia: "focus:ring-fuchsia-800",
  gray: "focus:ring-gray-800",
  orange: "focus:ring-orange-800",
  rose: "focus:ring-rose-800",
  lime: "focus:ring-lime-800",
  amber: "focus:ring-amber-800",
  cyan: "focus:ring-cyan-800",
  sky: "focus:ring-sky-800",
  violet: "focus:ring-violet-800",
  emerald: "focus:ring-emerald-800",
  lightBlue: "focus:ring-lightBlue-800",
};

function getFocusRingColor(theme) {
  const { name = "teal", value } = theme || {};

  switch (value) {
    case 500:
      return COLOR_500[name];
    case 700:
      return COLOR_700[name];
    case 800:
      return COLOR_800[name];
    default:
      return COLOR_600[name];
  }
}

export default getFocusRingColor;
