import { getBackgroundColor, getColorParams100, getRingColor, getShadowColor } from "@easybiz/utils";
import clsx from "clsx";

export default function BackgroundShadowSlashMiddle({ bgColor, theme }) {
  return (
    <>
      <div
        className={`absolute inset-0 pointer-events-none bg-[radial-gradient(45rem_50rem_at_top,theme(${getColorParams100(
          theme
        )}),white)] opacity-20`}
      />
      <div
        className={clsx(
          "absolute inset-y-0 right-1/2 pointer-events-none mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl ring-1 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center",
          getBackgroundColor(bgColor, theme),
          getRingColor({ ...theme, value: 50 }),
          getShadowColor(theme)
        )}
      />
    </>
  );
}
