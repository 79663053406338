import { DeleteOutlined } from "@ant-design/icons";
import { useAdminPhotoDelete } from "@easybiz/api-admin";
import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ orderId, photoId, onDeleted }) => {
  const { loading, onSubmit } = useAdminPhotoDelete((succeed) => {
    if (succeed) {
      onDeleted && onDeleted(photoId);
    }
  });
  

  return (
    <Button
      type="primary"
      
      loading={loading}
      danger
      icon={<DeleteOutlined />}
      onClick={() => {
        onSubmit({
          orderId,
          photoId,
        });
      }}
    >
      <FormattedMessage tagName="span" defaultMessage="Delete photo" />
    </Button>
  );
};
