const values = {
  0: "pr-0",
  0.5: "pr-0.5",
  1: "pr-1",
  1.5: "pr-1.5",
  2: "pr-2",
  2.5: "pr-2.5",
  3: "pr-3",
  3.5: "pr-3.5",
  4: "pr-4",
  5: "pr-5",
  6: "pr-6",
  7: "pr-7",
  8: "pr-8",
  9: "pr-9",
  10: "pr-10",
  12: "pr-12",
  14: "pr-14",
  16: "pr-16",
  20: "pr-20",
  24: "pr-24",
  28: "pr-28",
  32: "pr-32",
  36: "pr-36",
  40: "pr-40",
  44: "pr-44",
  48: "pr-48",
  52: "pr-52",
  56: "pr-56",
  60: "pr-60",
  64: "pr-64",
  72: "pr-72",
  80: "pr-80",
  96: "pr-96",
};

export default function getPaddingRight(value) {
  return values[value];
}
