import { CashDrawerMonitor, langZh as componentZh } from "@easybiz/component";
import { langZh as posZh } from "@easybiz/pos";
import { CashDrawerProvider } from "@easybiz/shell";
import { CLIENT_POS } from "@easybiz/utils";
import {
  langZh as antdZh,
  EmailStatusListener,
  GlobalImageGallery,
  PrinterPairingDialog,
  SystemNoticeDialog,
  useWebLocale,
} from "@easybiz/web-admin";
import { ErrorBoundary, langZh as webComponentZh, WebWorkflowApp } from "@easybiz/web-component";
import { langZh as firebaseZh } from "@easybiz/web-firebase";
import { ConfigProvider, message, notification } from "antd";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import React from "react";
import packageJSON from "../package.json";
import HelpCenterProvider from "./HelpCenterProvider";
import POSWebApplication from "./POSWebApplication";
import appZh from "./lang/zh.json";

const CHINESE = Object.assign({}, appZh, posZh, componentZh, webComponentZh, firebaseZh, antdZh);

const antdTheme = {
  token: {
    colorSuccessBg: process.env.REACT_APP_SECONDARY_CONTAINER_COLOR,
    colorPrimaryBg: process.env.REACT_APP_SECONDARY_CONTAINER_COLOR,
    colorInfoBg: process.env.REACT_APP_TERTIARY_CONTAINER_COLOR,
    colorWarningBg: "#fff8e1",
    colorWarning: process.env.REACT_APP_WARNING_COLOR,
    colorBgBase: process.env.REACT_APP_BACKGROUND_COLOR,
    colorError: process.env.REACT_APP_ERROR_COLOR,
    colorPrimary: process.env.REACT_APP_PRIMARY_COLOR,
    colorInfo: process.env.REACT_APP_TERTIARY_COLOR,
    colorSuccess: process.env.REACT_APP_PRIMARY_COLOR,
  },
};

const antdToaster = {
  error: (content) =>
    typeof content === "string"
      ? message.error(content)
      : notification.error({
          message: content?.title,
          description: content?.description,
          duration: 0,
        }),
  success: (content) =>
    typeof content === "string"
      ? message.success(content)
      : notification.success({
          message: content?.title,
          description: content?.description,
        }),
  info: (content) =>
    typeof content === "string"
      ? message.info(content)
      : notification.info({
          message: content?.title,
          description: content?.description,
          duration: 0,
        }),
};

export default function () {
  const { locale, antdLocale, messages, onUpdateLocale } = useWebLocale(CHINESE);

  return (
    <React.StrictMode>
      <ConfigProvider locale={antdLocale} theme={antdTheme} componentSize={"large"}>
        <ErrorBoundary>
          <WebWorkflowApp
            app={
              <HelpCenterProvider>
                <CashDrawerProvider>
                  <POSWebApplication />
                  <CashDrawerMonitor onSnapshot={onSnapshot} doc={doc} getFirestore={getFirestore} />
                </CashDrawerProvider>
              </HelpCenterProvider>
            }
            client={CLIENT_POS}
            CHINESE={CHINESE}
            version={packageJSON.version}
            lang={locale}
            messages={messages}
            onUpdateLocale={onUpdateLocale}
            toaster={antdToaster}
          >
            <PrinterPairingDialog />
            <SystemNoticeDialog />
            <EmailStatusListener />
            <GlobalImageGallery />
          </WebWorkflowApp>
        </ErrorBoundary>
      </ConfigProvider>
    </React.StrictMode>
  );
}
