import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_PAYMENT_METHOD_UPDATE, PAYMENT_ID_STRIPE } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useOrderPaymentIntentUpdate(callback) {
  const intl = useIntl();

  return useActionCallback(
    ({ paymentIntent, orderId }) => {
      if (!paymentIntent) {
        return intl.formatMessage({
          id: "please.select.payment.method",
          defaultMessage: "Please select payment method",
        });
      } else if (paymentIntent.id === PAYMENT_ID_STRIPE) {
        return intl.formatMessage({
          id: "please.enter.your.card",
          defaultMessage: "Please enter your card",
        });
      }

      return [OPERATION_GROUP_ORDER, ORDER_ACTION_PAYMENT_METHOD_UPDATE, { orderId, paymentIntent }];
    },
    callback,
    intl.formatMessage({
      id: "payment.method.updated",
      defaultMessage: "Payment method updated",
    })
  );
}

export default useOrderPaymentIntentUpdate;
