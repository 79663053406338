import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_POS, POS_ACTION_TOP_UP_REPORT } from "@easybiz/utils";
import { useCallback } from "react";

function usePOSTopUpReport(callback) {
  const onSubmit = useCallback(({ startDate, endDate }) => {
    return [
      OPERATION_GROUP_POS,
      POS_ACTION_TOP_UP_REPORT,
      {
        startDate,
        endDate,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback);
}

export default usePOSTopUpReport;
