const values = {
  normal: "break-normal",
  words: "break-words",
  all: "break-all",
  keep: "break-keep",
};

export default function getWordBreak(value) {
  return values[value];
}
