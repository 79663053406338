import { useEffect, useState } from "react";

function useDelayInput(value, onChange, milliseconds = 300) {
  const [delayValue, setDelayValue] = useState(value);

  useEffect(() => {
    if (value !== delayValue) {
      setDelayValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (typeof delayValue === "string" && value !== delayValue) {
      const handler = setTimeout(() => {
        onChange(delayValue);
      }, milliseconds);

      return () => clearTimeout(handler);
    }
  }, [delayValue, milliseconds]);

  return {
    delayValue,
    setDelayValue,
    drafting: typeof delayValue === "string" && delayValue !== value,
  };
}

export default useDelayInput;
