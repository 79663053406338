const options = {
  visible: "xl:overflow-y-visible",
  hidden: "xl:overflow-y-hidden",
  auto: "xl:overflow-y-auto",
  clip: "xl:overflow-y-clip",
};

export default function getOverflowYXl(value) {
  return options[value];
}
