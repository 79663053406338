import wcwidth from "wcwidth";

export default function formatFixedLengthTextLines(rawText, length, fillUp) {
  const lines = [];
  const paragraphs = rawText.split("\n"); // Split the text by newline characters

  for (const paragraph of paragraphs) {
    if (paragraph === "") {
      // Add an empty line to represent the line break
      lines.push("");
      continue;
    }

    let index = 0;
    const graphemes = Array.from(paragraph);

    while (index < graphemes.length) {
      // Skip leading spaces
      while (index < graphemes.length && graphemes[index] === " ") {
        index += 1;
      }

      let lineGraphemes = [];
      let cumulativeWidth = 0;
      let lastSpaceIndex = -1; // Position in lineGraphemes
      let lastIndexInLine = index;

      while (lastIndexInLine < graphemes.length) {
        const grapheme = graphemes[lastIndexInLine];
        const graphemeWidth = wcwidth(grapheme);

        // Check if adding this grapheme exceeds the line length
        if (cumulativeWidth + graphemeWidth > length) {
          if (lastSpaceIndex !== -1) {
            // Break at the last space
            lastIndexInLine = index + lastSpaceIndex + 1;
            lineGraphemes = lineGraphemes.slice(0, lastSpaceIndex);
          } else {
            // No space found; handle long word
            if (lineGraphemes.length === 0) {
              // Add at least one character to avoid infinite loop
              lineGraphemes.push(grapheme);
              cumulativeWidth += graphemeWidth;
              lastIndexInLine += 1;
            }
            // Ensure we move forward
            if (lastIndexInLine === index) {
              lastIndexInLine += 1;
            }
          }
          break;
        }

        lineGraphemes.push(grapheme);
        cumulativeWidth += graphemeWidth;

        // Track the last space position
        if (grapheme === " ") {
          lastSpaceIndex = lineGraphemes.length - 1;
        }

        lastIndexInLine += 1;
      }

      let lineStr = lineGraphemes.join("").trim();

      // Calculate padding based on alignment
      const paddingNeeded = length - wcwidth(lineStr);
      if (paddingNeeded > 0) {
        const padding = " ".repeat(paddingNeeded);
        switch (fillUp) {
          case "center": {
            const halfPadding = Math.floor(paddingNeeded / 2);
            const extraPadding = paddingNeeded % 2;
            lineStr = " ".repeat(halfPadding + extraPadding) + lineStr + " ".repeat(halfPadding);
            break;
          }
          case "right":
            lineStr = padding + lineStr;
            break;
          case "left":
            lineStr = lineStr + padding;
            break;
        }
      }

      lines.push(lineStr);
      index = lastIndexInLine;
    }
  }

  return lines;
}
