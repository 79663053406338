const options = {
  1: "xl:w-1",
  2: "xl:w-2",
  3: "xl:w-3",
  4: "xl:w-4",
  5: "xl:w-5",
  6: "xl:w-6",
  7: "xl:w-7",
  8: "xl:w-8",
  9: "xl:w-9",
  10: "xl:w-10",
  11: "xl:w-11",
  12: "xl:w-12",
  14: "xl:w-14",
  16: "xl:w-16",
  20: "xl:w-20",
  24: "xl:w-24",
  28: "xl:w-28",
  32: "xl:w-32",
  36: "xl:w-36",
  40: "xl:w-40",
  44: "xl:w-44",
  48: "xl:w-48",
  52: "xl:w-52",
  56: "xl:w-56",
  60: "xl:w-60",
  64: "xl:w-64",
  72: "xl:w-72",
  80: "xl:w-80",
  96: "xl:w-96",
  112: "xl:w-[28rem]",
  128: "xl:w-[32rem]",
  160: "xl:w-[40rem]",
  192: "xl:w-[48rem]",
  "1/2": "xl:w-1/2",
  "1/3": "xl:w-1/3",
  "2/3": "xl:w-2/3",
  "1/5": "xl:w-1/5",
  "2/5": "xl:w-2/5",
  "3/5": "xl:w-3/5",
  "4/5": "xl:w-4/5",
  px: "xl:w-px",
  screen: "xl:w-screen",
  grow: "xl:grow",
  fill: "xl:flex-1",
  full: "xl:w-full",
  ratio: "xl:w-auto",
  auto: "xl:w-auto",
};

export default function getWidthXl(size, parentDisplay) {
  return size === "fill" && !["row", "row-reverse"].includes(parentDisplay) ? "" : options[size];
}
