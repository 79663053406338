import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import {
  PLACEHOLDER_TRANSACTION_AMOUNT,
  PLACEHOLDER_TRANSACTION_DATE,
  PLACEHOLDER_TRANSACTION_DESCRIPTION,
  PLACEHOLDER_TRANSACTION_ID,
  PLACEHOLDER_TRANSACTION_MADE_BY,
  PLACEHOLDER_TRANSACTION_METHOD,
  PLACEHOLDER_TRANSACTION_NOTE,
  PLACEHOLDER_TRANSACTION_TIME,
} from "./constants/content.js";
import formatNumber from "./formatNumber.js";
import toDisplayName from "./toDisplayName.js";
import formatDate from "./formatDate.js";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function formatTemplateTransactions(data, context, currencyDisplay, dateFormat, placeholders) {
  const { currency, payments } = data || {};
  const { timeZone } = context;

  return (payments || []).map(({ id, m, n, o, t, v, balance }) => {
    // Have pt means its a refund
    let transaction = {};

    if (placeholders.includes(PLACEHOLDER_TRANSACTION_ID)) {
      transaction[PLACEHOLDER_TRANSACTION_ID] = id;
    }

    if (placeholders.includes(PLACEHOLDER_TRANSACTION_AMOUNT)) {
      transaction[PLACEHOLDER_TRANSACTION_AMOUNT] = formatNumber(-v, currency, true, currencyDisplay);
    }

    if (placeholders.includes(PLACEHOLDER_TRANSACTION_DESCRIPTION)) {
      const methodName = toDisplayName(m, context) || m;

      transaction[PLACEHOLDER_TRANSACTION_DESCRIPTION] =
        v < 0
          ? `Refund by ${methodName}`
          : `Pay by ${methodName}${
              typeof balance === "number" ? `(${formatNumber(balance, currency, true, currencyDisplay)} remaining)` : ""
            }${n ? ` (${n})` : ""}`;
    }

    if (placeholders.includes(PLACEHOLDER_TRANSACTION_METHOD)) {
      transaction[PLACEHOLDER_TRANSACTION_METHOD] = `${toDisplayName(m, context) || m}`;
    }

    if (n && placeholders.includes(PLACEHOLDER_TRANSACTION_NOTE)) {
      transaction[PLACEHOLDER_TRANSACTION_NOTE] = n;
    }

    if (placeholders.includes(PLACEHOLDER_TRANSACTION_MADE_BY)) {
      transaction[PLACEHOLDER_TRANSACTION_MADE_BY] = o;
    }

    if (placeholders.includes(PLACEHOLDER_TRANSACTION_DATE)) {
      transaction[PLACEHOLDER_TRANSACTION_DATE] = formatDate(dayjs.unix(t).tz(timeZone), dateFormat);
    }

    if (placeholders.includes(PLACEHOLDER_TRANSACTION_TIME)) {
      transaction[PLACEHOLDER_TRANSACTION_TIME] = dayjs.unix(t).tz(timeZone).format("HH:mm");
    }

    return transaction;
  });
}
