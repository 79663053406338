import { useClientState, useClientStateSetter } from "@easybiz/shell";
import { IconButton } from "@easybiz/web-admin";
import dayjs from "dayjs";

export default function ({ icon, targetMode }) {
  const { displayMode } = useClientState();
  const setState = useClientStateSetter();
  const selected = displayMode?.key === targetMode;

  return (
    <IconButton
      type={selected ? "primary" : "default"}
      onClick={() => setState({ displayMode: selected ? null : { key: targetMode, date: dayjs() } })}
      icon={icon}
    />
  );
}
