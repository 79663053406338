function formatFont(height, name, baseHeight, baseWidth) {
  const scale = Math.max(1, parseInt(height / baseHeight));

  return {
    name,
    scaleX: scale,
    scaleY: scale,
    height: baseHeight * scale,
    width: baseWidth * scale,
  };
}

export default function formatTSPLFont(fontSizeMM, dotsPerMM, language) {
  // fontSizeMM is the font height in mm
  const fontHeightDots =
    (typeof fontSizeMM === "number" ? fontSizeMM : 3) * (Number.isInteger(dotsPerMM) ? dotsPerMM : 8);

  if (language?.fonts) {
    const font = language.fonts.find((font) => fontHeightDots % font.height === 0);

    if (font) {
      return formatFont(fontHeightDots, font.name, font.height, font.width);
    } else {
      const remains = language.fonts.map((font) => fontHeightDots % font.height).sort();
      const defaultFont = language.fonts.find((font) => fontHeightDots % font.height === remains[0]);

      return formatFont(fontHeightDots, defaultFont.name, defaultFont.height, defaultFont.width);
    }
  } else if (fontHeightDots % 48 === 0) {
    return formatFont(fontHeightDots, "5", 48, 32 + 4);
  } else if (fontHeightDots % 32 === 0) {
    return formatFont(fontHeightDots, "4", 32, 24);
  } else if (fontHeightDots % 24 === 0) {
    return formatFont(fontHeightDots, "3", 24, 16);
  } else if (fontHeightDots % 20 === 0) {
    return formatFont(fontHeightDots, "2", 20, 12 + 2); // 2 dots Spaces
  } else if (fontHeightDots % 12 === 0) {
    return formatFont(fontHeightDots, "1", 12, 8 + 2); // 2 dots Spaces
  } else if (fontHeightDots === 56) {
    return formatFont(60, "2", 20, 12 + 2); // 2 dots Spaces
  } else {
    // Fall back to font 3
    const width = Math.floor((fontHeightDots * 2) / 3);
    return {
      name: "0",
      scaleX: width,
      scaleY: fontHeightDots,
      height: fontHeightDots,
      width,
    };
  }
}
