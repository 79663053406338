"use client";

import { useClientErrorColor, useClientPrimaryColor } from "@easybiz/shell";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import DateDisplay from "../DateDisplay";
import OrderProgressTitle from "../OrderProgressCustomerTitle";

function useOrderProgressPanel(order) {
  const primaryColor = useClientPrimaryColor();
  const errorColor = useClientErrorColor();

  return useMemo(() => {
    const { awaits, completes, next } = order?.data() || {};

    return {
      pending: next && (
        <FormattedMessage
          id="pending.action.x"
          defaultMessage={"Pending {actionName}"}
          values={{
            actionName: <OrderProgressTitle action={next} />,
          }}
        />
      ),
      dataSource: order && [
        ...(completes || []).map(({ a, t, o, option, sortIndex }) => ({
          completed: true,
          color: primaryColor,
          title: (
            <>
              <OrderProgressTitle action={a} />
              {option ? ` #[${sortIndex}] ${option}` : ""}
            </>
          ),
          description: (
            <>
              {o} | <DateDisplay timestamp={t} />
            </>
          ),
        })),
        ...(awaits || []).map((a) => ({
          completed: false,
          color: errorColor,
          title: <OrderProgressTitle action={a} />,
        })),
      ],
    };
  }, [order, primaryColor, errorColor]);
}

export default useOrderProgressPanel;
