import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast, useUserCurrency } from "@easybiz/shell";
import { CUSTOMER_ACTION_CREDIT_TOP_UP, OPERATION_GROUP_CUSTOMER, formatNumber } from "@easybiz/utils";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

function useCustomerCreditTopUp(callback) {
  const intl = useIntl();
  const toast = useFeedbackToast();
  const currency = useUserCurrency();
  const [browserPay, setBrowserPay] = useState();

  const onSubmit = useCallback(
    ({ businessCode, topUpOptionId, currentBalance, customerId, payment, signature, note }, browserPay) => {
      if (!topUpOptionId && (!payment?.amount || isNaN(payment.amount))) {
        return intl.formatMessage({
          id: "please.select.top.up.option",
          defaultMessage: "Please select top up option",
        });
      }
      if (!payment?.option) {
        return intl.formatMessage({
          id: "please.select.payment.method",
          defaultMessage: "Please select payment method",
        });
      }
      if (!payment.amount || isNaN(payment.amount) || parseFloat(payment.amount) <= 0) {
        return intl.formatMessage({
          id: "please.enter.the.payment.amount",
          defaultMessage: `Please enter the payment amount`,
        });
      }

      if (payment?.noteRequired && !payment.note) {
        return intl.formatMessage({
          id: "please.leave.a.payment.note",
          defaultMessage: "Please leave a payment note",
        });
      }

      setBrowserPay(browserPay);

      return [
        OPERATION_GROUP_CUSTOMER,
        CUSTOMER_ACTION_CREDIT_TOP_UP,
        {
          topUpOptionId,
          currentBalance,
          customerId,
          payment,
          businessCode,
          ...(note && { note }),
          ...(signature && { signature }),
        },
      ];
    },
    []
  );

  return useActionCallback(
    onSubmit,
    (succeed, ...args) => {
      if (callback) {
        callback(succeed, ...args);
      }

      if (browserPay && typeof browserPay.complete === "function") {
        if (succeed) {
          browserPay.complete("success");
          setBrowserPay(null);
        } else {
          browserPay.complete("fail");
          setBrowserPay(null);
        }
      }
    },
    (request, response) =>
      response.paymentIntent
        ? toast.success(
            intl.formatMessage({
              id: "continue.payment.to.complete.the.top.up",
              defaultMessage: "Continue payment to complete the top up",
            })
          )
        : toast.success(
            intl.formatMessage(
              {
                id: "top.up.succeed.new.balance.amount.x",
                defaultMessage: "Top up succeed, new balance {amount}",
              },
              {
                amount: formatNumber(response.newBalance, currency),
              }
            )
          )
  );
}

export default useCustomerCreditTopUp;
