import { PLACEHOLDER_ORDER_LINE_ITEM_NOTE } from "./constants/content.js";

export default function renderPlaceholder(renderParams, dataKey, params) {
  if (params?.offsetDays) {
    return renderParams?.[`${dataKey}${params?.offsetDays}`] || "";
  } else if (dataKey === PLACEHOLDER_ORDER_LINE_ITEM_NOTE && renderParams[dataKey]) {
    return `** ${renderParams[dataKey]}`;
  } else {
    return renderParams[dataKey] || "";
  }
}
