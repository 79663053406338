import { CloseCircleOutlined, RedoOutlined } from "@ant-design/icons";
import { useOrderProgressPanel } from "@easybiz/component";
import { useClientStateSetter } from "@easybiz/shell";
import { DISPLAY_MODE_REDO, isPudBusiness } from "@easybiz/utils";
import { Button, Col, Row, Timeline } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import VoidOrderDialog from "../VoidOrderDialog";

export default ({ orderId, order, permissions }) => {
  const [cancelVisible, setCancelVisible] = useState(false);
  const { dataSource, pending } = useOrderProgressPanel(order);
  const setState = useClientStateSetter();

  return (
    <>
      {dataSource && (
        <Timeline
          mode="right"
          pending={pending}
          items={dataSource.map(({ title, description, color }) => ({
            children: title,
            label: description,
            color,
          }))}
        />
      )}
      <Row gutter={16}>
        <Col span={12}>
          {isPudBusiness(order?.get("service.sector")) && (
            <Button
              icon={<RedoOutlined />}
              onClick={() => {
                setState({ displayMode: { key: DISPLAY_MODE_REDO, order }, openOrderId: null });
              }}
              disabled={!permissions?.canRedo}
              block
              danger
            >
              <FormattedMessage tagName="span" defaultMessage="Redo" />
            </Button>
          )}
        </Col>
        <Col span={12}>
          {!order?.get("voided") && (
            <Button
              type="primary"
              disabled={!permissions?.canCancel}
              danger
              block
              icon={<CloseCircleOutlined />}
              onClick={() => setCancelVisible(true)}
            >
              <FormattedMessage tagName="span" defaultMessage="Cancel order" />
            </Button>
          )}
        </Col>
      </Row>
      <VoidOrderDialog orderId={orderId} order={order} open={cancelVisible} onCancel={() => setCancelVisible(false)} />
    </>
  );
};
