"use client";

import { useClientState, useLicenseCloseOfDay } from "@easybiz/shell";

export default function usePOSIsDayClosed() {
  const { date } = useClientState();
  const closedDate = useLicenseCloseOfDay();

  return closedDate === date;
}
