export const TRANSACTION_TYPE_TOP_UP = "top up";
export const TRANSACTION_TYPE_GIFT = "gift";
export const TRANSACTION_TYPE_CASH_BACK = "cash_back";
export const TRANSACTION_TYPE_BONUS = "bonus";
export const TRANSACTION_TYPE_COMPENSATION = "compensation";
export const TRANSACTION_TYPE_SPEND = "spend";
export const TRANSACTION_TYPE_REFUND = "refund";
export const TRANSACTION_TYPE_VOID = "void";
export const TRANSACTION_TYPE_REDEEM = "redeem";

export const CREDIT_OPTION_CODE_GIFT = "GIFT";

export const PLUS_TRANSACTION_TYPES = [
  TRANSACTION_TYPE_TOP_UP,
  TRANSACTION_TYPE_GIFT,
  TRANSACTION_TYPE_COMPENSATION,
  TRANSACTION_TYPE_REFUND,
];

export const MINUS_TRANSACTION_TYPES = [TRANSACTION_TYPE_SPEND, TRANSACTION_TYPE_VOID];

export function isMinusTransactionType(type) {
  return MINUS_TRANSACTION_TYPES.includes(type);
}
