import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast } from "@easybiz/shell";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_REDO } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useOrderRedo(callback) {
  const toast = useFeedbackToast();
  const intl = useIntl();

  return useActionCallback(
    ({ source, orderId, redoOrderId, checkoutParams }) => {
      if (!checkoutParams?.returnSchedule) {
        return intl.formatMessage({
          id: "please.select.return.schedule",
          defaultMessage: "Please select return schedule",
        });
      }

      return [
        OPERATION_GROUP_ORDER,
        ORDER_ACTION_REDO,
        {
          orderId,
          redoOrderId,
          source,
          checkoutParams,
        },
      ];
    },
    callback,
    (request, response) =>
      toast.success(
        intl.formatMessage(
          {
            id: "redo.order.x.created",
            defaultMessage: "Redo order {orderNo} created",
          },
          { orderNo: `#${response.newOrder?.orderNo}` }
        )
      )
  );
}

export default useOrderRedo;
