export const WHATSAPP_TEMPLATE_CATEGORY_MARKETING = "marketing";
export const WHATSAPP_TEMPLATE_CATEGORY_UTILITY = "utility";
export const WHATSAPP_TEMPLATE_CATEGORY_AUTHENTICATION = "authentication";

export const WHATSAPP_COMPONENT_TEMPLATE = "template";
export const WHATSAPP_COMPONENT_HEADER = "header";
export const WHATSAPP_COMPONENT_BODY = "body";
export const WHATSAPP_COMPONENT_BUTTONS = "buttons";
export const WHATSAPP_COMPONENT_BUTTON = "button";
export const WHATSAPP_COMPONENT_FOOTER = "footer";

export const WHATSAPP_BUTTON_TYPE_URL = "url";
export const WHATSAPP_BUTTON_TYPE_PHONE_NUMBER = "phone_number";
export const WHATSAPP_BUTTON_TYPE_OTP = "otp";
export const WHATSAPP_BUTTON_TYPE_QUICK_REPLY = "quick_reply";
export const WHATSAPP_BUTTON_TYPE_COPY_CODE = "copy_code";

export const WHATSAPP_HEADER_FORMAT_NONE = "none";
export const WHATSAPP_HEADER_FORMAT_TEXT = "text";
export const WHATSAPP_HEADER_FORMAT_IMAGE = "image";
export const WHATSAPP_HEADER_FORMAT_VIDEO = "video";
export const WHATSAPP_HEADER_FORMAT_DOCUMENT = "document";
export const WHATSAPP_HEADER_FORMAT_LOCATION = "location";

export const WHATSAPP_OTP_TYPE_COPY_CODE = "copy_code";
export const WHATSAPP_OTP_TYPE_ONE_TAP = "one_tap";
export const WHATSAPP_OTP_TYPE_ZERO_TAP = "zero_tap";

export const WHATSAPP_PARAMETERS_TYPE_TEXT = "text";

export const WHATSAPP_REJECT_REASON_INCORRECT_CATEGORY = "INCORRECT_CATEGORY";

export const WHATSAPP_ACCOUNT_STATUS_BUSINESS_VERIFIED = "BUSINESS_VERIFIED";
export const WHATSAPP_ACCOUNT_STATUS_NOT_VERIFIED = "NOT_VERIFIED";
export const WHATSAPP_ACCOUNT_STATUS_VERIFIED = "VERIFIED";

export const WHATSAPP_MESSAGE_TYPE_TEMPLATE = "template";
export const WHATSAPP_MESSAGE_TYPE_TEXT = "text";
export const WHATSAPP_MESSAGE_TYPE_IMAGE = "image";
export const WHATSAPP_MESSAGE_TYPE_VIDEO = "video";
export const WHATSAPP_MESSAGE_TYPE_DOCUMENT = "document";

export const WHATSAPP_MESSAGE_STATUS_READ = "read";
export const WHATSAPP_MESSAGE_STATUS_DELIVERED = "delivered";
export const WHATSAPP_MESSAGE_STATUS_SENT = "sent";
export const WHATSAPP_MESSAGE_STATUS_FAILED = "failed";