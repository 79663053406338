const options = {
  xs: "xl:max-w-xs",
  sm: "xl:max-w-sm",
  md: "xl:max-w-md",
  lg: "xl:max-w-lg",
  xl: "xl:max-w-xl",
  "2xl": "xl:max-w-2xl",
  "3xl": "xl:max-w-3xl",
  "4xl": "xl:max-w-4xl",
  "5xl": "xl:max-w-5xl",
  "6xl": "xl:max-w-6xl",
  "7xl": "xl:max-w-7xl",
  none: "xl:max-w-none",
};

export default function getMaxWidthXl(width) {
  return options[width];
}
