export const BENEFIT_TYPE_DISCOUNT = "DISCOUNT";
export const BENEFIT_TYPE_CASH_BACK = "CASH_BACK";
export const BENEFIT_TYPE_POINTS = "POINTS";
export const BENEFIT_TYPE_MONTHLY_FOC = "MONTHLY FOC";
export const BENEFIT_TYPE_FLAT = "flat";
export const BENEFIT_TYPE_CUSTOM = "custom";

export const MEMBER_STATUS_PUBLIC = "public";
export const MEMBER_STATUS_INTERNAL = "internal";
export const MEMBER_STATUS_DISABLE = "disabled";

export const REWARD_TYPE_COUPON = "coupon";
export const REWARD_TYPE_POINTS = "points";