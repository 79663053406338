"use client";

import { useCheckoutEditItem, useCheckoutEditItemSetter } from "@easybiz/shell";
import { translate } from "@easybiz/utils";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import useUpdateCheckoutItems from "../useUpdateCheckoutItems";

function useLaundryLineItemEditor(item) {
  const editItem = useCheckoutEditItem();
  const setEditItem = useCheckoutEditItemSetter();
  const updateItems = useUpdateCheckoutItems();
  const { itemIndex, freeNote, commons, addons, accessories, note, unit, option, unitPrice } = item;
  const addonCount = Array.isArray(addons) ? addons.length : 0;
  const accessoryCount = Array.isArray(accessories)
    ? [0, ...accessories.map(({ qty }) => qty || 0)].reduce((a, b) => a + b)
    : 0;
  const selected = Number.isInteger(itemIndex) && editItem?.itemIndex === itemIndex;

  const onEditItem = useCallback(
    (updates) => {
      updateItems({ ...item, ...updates });
      if (!selected) {
        setEditItem(item);
      }
    },
    [item, selected]
  );

  return {
    selected,
    added: Number.isInteger(itemIndex),
    title: (
      <FormattedMessage
        id="line.item.index"
        defaultMessage={"Line item {index}"}
        values={{ index: `#${itemIndex + 1}` }}
      />
    ),
    optionTile: translate(option),
    descriptionHeader: <FormattedMessage id="line.item.detail" defaultMessage={"Line item details"} />,
    unit,
    note,
    unitPrice,
    freeNote,
    commons,
    addonCount,
    accessoryCount,
    onEditItem,
  };
}

export default useLaundryLineItemEditor;
