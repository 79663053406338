"use client";

import {
  AccessProvider,
  ActionProvider,
  AppDrawerProvider,
  AsyncUploadProvider,
  ChatProvider,
  FeedbackProvider,
  KeyboardProvider,
  KVStorageProvider,
  LicenseProvider,
  PrintingProvider,
  RealmProvider,
  useClientCustomerScreenSetter,
  useClientHasNetwork,
  useClientInstallId,
  useClientInstallIdSetter,
  useClientRegisterDeviceSetter,
  useClientType,
  UserProvider,
} from "@easybiz/shell";
import { ADMIN_ACTION_DEVICE_SIGN_OUT, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useCallback, useEffect, useState } from "react";
import ChatConversationMonitor from "./ChatConversationMonitor";
import ChatUnreadMonitor from "./ChatUnreadMonitor";
import ClientDateMonitor from "./ClientDateMonitor";
import DeviceRegister from "./DeviceRegister";
import SingleSignOnMonitor from "./SingleSignOnMonitor";

export default function WorkflowApp({
  app,
  splashScreen,
  children,
  localStorage,
  uploadStorage,
  getDeviceInfo,
  toaster,
  // Printing
  ESCEncoder,
  usbIO,
  bluetoothIO,
  connectPrinter,
  // Firebase: Installations
  getId,
  getInstallations,
  onIdChange,
  // Firebase: Auth
  onIdTokenChanged,
  signInWithCustomToken,
  signOut,
  getAuth,
  // Firebase: Firestore
  onSnapshot,
  runTransaction,
  arrayUnion,
  doc,
  getFirestore,
  setDoc,
  deleteDoc,
  // Firebase: Functions
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
}) {
  const client = useClientType();
  const installId = useClientInstallId();
  const hasNetwork = useClientHasNetwork();
  const setInstallId = useClientInstallIdSetter();
  const setRegisterDevice = useClientRegisterDeviceSetter();
  const setConnectDevice = useClientCustomerScreenSetter();
  const [user, setUser] = useState();
  const [realm, setRealm] = useState();
  const [license, setLicense] = useState();
  const realmId = user?.realmId;
  const pendingSignIn = Boolean(user && !user.uid);
  const signedIn = Boolean(user?.uid);
  const licenseId = user?.licenseId;
  const customerScreenId = user?.connectDeviceId || installId;

  useEffect(() => {
    if (!installId) {
      getId(getInstallations()).then(setInstallId);

      return onIdChange(getInstallations(), setInstallId);
    }
  }, [installId]);

  useEffect(() => {
    return onIdTokenChanged(getAuth(), async (user) => {
      if (user) {
        // Sign in
        const { claims } = await user.getIdTokenResult();

        if (claims.installId) {
          setInstallId(claims.installId);
        }

        setUser({
          // ID
          uid: user.uid,
          name: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          passwordUpdatedAt: user?.reloadUserInfo?.passwordUpdatedAt,
          ...(user.photoURL && { avatarUrl: user.photoURL }),
          // Auth
          authTime: claims.auth_time,
          realmId: claims.realmId,
          licenseId: claims.licenseId,
          permissions: claims.permissions,
          isTestMode: claims.isTestMode ? true : false,
          ...(claims.trial && { trial: claims.trial, realmId: null }), // For trial user, ignore the current realmId (Case like the user already registered under other realms as workflow accounts)
          ...(claims.ebAccess && { debugMode: claims.ebAccess }),
          ...(claims.easybussuper && { easybussuper: claims.easybussuper }),
          ...(claims.stripeAccount && { stripeAccount: claims.stripeAccount }), // TODO: Remove stripeAccount from user claims
          // Location
          currency: claims.currency,
          countryCode: claims.countryCode,
          dialCode: claims.dialCode,
          locationName: claims.locationName,
          locationCenter: claims.latitude && claims.longitude && { lat: claims.latitude, lng: claims.longitude },
          // POS
          ...(claims.businessCode && { businessCode: claims.businessCode }),
          ...(typeof claims.multiStation === "boolean" && { multiStation: claims.multiStation }),
          ...(claims.storeLocation && { storeLocation: claims.storeLocation }),
          ...(claims.clients && { clients: claims.clients }),
          ...(claims.connectDeviceId && { connectDeviceId: claims.connectDeviceId }),
          ...(claims.staffId && {
            checkInStaff: {
              id: claims.staffId,
              name: claims.staffName || "",
              ...(claims.clockInTime && { clockInTime: claims.clockInTime }),
            },
          }),
        });
      } else {
        setUser({});
      }
    });
  }, []);

  // Monitor realm
  useEffect(() => {
    if (realmId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}`), (snapshot) =>
        setRealm({ ...snapshot.data(), realmId: snapshot.id })
      );
    }
  }, [realmId]);

  // Monitor license
  useEffect(() => {
    if (realmId && licenseId) {
      return onSnapshot(doc(getFirestore(), `billings/${realmId}/licences/${licenseId}`), (snapshot) =>
        setLicense(snapshot.data() || {})
      );
    }
  }, [realmId, licenseId]);

  // Monitor registered device
  useEffect(() => {
    if (installId && client && realmId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/devices/${client}/installs/${installId}`),
        setRegisterDevice
      );
    }
  }, [installId, realmId, client]);

  // Monitor connected device (POS secondary screen)
  useEffect(() => {
    if (realmId && customerScreenId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/customer_screens/${customerScreenId}`),
        setConnectDevice
      );
    }
  }, [realmId, customerScreenId]);

  // Monitor unauthorized device (for admin controlled auto sign-in)
  useEffect(() => {
    if (installId && pendingSignIn) {
      return onSnapshot(doc(getFirestore(), `devices/${installId}`), (device) => {
        if (device.get("token")) {
          signInWithCustomToken(getAuth(), device.get("token")).then(() => {
            deleteDoc(device.ref);
          });
        }
      });
    }
  }, [installId, pendingSignIn]);

  const callable = useCallback((params, functionName) => {
    const functions = getFunctions();

    if (
      process.env.NODE_ENV === "development" &&
      (!functionName || `${functionName}`.startsWith(process.env.NEXT_PUBLIC_DEV))
    ) {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }

    if (typeof functionName !== "string") {
      let isStaging;

      if (typeof window !== "undefined") {
        isStaging = window.location.hostname.includes("staging");
      }

      functionName =
        process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" || isStaging ? "stagingOperationCreate" : "operationCreate";
    }

    return httpsCallable(functions, functionName)(params);
  }, []);

  const onSignOut = useCallback(() => {
    if (signedIn) {
      return new Promise((resolve) => {
        if (hasNetwork) {
          callable({
            group: OPERATION_GROUP_ADMIN,
            type: ADMIN_ACTION_DEVICE_SIGN_OUT,
            client,
          })
            .then(resolve)
            .catch(resolve);
        } else {
          resolve();
        }
      }).then(() => signOut(getAuth()));
    }
  }, [signedIn]);

  return (
    <LicenseProvider license={license}>
      <UserProvider user={user} setUser={setUser} onSignOut={onSignOut}>
        <RealmProvider realm={realm}>
          <AccessProvider>
            <ActionProvider
              callable={callable}
              firestore={{
                onSnapshot,
                runTransaction,
                arrayUnion,
                doc,
                getFirestore,
                deleteDoc,
                setDoc,
              }}
            >
              <PrintingProvider
                ESCEncoder={ESCEncoder}
                usbIO={usbIO}
                bluetoothIO={bluetoothIO}
                connectPrinter={connectPrinter}
              >
                <FeedbackProvider toaster={toaster}>
                  <AppDrawerProvider>
                    <AsyncUploadProvider uploadStorage={uploadStorage}>
                      <KVStorageProvider localStorage={localStorage}>
                        <KeyboardProvider>
                          <ChatProvider>
                            {/* app is the main application, pages from NextJS or Screens from Native navigation */}
                            {splashScreen && (!user || (user?.uid && !user.permissions && !user.trial))
                              ? splashScreen
                              : app}
                            {/* Children are additional components from web/native Apps */}
                            {children}
                            <ChatConversationMonitor onSnapshot={onSnapshot} doc={doc} getFirestore={getFirestore} />
                            <ChatUnreadMonitor onSnapshot={onSnapshot} doc={doc} getFirestore={getFirestore} />
                          </ChatProvider>
                        </KeyboardProvider>
                      </KVStorageProvider>
                    </AsyncUploadProvider>

                    <DeviceRegister
                      getDeviceInfo={getDeviceInfo}
                      signInWithCustomToken={signInWithCustomToken}
                      getAuth={getAuth}
                    />
                    <SingleSignOnMonitor signOut={signOut} getAuth={getAuth} />
                    <ClientDateMonitor />
                  </AppDrawerProvider>
                </FeedbackProvider>
              </PrintingProvider>
            </ActionProvider>
          </AccessProvider>
        </RealmProvider>
      </UserProvider>
    </LicenseProvider>
  );
}
