const values = {
  none: "lg:shadow-none",
  sm: "lg:shadow-sm",
  md: "lg:shadow-md",
  lg: "lg:shadow-lg",
  xl: "lg:shadow-xl",
  "2xl": "lg:shadow-2xl",
  inner: "lg:shadow-inner",
};

export default function getShadowLg(value) {
  return values[value];
}
