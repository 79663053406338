const options = {
  0: "border-l-0",
  1: "border-l",
  2: "border-l-2",
  4: "border-l-4",
  8: "border-l-8",
};

export default function getBorderLeft(width) {
  return options[width];
}
