const options = {
  5: "bg-amber-400/5",
  10: "bg-amber-400/10",
  25: "bg-amber-400/25",
  50: "bg-amber-400/50",
  75: "bg-amber-400/75",
  90: "bg-amber-400/90",
  100: "bg-amber-400",
};

const from = {
  5: "from-amber-400/5",
  10: "from-amber-400/10",
  25: "from-amber-400/25",
  50: "from-amber-400/50",
  75: "from-amber-400/75",
  90: "from-amber-400/90",
  100: "from-amber-400",
};

export default function getBackgroundAmber400(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
