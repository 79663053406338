const options = {
  0: "lg:translate-y-0",
  8: "lg:translate-y-8 transform",
  "1/2": "lg:translate-y-1/2 transform",
  "-1/2": "lg:-translate-y-1/2 transform",
};

export default function getTranslateYLg(value) {
  return options[value];
}
