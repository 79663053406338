"use client";

import { PRINTER_TYPE_LABEL, PRINTER_TYPE_RECEIPT, PRINTER_TYPE_STICKER } from "@easybiz/utils";
import React from "react";
import { FormattedMessage } from "react-intl";

function PrinterTypeDisplay({ printerType }) {
  switch (printerType) {
    case PRINTER_TYPE_LABEL:
      return <FormattedMessage id="label.printer" defaultMessage="Label printer" />;
    case PRINTER_TYPE_STICKER:
      return <FormattedMessage id="pack.sticker.printer" defaultMessage="Pack sticker printer" />;
    case PRINTER_TYPE_RECEIPT:
      return <FormattedMessage id="receipt.printer" defaultMessage="Receipt printer" />;
    default:
      return null;
  }
}

export default PrinterTypeDisplay;
