"use client";

import { useUserRealmId } from "@easybiz/shell";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (customerId) => {
  const realmId = useUserRealmId();
  const [wallet, setWallet] = useState(null);

  useEffect(() => {
    setWallet(null);
    if (realmId && customerId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/wallets/${customerId}`), setWallet);
    }
  }, [realmId, customerId]);

  return wallet;
};
