import { useOrderDischarge } from "@easybiz/api-order";
import { CUSTOMER_SCREEN_COLLECTION, UNIT_PCS } from "@easybiz/utils";
import { setDoc, updateDoc } from "firebase/firestore";
import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "../Dialog";
import SignatureStatusBadge from "../SignatureStatusBadge";
import Title from "../Title";
import { useClientCustomerScreen } from "@easybiz/shell";

export default ({ open, onCancel, order, selects, onDischarged }) => {
  const customerScreen = useClientCustomerScreen();
  const [signature, setSignature] = useState();
  const { onSubmit, loading } = useOrderDischarge((success, response) => {
    if (success && onDischarged) {
      onDischarged(response.lines);
    }
  });

  useEffect(() => {
    if (customerScreen?.exists()) {
      setSignature(customerScreen.get("signature"));
    }
  }, [customerScreen?.get("signature")]);

  useEffect(() => {
    if (customerScreen?.get("linking")) {
      if (open) {
        const collecedItems = (order?.get("items") || [])
          .map((item) => {
            const { labelIndexes } = item;
            const selectLabels = Array.isArray(labelIndexes)
              ? labelIndexes.filter((index) => Array.isArray(selects) && selects.indexOf(index) >= 0)
              : [];

            if (selectLabels.length > 0) {
              return {
                ...item,
                qty: selectLabels.length,
                unit: UNIT_PCS,
                labelIndexes: selectLabels,
                allCollceted: selectLabels.length === labelIndexes.length,
              };
            }
          })
          .filter((item) => item);

        updateDoc(customerScreen.ref, {
          mode: CUSTOMER_SCREEN_COLLECTION,
          items: collecedItems,
        });
      } else if (customerScreen.get("mode") === CUSTOMER_SCREEN_COLLECTION) {
        setDoc(customerScreen.ref, { linking: customerScreen.get("linking") });
      }
    }
  }, [open]);

  return (
    <Dialog
      title={
        order && (
          <FormattedMessage
            tagName="span"
            defaultMessage="Discharge Order #{orderNo}"
            values={{ orderNo: order.get("orderNo") }}
          />
        )
      }
      open={open}
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={() => {
        onSubmit({
          orderId: order.id,
          labelIds: (selects || []).map((sortIndex) => order.get("labels")[sortIndex - 1]).filter((id) => id),
          signature,
        });
      }}
    >
      {Array.isArray(selects) && selects.length > 0 && (
        <Space>
          <Title>
            <FormattedMessage
              tagName="span"
              defaultMessage="Total discharged {count} items"
              values={{ count: selects.length }}
            />
          </Title>
          <SignatureStatusBadge signature={signature} onSigned={setSignature} />
        </Space>
      )}
    </Dialog>
  );
};
