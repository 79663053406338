const ROWS = {
  1: "grid-rows-1",
  2: "grid-rows-2",
  3: "grid-rows-3",
  4: "grid-rows-4",
};

export default function getGridRows(value) {
  return ROWS[value];
}
