const options = {
  visible: "sm:overflow-y-visible",
  hidden: "sm:overflow-y-hidden",
  auto: "sm:overflow-y-auto",
  clip: "sm:overflow-y-clip",
};

export default function getOverflowYSm(value) {
  return options[value];
}
