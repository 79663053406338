import { useDelayInput } from "@easybiz/hook";
import { Input } from "antd";
import { useIntl } from "react-intl";

export default function ({
  value,
  onChange,
  placeholder,
  addonAfter,
  addonBefore,
  autoFocus,
  status,
  onFocus,
  onBlur,
}) {
  const intl = useIntl();
  const { delayValue, setDelayValue } = useDelayInput(value, onChange);

  return (
    <Input
      placeholder={placeholder || intl.formatMessage({ defaultMessage: "Search" })}
      autoFocus={autoFocus}
      value={delayValue}
      onChange={(e) => setDelayValue(e.target.value)}
      suffix={addonAfter}
      addonBefore={addonBefore}
      status={status}
      onFocus={onFocus}
      onBlur={onBlur}
      allowClear
    />
  );
}
