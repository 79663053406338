import { useCustomerBookingOptions } from "@easybiz/api-customer";
import { FlatPercentageDisplay } from "@easybiz/component";
import { useUserRealmId } from "@easybiz/shell";
import { Alert, List, Tabs } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import DateTimeOptionsList from "../DateTimeOptionsList";

export default function ({
  taskId,
  orderId,
  businessCode,
  customerId,
  serviceId,
  address,
  scheduleType,
  select,
  onSelect,
}) {
  const realmId = useUserRealmId();
  const { onSubmit, loading, response } = useCustomerBookingOptions();
  const options = response?.options;

  useEffect(() => {
    if (realmId && customerId && businessCode && serviceId && scheduleType && address) {
      onSubmit({
        customerId,
        businessCode,
        serviceId,
        address,
        ...(orderId && { orderId }),
        ...(taskId && { taskId }),
        scheduleType,
      });
    }
  }, [realmId, businessCode, customerId, serviceId, address?.id, scheduleType, orderId, taskId]);

  return Array.isArray(options) ? (
    options.length > 1 ? (
      <Tabs type="card" centered>
        {options.map(({ option, dates }, index) => (
          <Tabs.TabPane key={`${option.title}${index}`} tab={option.title}>
            {option.surcharge && (
              <Alert
                type="warning"
                showIcon
                message={
                  <FormattedMessage
                    defaultMessage="Default {amount} surcharge apply"
                    values={{
                      amount: (
                        <b>
                          <FlatPercentageDisplay {...option.surcharge} />
                        </b>
                      ),
                    }}
                  />
                }
              />
            )}
            <DateTimeOptionsList dates={dates} select={select} option={option} onSelect={onSelect} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    ) : (
      <DateTimeOptionsList dates={options[0]?.dates || []} select={select} onSelect={onSelect} />
    )
  ) : (
    <List loading={loading ? { tip: <FormattedMessage defaultMessage={"Loading date/time options..."} /> } : false} />
  );
}
