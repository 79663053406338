"use client";

import React from "react";
import { FormattedMessage } from "react-intl";

function ReceiptName({ name }) {
  switch (name) {
    case "Customer receipt":
      return <FormattedMessage id="customer.receipt" defaultMessage={"Customer receipt"} />;
    case "Internal receipt":
      return <FormattedMessage id="internal.receipt" defaultMessage={"Internal receipt"} />;
    default:
      return name || <FormattedMessage id="receipt" defaultMessage={"Receipt"} />;
  }
}

export default ReceiptName;
