"use client";

import { formatPrice } from "@easybiz/utils";
import { FormattedMessage, useIntl } from "react-intl";
import AmountDisplay from "../AmountDisplay";
import CustomerDisplay from "../CustomerDisplay";

function useCustomerTopUpOverview(customer, wallet, topUpOption) {
  const intl = useIntl();
  const currentBalance = wallet?.get("credit") || 0;
  const giftAmount = topUpOption?.oneTimeGiftAmount || topUpOption?.giftAmount || 0;
  const newBalance =
    wallet && topUpOption
      ? formatPrice(
          (currentBalance || 0) +
            (topUpOption.amount && !isNaN(topUpOption.amount) ? parseFloat(topUpOption.amount) : 0) +
            giftAmount
        )
      : null;

  return {
    title: intl.formatMessage({
      id: "top.up.preview",
      defaultMessage: "Top up preview",
    }),
    dataSource: [
      ...(customer
        ? [
            {
              description: <FormattedMessage id="top.up.customer" defaultMessage="Top up customer" />,
              title: <CustomerDisplay customer={customer} />,
            },
          ]
        : []),
      {
        title: <FormattedMessage id="current.balance" defaultMessage="Current balance" />,
        content: <AmountDisplay value={currentBalance} />,
        currentBalance: true,
      },
      {
        title: <FormattedMessage id="top.up.amount" defaultMessage="Top up amount" />,
        content: <AmountDisplay value={topUpOption?.amount || 0} />,
        topUpAmount: true,
      },
      ...(giftAmount
        ? [
            {
              title: <FormattedMessage id="gift.amount" defaultMessage="Gift amount" />,
              content: <AmountDisplay value={giftAmount} />,
              giftAmount: true,
            },
          ]
        : []),
      {
        title: <FormattedMessage id="new.balance" defaultMessage="New balance" />,
        content: <AmountDisplay value={newBalance} />,
        newBalance: true,
      },
    ],
  };
}

export default useCustomerTopUpOverview;
