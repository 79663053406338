import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_DISCHARGE } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function useOrderDischarge(callback) {
  const intl = useIntl();

  const onSubmit = useCallback(({ orderId, labelIds, signature }) => {
    return [
      OPERATION_GROUP_ORDER,
      ORDER_ACTION_DISCHARGE,
      {
        orderId,
        ...(Array.isArray(labelIds) && labelIds.length > 0 && { labelIds }),
        ...(signature && { signature }),
      },
    ];
  }, []);

  return useActionCallback(
    onSubmit,
    callback,
    intl.formatMessage({
      id: "order.discharged",
      defaultMessage: "Order discharged",
    })
  );
}

export default useOrderDischarge;
