// auth: Authentication
export const SERVER_ACTIONS_AUTH = "auth";
export const SERVER_ACTION_REALM_IDENTIFY = `${SERVER_ACTIONS_AUTH}UserIdentify`;
export const SERVER_ACTION_DEVICE_REGISTRATION = `${SERVER_ACTIONS_AUTH}DeviceRegistration`;
export const SERVER_ACTION_POS_TOKEN_GENERATE = `${SERVER_ACTIONS_AUTH}POSTokenGenerate`;
export const SERVER_ACTION_OTP_SEND = `${SERVER_ACTIONS_AUTH}OTPSend`;
export const SERVER_ACTION_OTP_VERIFY = `${SERVER_ACTIONS_AUTH}OTPVerify`;

// admin: Platform Administration
export const SERVER_ACTIONS_ADMIN = "admin";
export const SERVER_ACTION_ADMIN_IDENTIFY = `${SERVER_ACTIONS_ADMIN}Identify`;
export const SERVER_ACTION_ADMIN_LOGIN = `${SERVER_ACTIONS_ADMIN}LogIn`;
export const SERVER_ACTION_ADMIN_TRIAL_APPROVE = `${SERVER_ACTIONS_ADMIN}TrialApprove`;
export const SERVER_ACTION_ADMIN_REALM_DELETE = `${SERVER_ACTIONS_ADMIN}RealmDelete`;

// sub: Subscription Management
export const SERVER_ACTIONS_SUB = "sub";
export const SERVER_ACTION_TRIAL_FORM_INIT = `${SERVER_ACTIONS_SUB}TrialFormInit`;
export const SERVER_ACTION_TRIAL_FORM_SUBMIT = `${SERVER_ACTIONS_SUB}TrialFormSubmit`;
export const SERVER_ACTION_TRIAL_ACTIVATE = `${SERVER_ACTIONS_SUB}TrialActivate`;
export const SERVER_ACTION_SUBSCRIPTION_ITEM_ADD = `${SERVER_ACTIONS_SUB}ItemAdd`;
export const SERVER_ACTION_SUBSCRIPTION_ITEM_REMOVE = `${SERVER_ACTIONS_SUB}ItemRemove`;
export const SERVER_ACTION_PLANS_PREVIEW = `${SERVER_ACTIONS_SUB}PlansPreview`;
export const SERVER_ACTION_PLANS_SUBSCRIBE = `${SERVER_ACTIONS_SUB}PlansSubscribe`;
export const SERVER_ACTION_PLANS_UPDATE = `${SERVER_ACTIONS_SUB}PlansUpdate`;
export const SERVER_ACTION_INVOICE_PREVIEW = `${SERVER_ACTIONS_SUB}InvoicePreview`;
export const SERVER_ACTION_PAYMENT_METHOD_SETUP = `${SERVER_ACTIONS_SUB}PaymentMethodSetup`;
export const SERVER_ACTION_PAYMENT_METHOD_SET_DEFAULT = `${SERVER_ACTIONS_SUB}PaymentMethodSetDefault`;
export const SERVER_ACTION_PAYMENT_METHOD_REMOVE = `${SERVER_ACTIONS_SUB}PaymentMethodRemove`;
export const SERVER_ACTION_PAYMENT_METHOD_LIST = `${SERVER_ACTIONS_SUB}PaymentMethodList`;
export const SERVER_ACTION_BILLING_DETAIL_UPDATE = `${SERVER_ACTIONS_SUB}BillingDetailUpdate`;
export const SERVER_ACTION_CANCEL = `${SERVER_ACTIONS_SUB}Cancel`;

// webAuto: Website Automation
export const SERVER_ACTIONS_WEB_AUTO = "webAuto";
export const SERVER_ACTION_SCREENSHOT = `${SERVER_ACTIONS_WEB_AUTO}Screenshot`;

// cms: Content Management System
export const SERVER_ACTIONS_CMS = "cms";
export const SERVER_ACTION_WEBSITE_PUBLISH = `${SERVER_ACTIONS_CMS}WebsitePublish`;
export const SERVER_ACTION_PRICE_LIST_PUBLISH = `${SERVER_ACTIONS_CMS}PriceListPublish`;
export const SERVER_ACTION_PRICE_BUSINESS_BRAND_UPDATE = `${SERVER_ACTIONS_CMS}BusinessBrandUpdate`;
