const options = {
  5: "bg-stone-500/5",
  10: "bg-stone-500/10",
  25: "bg-stone-500/25",
  50: "bg-stone-500/50",
  75: "bg-stone-500/75",
  90: "bg-stone-500/90",
  100: "bg-stone-500",
};

const from = {
  5: "from-stone-500/5",
  10: "from-stone-500/10",
  25: "from-stone-500/25",
  50: "from-stone-500/50",
  75: "from-stone-500/75",
  90: "from-stone-500/90",
  100: "from-stone-500",
};

export default function getBackgroundStone500(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
