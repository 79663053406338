"use client";

import {
  PERMISSION_ACCESS_SALES_AND_REPORTS,
  PERMISSION_LEVEL_FULL,
  PERMISSION_LEVEL_TIME_LIMITED,
  PERMISSION_TIME_SAME_DAY,
  PERMISSION_TIME_WITHIN_X_DAY,
} from "@easybiz/constants";
import { useRealmPOSSettings } from "@easybiz/shell";
import dayjs from "dayjs";

export default function usePOSSalesAccessDate(date) {
  const { permissions } = useRealmPOSSettings();
  const permission = permissions?.find((p) => p.id === PERMISSION_ACCESS_SALES_AND_REPORTS);

  switch (permission?.level) {
    case PERMISSION_LEVEL_FULL:
      return date;
    case PERMISSION_LEVEL_TIME_LIMITED:
      if (permission.timeWindow === PERMISSION_TIME_SAME_DAY) {
        return dayjs().format("YYYY-MM-DD") === date ? date : false;
      } else if (permission.timeWindow === PERMISSION_TIME_WITHIN_X_DAY) {
        return date >= dayjs().subtract(parseInt(permission.days), "days").format("YYYY-MM-DD") ? date : false;
      } else {
        return false;
      }
    default:
      return false;
  }
}
