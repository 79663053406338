"use client";

import {
  useClientType,
  useRealmMemberSettings,
  useRealmPOSOnlinePaymentMethods,
  useUserCurrency,
  useUserStripeAccount,
} from "@easybiz/shell";
import {
  CLIENT_POS,
  PAYMENT_ID_ALIPAY,
  PAYMENT_ID_CREDIT,
  PAYMENT_ID_GRABPAY,
  PAYMENT_ID_PAYNOW_STRIPE,
  PAYMENT_ID_STRIPE,
  PAYMENT_ID_WECHATPAY,
  formatCard,
  formatPointsPayment,
  isDocumentExists,
} from "@easybiz/utils";
import { useMemo } from "react";

function useCustomerOnlinePaymentOptions(amount, creditWallet, pointsWallet, creditCards, isRefund, enablePartial) {
  const client = useClientType();
  const currency = useUserCurrency();
  const posStripeMethods = useRealmPOSOnlinePaymentMethods();
  const stripeAccount = useUserStripeAccount();
  const { pointToMoneyExRate } = useRealmMemberSettings();

  return useMemo(() => {
    const totalCredits = creditWallet?.get("credit") || 0;

    return [
      creditWallet &&
        (isDocumentExists(creditWallet) || isRefund) && {
          id: PAYMENT_ID_CREDIT,
          option: PAYMENT_ID_CREDIT,
          totalCredits,
          ...(!isRefund && { maximumPayable: totalCredits }),
          ...(!isRefund && typeof amount === "number" && amount > totalCredits && { amount: totalCredits }),
          disabled: isRefund ? false : totalCredits <= 0 || (!enablePartial && creditWallet?.get("credit") < amount),
        },
      pointsWallet && formatPointsPayment(pointToMoneyExRate, pointsWallet.get("point"), amount),
      ...(isRefund
        ? []
        : [].concat.apply(
            [],
            (client === CLIENT_POS
              ? posStripeMethods || []
              : [
                  PAYMENT_ID_STRIPE,
                  ...(currency === "SGD" ? [PAYMENT_ID_PAYNOW_STRIPE] : []),
                  PAYMENT_ID_GRABPAY,
                  PAYMENT_ID_WECHATPAY,
                  PAYMENT_ID_ALIPAY,
                ]
            ).map((id) => {
              if (id === PAYMENT_ID_STRIPE) {
                // Credit card
                return [
                  ...(creditCards || []).map(formatCard),
                  {
                    id: PAYMENT_ID_STRIPE,
                    option: PAYMENT_ID_STRIPE,
                  },
                ];
              } else {
                return [{ option: id, photoId: id, id }];
              }
            })
          )),
    ].filter((option) => option);
  }, [
    creditWallet,
    pointsWallet,
    creditCards,
    client,
    currency,
    posStripeMethods,
    Boolean(stripeAccount),
    isRefund,
    enablePartial,
    pointToMoneyExRate,
  ]);
}

export default useCustomerOnlinePaymentOptions;
