import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_TWO_STEP_PRICE_CONTINUE } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function useOrderTwoStepPriceContinue(callback) {
  const intl = useIntl();

  const onSubmit = useCallback(({ orderId, isAck }) => {
    return [
      OPERATION_GROUP_ORDER,
      ORDER_ACTION_TWO_STEP_PRICE_CONTINUE,
      {
        orderId,
        ...(isAck && { isAck }),
      },
    ];
  }, []);

  return useActionCallback(
    onSubmit,
    callback,
    intl.formatMessage({
      id: "order.continue.processed",
      defaultMessage: "Order continue processed",
    })
  );
}

export default useOrderTwoStepPriceContinue;
