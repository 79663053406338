export default function getMarginRightSm(value) {
  switch (value) {
    case -44:
      return "sm:-mr-44";
    case -40:
      return "sm:-mr-40";
    case -36:
      return "sm:-mr-36";
    case -32:
      return "sm:-mr-32";
    case -28:
      return "sm:-mr-28";
    case -24:
      return "sm:-mr-24";
    case -20:
      return "sm:-mr-20";
    case -16:
      return "sm:-mr-16";
    case -12:
      return "sm:-mr-12";
    case -8:
      return "sm:-mr-8";
    case -4:
      return "sm:-mr-4";
    case 0:
      return "sm:mr-0";
    case 1:
      return "sm:mr-1";
    case 2:
      return "sm:mr-2";
    case 3:
      return "sm:mr-3";
    case 4:
      return "sm:mr-4";
    case 5:
      return "sm:mr-5";
    case 6:
      return "sm:mr-6";
    case 7:
      return "sm:mr-7";
    case 8:
      return "sm:mr-8";
    case 9:
      return "sm:mr-9";
    case 10:
      return "sm:mr-10";
    case 12:
      return "sm:mr-12";
    case 14:
      return "sm:mr-14";
    case 16:
      return "sm:mr-16";
    case 20:
      return "sm:mr-20";
    case 24:
      return "sm:mr-24";
    default:
      return "";
  }
}
