import { EditOutlined } from "@ant-design/icons";
import { AmountDisplay } from "@easybiz/component";
import { usePOSCashDrawerStart } from "@easybiz/api-pos";
import { NumericUpdatePanel, PopConfirmButton, Title } from "@easybiz/web-admin";
import { Alert, Button, Col, List, message, Row, Space } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useCashDrawer, useCashDrawerNextDayDepositAmount } from "@easybiz/shell";

export default ({ onStarted }) => {
  const cashDrawer = useCashDrawer();
  const depositAmount = useCashDrawerNextDayDepositAmount();
  const [editVisible, setEditVisible] = useState(false);
  const [editAmount, setEditAmount] = useState();
  const { onSubmit, loading } = usePOSCashDrawerStart((succeed) => {
    if (succeed) {
      onStarted();
    }
  });
  const intl = useIntl();
  const balance = cashDrawer?.balance ?? 0;
  const openingAmount = typeof editAmount === "number" ? editAmount : depositAmount ?? balance;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List.Item
          extra={
            <Space direction="vertical">
              <Title>
                <AmountDisplay value={openingAmount} />
              </Title>
              <Button icon={<EditOutlined />} size="large" onClick={() => setEditVisible(!editVisible)}>
                <FormattedMessage tagName={"span"} defaultMessage="Edit" />
              </Button>
            </Space>
          }
        >
          <List.Item.Meta
            title={
              <Title>
                <FormattedMessage tagName="span" defaultMessage="Starting cash" />
              </Title>
            }
          />
        </List.Item>
      </Col>
      {typeof editAmount === "number" && typeof depositAmount !== "number" && editAmount !== balance && (
        <Col span={24}>
          <Alert
            banner
            type="error"
            message={
              <FormattedMessage
                tagName="b"
                defaultMessage="Starting cash not match with history drawer balance of {balance}, a notification will be sent to the team"
                values={{
                  balance: <AmountDisplay value={balance} />,
                }}
              />
            }
          />
        </Col>
      )}
      {editVisible && (
        <Col span={24}>
          <NumericUpdatePanel
            isCurrency
            value={openingAmount}
            color="green"
            onDone={(amount) => {
              if (typeof amount !== "number") {
                return message.info(
                  intl.formatMessage({
                    defaultMessage: "Please check your cash drawer and enter the starting cash amount",
                  })
                );
              }

              setEditAmount(amount);
              setEditVisible(false);
            }}
            buttonText={<FormattedMessage tagName="span" defaultMessage="OK" />}
          />
        </Col>
      )}
      {!editVisible && (
        <Col span={24}>
          <PopConfirmButton
            block
            type="primary"
            loading={loading}
            confirmTitle={
              <Title>
                <FormattedMessage
                  defaultMessage={"Starting cash amount: {amount}"}
                  values={{
                    amount: <AmountDisplay value={openingAmount} />,
                  }}
                />
              </Title>
            }
            okText={<FormattedMessage defaultMessage={"Confirm"} />}
            onConfirm={() => {
              onSubmit({
                amount: openingAmount,
              });
            }}
          >
            <FormattedMessage defaultMessage={"Start drawer"} />
          </PopConfirmButton>
        </Col>
      )}
    </Row>
  );
};
