import { useActionCallback } from "@easybiz/hook";
import { ADMIN_ACTION_INVOICE_PREVIEW, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useAdminInvoicePreview(callback) {
  const intl = useIntl();

  return useActionCallback(({ orderIds, businessCode, customer }) => {
    if (!Array.isArray(orderIds) || orderIds.length === 0) {
      return intl.formatMessage({
        defaultMessage: "Please select orders",
      });
    }

    return [
      OPERATION_GROUP_ADMIN,
      ADMIN_ACTION_INVOICE_PREVIEW,
      {
        businessCode,
        customer,
        orderIds,
      },
    ];
  }, callback);
}

export default useAdminInvoicePreview;
