import PaynowQR from "paynowqr";

function formatRefNumber(refNumber) {
  // 1. Normalize the string to convert full-width characters to ASCII
  let normalized = refNumber.normalize("NFKC");

  // 2. Define the allowed characters based on PAYNOW specifications
  const allowedCharsRegex = /[A-Za-z0-9 @#\-\.,&']/g;

  // 3. Remove any characters not in the allowed set
  let filtered = normalized.match(allowedCharsRegex);
  if (filtered) {
    normalized = filtered.join("");
  } else {
    normalized = "";
  }

  // 4. Truncate to maximum length (25 characters for PAYNOW refNumber)
  let truncated = normalized.substring(0, 25);

  return truncated;
}

export default function generatePAYNOWQR(companyRegisterNo, amount, refNumber, expiry) {
  try {
    return new PaynowQR({
      uen: companyRegisterNo, //Required: UEN of company
      amount: amount, //Specify amount of money to pay.
      editable: true, //Whether or not to allow editing of payment amount. Defaults to false if amount is specified
      expiry, //Set an expiry date for the Paynow QR code (YYYYMMDD). If omitted, defaults to 5 years from current time.
      ...(typeof refNumber === "string" &&
        refNumber.length > 0 && {
          refNumber: formatRefNumber(refNumber), //Reference number for Paynow Transaction. Useful if you need to track payments for recouncilation.
        }),
      // company: "ACME Pte Ltd.", //Company name to embed in the QR code. Optional.
    }).output();
  } catch (error) {
    console.error(error);
  }
}
