import calculateFlatPercentageWidth from "./calculateFlatPercentageWidth.js";
import calculateQRCodeWidth from "./calculateQRCodeWidth.js";
import { PLACEHOLDER_ORDER_LINE_ITEM_ID, PLACEHOLDER_ORDER_PACKAGE_ID } from "./constants/content.js";
import { QR_ECC_LEVEL_L } from "./constants/label.js";
import { COMPONENT_ITEM_QR_CODE, COMPONENT_PACKAGE_QR_CODE, COMPONENT_TEXT } from "./constants/template.js";
import formatLabelTextLines from "./formatLabelTextLines.js";
import formatMMPaddings from "./formatMMPaddings.js";
import formatTSPLFont from "./formatTSPLFont.js";
import { toItemQR, toPackQR } from "./qrcode.js";
import renderRichContentToText from "./renderRichContentToText.js";

function locateBlocks(component, blocks, renderParams, layout, dotsPerMM, intl, language) {
  const { type, content, attrs, childKeys } = component;
  const { display } = attrs;
  const { paddingTop, paddingLeft, paddingBottom, paddingRight } = formatMMPaddings(attrs, dotsPerMM);

  // Layout width is already calculated before other measurements
  let fullContentWidth = layout.width - paddingLeft - paddingRight;
  let remainingWidth = fullContentWidth;

  // Layout height is unknown, start accumulating height
  layout.height = paddingTop;

  if (Array.isArray(childKeys)) {
    const isHorizontal = display === "row";
    const childLayouts = [];

    // First measure child component with fixed width
    for (const childKey of childKeys) {
      const { mmWidth, cellWidth } = component[childKey].attrs || {};

      const childLayout = {
        id: component[childKey].id,
        parentId: component.id,
      };

      if (isHorizontal && component[childKey].attrs?.rotation) {
        // Font text can be rotated, so rotated width = line height
        const font = formatTSPLFont(component[childKey].attrs.fontSizeMM, dotsPerMM, language);
        const text = renderRichContentToText(component[childKey].content, renderParams);

        // For easy editing only, keep the width at least 50
        childLayout.width = Math.max(50, font.width * `${text} || " "`.length);

        remainingWidth -= font.height;
      } else if (mmWidth) {
        childLayout.width = Number.isInteger(mmWidth)
          ? mmWidth * dotsPerMM
          : calculateFlatPercentageWidth(mmWidth, fullContentWidth, remainingWidth);

        if (isHorizontal) {
          remainingWidth -= childLayout.width;
        }
      } else if (Number.isInteger(cellWidth)) {
        // QR code
        const eccLevel = attrs.eccLevel || QR_ECC_LEVEL_L;
        // The fixed ID is only used to calculate QR code width
        childLayout.qrWidth = calculateQRCodeWidth(toItemQR("ilQ87F3zkTk423mkhjvU"), eccLevel, cellWidth);

        childLayout.width =
          childLayout.qrWidth +
          parseInt(
            (typeof component[childKey].attrs?.mmPl === "number" ? component[childKey].attrs?.mmPl : 0) * dotsPerMM
          ) +
          parseInt(
            (typeof component[childKey].attrs?.mmPr === "number" ? component[childKey].attrs?.mmPr : 0) * dotsPerMM
          );

        if (isHorizontal) {
          remainingWidth -= childLayout.width;
        }
      }

      if (childLayout.width) {
        childLayouts.push(childLayout);
      }
    }

    // Then measure child component with auto width
    const unmeasuredChildKeys = childKeys.filter(
      (childKey) => !childLayouts.some((childLayout) => childLayout.id === component[childKey].id)
    );

    if (unmeasuredChildKeys.length > 0) {
      const autoWidth = isHorizontal ? Math.floor(remainingWidth / unmeasuredChildKeys.length) : fullContentWidth;

      for (const childKey of unmeasuredChildKeys) {
        const childLayout = {
          id: component[childKey].id,
          parentId: component.id,
        };

        if (isHorizontal && unmeasuredChildKeys[unmeasuredChildKeys.length - 1] === childKey) {
          childLayout.width = remainingWidth;
        } else {
          childLayout.width = autoWidth;

          if (isHorizontal) {
            remainingWidth -= autoWidth;
          }
        }
        childLayouts.push(childLayout);
      }
    }

    let leftOffset = paddingLeft;

    for (const childKey of childKeys) {
      const childLayout = childLayouts.find((childLayout) => childLayout.id === component[childKey].id);

      if (!childLayout) {
        continue;
      }

      locateBlocks(component[childKey], blocks, renderParams, childLayout, dotsPerMM, intl, language);

      childLayout.left = leftOffset;

      if (isHorizontal) {
        childLayout.top = paddingTop;
        layout.height = Math.max(layout.height, childLayout.height + paddingTop);
        leftOffset += childLayout.text?.rotation ? childLayout.text.height : childLayout.width;
      } else {
        childLayout.top = layout.height;
        layout.height += childLayout.height;
      }
    }
  } else {
    if (paddingTop) {
      layout.paddingTop = paddingTop;
    }
    if (paddingLeft) {
      layout.paddingLeft = paddingLeft;
    }

    switch (attrs?.subType || type) {
      case COMPONENT_ITEM_QR_CODE:
      case COMPONENT_PACKAGE_QR_CODE: {
        const isItem = (attrs?.subType || type) === COMPONENT_ITEM_QR_CODE;

        if (layout.qrWidth) {
          layout.height += layout.qrWidth;

          const eccLevel = attrs.eccLevel || QR_ECC_LEVEL_L;
          const content = isItem
            ? renderParams?.[PLACEHOLDER_ORDER_LINE_ITEM_ID] && toItemQR(renderParams[PLACEHOLDER_ORDER_LINE_ITEM_ID])
            : renderParams?.[PLACEHOLDER_ORDER_PACKAGE_ID] && toPackQR(renderParams[PLACEHOLDER_ORDER_PACKAGE_ID]);

          if (content) {
            layout.qrCode = {
              eccLevel,
              content,
              cellWidth: attrs.cellWidth,
              width: layout.qrWidth,
            };
          }
        }

        break;
      }
      case COMPONENT_TEXT: {
        const font = formatTSPLFont(attrs.fontSizeMM, dotsPerMM, language);
        const characterPerLine = Math.round(fullContentWidth / font.width);
        const lines = formatLabelTextLines(content, attrs, renderParams, characterPerLine, intl);

        layout.height += font.height * lines.length;
        layout.text = {
          ...font,
          rotation: attrs.rotation,
          lines,
          textToImage: attrs.textToImage,
        };

        if (attrs?.ring) {
          layout.box = {
            width: attrs?.ring,
          };
        }

        if (attrs?.invertMode) {
          layout.reverse = true;
        }

        break;
      }
    }
  }

  layout.height += paddingBottom;

  blocks.push(layout);
}

export default function positionLabelBlocks(template, intl, renderParams) {
  const { mmWidth, mmMl, mmMr } = template.attrs || {};
  const language = template.content?.language;
  const dotsPerMM = Number.isInteger(template.attrs?.dotsPerMM) ? template.attrs.dotsPerMM : 8;

  const blocks = [];

  locateBlocks(
    template,
    blocks,
    renderParams,
    {
      id: template.id,
      top: 0,
      left: 0,
      width: parseInt(((mmWidth || 110) - (mmMl || 0) - (mmMr || 0)) * dotsPerMM),
    },
    dotsPerMM,
    intl,
    language
  );

  return blocks;
}
