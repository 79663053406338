"use client";

import { Suspense, lazy } from "react";

const DraftPageLinkClient = lazy(() => import("./DraftPageLinkClient"));

export default function DraftPageLinkLazy(props) {
  return (
    <Suspense>
      <DraftPageLinkClient {...props} />
    </Suspense>
  );
}
