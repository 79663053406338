const options = {
  5: "border-white/5",
  10: "border-white/10",
  25: "border-white/25",
  50: "border-white/50",
  75: "border-white/75",
  90: "border-white/90",
};

export default function getBorderWhite(opacity) {
  return options[opacity] || "border-white";
}
