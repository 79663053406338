const options = {
  5: "bg-emerald-300/5",
  10: "bg-emerald-300/10",
  25: "bg-emerald-300/25",
  50: "bg-emerald-300/50",
  75: "bg-emerald-300/75",
  90: "bg-emerald-300/90",
  100: "bg-emerald-300",
};

const from = {
  5: "from-emerald-300/5",
  10: "from-emerald-300/10",
  25: "from-emerald-300/25",
  50: "from-emerald-300/50",
  75: "from-emerald-300/75",
  90: "from-emerald-300/90",
  100: "from-emerald-300",
};

export default function getBackgroundEmerald300(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
