import {
  COMPONENT_ITEM_QR_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_ID,
  PLACEHOLDER_ORDER_PACKAGE_ID,
  toItemQR,
  toPackQR,
} from "@easybiz/utils";
import QRCodeLazy from "../components/client/QRCodeLazy";
import Node from "../Node";

export default function LabelQRCode(props) {
  const contentId =
    (props.type === COMPONENT_ITEM_QR_CODE
      ? props.context?.renderParams?.[PLACEHOLDER_ORDER_LINE_ITEM_ID]
      : props.context?.renderParams?.[PLACEHOLDER_ORDER_PACKAGE_ID]) ||
    (props.context?.renderParams ? null : "ilQ87F3zkTk423mkhjvU");

  return (
    <Node {...props}>
      {contentId && (
        <QRCodeLazy
          className="w-full h-full"
          errorCorrectionLevel={props.attrs?.eccLevel}
          content={props.type === COMPONENT_ITEM_QR_CODE ? toItemQR(contentId) : toPackQR(contentId)}
        />
      )}
    </Node>
  );
}
