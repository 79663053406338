const options = {
  center: "sm:items-center",
  end: "sm:items-end",
  start: "sm:items-start",
  stretch: "sm:items-stretch",
  baseline: "sm:items-baseline",
};

export default function getAlignItemsSm(align) {
  return options[align];
}
