const values = {
  0: "pl-0",
  0.5: "pl-0.5",
  1: "pl-1",
  1.5: "pl-1.5",
  2: "pl-2",
  2.5: "pl-2.5",
  3: "pl-3",
  3.5: "pl-3.5",
  4: "pl-4",
  5: "pl-5",
  6: "pl-6",
  7: "pl-7",
  8: "pl-8",
  9: "pl-9",
  10: "pl-10",
  12: "pl-12",
  14: "pl-14",
  16: "pl-16",
  20: "pl-20",
  24: "pl-24",
  28: "pl-28",
  32: "pl-32",
  36: "pl-36",
  40: "pl-40",
  44: "pl-44",
  48: "pl-48",
  52: "pl-52",
  56: "pl-56",
  60: "pl-60",
  64: "pl-64",
  72: "pl-72",
  80: "pl-80",
  96: "pl-96",
};

export default function getPaddingLeft(value) {
  return values[value];
}
