import {
  BUSINESS_ID_FACTORY,
  BUSINESS_SECTOR_ALTERATION,
  BUSINESS_SECTOR_BAG_SERVICE,
  BUSINESS_SECTOR_LAUNDRY,
  UNIT_BAG,
  UNIT_BUNDLE,
  UNIT_KG,
  UNIT_PAIR,
  UNIT_PCS,
  UNIT_SET,
  UNIT_SQFT,
} from "@easybiz/utils";
import { useMemo } from "react";

function useBusinessUnits(sector) {
  return useMemo(() => {
    switch (sector) {
      case BUSINESS_SECTOR_LAUNDRY:
      case BUSINESS_ID_FACTORY:
        return [UNIT_PCS, UNIT_PAIR, UNIT_SET, UNIT_BUNDLE, UNIT_KG, UNIT_SQFT, UNIT_BAG].map((unit) => {
          const isB2B = sector === BUSINESS_ID_FACTORY;

          switch (unit) {
            case UNIT_PCS:
              return {
                unit,
                pcsPerUnit: 1,
              };
            case UNIT_PAIR:
              return {
                unit,
                pcsPerUnit: isB2B ? 1 : 2,
              };
            case UNIT_SET:
            case UNIT_BUNDLE:
              return {
                unit,
                pcsPerUnit: 1,
              };
            case UNIT_KG:
            case UNIT_SQFT:
            case UNIT_BAG:
              return {
                unit,
                manualPcs: true,
                decimal: true,
              };
            default:
              return null;
          }
        });
      case BUSINESS_SECTOR_ALTERATION:
      case BUSINESS_SECTOR_BAG_SERVICE:
        return [UNIT_PCS, UNIT_SET].map((unit) => ({ unit }));
      default:
        return null;
    }
  }, [sector]);
}

export default useBusinessUnits;
