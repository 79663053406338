"use client";

import { useUserRealmId } from "@easybiz/shell";
import { doc, getDoc, getFirestore } from "firebase/firestore/lite";
import { useEffect, useState } from "react";

export default (customerId) => {
  const realmId = useUserRealmId();
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    setCustomer(null);
    if (realmId && customerId) {
      getDoc(doc(getFirestore(), `realms/${realmId}/customers/${customerId}`)).then(setCustomer);
    }
  }, [realmId, customerId]);

  return customer;
};
