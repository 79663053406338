import { getRingColor, isDarkTone, parseColorNameValue } from "@easybiz/utils";
import clsx from "clsx";

const ringWidths = {
  0: "ring-0",
  1: "ring-1",
  2: "ring-2",
  3: "ring-3",
  4: "ring-4",
  8: "ring-8",
};

export default function getRing(attrs, bgColor, theme) {
  const { ring, ringColor, ringInset } = attrs || {};

  if (!ringWidths[ring]) {
    return "";
  }

  const [name, value] = parseColorNameValue(ringColor, theme);
  const color = getRingColor({ name, value: isDarkTone(bgColor, theme) && value > 500 ? 1000 - value : value });

  return clsx(ringWidths[ring], ringInset ? "ring-inset" : "", color);
}
