const options = {
  1: "lg:row-end-1",
  2: "lg:row-end-2",
  3: "lg:row-end-3",
  4: "lg:row-end-4",
};

export default function getGridRowEndLg(end) {
  return options[end];
}
