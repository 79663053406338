function formatPhotoUrl(photoId, realmId, size) {
  if (`${photoId}`.includes("_")) {
    const [folder, id] = `${photoId}`.split("_");
    return `https://storage.googleapis.com/easybiz-sg/realms/${realmId}/media/${folder}/${id}/${size || "default"}`;
  } else if (photoId) {
    return `https://storage.googleapis.com/easybus-img/${photoId}`;
  }
}

export default formatPhotoUrl;
