import {
  PLACEHOLDER_JOB_ID,
  PLACEHOLDER_ORDER_BUSINESS_UNIT_ID,
  PLACEHOLDER_ORDER_ID,
  PLACEHOLDER_SALES_CHANNEL_DOMAIN,
} from "./constants/content.js";
import {
  PAGE_ID_CASHIER,
  PAGE_ID_HOME,
  PAGE_ID_ORDER_DETAIL,
  PAGE_ID_RESCHEDULE,
  PAGE_ID_TRACKING,
} from "./constants/template.js";
import { WHATSAPP_PARAMETERS_TYPE_TEXT } from "./constants/whatsapp.js";

export default function retrieveUrlParams(link, placeholders, renderParams) {
  let pathname;
  let example;

  if (link?.pageId && Array.isArray(placeholders) && !placeholders.includes(PLACEHOLDER_SALES_CHANNEL_DOMAIN)) {
    placeholders.push(PLACEHOLDER_SALES_CHANNEL_DOMAIN);
  }

  switch (link?.pageId) {
    case PAGE_ID_CASHIER:
    case PAGE_ID_ORDER_DETAIL:
    case PAGE_ID_TRACKING:
      if (Array.isArray(placeholders) && !placeholders.includes(PLACEHOLDER_ORDER_ID)) {
        placeholders.push(PLACEHOLDER_ORDER_ID);
      }

      if (renderParams) {
        return [
          {
            type: WHATSAPP_PARAMETERS_TYPE_TEXT,
            text: renderParams[PLACEHOLDER_ORDER_ID],
          },
        ];
      } else {
        (pathname = `${link?.pageId}?${link?.pageId === PAGE_ID_TRACKING ? "tracking_id" : "order_id"}={{1}}`),
          (example = "P3qSBhcY5H9Pp0Zs8VQm");
      }
      break;
    case PAGE_ID_RESCHEDULE:
      if (Array.isArray(placeholders) && !placeholders.includes(PLACEHOLDER_ORDER_BUSINESS_UNIT_ID)) {
        placeholders.push(PLACEHOLDER_ORDER_BUSINESS_UNIT_ID);
      }
      if (Array.isArray(placeholders) && !placeholders.includes(PLACEHOLDER_JOB_ID)) {
        placeholders.push(PLACEHOLDER_JOB_ID);
      }

      if (renderParams) {
        return [
          {
            type: WHATSAPP_PARAMETERS_TYPE_TEXT,
            text: `${renderParams[PLACEHOLDER_ORDER_BUSINESS_UNIT_ID]}?reschedule=${renderParams[PLACEHOLDER_JOB_ID]}#date_time`,
          },
        ];
      } else {
        pathname = "checkout/{{1}}";
        example = "biz1?reschedule=P3qSBhcY5H9Pp0Zs8VQm#date_time";
      }
      break;
    default:
      if (link?.pageId && link?.pageId !== PAGE_ID_HOME) {
        pathname = link?.pageId;
      }
  }

  return {
    pathname,
    example,
  };
}
