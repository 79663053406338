const options = {
  center: "xl:items-center",
  end: "xl:items-end",
  start: "xl:items-start",
  stretch: "xl:items-stretch",
  baseline: "xl:items-baseline",
};

export default function getAlignItemsXl(align) {
  return options[align];
}
