const options = {
  0: "sm:border-b-0",
  1: "sm:border-b",
  2: "sm:border-b-2",
  4: "sm:border-b-4",
  8: "sm:border-b-8",
};

export default function getBorderBottomSm(width) {
  return options[width];
}
