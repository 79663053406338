const values = {
  none: "xl:rounded-none",
  md: "xl:rounded-md",
  lg: "xl:rounded-lg",
  xl: "xl:rounded-xl",
  "2xl": "xl:rounded-2xl",
  "3xl": "xl:rounded-3xl",
  full: "xl:rounded-full",
};

export default function getRoundedXl(value) {
  return values[value];
}
