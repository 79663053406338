import formatTime from "./formatTime.js";

export default function formatScheduleTime(schedule, timeZone, dateFormat) {
  const { date, start, end } = schedule;

  if (date && start && end) {
    return `${formatTime(`${date}T${start}`, timeZone, dateFormat)} - ${formatTime(
      `${date}T${end}`,
      timeZone,
      dateFormat
    )}`;
  }
}
