"use client";

import { useUserRealmId } from "@easybiz/shell";
import { PROMOTION_ID_BIRTHDAY, PROMOTION_ID_PUBLIC } from "@easybiz/utils";
import { collection, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (date, customerId) => {
  const realmId = useUserRealmId();
  const [promotions, setPromotions] = useState();

  useEffect(() => {
    setPromotions(null);
    if (realmId && date) {
      return onSnapshot(
        query(
          collection(getFirestore(), `realms/${realmId}/promotions`),
          where("valids", "array-contains-any", [
            PROMOTION_ID_BIRTHDAY,
            PROMOTION_ID_PUBLIC,
            date,
            ...(customerId
              ? [
                  customerId, // Member state
                ]
              : []),
          ])
        ),
        (result) => setPromotions(result.docs)
      );
    }
  }, [realmId, customerId, date]);

  return promotions;
};
