import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_REFUND } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useOrderRefund(callback) {
  const intl = useIntl();

  return useActionCallback(
    ({ orderId, payment, amount, option, note }) => {
      if (!payment) {
        if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
          return intl.formatMessage({
            id: "please.enter.refund.amount",
            defaultMessage: `Please enter refund amount`,
          });
        }
        if (!option) {
          return intl.formatMessage({
            id: "please.select.refund.option",
            defaultMessage: `Please select refund option`,
          });
        }
      } else if (!note) {
        return intl.formatMessage({
          id: "please.leave.a.refund.note",
          defaultMessage: "Please leave a refund note",
        });
      }

      return [
        OPERATION_GROUP_ORDER,
        ORDER_ACTION_REFUND,
        {
          orderId,
          ...(payment ? { payment } : { amount: parseFloat(amount), option }),
          ...(note && { note }),
        },
      ];
    },
    callback,
    intl.formatMessage({
      id: "refund.succeed",
      defaultMessage: `Refund succeed`,
    })
  );
}

export default useOrderRefund;
