const options = {
  5: "bg-indigo-800/5",
  10: "bg-indigo-800/10",
  25: "bg-indigo-800/25",
  50: "bg-indigo-800/50",
  75: "bg-indigo-800/75",
  90: "bg-indigo-800/90",
  100: "bg-indigo-800",
};

const from = {
  5: "from-indigo-800/5",
  10: "from-indigo-800/10",
  25: "from-indigo-800/25",
  50: "from-indigo-800/50",
  75: "from-indigo-800/75",
  90: "from-indigo-800/90",
  100: "from-indigo-800",
};

export default function getBackgroundIndigo800(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
