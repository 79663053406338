"use client";

import { useAccessSelfAgentId } from "@easybiz/shell";
import useProcedureTitle from "../useProcedureTitle";

function ProcedureDisplay({ procedure, processing }) {
  const agentId = useAccessSelfAgentId();
  const title = useProcedureTitle(procedure, agentId, processing);

  return title || null;
}

export default ProcedureDisplay;
