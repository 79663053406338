const options = {
  5: "bg-purple-200/5",
  10: "bg-purple-200/10",
  25: "bg-purple-200/25",
  50: "bg-purple-200/50",
  75: "bg-purple-200/75",
  90: "bg-purple-200/90",
  100: "bg-purple-200",
};

const from = {
  5: "from-purple-200/5",
  10: "from-purple-200/10",
  25: "from-purple-200/25",
  50: "from-purple-200/50",
  75: "from-purple-200/75",
  90: "from-purple-200/90",
  100: "from-purple-200",
};

export default function getBackgroundPurple200(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
