import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_NOTE } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function useOrderNoteUpdate(callback) {
  const intl = useIntl();

  const onSubmit = useCallback(({ note, selects, sortIndex, allItems, orderId }) => {
    return [
      OPERATION_GROUP_ORDER,
      ORDER_ACTION_NOTE,
      {
        note: Array.isArray(selects) ? [note, ...selects].filter((data) => data).join("; ") : note || "",
        ...(Number.isInteger(sortIndex) && { sortIndex }),
        ...(allItems && { allItems }),
        orderId,
      },
    ];
  }, []);

  return useActionCallback(
    onSubmit,
    callback,
    intl.formatMessage({
      id: "note.updated",
      defaultMessage: "Note updated",
    })
  );
}

export default useOrderNoteUpdate;
