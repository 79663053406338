export default function getMarginLeftSm(value) {
  switch (value) {
    case -44:
      return "sm:-ml-44";
    case -40:
      return "sm:-ml-40";
    case -36:
      return "sm:-ml-36";
    case -32:
      return "sm:-ml-32";
    case -28:
      return "sm:-ml-28";
    case -24:
      return "sm:-ml-24";
    case -20:
      return "sm:-ml-20";
    case -16:
      return "sm:-ml-16";
    case -12:
      return "sm:-ml-12";
    case -8:
      return "sm:-ml-8";
    case -4:
      return "sm:-ml-4";
    case 0:
      return "sm:ml-0";
    case 1:
      return "sm:ml-1";
    case 2:
      return "sm:ml-2";
    case 3:
      return "sm:ml-3";
    case 4:
      return "sm:ml-4";
    case 5:
      return "sm:ml-5";
    case 6:
      return "sm:ml-6";
    case 7:
      return "sm:ml-7";
    case 8:
      return "sm:ml-8";
    case 9:
      return "sm:ml-9";
    case 10:
      return "sm:ml-10";
    case 12:
      return "sm:ml-12";
    case 14:
      return "sm:ml-14";
    case 16:
      return "sm:ml-16";
    case 20:
      return "sm:ml-20";
    case 24:
      return "sm:ml-24";
    default:
      return "";
  }
}
