export default function getDisplayMd(display) {
  switch (display) {
    case "block":
      return "md:block";
    case "inline-block":
      return "md:inline-block";
    case "grid":
      return "md:grid";
    case "inline":
      return "md:inline-flex";
    case "row":
      return "md:flex md:flex-row";
    case "col":
      return "md:flex md:flex-col";
    case "row-reverse":
      return "md:flex md:flex-row-reverse";
    case "col-reverse":
      return "md:flex md:flex-col-reverse";
    case "none":
      return "md:flex-none";
    case "contents":
      return "md:contents";
    case "hidden":
      return "md:hidden";
    default:
      return "";
  }
}
