const options = {
  0: "border-b-0",
  1: "border-b",
  2: "border-b-2",
  4: "border-b-4",
  8: "border-b-8",
};

export default function getBorderBottom(width) {
  return options[width];
}
