const options = {
  5: "bg-cyan-900/5",
  10: "bg-cyan-900/10",
  25: "bg-cyan-900/25",
  50: "bg-cyan-900/50",
  75: "bg-cyan-900/75",
  90: "bg-cyan-900/90",
  100: "bg-cyan-900",
};

const from = {
  5: "from-cyan-900/5",
  10: "from-cyan-900/10",
  25: "from-cyan-900/25",
  50: "from-cyan-900/50",
  75: "from-cyan-900/75",
  90: "from-cyan-900/90",
  100: "from-cyan-900",
};

export default function getBackgroundCyan900(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
