import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast } from "@easybiz/shell";
import { OPERATION_GROUP_POS, POS_ACTION_CLOCK_IN } from "@easybiz/utils";
import dayjs from "dayjs";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function usePOSClockIn(callback) {
  const toast = useFeedbackToast();
  const intl = useIntl();

  const onSubmit = useCallback(({ passcode }) => {
    if (!passcode) return intl.formatMessage({ defaultMessage: "Please enter your passcode" });

    return [
      OPERATION_GROUP_POS,
      POS_ACTION_CLOCK_IN,
      {
        staffId: passcode,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, (request, response) => {
    if (response.checkInDate) {
      toast.success(
        intl.formatMessage(
          { defaultMessage: "Welcome {name} (Clocked in at {time})" },
          {
            name: response.checkInStaff.name,
            time: dayjs.unix(response.checkInDate).format("HH:mm"),
          }
        )
      );
    }
  });
}

export default usePOSClockIn;
