export const OWNER_LICENSE_ID = "owner";

export const SUBSCRIPTION_STATUS_ACTIVE = "active";
export const SUBSCRIPTION_STATUS_PAST_DUE = "past_due";
export const SUBSCRIPTION_STATUS_UNPAID = "unpaid";
export const SUBSCRIPTION_STATUS_CANCELLED = "canceled";
export const SUBSCRIPTION_STATUS_INCOMPLETE = "incomplete";
export const SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED = "incomplete_expired";
export const SUBSCRIPTION_STATUS_TRIALING = "trialing";
export const SUBSCRIPTION_STATUS_PAUSED = "paused";

export const INVOICE_STATUS_OPEN = "open";
export const INVOICE_STATUS_PAID = "paid";
export const INVOICE_STATUS_DRAFT = "draft";
export const INVOICE_STATUS_UNCOLLECTIBLE = "uncollectible";
export const INVOICE_STATUS_VOID = "void";

export const CANCEL_FEEDBACK_CUSTOMER_SERVICE = "customer_service";
export const CANCEL_FEEDBACK_LOW_QUALITY = "low_quality";
export const CANCEL_FEEDBACK_TOO_EXPENSIVE = "too_expensive";
export const CANCEL_FEEDBACK_SWITCHED_SERVICE = "switched_service";
export const CANCEL_FEEDBACK_MISSING_FEATURES = "missing_features";
export const CANCEL_FEEDBACK_TOO_COMPLEX = "too_complex";
export const CANCEL_FEEDBACK_UNUSED = "unused";
export const CANCEL_FEEDBACK_OTHER = "other";
