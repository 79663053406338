import {
  COLOR_PRIMARY,
  formatPlaceholderName,
  getBackgroundColor,
  getTextColor,
  renderPlaceholder,
  TEMPLATE_CATEGORY_LABEL,
} from "@easybiz/utils";
import clsx from "clsx";
import getFontFamily from "../utils/getFontFamily";
import getFontSize from "../utils/getFontSize";
import getFontSizeLg from "../utils/getFontSizeLg";
import getFontSizeSm from "../utils/getFontSizeSm";
import getFontWeight from "../utils/getFontWeight";
import getLeading from "../utils/getLeading";
import getTracking from "../utils/getTracking";

const defaultHeadingSize = {
  1: "4xl",
  2: "3xl",
  3: "2xl",
  4: "xl",
  5: "lg",
};

export default function TextContent({
  as,
  inlinePrefix,
  headingLevel,
  content,
  context,
  className,
  style,
  placeholder,
  isEditMode,
  invertMode,
  intl,
  LinkComponent,
  Tooltip,
  offsetDays,
}) {
  return Array.isArray(content) ? (
    content.map(({ type, content, text, marks, attrs }, index) => {
      const markStyles = [
        ...(marks || []),
        ...(headingLevel && !marks?.some((mark) => mark.type === "textStyle")
          ? [{ type: "textStyle", attrs: { fontSize: defaultHeadingSize[headingLevel] } }]
          : []),
      ].map((mark) => {
        switch (mark.type) {
          case "link":
            return "cursor-pointer";
          case "italic":
            return "italic";
          case "strike":
            return "line-through";
          case "underline":
            return "underline";
          case "highlight":
            return getBackgroundColor(mark.attrs?.color, context?.theme);
          case "textStyle": {
            return clsx(
              ...Object.keys(mark.attrs || {}).map((key) => {
                switch (key) {
                  case "color":
                    return getTextColor(
                      marks.some((mark) => mark.type === "link") ? COLOR_PRIMARY : mark.attrs.color,
                      context
                    );
                  case "fontFamily":
                    return getFontFamily(mark.attrs.fontFamily);
                  case "fontWeight":
                    return getFontWeight(mark.attrs.fontWeight);
                  case "fontSize":
                    return getFontSize(mark.attrs.fontSize || defaultHeadingSize[headingLevel]);
                  case "fontSizeSm":
                    return getFontSizeSm(mark.attrs.fontSizeSm);
                  case "fontSizeLg":
                    return getFontSizeLg(mark.attrs.fontSizeLg);
                  case "leading":
                    return getLeading(mark.attrs.leading);
                  case "tracking":
                    return getTracking(mark.attrs.tracking);
                }
              })
            );
          }
        }
      });

      const isLabel = context.templateCategory === TEMPLATE_CATEGORY_LABEL;

      switch (type) {
        case "heading":
        case "paragraph":
          if (Array.isArray(content)) {
            const Node = as || "p";
            const isHeading = type === "heading";

            return (
              <Node key={index} className={className} style={isLabel ? { ...style, margin: 0 } : style}>
                {inlinePrefix}
                <TextContent
                  content={content}
                  context={context}
                  isEditMode={isEditMode}
                  invertMode={invertMode}
                  headingLevel={isHeading ? attrs?.level : null}
                  intl={intl}
                  LinkComponent={LinkComponent}
                  Tooltip={Tooltip}
                  offsetDays={offsetDays}
                />
              </Node>
            );
          } else {
            // Line break
            return <br key={index} />;
          }
        case "text": {
          const link = marks?.find(({ type }) => type === "link")?.attrs;

          return (
            <span
              key={index}
              className={clsx(link && isEditMode ? "pointer-events-none" : "", className, ...markStyles)}
              style={style}
            >
              {inlinePrefix}
              {link && LinkComponent ? (
                <LinkComponent
                  {...link}
                  isEditMode={isEditMode}
                  siteMap={context.siteMap}
                  isEmbedMode={context.isEmbedMode}
                >
                  {text}
                </LinkComponent>
              ) : (
                text
              )}
            </span>
          );
        }
        case "mention": {
          const text = context.renderParams
            ? renderPlaceholder(context.renderParams, attrs?.id, { offsetDays })
            : formatPlaceholderName(attrs?.id, intl, isLabel);

          return (
            <span
              key={index}
              className={clsx(
                ...(isEditMode
                  ? [
                      "outline outline-2 rounded",
                      isLabel ? "-outline-offset-4" : "-outline-offset-1",
                      invertMode
                        ? context?.loading
                          ? "bg-gray-500 outline-gray-100 text-white animate-pulse"
                          : "bg-yellow-500 outline-yellow-100 text-white"
                        : context?.loading
                        ? "outline-gray-600 bg-gray-50 text-gray-600 animate-pulse"
                        : "outline-yellow-600 bg-yellow-50 text-gray-600",
                    ]
                  : []),
                className,
                ...markStyles
              )}
              style={style}
            >
              {Tooltip ? (
                <Tooltip tip={formatPlaceholderName(attrs?.id, intl)} span>
                  {text}
                </Tooltip>
              ) : (
                text
              )}
            </span>
          );
        }
        case "bulletList":
        case "orderedList": {
          const Node = type === "bulletList" ? "ul" : "ol";

          return (
            <Node
              key={index}
              className={clsx(className, type === "bulletList" ? "list-disc" : "list-decimal")}
              style={style}
            >
              <TextContent
                content={content}
                context={context}
                isEditMode={isEditMode}
                invertMode={invertMode}
                intl={intl}
                LinkComponent={LinkComponent}
                Tooltip={Tooltip}
                offsetDays={offsetDays}
              />
            </Node>
          );
        }
        case "listItem":
          return (
            <li key={index} className={className} style={style}>
              <TextContent
                content={content}
                context={context}
                isEditMode={isEditMode}
                invertMode={invertMode}
                intl={intl}
                LinkComponent={LinkComponent}
                Tooltip={Tooltip}
                offsetDays={offsetDays}
              />
            </li>
          );
      }
    })
  ) : placeholder ? (
    <span />
  ) : null;
}
