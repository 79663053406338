const options = {
  normal: "xl:justify-normal",
  center: "xl:justify-center",
  end: "xl:justify-end",
  start: "xl:justify-start",
  between: "xl:justify-between",
  around: "xl:justify-around",
  evenly: "xl:justify-evenly",
};

export default function getJustifyItemsXl(justify) {
  return options[justify];
}
