import {
  PLACEHOLDER_ORDER_PACKAGE_DATE,
  PLACEHOLDER_ORDER_PACKAGE_ID,
  PLACEHOLDER_ORDER_PACKAGE_INDEX,
  PLACEHOLDER_ORDER_PACKAGE_ITEM_INDEXES,
  PLACEHOLDER_ORDER_PACKAGE_LOCATION,
} from "./constants/content.js";
import formatDate from "./formatDate.js";

export default function formatTemplatePackages(order, dateFormat) {
  const { packs, stores, packDates, packDate, packIndexes } = order;

  return (packs || []).map((id, index) => {
    const date = Array.isArray(packDates) ? packDates[index] : packDate;

    return {
      [PLACEHOLDER_ORDER_PACKAGE_ID]: id,
      [PLACEHOLDER_ORDER_PACKAGE_INDEX]: index + 1,
      [PLACEHOLDER_ORDER_PACKAGE_LOCATION]: Array.isArray(stores) ? stores[index] : null,
      [PLACEHOLDER_ORDER_PACKAGE_DATE]: date ? formatDate(date, dateFormat) : null,
      [PLACEHOLDER_ORDER_PACKAGE_ITEM_INDEXES]: Array.isArray(packIndexes)
        ? packIndexes[index]
          ? packIndexes[index].split(",").map((index) => parseInt(index))
          : null
        : null,
    };
  });
}
