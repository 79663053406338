import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useServiceAdjustmentQuery } from "@easybiz/web-firebase";
import { ADJUSTMENT_MODE_DISCOUNT } from "@easybiz/utils";
import { Avatar, List } from "antd";
import React from "react";
import IconButton from "../IconButton";
import { useClientErrorColor, useClientPrimaryColor } from "@easybiz/shell";

export default ({ selects, serviceId, mode, onSelect }) => {
  const options = useServiceAdjustmentQuery(serviceId, mode, true);
  const isDiscount = mode === ADJUSTMENT_MODE_DISCOUNT;
  const discountColor = useClientPrimaryColor();
  const surchargeColor = useClientErrorColor();

  return (
    <List
      style={{ width: 400, maxHeight: 400, overflowY: "auto" }}
      dataSource={options || []}
      loading={!options}
      renderItem={(item) => {
        const selected = Boolean(selects?.find((select) => select.id === item.id));

        return (
          <List.Item
            actions={
              selected
                ? [<IconButton icon={<DeleteOutlined />} danger onClick={() => onSelect(item)} />]
                : [<IconButton icon={<PlusOutlined />} onClick={() => onSelect(item)} />]
            }
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  size={"small"}
                  style={{
                    backgroundColor: isDiscount ? discountColor : surchargeColor,
                  }}
                >
                  {isDiscount ? <MinusOutlined /> : <PlusOutlined />}
                </Avatar>
              }
              title={item.title}
            />
          </List.Item>
        );
      }}
    />
  );
};
