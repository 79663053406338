const values = {
  none: "sm:shadow-none",
  sm: "sm:shadow-sm",
  md: "sm:shadow-md",
  lg: "sm:shadow-sm",
  xl: "sm:shadow-xl",
  "2xl": "sm:shadow-2xl",
  inner: "sm:shadow-inner",
};

export default function getShadowSm(value) {
  return values[value];
}
