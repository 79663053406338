import { DeleteOutlined, PrinterOutlined, SettingOutlined } from "@ant-design/icons";
import { PrinterIODisplay, PrinterTypeDisplay } from "@easybiz/component";
import { useAdminPrinterRegister } from "@easybiz/api-admin";
import { Alert, Button, Space } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import PrinterSettingDialog from "../PrinterSettingDialog";
import { useConnectedPrinter } from "@easybiz/hook";

export default function ({ printerType }) {
  const printer = useConnectedPrinter(printerType);
  const [settingVisible, setSettingVisible] = useState(false);
  const { onSubmit, loading } = useAdminPrinterRegister();

  return (
    <>
      <Alert
        type={printer ? "success" : "error"}
        showIcon
        icon={<PrinterOutlined style={{ fontSize: 24 }} />}
        message={
          printer ? (
            <FormattedMessage
              defaultMessage={`{printerType} set to {printerName}`}
              values={{
                printerType: <PrinterTypeDisplay printerType={printerType} />,
                printerName: (
                  <b>
                    {printer.productName} (<PrinterIODisplay io={printer.io} />)
                  </b>
                ),
              }}
            />
          ) : (
            <FormattedMessage defaultMessage={"Printer not connected"} />
          )
        }
        action={
          printer ? (
            <Space>
              <Button style={{ width: 64 }} icon={<SettingOutlined />} onClick={() => setSettingVisible(true)} />
              <Button
                danger
                icon={<DeleteOutlined />}
                loading={loading}
                onClick={() =>
                  onSubmit({
                    printerType,
                    printer,
                    deleted: true,
                  })
                }
              >
                <FormattedMessage tagName={"span"} defaultMessage={"Forgot"} />
              </Button>
            </Space>
          ) : null
        }
      />
      <PrinterSettingDialog
        open={settingVisible}
        onCancel={() => setSettingVisible(false)}
        printerType={printerType}
      />
    </>
  );
}
