"use client";

import clsx from "clsx";
import LocaleLink from "./LocaleLink";
import Spinner from "./Spinner";

export default function Button({
  buttonRef,
  href,
  danger,
  small,
  large,
  huge,
  disabled,
  loading,
  children,
  type,
  className,
  accept,
  roundedLeft,
  roundedFull,
  buttonType,
  onClick,
  fontClassName,
  ...props
}) {
  let colorNames;

  switch (type) {
    case "primary":
      colorNames = clsx(
        "text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        danger
          ? "bg-error-600 hover:bg-error-500 focus-visible:outline-error-600"
          : "bg-primary-900 hover:bg-primary-700 focus-visible:outline-secondary-500"
      );
      break;
    case "link":
      colorNames = clsx(
        "focus:outline-none focus:ring-2",
        danger ? "focus:ring-error-500" : "focus:ring-secondary-500",
        danger ? "bg-error-50 text-error-600 hover:bg-error-100" : "text-secondary-600 hover:bg-secondary-100"
      );
      break;
    case "text":
      colorNames = clsx(
        "focus:outline-none focus:ring-2",
        danger ? "focus:ring-error-500" : "focus:ring-secondary-500",
        danger ? "text-error-600 hover:bg-error-50" : "text-gray-600 hover:bg-gray-100"
      );
      break;
    default:
      colorNames = clsx(
        "bg-white shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 focus:ring-secondary-500",
        danger ? "ring-error-300 hover:bg-error-50 text-error-600" : "ring-gray-300 hover:bg-gray-50 text-gray-900"
      );
  }

  const finalNames = clsx(
    className,
    "inline-flex items-center justify-center font-semibold",
    roundedLeft ? "rounded-l-md" : roundedFull ? "rounded-full" : "rounded-md",
    small
      ? "gap-x-1 px-2 py-1"
      : large
      ? "gap-x-2 px-3 sm:px-3.5 py-2.5"
      : huge
      ? "gap-x-3 px-3 sm:px-6 py-3"
      : "gap-x-1.5 px-3.5 sm:px-3 py-2.5 sm:py-2",
    fontClassName || (small ? "text-xs" : "text-sm"),
    colorNames,
    disabled || loading ? "opacity-50 cursor-not-allowed" : "hover:cursor-pointer"
  );

  return href && !disabled ? (
    <LocaleLink href={href} target={props.target} className={finalNames} disabled={disabled ?? loading} type="button">
      {loading && <Spinner />}
      {children}
    </LocaleLink>
  ) : (
    <button
      ref={buttonRef}
      {...props}
      onClick={disabled || loading ? null : onClick}
      type={buttonType || "button"}
      className={finalNames}
      disabled={disabled ?? loading}
    >
      {loading && <Spinner />}
      {children}
    </button>
  );
}
