const options = {
  0: "lg:border-b-0",
  1: "lg:border-b",
  2: "lg:border-b-2",
  4: "lg:border-b-4",
  8: "lg:border-b-8",
};

export default function getBorderBottomLg(width) {
  return options[width];
}
