export default function formatTSPLImageData(imageData) {
  // Obtain image data using the provided callback
  const pixels = imageData.data;
  const width = imageData.width;
  const height = imageData.height;
  const binaryData = [];

  // Convert image data to bitmap hex data
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x += 8) {
      let byte = 0;
      let mask = 128;
      for (let bit = 0; bit < 8; bit++) {
        const pixelIndex = (y * width + (x + bit)) * 4;
        const r = pixels[pixelIndex];
        const g = pixels[pixelIndex + 1];
        const b = pixels[pixelIndex + 2];
        const a = pixels[pixelIndex + 3];

        const grayscale = 0.299 * r + 0.587 * g + 0.114 * b;
        const isWhite = grayscale > 128 || a < 128; // Text should be black, background should be white
        if (isWhite) {
          byte |= mask;
        }
        mask >>= 1;
      }
      binaryData.push(byte);
    }
  }

  return binaryData;
}
