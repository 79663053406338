export default function getGapXXl(value) {
  switch (value) {
    case 0.5:
      return "xl:gap-x-0.5";
    case 1:
      return "xl:gap-x-1";
    case 2:
      return "xl:gap-x-2";
    case 3:
      return "xl:gap-x-3";
    case 4:
      return "xl:gap-x-4";
    case 5:
      return "xl:gap-x-5";
    case 6:
      return "xl:gap-x-6";
    case 7:
      return "xl:gap-x-7";
    case 8:
      return "xl:gap-x-8";
    case 9:
      return "xl:gap-x-9";
    case 10:
      return "xl:gap-x-10";
    case 12:
      return "xl:gap-x-12";
    case 14:
      return "xl:gap-x-14";
    case 16:
      return "xl:gap-x-16";
    case 20:
      return "xl:gap-x-20";
    case 24:
      return "xl:gap-x-24";
    case 28:
      return "xl:gap-x-28";
    case 32:
      return "xl:gap-x-32";
    case 36:
      return "xl:gap-x-36";
    case 40:
      return "xl:gap-x-40";
    case 44:
      return "xl:gap-x-44";
  }
}
