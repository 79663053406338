"use client";

import { useClientState, useClientStateSetter } from "@easybiz/shell";
import dayjs from "dayjs";
import { useEffect } from "react";

export default function ClientDateMonitor() {
  const { date } = useClientState();
  const setState = useClientStateSetter();

  useEffect(() => {
    const handler = setInterval(() => {
      if (dayjs().format("YYYY-MM-DD") !== date) {
        setState({ date: dayjs().format("YYYY-MM-DD") });
      }
    }, 5 * 60 * 1000);

    if (!date) {
      setState({ date: dayjs().format("YYYY-MM-DD") });
    }

    return () => clearInterval(handler);
  }, [date]);
}
