
import { getColorHex } from "@easybiz/utils";
import WaveRepeat from "./WaveRepeat";

export default function Wave({ className, theme, color, bgColor, content }) {
  // const inside = className?.includes("top-0") || className?.includes("bottom-0");
  // TODO: Auto detect color based on background color

  const fill = getColorHex(color || bgColor, theme);

  return content === "repeat" ? (
    <WaveRepeat className={className} fill={fill} />
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 1440 320">
      <path fill={fill} className="translate-y-px" fillOpacity="1" d={content}></path>
    </svg>
  );
}
