const options = {
  1: "sm:w-1",
  2: "sm:w-2",
  3: "sm:w-3",
  4: "sm:w-4",
  5: "sm:w-5",
  6: "sm:w-6",
  7: "sm:w-7",
  8: "sm:w-8",
  9: "sm:w-9",
  10: "sm:w-10",
  11: "sm:w-11",
  12: "sm:w-12",
  14: "sm:w-14",
  16: "sm:w-16",
  20: "sm:w-20",
  24: "sm:w-24",
  28: "sm:w-28",
  32: "sm:w-32",
  36: "sm:w-36",
  40: "sm:w-40",
  44: "sm:w-44",
  48: "sm:w-48",
  52: "sm:w-52",
  56: "sm:w-56",
  60: "sm:w-60",
  64: "sm:w-64",
  72: "sm:w-72",
  80: "sm:w-80",
  96: "sm:w-96",
  112: "sm:w-[28rem]",
  128: "sm:w-[32rem]",
  160: "sm:w-[40rem]",
  192: "sm:w-[48rem]",
  "1/2": "sm:w-1/2",
  "1/3": "sm:w-1/3",
  "2/3": "sm:w-2/3",
  "1/5": "sm:w-1/5",
  "2/5": "sm:w-2/5",
  "3/5": "sm:w-3/5",
  "4/5": "sm:w-4/5",
  px: "sm:w-px",
  screen: "sm:w-screen",
  grow: "sm:grow",
  fill: "sm:flex-1",
  full: "sm:w-full",
  ratio: "sm:w-auto",
  auto: "sm:w-auto",
};

export default function getWidthSm(size, parentDisplay) {
  return size === "fill" && !["row", "row-reverse"].includes(parentDisplay) ? "" : options[size];
}
