import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_POS, POS_ACTION_CASH_DRAWER_OPEN } from "@easybiz/utils";
import { useCallback } from "react";

function usePOSCashDrawerOpen(callback) {
  const onSubmit = useCallback(() => {
    return [OPERATION_GROUP_POS, POS_ACTION_CASH_DRAWER_OPEN];
  }, []);

  return useActionCallback(onSubmit, callback);
}

export default usePOSCashDrawerOpen;
