import { COLOR_ACCENT, COLOR_BLACK, COLOR_PRIMARY, COLOR_THIRD, getFillColor500, isDarkTone } from "@easybiz/utils";
import clsx from "clsx";

export default function BackgroundGridFromTopLeftWithColorTiles({ bgColor, theme, context }) {
  const dark = isDarkTone(bgColor, theme);

  return (
    <svg
      className={clsx(
        "absolute inset-0 pointer-events-none h-full w-full [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]",
        dark ? "stroke-gray-600" : "stroke-gray-200"
      )}
      aria-hidden="true"
    >
      <defs>
        <pattern
          id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
          width={200}
          height={200}
          x="50%"
          y={-64}
          patternUnits="userSpaceOnUse"
        >
          <path d="M100 200V.5M.5 .5H200" fill="none" />
        </pattern>
      </defs>
      <svg
        x="50%"
        y={-64}
        className={clsx(
          "overflow-visible",
          bgColor === COLOR_BLACK
            ? getFillColor500({ name: "gray" })
            : bgColor === COLOR_PRIMARY
            ? getFillColor500(context?.theme)
            : bgColor === COLOR_ACCENT
            ? getFillColor500(context?.theme?.accent)
            : bgColor === COLOR_THIRD
            ? getFillColor500(context?.theme?.third)
            : "fill-gray-50"
        )}
      >
        <path
          d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M299.5 800h201v201h-201Z"
          strokeWidth={0}
        />
      </svg>
      <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
    </svg>
  );
}
