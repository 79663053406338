import clsx from "clsx";
import getRoundedLg from "./getRoundedLg";
import getRoundedMd from "./getRoundedMd";
import getRoundedSm from "./getRoundedSm";
import getRoundedXl from "./getRoundedXl";

const values = {
  none: "rounded-none",
  sm: "rounded-sm",
  md: "rounded-md",
  lg: "rounded-lg",
  xl: "rounded-xl",
  "2xl": "rounded-2xl",
  "3xl": "rounded-3xl",
  full: "rounded-full",
};

export default function getRounded(attrs) {
  return clsx(
    values[attrs?.rounded],
    getRoundedSm(attrs?.roundedSm),
    getRoundedMd(attrs?.roundedMd),
    getRoundedLg(attrs?.roundedLg),
    getRoundedXl(attrs?.roundedXl)
  );
}
