export default function toDisplayName(nameKey, context) {
  // TODO: DELETE Backward support;
  if (nameKey?.en) {
    return nameKey.en;
  }

  return `${
    context?.["businessCodes"]?.[nameKey] ||
    context?.["stores"]?.[nameKey] ||
    context?.["webapps"]?.[nameKey] ||
    context?.["accounts"]?.[nameKey] ||
    context?.["serviceNames"]?.[nameKey] ||
    context?.["paymentMethods"]?.[nameKey] ||
    context?.["serviceTypes"]?.[nameKey] ||
    context?.["procedureNames"]?.[nameKey] ||
    context?.["workflows"]?.[nameKey] ||
    context?.["variants"]?.[nameKey] ||
    context?.["products"]?.[nameKey] ||
    context?.["service-options"]?.[nameKey] ||
    context?.["price-lists"]?.[nameKey] ||
    context?.["booking-options"]?.[nameKey] ||
    context?.["pdfTemplates"]?.[nameKey] ||
    context?.["receiptTemplates"]?.[nameKey] ||
    context?.["whatsappTemplates"]?.[nameKey] ||
    context?.["labelTemplates"]?.[nameKey] ||
    ""
  }`;
}
