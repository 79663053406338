export default function formatOpeningHours(array) {
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Filter out days where open is false
  let openingHours = array.filter((item) => item.open !== false);

  // Group days by opening times
  let groups = {};
  openingHours.forEach((item) => {
    const key = item.start + "-" + item.end;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(item.day);
  });

  // Function to get ranges of consecutive days
  function getRanges(days) {
    let ranges = [];
    let mappedDays = [];
    const baseDay = days[0];
    for (const day of days) {
      if (day < baseDay) {
        mappedDays.push(day + 7);
      } else {
        mappedDays.push(day);
      }
    }

    mappedDays.sort((a, b) => a - b);

    let start = mappedDays[0];
    let end = mappedDays[0];

    for (let i = 1; i <= mappedDays.length; i++) {
      const current = mappedDays[i];
      if (current === end + 1) {
        end = current;
      } else {
        ranges.push([start % 7, end % 7]);
        start = current;
        end = current;
      }
    }

    return ranges;
  }

  // Assemble the final output
  let result = [];

  for (const key in groups) {
    let days = groups[key];
    let ranges = getRanges(days);

    let dayStrings = ranges.map((range) => {
      const [start, end] = range;
      if (start === end) {
        return dayNames[start];
      } else {
        return `${dayNames[start]} ~ ${dayNames[end]}`;
      }
    });

    const times = key.replace("-", " - ");
    const dayString = dayStrings.join(", ");
    result.push(`${dayString}: ${times}`);
  }

  return result.join("\n");
}
