import { AmountDisplay } from "@easybiz/component";
import {
  useCheckoutOutstanding,
  useCheckoutParams,
  useCheckoutParamsUpdate,
  useCheckoutPricing,
  useCheckoutService,
  useClientState, useClientStateSetter,
} from "@easybiz/shell";
import {
  BUSINESS_SECTOR_ALTERATION,
  BUSINESS_SECTOR_BAG_SERVICE,
  DISPLAY_MODE_SCHEDULE,
  isPudBusiness,
} from "@easybiz/utils";
import { ClearShoppingCarButton, OrderPricingPanel, Title } from "@easybiz/web-admin";
import { Checkbox, Col, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import CheckoutCollectionScheduleButton from "./CheckoutCollectionScheduleButton";
import NewOrderPaymentButton from "./NewOrderPaymentButton";

export default function MainActionNewOrderPanel() {
  const service = useCheckoutService();
  const totalPayable = useCheckoutOutstanding();
  const updateCheckoutParams = useCheckoutParamsUpdate();
  const { displayMode } = useClientState();
  const setState = useClientStateSetter();
  const pricing = useCheckoutPricing();
  const { address, returnSchedule, collectionPlace, pricingTBD } = useCheckoutParams();
  const isBagAlter = [BUSINESS_SECTOR_BAG_SERVICE, BUSINESS_SECTOR_ALTERATION].indexOf(service?.sector) >= 0;

  return (
    <div className="flex flex-fill scroll-container">
      <OrderPricingPanel
        header={
          <div className="flex flex-row flex-space-between padding-horizontal">
            <span>
              <FormattedMessage defaultMessage={"Line items preview"} />{" "}
              <b>
                (
                <FormattedMessage
                  defaultMessage={"Total {count} unit"}
                  values={{
                    count: pricing?.totalUnit,
                  }}
                />
                )
              </b>
            </span>
            {pricing?.totalUnit > 0 ? <ClearShoppingCarButton /> : null}
          </div>
        }
      />
      <Row gutter={[8, 8]} className="padding-half">
        <Col span={24} className="flex flex-row flex-space-between flex-align-end">
          {isPudBusiness(service?.sector) ? (
            <CheckoutCollectionScheduleButton
              address={address}
              returnSchedule={returnSchedule}
              collectionPlace={collectionPlace}
              selected={displayMode?.key === DISPLAY_MODE_SCHEDULE}
              onSelect={() =>
                setState({
                  displayMode: displayMode?.key === DISPLAY_MODE_SCHEDULE ? null : { key: DISPLAY_MODE_SCHEDULE },
                })
              }
            />
          ) : (
            <span />
          )}
          <div className="flex flex-align-end">
            {isBagAlter && (
              <Checkbox checked={pricingTBD} onChange={(e) => updateCheckoutParams({ pricingTBD: e.target.checked })}>
                <FormattedMessage tagName="span" defaultMessage="Need further confirmation" />
              </Checkbox>
            )}
            <div className="flex flex-row flex-wrap flex-end">
              {pricingTBD && (
                <Title fontSize={22} type="warning">
                  <FormattedMessage defaultMessage={"Estimated"} />
                </Title>
              )}
              <Title fontSize={24} type={pricingTBD ? "warning" : null} style={{ paddingLeft: 4 }}>
                <AmountDisplay value={totalPayable || 0} />
              </Title>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <NewOrderPaymentButton />
        </Col>
      </Row>
    </div>
  );
}
