import renderPlaceholder from "./renderPlaceholder.js";

export default function renderRichContentToText(json, renderParams, textJoin, renderId, joinSingleString = true) {
  const isWhatsAppRender = Array.isArray(renderParams);

  const formattedContent = (json?.content || [])
    .map(({ content, type }) => {
      return type === "paragraph"
        ? (content || [])
            .map(({ attrs, type, text }) => {
              switch (type) {
                case "text":
                  return text;
                case "mention":
                  return isWhatsAppRender
                    ? `@parameter`
                    : renderParams
                    ? renderPlaceholder(renderParams, attrs?.id, json)
                    : typeof renderId === "function"
                    ? renderId(attrs?.id)
                    : attrs?.id;
              }
            })
            .filter(Boolean)
        : null;
    })
    .filter(Boolean);

  let paragraphs;

  if (isWhatsAppRender) {
    let renderIndex = 0;

    paragraphs = formattedContent.map((paragraph) =>
      paragraph
        .map((word) => (word === "@parameter" ? renderParams[renderIndex++]?.text : word))
        .filter(Boolean)
        .join(" ")
    );
  } else {
    paragraphs = formattedContent.map((paragraph) => paragraph.join(textJoin ?? ""));
  }

  return joinSingleString ? paragraphs.join("\n") : paragraphs;
}
