const values = {
  static: "lg:static",
  relative: "lg:relative",
  fixed: "lg:fixed",
  absolute: "lg:absolute",
  sticky: "lg:sticky",
};

export default function getPositionLg(positionLg) {
  return values[positionLg];
}
