const options = {
  1: "lg:row-span-1",
  2: "lg:row-span-2",
  3: "lg:row-span-3",
  4: "lg:row-span-4",
};

export default function getGridRowSpanLg(span) {
  return options[span];
}
