"use client";

function speak(message) {
  if ("speechSynthesis" in window) {
    var msg = new SpeechSynthesisUtterance(message);
    window.speechSynthesis.speak(msg);
  }
}

export default speak;
