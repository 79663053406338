import { useCheckoutBusinessServiceTypes } from "@easybiz/shell";
import { Radio, Spin } from "antd";
import React from "react";

export default ({ option, onChange }) => {
  const serviceTypes = useCheckoutBusinessServiceTypes();

  return serviceTypes ? (
    <Radio.Group
      options={serviceTypes.map((option) => ({
        value: option.id,
        label: option.title,
      }))}
      onChange={(e) => {
        const option = serviceTypes.find((option) => option.id === e.target.value);

        if (option) {
          onChange({
            id: option.id,
            mode: option.mode,
            title: option.title,
            ...(option.code && { code: option.code }),
          });
        }
      }}
      value={option?.id}
      optionType="button"
      buttonStyle="solid"
    />
  ) : (
    <Spin />
  );
};
