import { getBackgroundColor, getRingColor, getShadowColor } from "@easybiz/utils";
import clsx from "clsx";

export default function BackgroundShadowSlashFull({ bgColor, theme }) {
  return (
    <>
      <div className="absolute inset-0  h-full w-full pointer-events-none bg-gradient-to-b from-indigo-100/20" />
      <div
        className={clsx(
          "absolute inset-y-0 right-1/2 pointer-events-none -mr-96 w-[200%] origin-top-right skew-x-[-30deg] shadow-xl ring-1 sm:-mr-80 lg:-mr-96",
          getShadowColor(theme),
          getRingColor({ ...theme, value: 50 }),
          getBackgroundColor(bgColor, theme)
        )}
        aria-hidden="true"
      />
      <div className="absolute inset-x-0 -bottom-0 pointer-events-none h-24 bg-gradient-to-t from-white sm:h-32" />
    </>
  );
}
