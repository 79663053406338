const options = {
  visible: "sm:overflow-x-visible",
  hidden: "sm:overflow-x-hidden",
  auto: "sm:overflow-x-auto",
  clip: "sm:overflow-x-clip",
};

export default function getOverflowXSm(value) {
  return options[value];
}
