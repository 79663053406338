const options = {
  1: "xl:row-span-1",
  2: "xl:row-span-2",
  3: "xl:row-span-3",
  4: "xl:row-span-4",
};

export default function getGridRowSpanXl(span) {
  return options[span];
}
