const values = {
  0: "lg:pt-0",
  0.5: "lg:pt-0.5",
  1: "lg:pt-1",
  1.5: "lg:pt-1.5",
  2: "lg:pt-2",
  2.5: "lg:pt-2.5",
  3: "lg:pt-3",
  3.5: "lg:pt-3.5",
  4: "lg:pt-4",
  5: "lg:pt-5",
  6: "lg:pt-6",
  7: "lg:pt-7",
  8: "lg:pt-8",
  9: "lg:pt-9",
  10: "lg:pt-10",
  12: "lg:pt-12",
  14: "lg:pt-14",
  16: "lg:pt-16",
  20: "lg:pt-20",
  24: "lg:pt-24",
  28: "lg:pt-28",
  32: "lg:pt-32",
  36: "lg:pt-36",
  40: "lg:pt-40",
  44: "lg:pt-44",
  48: "lg:pt-48",
  52: "lg:pt-52",
  56: "lg:pt-56",
  60: "lg:pt-60",
  64: "lg:pt-64",
  72: "lg:pt-72",
  80: "lg:pt-80",
  96: "lg:pt-96",
};

export default function getPaddingTopLg(value) {
  return values[value];
}
