"use client";

import { WorkflowApp } from "@easybiz/component";
import { ClientProvider } from "@easybiz/shell";
import { CLIENT_POS, PLATFORM_APP_THEME, PLATFORM_WEB } from "@easybiz/utils";
import { SplashScreen } from "@easybiz/web-ui";
import { initializeApp } from "firebase/app";
import { getAuth, onIdTokenChanged, signInWithCustomToken, signOut } from "firebase/auth";
import {
  arrayUnion,
  deleteDoc,
  doc,
  getFirestore,
  increment,
  initializeFirestore,
  onSnapshot,
  persistentLocalCache,
  persistentMultipleTabManager,
  runTransaction,
  setDoc,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getId, getInstallations, onIdChange } from "firebase/installations";
import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import ErrorBoundary from "./ErrorBoundary";
import ESCEncoder from "./ESCEncoder";
import getDeviceInfo from "./getDeviceInfo";
import QueryTokenAutoSignIn from "./QueryTokenAutoSignIn";
import useBluetoothIO from "./useBluetoothIO";
import useOnlineStatus from "./useOnlineStatus";
import useServiceWorkerUpdate from "./useServiceWorkerUpdate";
import useUsbIO from "./useUsbIO";
import WebKeyboardListener from "./WebKeyboardListener";

const firebaseApp = initializeApp({
  apiKey: process.env.NEXT_PUBLIC_apiKey || process.env.REACT_APP_apiKey,
  authDomain: process.env.NEXT_PUBLIC_authDomain || process.env.REACT_APP_authDomain,
  databaseURL: process.env.NEXT_PUBLIC_databaseURL || process.env.REACT_APP_databaseURL,
  projectId: process.env.NEXT_PUBLIC_projectId || process.env.REACT_APP_projectId,
  storageBucket: process.env.NEXT_PUBLIC_storageBucket || process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.NEXT_PUBLIC_messagingSenderId || process.env.REACT_APP_messagingSenderId,
  appId: process.env.NEXT_PUBLIC_appId || process.env.REACT_APP_appId,
});

let offlineEnabled = false;

if (typeof window !== "undefined") {
  offlineEnabled = !window.localStorage.getItem("disableoffline");

  if (offlineEnabled) {
    initializeFirestore(firebaseApp, {
      localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
    });
  }
}

if (process.env.NODE_ENV === "production" && typeof window !== "undefined") {
  // initializeAppCheck(app, {
  //   provider: new ReCaptchaV3Provider(process.env.NEXT_PUBLIC_siteKey),
  //   // Optional argument. If true, the SDK automatically refreshes App Check
  //   // tokens as needed.
  //   isTokenAutoRefreshEnabled: true,
  // });
}

export default function WebWorkflowApp({ children, client, lang, version, messages, onUpdateLocale, toaster, app }) {
  const usbIO = useUsbIO();
  const bluetoothIO = useBluetoothIO();
  const hasNetwork = useOnlineStatus();
  const onOTAUpdate = useServiceWorkerUpdate();

  useEffect(() => {}, []);

  return (
    <IntlProvider locale={lang} messages={messages}>
      <ErrorBoundary doc={doc} setDoc={setDoc} getFirestore={getFirestore} increment={increment}>
        <ClientProvider
          platform={PLATFORM_WEB}
          client={client}
          lang={lang}
          theme={PLATFORM_APP_THEME}
          version={version}
          offlineEnabled={offlineEnabled}
          hasNetwork={hasNetwork}
          onOTAUpdate={onOTAUpdate}
          onUpdateLocale={onUpdateLocale}
        >
          <WorkflowApp
            app={app}
            splashScreen={client !== CLIENT_POS ? <SplashScreen /> : null}
            toaster={toaster}
            localStorage={typeof localStorage !== "undefined" ? localStorage : null}
            getDeviceInfo={getDeviceInfo}
            // Printing
            ESCEncoder={ESCEncoder}
            usbIO={usbIO}
            bluetoothIO={bluetoothIO}
            // Installations
            getId={getId}
            getInstallations={getInstallations}
            onIdChange={onIdChange}
            // Auth
            onIdTokenChanged={onIdTokenChanged}
            signInWithCustomToken={signInWithCustomToken}
            signOut={signOut}
            getAuth={getAuth}
            // Firestore
            onSnapshot={onSnapshot}
            runTransaction={runTransaction}
            doc={doc}
            getFirestore={getFirestore}
            deleteDoc={deleteDoc}
            setDoc={setDoc}
            arrayUnion={arrayUnion}
            // Functions
            getFunctions={getFunctions}
            httpsCallable={httpsCallable}
            connectFunctionsEmulator={connectFunctionsEmulator}
          >
            {children}
            <WebKeyboardListener />
            <QueryTokenAutoSignIn />
          </WorkflowApp>
        </ClientProvider>
      </ErrorBoundary>
    </IntlProvider>
  );
}
