import { FilePdfFilled } from "@ant-design/icons";
import { useAdminInvoicePreview } from "@easybiz/api-admin";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";

export default function ({ orderIds, businessCode, customer, onPreviewLoaded }) {
  const { onSubmit, loading } = useAdminInvoicePreview((succeed, response) => {
    if (succeed) {
      onPreviewLoaded(response.invoice);
    }
  });

  return (
    <Button
      type={"default"}
      icon={<FilePdfFilled />}
      block
      loading={loading}
      onClick={() => {
        onSubmit({
          businessCode,
          customer,
          orderIds,
        });
      }}
    >
      <FormattedMessage tagName="span" defaultMessage="Invoice" />
      {orderIds?.length ? `(${orderIds.length})` : null}
    </Button>
  );
}
