import {
  CODE_DIVIDER,
  QR_CODE_TYPE_ITEM,
  QR_CODE_TYPE_ORDER,
  QR_CODE_TYPE_PACK
} from "./constants/index.js";

export function toItemQR(id) {
  return `${QR_CODE_TYPE_ITEM}${CODE_DIVIDER}${id || ""}`;
}

export function toOrderQR(content) {
  return `${QR_CODE_TYPE_ORDER}${CODE_DIVIDER}${content || ""}`;
}

export function toPackQR(id) {
  return `${QR_CODE_TYPE_PACK}${CODE_DIVIDER}${id || ""}`;
}
