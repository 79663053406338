const options = {
  repeat: "bg-repeat",
  "no-repeat": "bg-no-repeat",
  "repeat-x": "bg-repeat-x",
  "repeat-y": "bg-repeat-y",
  space: "bg-repeat-space",
  round: "bg-repeat-round",
};

export default function getBgImageRepeat(bgRepeat) {
  return options[bgRepeat];
}
