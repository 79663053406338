"use client";

import usePaymentMethodDisplay from "../usePaymentMethodDisplay";

function PaymentMethodDisplay({ option, title }) {
  const display = usePaymentMethodDisplay(option, title);
  return display || title || null;
}

export default PaymentMethodDisplay;
