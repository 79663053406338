import clsx from "clsx";
import getGapX from "./getGapX";
import getGapXLg from "./getGapXLg";
import getGapXSm from "./getGapXSm";
import getGapXXl from "./getGapXXl";
import getGapY from "./getGapY";
import getGapYLg from "./getGapYLg";
import getGapYSm from "./getGapYSm";
import getGapYXl from "./getGapYXl";

export default function getGaps(attrs) {
  return clsx(
    getGapY(attrs?.gapY),
    getGapX(attrs?.gapX),
    getGapYSm(attrs?.gapYSm),
    getGapXSm(attrs?.gapXSm),
    getGapYLg(attrs?.gapYLg),
    getGapXLg(attrs?.gapXLg),
    getGapYXl(attrs?.gapYXl),
    getGapXXl(attrs?.gapXXl)
  );
}
