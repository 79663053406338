const options = {
  5: "bg-teal-500/5",
  10: "bg-teal-500/10",
  25: "bg-teal-500/25",
  50: "bg-teal-500/50",
  75: "bg-teal-500/75",
  90: "bg-teal-500/90",
  100: "bg-teal-500",
};

const from = {
  5: "from-teal-500/5",
  10: "from-teal-500/10",
  25: "from-teal-500/25",
  50: "from-teal-500/50",
  75: "from-teal-500/75",
  90: "from-teal-500/90",
  100: "from-teal-500",
};

export default function getBackgroundTeal500(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
