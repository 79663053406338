"use client";

import { createContext, useContext, useMemo, useState } from "react";

const AppDrawerContext = createContext();

export function AppDrawerProvider({ children }) {
  const [stack, setStack] = useState([]);

  const context = useMemo(() => {
    return {
      route: stack[stack.length - 1],
      openInbox: (params) =>
        setStack((stack) => {
          const inbox = stack.find((route) => route.inbox);

          return [
            ...stack.filter((route) => !route.inbox),
            {
              ...(inbox || { inbox: true }),
              ...params,
            },
          ];
        }),
      openGuide: (guide) => {
        setStack((stack) => {
          return [
            ...stack.filter((route) => !route.guidePath),
            guide,
          ];
        });
      },
      onClose: () => setStack((stack) => stack.slice(0, -1)),
      onUpdate: (updates) => {
        setStack((stack) =>
          stack.map((route, index) => (index === stack.length - 1 ? { ...route, ...updates } : route))
        );
      },
    };
  }, [stack]);

  return <AppDrawerContext.Provider value={context}>{children}</AppDrawerContext.Provider>;
}

export const useAppDrawerOpenInbox = () => useContext(AppDrawerContext).openInbox;
export const useAppDrawerOpenGuide = () => useContext(AppDrawerContext).openGuide;
export const useAppDrawerUpdate = () => useContext(AppDrawerContext).onUpdate;
export const useAppDrawerClose = () => useContext(AppDrawerContext).onClose;
export const useAppDrawer = () => useContext(AppDrawerContext).route;
