"use client";

import React, { createContext, useContext, useMemo, useRef } from "react";

const KeyboardContext = createContext();

function addListener(ref, listener) {
  if (Array.isArray(ref.current)) {
    ref.current.push(listener);
  } else {
    ref.current = [listener];
  }
}

function removeListener(ref, listener) {
  const index = Array.isArray(ref.current) ? ref.current.findIndex((data) => data === listener) : -1;
  if (index >= 0) {
    ref.current.splice(index, 1);
  }
}

export function KeyboardProvider({ children }) {
  const dialPadListenerRef = useRef();
  const returnListenerRef = useRef();

  const context = useMemo(() => {
    return {
      dialPadListenerRef,
      returnListenerRef,
      addDialPadListener: (listener) => {
        addListener(dialPadListenerRef, listener);
      },
      removeDialPadListener: (listener) => {
        removeListener(dialPadListenerRef, listener);
      },
      addReturnListener: (listener) => {
        addListener(returnListenerRef, listener);
      },
      removeReturnListener: (listener) => {
        removeListener(returnListenerRef, listener);
      },
    };
  }, []);

  return <KeyboardContext.Provider value={context}>{children}</KeyboardContext.Provider>;
}

export const useKeyboardDialPadListenerRef = () => useContext(KeyboardContext).dialPadListenerRef;
export const useKeyboardReturnListenerRef = () => useContext(KeyboardContext).returnListenerRef;
export const useKeyboardAddDialPadListener = () => useContext(KeyboardContext).addDialPadListener;
export const useKeyboardRemoveDialPadListener = () => useContext(KeyboardContext).removeDialPadListener;
export const useKeyboardAddReturnListener = () => useContext(KeyboardContext).addReturnListener;
export const useKeyboardRemoveReturnListener = () => useContext(KeyboardContext).removeReturnListener;
