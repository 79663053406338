"use client";

import { CONVERSATION_STATUS_ACTIVE, CONVERSATION_STATUS_CLOSED, CONVERSATION_STATUS_PENDING } from "@easybiz/utils";
import React, { useContext, useMemo, useState } from "react";

const ChatContext = React.createContext();

export function ChatProvider({ children }) {
  const [conversationState, setConversationState] = useState(null);
  const [chatUnreadState, setChatUnreadState] = useState(null);

  const context = useMemo(() => {
    return {
      conversationState,
      unreadConversation: Object.keys(chatUnreadState || {}).filter((key) => chatUnreadState[key] > 0).length,
      totalUnassigned: conversationState?.[CONVERSATION_STATUS_PENDING],
      totalOpen: conversationState?.[CONVERSATION_STATUS_ACTIVE],
      totalClosed: conversationState?.[CONVERSATION_STATUS_CLOSED],
      setConversationState,
      setChatUnreadState,
    };
  }, [conversationState, chatUnreadState]);

  return <ChatContext.Provider value={context}>{children}</ChatContext.Provider>;
}

export const useChatConversationUnreadCount = () => useContext(ChatContext).unreadConversation || 0;
export const useChatConversationUnassignedCount = () => useContext(ChatContext).totalUnassigned || 0;
export const useChatConversationOpenCount = () => {
  const { totalOpen, totalUnassigned } = useContext(ChatContext);
  return (totalOpen || 0) + (totalUnassigned || 0);
};
export const useChatConversationClosedCount = () => useContext(ChatContext).totalClosed || 0;
export const useChatUnreadCount = (conversationId) => {
  const { chatUnreadState } = useContext(ChatContext);
  return chatUnreadState?.[conversationId] || 0;
};
export const useChatConversationStateSetter = () => useContext(ChatContext).setConversationState;
export const useChatUnreadStateSetter = () => useContext(ChatContext).setChatUnreadState;
