import { COLOR_BLACK, COLOR_TRANSPARENT, COLOR_WHITE } from "./constants/template.js";
import isDarkTone from "./isDarkTone.js";
import parseColorNameValue from "./parseColorNameValue.js";

const COLOR_100 = {
  slate: "text-slate-100",
  gray: "text-gray-100",
  zinc: "text-zinc-100",
  neutral: "text-neutral-100",
  stone: "text-stone-100",
  red: "text-red-100",
  orange: "text-orange-100",
  amber: "text-amber-100",
  yellow: "text-yellow-100",
  lime: "text-lime-100",
  green: "text-green-100",
  emerald: "text-emerald-100",
  teal: "text-teal-100",
  cyan: "text-cyan-100",
  sky: "text-sky-100",
  blue: "text-blue-100",
  indigo: "text-indigo-100",
  violet: "text-violet-100",
  purple: "text-purple-100",
  fuchsia: "text-fuchsia-100",
  pink: "text-pink-100",
  rose: "text-rose-100",
};

const COLOR_200 = {
  slate: "text-slate-200",
  gray: "text-gray-200",
  zinc: "text-zinc-200",
  neutral: "text-neutral-200",
  stone: "text-stone-200",
  red: "text-red-200",
  orange: "text-orange-200",
  amber: "text-amber-200",
  yellow: "text-yellow-200",
  lime: "text-lime-200",
  green: "text-green-200",
  emerald: "text-emerald-200",
  teal: "text-teal-200",
  cyan: "text-cyan-200",
  sky: "text-sky-200",
  blue: "text-blue-200",
  indigo: "text-indigo-200",
  violet: "text-violet-200",
  purple: "text-purple-200",
  fuchsia: "text-fuchsia-200",
  pink: "text-pink-200",
  rose: "text-rose-200",
};

const COLOR_300 = {
  slate: "text-slate-300",
  gray: "text-gray-300",
  zinc: "text-zinc-300",
  neutral: "text-neutral-300",
  stone: "text-stone-300",
  red: "text-red-300",
  orange: "text-orange-300",
  amber: "text-amber-300",
  yellow: "text-yellow-300",
  lime: "text-lime-300",
  green: "text-green-300",
  emerald: "text-emerald-300",
  teal: "text-teal-300",
  cyan: "text-cyan-300",
  sky: "text-sky-300",
  blue: "text-blue-300",
  indigo: "text-indigo-300",
  violet: "text-violet-300",
  purple: "text-purple-300",
  fuchsia: "text-fuchsia-300",
  pink: "text-pink-300",
  rose: "text-rose-300",
};

const COLOR_400 = {
  slate: "text-slate-400",
  gray: "text-gray-400",
  zinc: "text-zinc-400",
  neutral: "text-neutral-400",
  stone: "text-stone-400",
  red: "text-red-400",
  orange: "text-orange-400",
  amber: "text-amber-400",
  yellow: "text-yellow-400",
  lime: "text-lime-400",
  green: "text-green-400",
  emerald: "text-emerald-400",
  teal: "text-teal-400",
  cyan: "text-cyan-400",
  sky: "text-sky-400",
  blue: "text-blue-400",
  indigo: "text-indigo-400",
  violet: "text-violet-400",
  purple: "text-purple-400",
  fuchsia: "text-fuchsia-400",
  pink: "text-pink-400",
  rose: "text-rose-400",
};

const COLOR_500 = {
  slate: "text-slate-500",
  gray: "text-gray-500",
  zinc: "text-zinc-500",
  neutral: "text-neutral-500",
  stone: "text-stone-500",
  red: "text-red-500",
  orange: "text-orange-500",
  amber: "text-amber-500",
  yellow: "text-yellow-500",
  lime: "text-lime-500",
  green: "text-green-500",
  emerald: "text-emerald-500",
  teal: "text-teal-500",
  cyan: "text-cyan-500",
  sky: "text-sky-500",
  blue: "text-blue-500",
  indigo: "text-indigo-500",
  violet: "text-violet-500",
  purple: "text-purple-500",
  fuchsia: "text-fuchsia-500",
  pink: "text-pink-500",
  rose: "text-rose-500",
};

const COLOR_600 = {
  slate: "text-slate-600",
  gray: "text-gray-600",
  zinc: "text-zinc-600",
  neutral: "text-neutral-600",
  stone: "text-stone-600",
  red: "text-red-600",
  orange: "text-orange-600",
  amber: "text-amber-600",
  yellow: "text-yellow-600",
  lime: "text-lime-600",
  green: "text-green-600",
  emerald: "text-emerald-600",
  teal: "text-teal-600",
  cyan: "text-cyan-600",
  sky: "text-sky-600",
  blue: "text-blue-600",
  indigo: "text-indigo-600",
  violet: "text-violet-600",
  purple: "text-purple-600",
  fuchsia: "text-fuchsia-600",
  pink: "text-pink-600",
  rose: "text-rose-600",
};

const COLOR_700 = {
  slate: "text-slate-700",
  gray: "text-gray-700",
  zinc: "text-zinc-700",
  neutral: "text-neutral-700",
  stone: "text-stone-700",
  red: "text-red-700",
  orange: "text-orange-700",
  amber: "text-amber-700",
  yellow: "text-yellow-700",
  lime: "text-lime-700",
  green: "text-green-700",
  emerald: "text-emerald-700",
  teal: "text-teal-700",
  cyan: "text-cyan-700",
  sky: "text-sky-700",
  blue: "text-blue-700",
  indigo: "text-indigo-700",
  violet: "text-violet-700",
  purple: "text-purple-700",
  fuchsia: "text-fuchsia-700",
  pink: "text-pink-700",
  rose: "text-rose-700",
};

const COLOR_800 = {
  slate: "text-slate-800",
  gray: "text-gray-800",
  zinc: "text-zinc-800",
  neutral: "text-neutral-800",
  stone: "text-stone-800",
  red: "text-red-800",
  orange: "text-orange-800",
  amber: "text-amber-800",
  yellow: "text-yellow-800",
  lime: "text-lime-800",
  green: "text-green-800",
  emerald: "text-emerald-800",
  teal: "text-teal-800",
  cyan: "text-cyan-800",
  sky: "text-sky-800",
  blue: "text-blue-800",
  indigo: "text-indigo-800",
  violet: "text-violet-800",
  purple: "text-purple-800",
  fuchsia: "text-fuchsia-800",
  pink: "text-pink-800",
  rose: "text-rose-800",
};

const COLOR_900 = {
  slate: "text-slate-900",
  gray: "text-gray-900",
  zinc: "text-zinc-900",
  neutral: "text-neutral-900",
  stone: "text-stone-900",
  red: "text-red-900",
  orange: "text-orange-900",
  amber: "text-amber-900",
  yellow: "text-yellow-900",
  lime: "text-lime-900",
  green: "text-green-900",
  emerald: "text-emerald-900",
  teal: "text-teal-900",
  cyan: "text-cyan-900",
  sky: "text-sky-900",
  blue: "text-blue-900",
  indigo: "text-indigo-900",
  violet: "text-violet-900",
  purple: "text-purple-900",
  fuchsia: "text-fuchsia-900",
  pink: "text-pink-900",
  rose: "text-rose-900",
};

export default function getTextColor(color, context) {
  const { bgColor, theme } = context || {};
  const [name, value] = parseColorNameValue(color, theme, isDarkTone(bgColor, theme));

  switch (name) {
    case COLOR_BLACK:
      return "text-black";
    case COLOR_WHITE:
      return "text-white";
    case COLOR_TRANSPARENT:
      return "text-transparent";
    default: {
      switch (value) {
        case 100:
          return COLOR_100[name];
        case 200:
          return COLOR_200[name];
        case 300:
          return COLOR_300[name];
        case 400:
          return COLOR_400[name];
        case 500:
          return COLOR_500[name];
        case 600:
          return COLOR_600[name];
        case 700:
          return COLOR_700[name];
        case 800:
          return COLOR_800[name];
        case 900:
          return COLOR_900[name];
      }
    }
  }
}
