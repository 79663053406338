const ROWS = {
  1: "xl:grid-rows-1",
  2: "xl:grid-rows-2",
  3: "xl:grid-rows-3",
  4: "xl:grid-rows-4",
};

export default function getGridRowsXl(value) {
  return ROWS[value];
}
