import { COLOR_TRANSPARENT } from "./constants/template.js";

export default function getComponentProps(
  { id, name, type, attrs, content, childKeys, ...subComponents },
  intl,
  context,
  parent
) {
  return {
    id,
    name,
    type,
    attrs,
    content,
    child: Array.isArray(childKeys)
      ? {
          ...subComponents,
          keys: childKeys,
          ids: childKeys.map((key) => subComponents[key]?.id || key),
        }
      : null,
    intl,
    context: {
      ...context,
      ...(attrs?.bgColor &&
        attrs.bgColor !== COLOR_TRANSPARENT && {
          bgColor: attrs.bgColor,
        }),
      ...(Number.isInteger(context?.viewLevel) && {
        viewLevel: context?.viewLevel + 1,
      }),
      ...(parent && {
        parent: {
          id: parent.id,
          type: parent.type,
          keys: parent.child?.keys,
          ids: parent.child?.ids,
          attrs: parent.attrs,
          content: parent.content,
        },
      }),
    },
  };
}
