import { usePendingActionCount } from "@easybiz/hook";
import { useCheckoutEditOrderSetter, useUserBusinessCode } from "@easybiz/shell";
import { ACTION_ACK_PRICE, ACTION_AGREE_PRICE, ACTION_CONFIRM_PRICE } from "@easybiz/utils";
import { usePendingActionDoc } from "@easybiz/web-firebase";
import { Badge, Tabs } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import ConfirmACKDialog from "./ConfirmACKDialog";
import OrderPendingList from "./OrderPendingList";
import PanelHeader from "./PanelHeader";
export default ({ onClose }) => {
  const setConfirmOrder = useCheckoutEditOrderSetter();
  const businessCode = useUserBusinessCode();
  const pendingConfirm = usePendingActionCount(usePendingActionDoc(ACTION_CONFIRM_PRICE), businessCode);
  const pendingAgree = usePendingActionCount(usePendingActionDoc(ACTION_AGREE_PRICE), businessCode);
  const pendingACK = usePendingActionCount(usePendingActionDoc(ACTION_ACK_PRICE), businessCode);
  const [ackOrder, setAckOrder] = useState();

  return (
    <div className="flex flex-fill scroll-container">
      <PanelHeader title={<FormattedMessage defaultMessage={"Pending Price Confirmation"} />} onClose={onClose} />
      <Tabs type="card" className="full-width">
        <Tabs.TabPane
          key={"pending"}
          tab={
            <Badge count={pendingConfirm}>
              <FormattedMessage tagName="span" defaultMessage="Pending confirm" />
            </Badge>
          }
        >
          <OrderPendingList
            awaitAction={ACTION_CONFIRM_PRICE}
            onSelect={(order) => {
              setConfirmOrder(order);
              onClose();
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key={"awaiting"}
          tab={
            <Badge count={pendingAgree}>
              <FormattedMessage defaultMessage="Customer confirm" />
            </Badge>
          }
          forceRender
        >
          <OrderPendingList awaitAction={ACTION_AGREE_PRICE} onSelect={setAckOrder} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key={"ack"}
          tab={
            <Badge count={pendingACK}>
              <FormattedMessage defaultMessage="Acknowledge" />
            </Badge>
          }
          forceRender
        >
          <OrderPendingList awaitAction={ACTION_ACK_PRICE} onSelect={setAckOrder} />
        </Tabs.TabPane>
      </Tabs>
      <ConfirmACKDialog order={ackOrder} open={Boolean(ackOrder)} onCancel={() => setAckOrder(null)} />
    </div>
  );
};
