import formatPrice from "./formatPrice.js";

export default function calculateIncludedTax({ netSales, totalWaived, priceList }) {
  const totalPayable = totalWaived > 0 ? formatPrice(netSales - totalWaived) : netSales || 0;

  if (totalPayable > 0 && priceList?.taxType && typeof priceList.taxRate === "number") {
    const amount = formatPrice((totalPayable / (100 + priceList.taxRate)) * priceList.taxRate);

    return {
      type: priceList.taxType,
      rate: priceList.taxRate,
      amount,
      totalWithOutTax: formatPrice(totalPayable - amount),
    };
  }
}
