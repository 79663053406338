import { EditOutlined } from "@ant-design/icons";
import { useJobUpdateContact } from "@easybiz/api-job";
import { JOB_STATUS_COMPLETE } from "@easybiz/utils";
import { Button, Form, Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ContactsInput from "../ContactsInput";
import Dialog from "../Dialog";
import Uploader from "../Uploader";
import { useUserDialCode } from "@easybiz/shell";

export default function JobContactUpdateButton({ disabled, task }) {
  const defaultDialCode = useUserDialCode();

  const [open, setVisible] = useState(false);
  const [name, setName] = useState();
  const [tels, setTels] = useState();
  const [note, setNote] = useState();
  const [photoId, setPhotoId] = useState();
  const intl = useIntl();
  const { onSubmit, loading } = useJobUpdateContact((succeed) => succeed && setVisible(false));

  useEffect(() => {
    if (open) {
      setName(task?.get("customer.name"));
      setTels(
        task?.get("customer.tels") ||
          (task?.get("customer.contacts") || []).map((contact, index) => {
            const dialCode = (task.get("customer.dialCodes") || [])[index] || defaultDialCode;
            return {
              number: contact,
              dialCode,
            };
          })
      );
      setNote(task?.get("note"));
      setPhotoId(null);
    }
  }, [open, task]);

  return (
    <>
      <Button
        style={{ minWidth: 64 }}
        icon={<EditOutlined />}
        disabled={disabled || task?.get("status") == JOB_STATUS_COMPLETE}
        onClick={() => setVisible(true)}
      />
      <Dialog
        title={<FormattedMessage defaultMessage="Edit Job" />}
        open={open}
        onCancel={() => setVisible(false)}
        onOk={() =>
          onSubmit({
            taskId: task.id,
            name,
            tels,
            note,
            photoId,
          })
        }
        confirmLoading={loading}
        destroyOnClose
      >
        <Form layout="vertical">
          <Form.Item label={<FormattedMessage defaultMessage="Customer profile" />}>
            <Space direction="vertical" className="full-width">
              <Input value={name} disabled={disabled} onChange={(e) => setName(e.target.value)} />
              <ContactsInput tels={tels} disabled={disabled} onChange={setTels} />
            </Space>
          </Form.Item>
          <Form.Item label={<FormattedMessage defaultMessage="Note" />}>
            <Input.TextArea
              placeholder={intl.formatMessage({ defaultMessage: "Job note" })}
              value={note}
              disabled={disabled}
              onChange={(e) => setNote(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={<FormattedMessage defaultMessage="Photos" />}>
            <Uploader
              disabled={disabled}
              photoId={photoId}
              onUploaded={setPhotoId}
              resizeHeight={800}
              resizeWidth={800}
              width={300}
              expireDays={180}
            />
          </Form.Item>
        </Form>
      </Dialog>
    </>
  );
}
