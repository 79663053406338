import { useActionCallback } from "@easybiz/hook";
import { CUSTOMER_ACTION_TIME_SLOT_OPTIONS, OPERATION_GROUP_CUSTOMER } from "@easybiz/utils";

function useCustomerBookingOptions(callback) {
  return useActionCallback(
    ({ customerId, businessCode, serviceId, address, orderId, taskId, newTaskType, serviceTypes, select }) => {
      return [
        OPERATION_GROUP_CUSTOMER,
        CUSTOMER_ACTION_TIME_SLOT_OPTIONS,
        {
          customerId,
          businessCode,
          serviceId,
          address,
          ...(orderId && { orderId }),
          ...(taskId && { taskId }),
          ...(newTaskType && { newTaskType }),
          ...(select && { select }),
          ...(Array.isArray(serviceTypes) && { serviceTypes }),
        },
      ];
    },
    callback
  );
}

export default useCustomerBookingOptions;
