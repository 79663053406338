"use client";

import { createContext, useContext, useMemo, useState } from "react";
import { useClientStateSetter } from "./ClientContext";

const PrintingContext = createContext();

export function PrintingProvider({ ESCEncoder, usbIO, bluetoothIO, connectPrinter, children }) {
  const [bleReceiptPrinter, setBleReceiptPrinter] = useState();
  const [bleLabelPrinter, setBleLabelPrinter] = useState();
  const setState = useClientStateSetter();

  const context = useMemo(() => {
    return {
      ESCEncoder,
      usbIO,
      bluetoothIO,
      connectPrinter: connectPrinter || ((printerPairing) => setState({ printerPairing })),
      bleReceiptPrinter,
      bleLabelPrinter,
      setBleReceiptPrinter,
      setBleLabelPrinter,
    };
  }, [ESCEncoder, usbIO, bluetoothIO, connectPrinter, bleReceiptPrinter, bleLabelPrinter]);

  return <PrintingContext.Provider value={context}>{children}</PrintingContext.Provider>;
}

export const usePrintingESCEncoder = () => useContext(PrintingContext).ESCEncoder;
export const usePrintingUsbWrite = () => useContext(PrintingContext).usbIO;
export const usePrintingBluetoothWrite = () => useContext(PrintingContext).bluetoothIO;
export const usePrintingConnectPrinter = () => useContext(PrintingContext).connectPrinter;

export const usePrintingBleReceiptPrinter = () => useContext(PrintingContext).bleReceiptPrinter;
export const usePrintingBleReceiptPrinterSetter = () => useContext(PrintingContext).setBleReceiptPrinter;
export const usePrintingBleLabelPrinter = () => useContext(PrintingContext).bleLabelPrinter;
export const usePrintingBleLabelPrinterSetter = () => useContext(PrintingContext).setBleLabelPrinter;
