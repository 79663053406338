"use client";

import { useUserCurrency } from "@easybiz/shell";
import {
  BENEFIT_TYPE_CASH_BACK,
  BENEFIT_TYPE_DISCOUNT,
  BENEFIT_TYPE_MONTHLY_FOC,
  BENEFIT_TYPE_POINTS,
} from "@easybiz/utils";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

function MemberBenefitDisplay({ promoMethod, value }) {
  const currency = useUserCurrency();

  switch (promoMethod) {
    case BENEFIT_TYPE_DISCOUNT:
      return (
        <FormattedMessage
          id="x.percentage.direct.discount"
          defaultMessage={`{amount}% direct discount`}
          values={{ amount: value }}
        />
      );
    case BENEFIT_TYPE_CASH_BACK:
      return (
        <FormattedMessage
          id="x.percentage.cash.back.to.credit.wallet"
          defaultMessage={`{amount}% cash back to credit wallet`}
          values={{ amount: value }}
        />
      );
    case BENEFIT_TYPE_POINTS:
      return (
        <FormattedMessage id="x.times.points" defaultMessage={`{amount} times points`} values={{ amount: value }} />
      );
    case BENEFIT_TYPE_MONTHLY_FOC:
      return (
        <FormattedMessage
          id="x.monthly.free.of.charge.tier"
          defaultMessage={`{amount} monthly free of charge tier`}
          values={{
            amount: <FormattedNumber currency={currency} style="currency" value={value} />,
          }}
        />
      );
    default:
      return null;
  }
}

export default MemberBenefitDisplay;
