import { ContainerOutlined, EditOutlined } from "@ant-design/icons";
import { useOrderRefund } from "@easybiz/api-order";
import { AmountDisplay, PaymentMethodDisplay } from "@easybiz/component";
import { useUserBusinessCode } from "@easybiz/shell";
import { usePrinter } from "@easybiz/hook";
import { PAYMENT_ID_CASH } from "@easybiz/utils";
import { useOrderDoc } from "@easybiz/web-firebase";
import { Alert, Button, Input, Space } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from "../Dialog";
import PaymentMethodSelecter from "../PaymentMethodSelecter";
import Title from "../Title";

export default ({ cashDrawer, open, orderId, onClose, payment, amount }) => {
  const order = useOrderDoc(orderId);
  const businessCode = useUserBusinessCode();
  const [refundDescription, setRefundDescription] = useState("");
  const [refund, setRefund] = useState();
  const printer = usePrinter();
  const intl = useIntl();
  const refundAmount = payment ? payment.v : amount;
  const isCashRefund = payment ? payment.m === PAYMENT_ID_CASH : refund?.option === PAYMENT_ID_CASH;
  const cashNotEnough = isCashRefund && cashDrawer ? refundAmount > cashDrawer.balance : false;
  const { onSubmit, loading } = useOrderRefund((succeed, response, request) => {
    if (succeed) {
      onClose();

      if (request.payment?.m === PAYMENT_ID_CASH) {
        // Open cash drawer
        printer.openDrawer(true);
      }
    }
  });

  useEffect(() => {
    if (open) {
      setRefundDescription("");
      setRefund(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onCancel={onClose}
      confirmLoading={loading}
      title={
        payment ? (
          <FormattedMessage tagName="span" defaultMessage="Payment Refund" />
        ) : (
          <FormattedMessage tagName="span" defaultMessage="Refund" />
        )
      }
      okText={<FormattedMessage tagName="span" defaultMessage="Refund" />}
      onOk={() =>
        onSubmit({
          orderId: order.id,
          businessCode,
          payment,
          amount,
          note: refundDescription,
          option: refund?.option,
        })
      }
      okButtonProps={cashNotEnough ? { disabled: true } : null}
      okDanger
    >
      <Space direction="vertical" size={"large"} className="full-width">
        {payment ? (
          <>
            <Title type="danger">
              <FormattedMessage
                defaultMessage={"Refund amount {amount}"}
                values={{
                  amount: (
                    <b>
                      <AmountDisplay value={payment.v} />
                    </b>
                  ),
                }}
              />
            </Title>
            <Title>
              <FormattedMessage
                tagName="span"
                defaultMessage="Refund by {option}"
                values={{ option: <PaymentMethodDisplay option={payment.m} /> }}
              />
            </Title>
            <Input.TextArea
              autoFocus
              value={refundDescription}
              placeholder={intl.formatMessage({
                defaultMessage: "Refund note",
              })}
              onChange={(e) => setRefundDescription(e.target.value)}
              disabled={cashNotEnough || loading}
            />
          </>
        ) : (
          <>
            <Title type="danger">
              <FormattedMessage
                defaultMessage={"Refund amount {amount}"}
                values={{
                  amount: (
                    <b>
                      <AmountDisplay value={amount} />
                    </b>
                  ),
                }}
              />
            </Title>
            <Space>
              <Title>
                <FormattedMessage
                  tagName="span"
                  defaultMessage="Refund by {option}"
                  values={{
                    option: refund?.option && <PaymentMethodDisplay option={refund?.option} />,
                  }}
                />
              </Title>
              {refund && (
                <Button icon={<EditOutlined />} disabled={loading} onClick={() => setRefund(null)}>
                  <FormattedMessage tagName={"span"} defaultMessage={"Edit"} />
                </Button>
              )}
            </Space>
            {!refund && (
              <PaymentMethodSelecter
                customerId={order?.get("customer.id")}
                payment={refund}
                onSelect={setRefund}
                amount={amount}
                isRefund
                enablePoints
              />
            )}
          </>
        )}
        {cashNotEnough && (
          <Alert
            type="error"
            showIcon
            icon={<ContainerOutlined />}
            message={<FormattedMessage defaultMessage={"Cash Drawer Balance Not Enough"} />}
            description={
              <FormattedMessage
                defaultMessage={"Current cash drawer balance: {amount}"}
                values={{
                  amount: (
                    <b>
                      <AmountDisplay value={cashDrawer?.balance} />
                    </b>
                  ),
                }}
              />
            }
          />
        )}
      </Space>
    </Dialog>
  );
};
