import { useActionCallback } from "@easybiz/hook";
import { ADMIN_ACTION_PHOTO_DELETE, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useAdminPhotoDelete(callback) {
  const intl = useIntl();

  return useActionCallback(
    ({ photoId, orderId, localIndex }) => {
      return [
        OPERATION_GROUP_ADMIN,
        ADMIN_ACTION_PHOTO_DELETE,
        {
          orderId,
          photoId,
          ...(Number.isInteger(localIndex) && { localIndex }),
        },
      ];
    },
    callback,
    intl.formatMessage({ defaultMessage: "Photo deleted" })
  );
}

export default useAdminPhotoDelete;
