const fontSize = {
  xs: "lg:text-xs",
  sm: "lg:text-sm",
  base: "lg:text-base",
  lg: "lg:text-lg",
  xl: "lg:text-xl",
  "2xl": "lg:text-2xl",
  "3xl": "lg:text-3xl",
  "4xl": "lg:text-4xl",
  "5xl": "lg:text-5xl",
  "6xl": "lg:text-6xl",
  "7xl": "lg:text-7xl",
  "8xl": "lg:text-8xl",
  "9xl": "lg:text-9xl",
};

export default function getFontSizeLg(value) {
  return fontSize[value];
}
