"use client";

import { useChatUnreadStateSetter, useUserRealmId, useUserUID } from "@easybiz/shell";
import { STATUSES_KEY_CHAT_UNREAD } from "@easybiz/utils";
import { useEffect } from "react";

export default function ChatUnreadMonitor({ onSnapshot, doc, getFirestore }) {
  const realmId = useUserRealmId();
  const setChatUnread = useChatUnreadStateSetter();
  const uid = useUserUID();

  useEffect(() => {
    if (realmId && uid) {
      const handler = onSnapshot(
        doc(getFirestore(), `realms/${realmId}/statuses/${STATUSES_KEY_CHAT_UNREAD}/keys/${uid}`),
        (snapshot) => setChatUnread(snapshot.get("data") || {})
      );
      return handler;
    }
  }, [realmId, uid]);
}
