import { SendOutlined } from "@ant-design/icons";
import { useAdminEventTemplateSend } from "@easybiz/api-admin";
import { useClientStateSetter, useUserBusinessCode, useUserRealmId } from "@easybiz/shell";
import { EVENT_TYPE_ORDER_READY_FOR_COLLECTION } from "@easybiz/utils";
import { Button } from "antd";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PopConfirm from "../PopConfirm";

export default ({ order, disabled }) => {
  const businessCode = useUserBusinessCode();
  const realmId = useUserRealmId();
  const [noticeVisible, setNoticeVisible] = useState(false);
  const [email, setEmail] = useState(null);
  const setState = useClientStateSetter();
  const { onSubmit, loading } = useAdminEventTemplateSend((succeed, response) => {
    if (succeed) {
      setState({ emailId: response.emailId });
    }
  }, true);

  useEffect(() => {
    if (realmId && noticeVisible && order?.get("customer.id")) {
      setEmail(null);
      getDoc(doc(getFirestore(), `realms/${realmId}/customers/${order?.get("customer.id")}`)).then((customer) => {
        setEmail(customer.get("email") || false);
      });
    }
  }, [realmId, noticeVisible, order?.get("customer.id")]);

  return (
    <PopConfirm
      open={noticeVisible}
      onOpenChange={setNoticeVisible}
      danger={email === false}
      title={
        email === false ? (
          <FormattedMessage defaultMessage={"Customer account do not have a valid email address"} />
        ) : (
          <FormattedMessage defaultMessage={"Send ready for collection email to {email}?"} values={{ email }} />
        )
      }
      okButtonProps={{ disabled: !email }}
      okText={<FormattedMessage tagName={"span"} defaultMessage={"Send email"} />}
      onConfirm={() => {
        setNoticeVisible(false);
        onSubmit({
          sendTo: email,
          email: EVENT_TYPE_ORDER_READY_FOR_COLLECTION,
          eventType: EVENT_TYPE_ORDER_READY_FOR_COLLECTION,
          orderId: order.id,
          collectionPlace: businessCode,
        });
      }}
    >
      <Button block icon={<SendOutlined />} loading={loading} disabled={disabled}>
        <FormattedMessage tagName="span" defaultMessage="Collection notice" />
      </Button>
    </PopConfirm>
  );
};
