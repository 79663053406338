"use client";

import { PRINTER_IO_BLUETOOTH, PRINTER_IO_USB } from "@easybiz/utils";
import React from "react";
import { FormattedMessage } from "react-intl";

function PrinterIODisplay({ io }) {
  switch (io) {
    case PRINTER_IO_USB:
      return <FormattedMessage id="usb" defaultMessage="USB" />;
    case PRINTER_IO_BLUETOOTH:
      return <FormattedMessage id="bluetooth" defaultMessage="Bluetooth" />;
    default:
      return null;
  }
}

export default PrinterIODisplay;
