import { AmountDisplay, DateDisplay } from "@easybiz/component";
import { useUserBusinessCode, useUserRealmId } from "@easybiz/shell";
import {
  DRAWER_TRANSACTION_TYPE_CASH_IN,
  DRAWER_TRANSACTION_TYPE_CASH_OUT,
  DRAWER_TRANSACTION_TYPE_PAYMENT,
  DRAWER_TRANSACTION_TYPE_REFUND,
} from "@easybiz/utils";
import { LoadMoreButton } from "@easybiz/web-admin";
import { Drawer, List, Typography, message } from "antd";
import { collection, getDocs, getFirestore, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default ({ startTime, type, open, onCancel }) => {
  const businessCode = useUserBusinessCode();
  const realmId = useUserRealmId();
  const [loading, setLoading] = useState(false);
  const [cashNotes, setCashNotes] = useState(null);
  const intel = useIntl();

  useEffect(() => {
    setCashNotes(null);
    setLoading(false);
  }, [open]);

  const onLoad = (reset) => {
    let operation = query(
      collection(getFirestore(), `realms/${realmId}/cashnotes`),
      where("businessCode", "==", businessCode),
      where(
        "type",
        type === DRAWER_TRANSACTION_TYPE_CASH_IN ? "in" : "==",
        type === DRAWER_TRANSACTION_TYPE_CASH_IN
          ? [DRAWER_TRANSACTION_TYPE_CASH_IN, DRAWER_TRANSACTION_TYPE_CASH_OUT]
          : type
      ),
      where("timestamp", ">=", startTime),
      orderBy("timestamp", "desc"),
      limit(10)
    );

    if (!reset && cashNotes && cashNotes.length > 0) {
      operation = query(operation, startAfter(cashNotes[cashNotes.length - 1]));
    }

    setLoading(true);
    getDocs(operation)
      .then((result) => {
        if (result.empty && !reset) {
          message.info(intel.formatMessage({ defaultMessage: "All transactions loaded" }));
        }

        if (reset) {
          setCashNotes(result.docs);
        } else {
          setCashNotes([...(cashNotes || []), ...result.docs]);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (realmId && businessCode && startTime && type) {
      onLoad(true);
    }
  }, [realmId, businessCode, startTime, type]);

  const title = useMemo(() => {
    switch (type) {
      case DRAWER_TRANSACTION_TYPE_PAYMENT:
        return <FormattedMessage defaultMessage={"Payments"} />;
      case DRAWER_TRANSACTION_TYPE_REFUND:
        return <FormattedMessage defaultMessage={"Refunds"} />;
      case DRAWER_TRANSACTION_TYPE_CASH_IN:
        return <FormattedMessage defaultMessage={"Cash In/Out"} />;
    }
  }, [type]);

  return (
    <Drawer title={title} open={open} onClose={onCancel} bodyStyle={{ padding: 0 }} width={470}>
      <List
        dataSource={cashNotes || []}
        size="small"
        loading={!cashNotes}
        renderItem={(cashNote) => {
          const { remark, createDate, operator, amount, type } = cashNote.data();
          const isIn = [DRAWER_TRANSACTION_TYPE_CASH_IN, DRAWER_TRANSACTION_TYPE_PAYMENT].indexOf(type) >= 0;

          return (
            <List.Item
              extra={
                <Typography.Text type={isIn ? "success" : "danger"} strong>
                  {isIn ? `+` : `-`}
                  <AmountDisplay value={amount} />
                </Typography.Text>
              }
            >
              <List.Item.Meta
                title={remark}
                description={
                  <span>
                    <DateDisplay {...createDate} /> ({operator.name})
                  </span>
                }
              />
            </List.Item>
          );
        }}
        footer={<LoadMoreButton loading={loading} dataSource={cashNotes} onClick={() => onLoad()} />}
      />
    </Drawer>
  );
};
