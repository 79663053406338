"use client";

import { BUSINESS_SECTOR_LAUNDRY } from "@easybiz/utils";
import { FormattedMessage } from "react-intl";
import DateDisplay from "../DateDisplay";
import NameDisplay from "../NameDisplay";
import NA from "../NA";

function useOrderCollectionPanel(order) {
  const { collectionPlace, delivery, service } = order?.data() || {};
  const isLaundry = service?.sector === BUSINESS_SECTOR_LAUNDRY;
  const collection = (
    <FormattedMessage
      id="collect.at.x"
      defaultMessage={"Collect at {storeName}"}
      values={{
        storeName: collectionPlace ? <NameDisplay nameKey={collectionPlace} showKey /> : <NA />,
      }}
    />
  );

  return {
    title: isLaundry ? delivery?.date && <DateDisplay date={delivery.date} /> : collection,
    subTitle: isLaundry ? collection : null,
    homeDeliveryText: <FormattedMessage id="home.delivery" defaultMessage="Home delivery" />,
    rescheduleText: <FormattedMessage id="reschedule" defaultMessage="Reschedule" />,
  };
}

export default useOrderCollectionPanel;
