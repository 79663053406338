const values = {
  none: "xl:shadow-none",
  sm: "xl:shadow-sm",
  md: "xl:shadow-md",
  lg: "xl:shadow-xl",
  xl: "xl:shadow-xl",
  "2xl": "xl:shadow-2xl",
  inner: "xl:shadow-inner",
};

export default function getShadowXl(value) {
  return values[value];
}
