import { useClientHasNetwork, useClientType, useFeedbackToast, useActionServerFunction } from "@easybiz/shell";
import { ADMIN_ACTION_DEVICE_SIGN_OUT, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useCallback } from "react";

export default function useWorkflowSignOut(signOut, getAuth) {
  const httpsFunction = useActionServerFunction();
  const hasNetwork = useClientHasNetwork();
  const client = useClientType();
  const toast = useFeedbackToast();

  return useCallback(
    async (title, description) => {
      return new Promise((resolve) => {
        if (hasNetwork) {
          httpsFunction({
            group: OPERATION_GROUP_ADMIN,
            type: ADMIN_ACTION_DEVICE_SIGN_OUT,
            client,
          })
            .then(resolve)
            .catch(resolve);
        } else {
          resolve();
        }
      }).then(() => {
        signOut(getAuth());

        if (typeof title === "string") {
          toast.info(title, {
            title,
            description: typeof description === "string" ? description : null,
            dialog: true,
          });
        }
      });
    },
    [hasNetwork, client]
  );
}
