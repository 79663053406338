const options = {
  5: "bg-cyan-50/5",
  10: "bg-cyan-50/10",
  25: "bg-cyan-50/25",
  50: "bg-cyan-50/50",
  75: "bg-cyan-50/75",
  90: "bg-cyan-50/90",
  100: "bg-cyan-50",
};

const from = {
  5: "from-cyan-50/5",
  10: "from-cyan-50/10",
  25: "from-cyan-50/25",
  50: "from-cyan-50/50",
  75: "from-cyan-50/75",
  90: "from-cyan-50/90",
  100: "from-cyan-50",
};

export default function getBackgroundCyan50(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
