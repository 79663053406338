const options = {
  1: "lg:h-1",
  2: "lg:h-2",
  3: "lg:h-3",
  4: "lg:h-4",
  5: "lg:h-5",
  6: "lg:h-6",
  7: "lg:h-7",
  8: "lg:h-8",
  9: "lg:h-9",
  10: "lg:h-10",
  11: "lg:h-11",
  12: "lg:h-12",
  14: "lg:h-14",
  16: "lg:h-16",
  20: "lg:h-20",
  24: "lg:h-24",
  28: "lg:h-28",
  32: "lg:h-32",
  36: "lg:h-36",
  40: "lg:h-40",
  44: "lg:h-44",
  48: "lg:h-48",
  52: "lg:h-52",
  56: "lg:h-56",
  60: "lg:h-60",
  64: "lg:h-64",
  72: "lg:h-72",
  80: "lg:h-80",
  96: "lg:h-96",
  112: "lg:h-[28rem]",
  128: "lg:h-[32rem]",
  "1/2": "lg:h-1/2",
  "1/3": "lg:h-1/3",
  "2/3": "lg:h-2/3",
  "1/5": "lg:h-1/5",
  "2/5": "lg:h-2/5",
  "3/5": "lg:h-3/5",
  "4/5": "lg:h-4/5",
  px: "lg:h-px",
  screen: "lg:h-screen",
  fill: "lg:grow",
  full: "lg:h-full",
  ratio: "lg:h-auto",
  auto: "lg:h-auto",
};

export default function getHeightLg(size, parentDisplay) {
  return size === "fill" && !["col", "col-reverse"].includes(parentDisplay || "col") ? "" : options[size];
}
