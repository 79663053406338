"use client";

import {
  PERMISSION_ACCESS_CUSTOMER_DATABASE,
  PERMISSION_ACCESS_ORDER_DATABASE,
  PERMISSION_LEVEL_FULL,
} from "@easybiz/constants";
import { useRealmPOSSettings } from "@easybiz/shell";

export default function usePOSPermission(permissionId) {
  const posSettings = useRealmPOSSettings();
  return (
    posSettings?.permissions?.find((permission) => permission.id === permissionId) || {
      ...([PERMISSION_ACCESS_CUSTOMER_DATABASE, PERMISSION_ACCESS_ORDER_DATABASE].includes(permissionId) && {
        level: PERMISSION_LEVEL_FULL,
      }),
    }
  );
}
