export const JOB_TYPE_PICKUP = "pickup";
export const JOB_TYPE_DELIVERY = "delivery";
export const JOB_TYPE_SERVICE = "service";

export const JOB_ACTION_KEY_ASSIGN = "assign";
export const JOB_ACTION_KEY_RESCHEDULE = "reschedule";
export const JOB_ACTION_KEY_EDIT = "edit";
export const JOB_ACTION_KEY_COMPLETE = "complete";

export const JOB_STATUS_PENDING = "pending";
export const JOB_STATUS_SCHEDULED = "scheduled";
export const JOB_STATUS_COLLECTED = "collected";
export const JOB_STATUS_FAILED = "failed";
export const JOB_STATUS_COMPLETE = "completed";
export const JOB_STATUS_CANCELLED = "cancelled";

export const LOGISTIC_STATUS_ORDER = [
  JOB_STATUS_CANCELLED,
  JOB_STATUS_COMPLETE,
  JOB_STATUS_PENDING,
  JOB_STATUS_SCHEDULED,
  JOB_STATUS_FAILED,
];

export const JOB_ACTION_ASSIGN_WORKER = 'assign_worker';
export const JOB_ACTION_RESCHEDULE = 'reschedule';
export const JOB_ACTION_UPDATE_DETAIL = 'update_detail';
export const JOB_ACTION_ACCEPT = 'accept';
export const JOB_ACTION_GIVE_UP = 'give_up';
export const JOB_ACTION_FAILED = 'failed';
export const JOB_ACTION_COMPLETE = 'complete';
export const JOB_ACTION_REVERSE = 'reverse';