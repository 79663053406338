import { ArrowLeftOutlined } from "@ant-design/icons";
import { useKeyboardAddDialPadListener, useKeyboardRemoveDialPadListener } from "@easybiz/shell";
import { ReturnListener } from "@easybiz/web-component";
import { Button, Col, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";

export default function ({ value, initial, disabled, onChange, onDone, decimal, disableListener, disableSubmit }) {
  const addEventListener = useKeyboardAddDialPadListener();
  const removeEventListener = useKeyboardRemoveDialPadListener();
  const [select, setSelect] = useState();

  useEffect(() => {
    if (select !== undefined) {
      if (select === null) {
        onChange(``);
      } else if (select === "Backspace") {
        onChange(`${value || ""}`.slice(0, Math.max(0, `${value || ""}`.length - 1)));
      } else if (select) {
        if (select === ".") {
          if (!value || `${value}`.indexOf(".") >= 0) {
            return;
          }
        }

        onChange(`${(!initial && value) || ""}${select}`);
      }

      setSelect(undefined);
    }
  }, [select]);

  useEffect(() => {
    if (!disableListener) {
      addEventListener(setSelect);
      return () => removeEventListener(setSelect);
    }
  }, [Boolean(disableListener), setSelect]);

  const buttons = useMemo(() => {
    return ["1", "2", "3", "4", "5", "6", "7", "8", "9", decimal ? "." : null, "0", "Backspace"];
  }, [decimal]);

  return (
    <Row gutter={[16, 16]} type="flex" justify="center">
      {!disableListener && !disableSubmit && onDone && <ReturnListener callback={onDone} />}
      {buttons.map((key, index) => {
        return (
          <Col key={`${key}${index}`} span={8}>
            <Button
              block
              danger={!key}
              tabIndex={-1}
              disabled={disabled}
              icon={key === "Backspace" ? <ArrowLeftOutlined /> : null}
              variant="outlined"
              onMouseDown={() => setSelect(key)}
            >
              {key === null ? `AC` : key.length === 1 ? key : " "}
            </Button>
          </Col>
        );
      })}
    </Row>
  );
}
