const fontSize = {
  xs: "sm:text-xs",
  sm: "sm:text-sm",
  base: "sm:text-base",
  lg: "sm:text-lg",
  xl: "sm:text-xl",
  "2xl": "sm:text-2xl",
  "3xl": "sm:text-3xl",
  "4xl": "sm:text-4xl",
  "5xl": "sm:text-5xl",
  "6xl": "sm:text-6xl",
  "7xl": "sm:text-7xl",
  "8xl": "sm:text-8xl",
  "9xl": "sm:text-9xl",
};

export default function getFontSizeSm(value) {
  return fontSize[value];
}
