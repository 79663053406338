const options = {
  0: "sm:border-t-0",
  1: "sm:border-t",
  2: "sm:border-t-2",
  4: "sm:border-t-4",
  8: "sm:border-t-8",
};

export default function getBorderTopSm(width) {
  return options[width];
}
