import { DateDisplay, NameDisplay } from "@easybiz/component";
import { OrderList, Title } from "@easybiz/web-admin";
import { useOrderStateQuery } from "@easybiz/web-firebase";
import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default ({ selectOrderId, businessCode, orderType, date, onSelect }) => {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(5);
  const [orders, loading] = useOrderStateQuery({
    businessCode,
    orderType,
    startTime: date.startOf("day").unix(),
    endTime: date.endOf("day").unix(),
    limit,
    search,
  });
  const intl = useIntl();

  useEffect(() => {
    if (limit !== 5) {
      setLimit(5);
    }
  }, [date, orderType, businessCode]);

  return (
    <OrderList
      header={
        <div className="flex flex-row flex-space-between flex-align-center padding-horizontal">
          <Title>
            <NameDisplay nameKey={businessCode} />
          </Title>
          <Form autoComplete="off">
            <Input
              placeholder={intl.formatMessage({ defaultMessage: "Day order search" })}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Form>
        </div>
      }
      loading={!orders || loading}
      orders={orders}
      onLoadMore={() => setLimit(limit + 5)}
      onSelect={onSelect}
      selectId={selectOrderId}
      emptyText={
        !search && (
          <FormattedMessage
            defaultMessage={"No order made on {date}"}
            values={{
              date: <DateDisplay date={date.format("YYYY-MM-DD")} />,
            }}
          />
        )
      }
    />
  );
};
