import { useOrderCollectionUpdate } from "@easybiz/api-order";
import { Drawer } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "../Dialog";
import QuickSwitchCalendar from "../QuickSwitchCalendar";

export default ({ placement, order, item, open, onClose }) => {
  const [rescheduleDate, setRescheduleDate] = useState();
  const { onSubmit, loading } = useOrderCollectionUpdate((success) => {
    if (success) {
      setRescheduleDate(null);
      onClose();
    }
  });

  return (
    <Drawer
      width={470}
      height="100%"
      placement={placement}
      open={open}
      onClose={onClose}
      destroyOnClose
      bodyStyle={{ padding: 0 }}
      title={<FormattedMessage defaultMessage="Reschedule" />}
    >
      <QuickSwitchCalendar value={item?.date ? dayjs(item?.date) : null} onSelect={setRescheduleDate} />
      <Dialog
        title={
          <FormattedMessage
            defaultMessage={`Reschedule collection date to {date}?`}
            values={{
              date: rescheduleDate?.format("DD/MM/YYYY"),
            }}
          />
        }
        open={!!rescheduleDate}
        confirmLoading={loading}
        onCancel={() => setRescheduleDate(null)}
        onOk={() =>
          onSubmit({
            orderId: order.id,
            itemIds: (item.labelIndexes || [])
              .map((sortIndex) => order.get("labels")[sortIndex - 1])
              .filter((id) => id),
            date: rescheduleDate.format("YYYY-MM-DD"),
          })
        }
      ></Dialog>
    </Drawer>
  );
};
