const options = {
  visible: "lg:overflow-y-visible",
  hidden: "lg:overflow-y-hidden",
  auto: "lg:overflow-y-auto",
  clip: "lg:overflow-y-clip",
};

export default function getOverflowYLg(value) {
  return options[value];
}
