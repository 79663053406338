const values = {
  0: "left-0",
  1: "left-1",
  2: "left-2",
  3: "left-3",
  4: "left-4",
  5: "left-5",
  6: "left-6",
  7: "left-7",
  8: "left-8",
  9: "left-9",
  10: "left-10",
  11: "left-11",
  12: "left-12",
  14: "left-14",
  16: "left-16",
  20: "left-20",
  "1/2": "left-1/2",
};

export default function getAbsoluteLeft(value) {
  return values[value];
}
