import { PAYMENT_ID_CREDIT } from "@easybiz/utils";
import { useCustomerPaymentMethodQuery, useOfflinePaymentMethodQuery, usePointsWalletDoc, useTopUpWalletDoc } from "@easybiz/web-firebase";
import { Button, Space } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import PaymentMethodButton from "../PaymentMethodButton";
import { useCustomerOnlinePaymentOptions } from "@easybiz/component";

export default function ({
  className,
  customerId,
  payment,
  onSelect,
  amount,
  disableCredit,
  disablePoints,
  enableUnpaid,
  isRefund,
  enablePartial,
  isBatchPayment,
}) {
  const creditWallet = useTopUpWalletDoc(disableCredit ? null : customerId);
  const pointsWallet = usePointsWalletDoc(disablePoints || isRefund ? null : customerId);
  const cards = useCustomerPaymentMethodQuery(isRefund ? null : customerId);
  const onlineOptions = useCustomerOnlinePaymentOptions(
    amount,
    creditWallet,
    pointsWallet,
    cards,
    isRefund,
    enablePartial
  );
  const offlineMethods = useOfflinePaymentMethodQuery(isRefund);
  const disabled = amount === 0;

  const onSelectOption = (payment) => {
    if (payment) {
      onSelect({
        ...payment,
        ...(typeof payment.amount !== "number" && typeof amount === "number" && { amount }),
        ...(payment && payment.note && { note: payment.note }),
        ...(typeof amount === "number" &&
          typeof payment.totalCredits === "number" &&
          payment.totalCredits < amount && {
            amount: payment.totalCredits,
          }),
      });
    }
  };

  return (
    <Space direction="vertical" size={"middle"} className={`full-width${className ? ` ${className}` : ""}`}>
      {onlineOptions.length > 0 && (
        <Space wrap size={"middle"}>
          {onlineOptions.map(
            (paymentMethod) =>
              (!isBatchPayment || paymentMethod.id === PAYMENT_ID_CREDIT) && (
                <PaymentMethodButton
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                  select={payment}
                  onSelect={onSelectOption}
                  disabled={disabled}
                />
              )
          )}
        </Space>
      )}
      <Space wrap size={"middle"}>
        {(offlineMethods || []).map((paymentMethod) => (
          <PaymentMethodButton
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            select={payment}
            onSelect={onSelectOption}
            disabled={disabled}
          />
        ))}
        {enableUnpaid && !isBatchPayment && (
          <Button shape="round" danger type={payment === null ? "primary" : "default"} onClick={() => onSelect(null)}>
            <FormattedMessage defaultMessage="Unpaid" />
          </Button>
        )}
      </Space>
    </Space>
  );
}
