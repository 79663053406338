import {
  LINK_TYPE_EMAIL,
  LINK_TYPE_HTTP,
  LINK_TYPE_TEL,
  LINK_TYPE_WHATSAPP,
  getGeneralHref,
  getPageHref,
} from "@easybiz/utils";
import DraftPageLinkLazy from "./client/DraftPageLinkLazy";

export default function PageLink({
  lang,
  href,
  type,
  hash,
  pageId,
  serviceId,
  serviceSector,
  text,
  tel,
  email,
  subject,
  children,
  params,
  className,
  siteMap,
  isEditMode,
  isEmbedMode,
  selfEditing,
  onClick,
  Link,
}) {
  const generalHref = getGeneralHref({ type, href, text, tel, email, subject });
  const LinkComponent = Link || "a";

  return generalHref || !isEditMode ? (
    <LinkComponent
      href={generalHref || getPageHref({ lang, pageId, serviceId, serviceSector, isEmbedMode, siteMap, params, hash })}
      target={
        [LINK_TYPE_HTTP, LINK_TYPE_EMAIL, LINK_TYPE_TEL, LINK_TYPE_WHATSAPP].includes(type) ? "_blank" : undefined
      }
      aria-label={pageId || email || tel?.intl}
      rel="noopener noreferrer"
      className={className}
      onClick={onClick}
    >
      {children}
    </LinkComponent>
  ) : (
    <DraftPageLinkLazy
      pageId={pageId}
      serviceId={serviceId}
      serviceSector={serviceSector}
      params={params}
      className={className}
      onClick={onClick}
      selfEditing={selfEditing}
    >
      {children}
    </DraftPageLinkLazy>
  );
}
