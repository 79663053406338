const values = {
  center: "sm:justify-self-center",
  end: "sm:justify-self-end",
  start: "sm:justify-self-start",
  stretch: "sm:justify-self-stretch",
  auto: "sm:justify-self-auto",
};

export default function getJustifySelfSm(align) {
  return values[align];
}
