const values = {
  "-32": "lg:-bottom-32",
  "-12": "lg:-bottom-12",
  "-11": "lg:-bottom-11",
  "-10": "lg:-bottom-10",
  "-9": "lg:-bottom-9",
  "-8": "lg:-bottom-8",
  "-7": "lg:-bottom-7",
  "-6": "lg:-bottom-6",
  "-5": "lg:-bottom-5",
  "-4": "lg:-bottom-4",
  "-3": "lg:-bottom-3",
  "-2": "lg:-bottom-2",
  "-1": "lg:-bottom-1",
  0: "lg:bottom-0",
  1: "lg:bottom-1",
  2: "lg:bottom-2",
  3: "lg:bottom-3",
  4: "lg:bottom-4",
  5: "lg:bottom-5",
  6: "lg:bottom-6",
  7: "lg:bottom-7",
  8: "lg:bottom-8",
  9: "lg:bottom-9",
  10: "lg:bottom-10",
  11: "lg:bottom-11",
  12: "lg:bottom-12",
  14: "lg:bottom-14",
  16: "lg:bottom-16",
  20: "lg:bottom-20",
  "1/2": "lg:bottom-1/2",
};

export default function getAbsoluteBottomLg(value) {
  return values[value];
}
