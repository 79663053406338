const options = {
  5: "bg-sky-300/5",
  10: "bg-sky-300/10",
  25: "bg-sky-300/25",
  50: "bg-sky-300/50",
  75: "bg-sky-300/75",
  90: "bg-sky-300/90",
  100: "bg-sky-300",
};

const from = {
  5: "from-sky-300/5",
  10: "from-sky-300/10",
  25: "from-sky-300/25",
  50: "from-sky-300/50",
  75: "from-sky-300/75",
  90: "from-sky-300/90",
  100: "from-sky-300",
};

export default function getBackgroundSky300(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
