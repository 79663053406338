import { getChildProps, getLabelFontWeight, mmToPixel } from "@easybiz/utils";
import LabelComponent from "../LabelComponent";
import Node from "../Node";

export default function PreviewLabel({ className, ...props }) {
  const ComponentTag = props.draft?.Component || LabelComponent;
  const paddingRight = props.attrs.mmMr ? mmToPixel(props.attrs.mmMr, props.attrs.dotsPerMM) : 0;
  const paddingLeft = props.attrs.mmMl ? mmToPixel(props.attrs.mmMl, props.attrs.dotsPerMM) : 0;
  const width = mmToPixel(props?.attrs?.mmWidth || 110, props.attrs.dotsPerMM);
  const height = mmToPixel(props?.attrs?.mmHeight || 10, props.attrs.dotsPerMM);
  const scale = props.attrs.dotsPerMM === 12 ? 2 / 3 : props.attrs.dotsPerMM === 24 ? 1 / 3 : null;
  const transformScale =
    typeof scale === "number" || typeof props.scale === "number" ? (props.scale || 1) * (scale || 1) : null;

  return (
    <div
      className={className}
      style={{
        width: width * (transformScale || 1),
        height: height * (transformScale || 1),
        overflow: "clip",
      }}
    >
      <div
        style={{
          width,
          height,
          paddingLeft,
          paddingRight,
          ...(typeof transformScale === "number" && {
            transform: `scale(${transformScale})`,
            transformOrigin: "top left",
          }),
        }}
      >
        <Node
          {...props}
          style={{
            ...props.style,
            position: "relative",
            ...getLabelFontWeight(props.attrs?.density),
            fontFamily: `ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
            width: "100%",
            height: "100%",
          }}
        >
          {props.child?.keys?.map((key) => {
            const childProps = getChildProps(props, key);

            return (
              <ComponentTag
                key={key}
                {...childProps}
                context={{
                  ...childProps.context,
                  mmWidth: props.attrs?.mmWidth,
                  mmHeight: props.attrs?.mmHeight,
                  contentType: props.content?.contentType,
                }}
                {...(props.draft === true && { draft: true })}
              />
            );
          })}
        </Node>
      </div>
    </div>
  );
}
