import { DeleteOutlined } from "@ant-design/icons";
import { useCheckoutClear } from "@easybiz/shell";
import { Button, message } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PopConfirm from "../PopConfirm";

export default function () {
  const clearCheckOut = useCheckoutClear();
  const intl = useIntl();

  return (
    <PopConfirm
      title={<FormattedMessage tagName="span" defaultMessage="Clear new order line items?" />}
      onConfirm={() => {
        clearCheckOut();
        message.success(intl.formatMessage({ defaultMessage: "New order cleared" }));
      }}
    >
      <Button type="text" size="small" danger icon={<DeleteOutlined />}>
        <span>
          <FormattedMessage defaultMessage={"Clear"} />
        </span>
      </Button>
    </PopConfirm>
  );
}
