import { FlatPercentageDisplay, ScheduleDisableDisplay } from "@easybiz/component";
import { Space, Tag } from "antd";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";

export default ({ option, enabled, disabledType, dayIndex, cutOffTime }) => {
  const { color, title } = useMemo(() => {
    if (enabled) {
      return {
        color: "success",
        ...(option && {
          title: option.title,
        }),
      };
    } else {
      return {
        title: <ScheduleDisableDisplay disabledType={disabledType} dayIndex={dayIndex} cutOffTime={cutOffTime} />,
      };
    }
  }, [enabled, disabledType, dayIndex, cutOffTime]);

  return (
    <Space wrap size="small">
      {title && <Tag color={color}>{title}</Tag>}
      {option?.surcharge && enabled && (
        <Tag color="warning">
          <FlatPercentageDisplay {...option.surcharge} /> <FormattedMessage defaultMessage="surcharge" />
        </Tag>
      )}
    </Space>
  );
};
