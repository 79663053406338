"use client";

import React, { createContext, useContext, useMemo, useState } from "react";
import { useRealmPOSSettings } from "./RealmContext";
import { useUserBusinessCode } from "./UserContext";

const CashDrawerContext = createContext();

export function CashDrawerProvider({ children }) {
  const [cashDrawer, setCashDrawer] = useState(null);
  const { depositCheck } = useRealmPOSSettings();
  const enabled = Boolean(depositCheck);

  const context = useMemo(() => {
    return {
      enabled,
      cashDrawer,
      setCashDrawer,
    };
  }, [cashDrawer, enabled]);

  return <CashDrawerContext.Provider value={context}>{children}</CashDrawerContext.Provider>;
}

export const useCashDrawerIsEnabled = () => useContext(CashDrawerContext).enabled;
export const useCashDrawerIsPendingStart = () => {
  const { cashDrawer } = useContext(CashDrawerContext);
  const enabled = useCashDrawerIsEnabled();

  return Boolean(enabled && cashDrawer && !cashDrawer.startTime);
};

export const useCashDrawer = () => useContext(CashDrawerContext).cashDrawer;
export const useCashDrawerSetter = () => useContext(CashDrawerContext).setCashDrawer;

export const useCashDrawerNextDayDepositAmount = () => {
  const businessCode = useUserBusinessCode();
  const { startingCashShops, depositAmount } = useRealmPOSSettings();

  if (Array.isArray(startingCashShops)) {
    const setting = startingCashShops.find(
      ({ businessCodes }) => Array.isArray(businessCodes) && businessCodes.find((code) => code === businessCode)
    );
    if (setting && typeof setting.amount === "number") {
      return setting.amount;
    }
  }

  return depositAmount;
};
