"use client";

import { useCashDrawerSetter, useUserBusinessCode, useUserRealmId } from "@easybiz/shell";
import { useEffect } from "react";

export default function CashDrawerMonitor({ onSnapshot, doc, getFirestore }) {
  const realmId = useUserRealmId();
  const businessCode = useUserBusinessCode();
  const setCashDrawer = useCashDrawerSetter();

  useEffect(() => {
    setCashDrawer(null);
    if (realmId && businessCode) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/cashdrawer/${businessCode}`), (snapshot) =>
        setCashDrawer(snapshot.data() || {})
      );
    }
  }, [realmId, businessCode]);
}
