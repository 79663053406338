export default function getDisplayLg(display) {
  switch (display) {
    case "block":
      return "lg:block";
    case "inline-block":
      return "lg:inline-block";
    case "grid":
      return "lg:grid";
    case "inline":
      return "lg:inline-flex";
    case "row":
      return "lg:flex lg:flex-row";
    case "col":
      return "lg:flex lg:flex-col";
    case "row-reverse":
      return "lg:flex lg:flex-row-reverse";
    case "col-reverse":
      return "lg:flex lg:flex-col-reverse";
    case "none":
      return "lg:flex-none";
    case "contents":
      return "lg:contents";
    case "hidden":
      return "lg:hidden";
    default:
      return "";
  }
}
