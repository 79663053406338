import { COLOR_BLACK, COLOR_WHITE } from "./constants/template.js";
import getBackgroundAmber100 from "./getBackgroundAmber100.js";
import getBackgroundAmber200 from "./getBackgroundAmber200.js";
import getBackgroundAmber300 from "./getBackgroundAmber300.js";
import getBackgroundAmber400 from "./getBackgroundAmber400.js";
import getBackgroundAmber50 from "./getBackgroundAmber50.js";
import getBackgroundAmber500 from "./getBackgroundAmber500.js";
import getBackgroundAmber600 from "./getBackgroundAmber600.js";
import getBackgroundAmber700 from "./getBackgroundAmber700.js";
import getBackgroundAmber800 from "./getBackgroundAmber800.js";
import getBackgroundAmber900 from "./getBackgroundAmber900.js";
import getBackgroundBlack from "./getBackgroundBlack.js";
import getBackgroundBlue100 from "./getBackgroundBlue100.js";
import getBackgroundBlue200 from "./getBackgroundBlue200.js";
import getBackgroundBlue300 from "./getBackgroundBlue300.js";
import getBackgroundBlue400 from "./getBackgroundBlue400.js";
import getBackgroundBlue50 from "./getBackgroundBlue50.js";
import getBackgroundBlue500 from "./getBackgroundBlue500.js";
import getBackgroundBlue600 from "./getBackgroundBlue600.js";
import getBackgroundBlue700 from "./getBackgroundBlue700.js";
import getBackgroundBlue800 from "./getBackgroundBlue800.js";
import getBackgroundBlue900 from "./getBackgroundBlue900.js";
import getBackgroundCyan100 from "./getBackgroundCyan100.js";
import getBackgroundCyan200 from "./getBackgroundCyan200.js";
import getBackgroundCyan300 from "./getBackgroundCyan300.js";
import getBackgroundCyan400 from "./getBackgroundCyan400.js";
import getBackgroundCyan50 from "./getBackgroundCyan50.js";
import getBackgroundCyan500 from "./getBackgroundCyan500.js";
import getBackgroundCyan600 from "./getBackgroundCyan600.js";
import getBackgroundCyan700 from "./getBackgroundCyan700.js";
import getBackgroundCyan800 from "./getBackgroundCyan800.js";
import getBackgroundCyan900 from "./getBackgroundCyan900.js";
import getBackgroundEmerald100 from "./getBackgroundEmerald100.js";
import getBackgroundEmerald200 from "./getBackgroundEmerald200.js";
import getBackgroundEmerald300 from "./getBackgroundEmerald300.js";
import getBackgroundEmerald400 from "./getBackgroundEmerald400.js";
import getBackgroundEmerald50 from "./getBackgroundEmerald50.js";
import getBackgroundEmerald500 from "./getBackgroundEmerald500.js";
import getBackgroundEmerald600 from "./getBackgroundEmerald600.js";
import getBackgroundEmerald700 from "./getBackgroundEmerald700.js";
import getBackgroundEmerald800 from "./getBackgroundEmerald800.js";
import getBackgroundEmerald900 from "./getBackgroundEmerald900.js";
import getBackgroundFuchsia100 from "./getBackgroundFuchsia100.js";
import getBackgroundFuchsia200 from "./getBackgroundFuchsia200.js";
import getBackgroundFuchsia300 from "./getBackgroundFuchsia300.js";
import getBackgroundFuchsia400 from "./getBackgroundFuchsia400.js";
import getBackgroundFuchsia50 from "./getBackgroundFuchsia50.js";
import getBackgroundFuchsia500 from "./getBackgroundFuchsia500.js";
import getBackgroundFuchsia600 from "./getBackgroundFuchsia600.js";
import getBackgroundFuchsia700 from "./getBackgroundFuchsia700.js";
import getBackgroundFuchsia800 from "./getBackgroundFuchsia800.js";
import getBackgroundFuchsia900 from "./getBackgroundFuchsia900.js";
import getBackgroundGray100 from "./getBackgroundGray100.js";
import getBackgroundGray200 from "./getBackgroundGray200.js";
import getBackgroundGray300 from "./getBackgroundGray300.js";
import getBackgroundGray400 from "./getBackgroundGray400.js";
import getBackgroundGray50 from "./getBackgroundGray50.js";
import getBackgroundGray500 from "./getBackgroundGray500.js";
import getBackgroundGray600 from "./getBackgroundGray600.js";
import getBackgroundGray700 from "./getBackgroundGray700.js";
import getBackgroundGray800 from "./getBackgroundGray800.js";
import getBackgroundGray900 from "./getBackgroundGray900.js";
import getBackgroundGreen100 from "./getBackgroundGreen100.js";
import getBackgroundGreen200 from "./getBackgroundGreen200.js";
import getBackgroundGreen300 from "./getBackgroundGreen300.js";
import getBackgroundGreen400 from "./getBackgroundGreen400.js";
import getBackgroundGreen50 from "./getBackgroundGreen50.js";
import getBackgroundGreen500 from "./getBackgroundGreen500.js";
import getBackgroundGreen600 from "./getBackgroundGreen600.js";
import getBackgroundGreen700 from "./getBackgroundGreen700.js";
import getBackgroundGreen800 from "./getBackgroundGreen800.js";
import getBackgroundGreen900 from "./getBackgroundGreen900.js";
import getBackgroundIndigo100 from "./getBackgroundIndigo100.js";
import getBackgroundIndigo200 from "./getBackgroundIndigo200.js";
import getBackgroundIndigo300 from "./getBackgroundIndigo300.js";
import getBackgroundIndigo400 from "./getBackgroundIndigo400.js";
import getBackgroundIndigo50 from "./getBackgroundIndigo50.js";
import getBackgroundIndigo500 from "./getBackgroundIndigo500.js";
import getBackgroundIndigo600 from "./getBackgroundIndigo600.js";
import getBackgroundIndigo700 from "./getBackgroundIndigo700.js";
import getBackgroundIndigo800 from "./getBackgroundIndigo800.js";
import getBackgroundIndigo900 from "./getBackgroundIndigo900.js";
import getBackgroundLime100 from "./getBackgroundLime100.js";
import getBackgroundLime200 from "./getBackgroundLime200.js";
import getBackgroundLime300 from "./getBackgroundLime300.js";
import getBackgroundLime400 from "./getBackgroundLime400.js";
import getBackgroundLime50 from "./getBackgroundLime50.js";
import getBackgroundLime500 from "./getBackgroundLime500.js";
import getBackgroundLime600 from "./getBackgroundLime600.js";
import getBackgroundLime700 from "./getBackgroundLime700.js";
import getBackgroundLime800 from "./getBackgroundLime800.js";
import getBackgroundLime900 from "./getBackgroundLime900.js";
import getBackgroundNeutral100 from "./getBackgroundNeutral100.js";
import getBackgroundNeutral200 from "./getBackgroundNeutral200.js";
import getBackgroundNeutral300 from "./getBackgroundNeutral300.js";
import getBackgroundNeutral400 from "./getBackgroundNeutral400.js";
import getBackgroundNeutral50 from "./getBackgroundNeutral50.js";
import getBackgroundNeutral500 from "./getBackgroundNeutral500.js";
import getBackgroundNeutral600 from "./getBackgroundNeutral600.js";
import getBackgroundNeutral700 from "./getBackgroundNeutral700.js";
import getBackgroundNeutral800 from "./getBackgroundNeutral800.js";
import getBackgroundNeutral900 from "./getBackgroundNeutral900.js";
import getBackgroundOrange100 from "./getBackgroundOrange100.js";
import getBackgroundOrange200 from "./getBackgroundOrange200.js";
import getBackgroundOrange300 from "./getBackgroundOrange300.js";
import getBackgroundOrange400 from "./getBackgroundOrange400.js";
import getBackgroundOrange50 from "./getBackgroundOrange50.js";
import getBackgroundOrange500 from "./getBackgroundOrange500.js";
import getBackgroundOrange600 from "./getBackgroundOrange600.js";
import getBackgroundOrange700 from "./getBackgroundOrange700.js";
import getBackgroundOrange800 from "./getBackgroundOrange800.js";
import getBackgroundOrange900 from "./getBackgroundOrange900.js";
import getBackgroundPink100 from "./getBackgroundPink100.js";
import getBackgroundPink200 from "./getBackgroundPink200.js";
import getBackgroundPink300 from "./getBackgroundPink300.js";
import getBackgroundPink400 from "./getBackgroundPink400.js";
import getBackgroundPink50 from "./getBackgroundPink50.js";
import getBackgroundPink500 from "./getBackgroundPink500.js";
import getBackgroundPink600 from "./getBackgroundPink600.js";
import getBackgroundPink700 from "./getBackgroundPink700.js";
import getBackgroundPink800 from "./getBackgroundPink800.js";
import getBackgroundPink900 from "./getBackgroundPink900.js";
import getBackgroundPurple100 from "./getBackgroundPurple100.js";
import getBackgroundPurple200 from "./getBackgroundPurple200.js";
import getBackgroundPurple300 from "./getBackgroundPurple300.js";
import getBackgroundPurple400 from "./getBackgroundPurple400.js";
import getBackgroundPurple50 from "./getBackgroundPurple50.js";
import getBackgroundPurple500 from "./getBackgroundPurple500.js";
import getBackgroundPurple600 from "./getBackgroundPurple600.js";
import getBackgroundPurple700 from "./getBackgroundPurple700.js";
import getBackgroundPurple800 from "./getBackgroundPurple800.js";
import getBackgroundPurple900 from "./getBackgroundPurple900.js";
import getBackgroundRed100 from "./getBackgroundRed100.js";
import getBackgroundRed200 from "./getBackgroundRed200.js";
import getBackgroundRed300 from "./getBackgroundRed300.js";
import getBackgroundRed400 from "./getBackgroundRed400.js";
import getBackgroundRed50 from "./getBackgroundRed50.js";
import getBackgroundRed500 from "./getBackgroundRed500.js";
import getBackgroundRed600 from "./getBackgroundRed600.js";
import getBackgroundRed700 from "./getBackgroundRed700.js";
import getBackgroundRed800 from "./getBackgroundRed800.js";
import getBackgroundRed900 from "./getBackgroundRed900.js";
import getBackgroundRose100 from "./getBackgroundRose100.js";
import getBackgroundRose200 from "./getBackgroundRose200.js";
import getBackgroundRose300 from "./getBackgroundRose300.js";
import getBackgroundRose400 from "./getBackgroundRose400.js";
import getBackgroundRose50 from "./getBackgroundRose50.js";
import getBackgroundRose500 from "./getBackgroundRose500.js";
import getBackgroundRose600 from "./getBackgroundRose600.js";
import getBackgroundRose700 from "./getBackgroundRose700.js";
import getBackgroundRose800 from "./getBackgroundRose800.js";
import getBackgroundRose900 from "./getBackgroundRose900.js";
import getBackgroundSky100 from "./getBackgroundSky100.js";
import getBackgroundSky200 from "./getBackgroundSky200.js";
import getBackgroundSky300 from "./getBackgroundSky300.js";
import getBackgroundSky400 from "./getBackgroundSky400.js";
import getBackgroundSky50 from "./getBackgroundSky50.js";
import getBackgroundSky500 from "./getBackgroundSky500.js";
import getBackgroundSky600 from "./getBackgroundSky600.js";
import getBackgroundSky700 from "./getBackgroundSky700.js";
import getBackgroundSky800 from "./getBackgroundSky800.js";
import getBackgroundSky900 from "./getBackgroundSky900.js";
import getBackgroundSlate100 from "./getBackgroundSlate100.js";
import getBackgroundSlate200 from "./getBackgroundSlate200.js";
import getBackgroundSlate300 from "./getBackgroundSlate300.js";
import getBackgroundSlate400 from "./getBackgroundSlate400.js";
import getBackgroundSlate50 from "./getBackgroundSlate50.js";
import getBackgroundSlate500 from "./getBackgroundSlate500.js";
import getBackgroundSlate600 from "./getBackgroundSlate600.js";
import getBackgroundSlate700 from "./getBackgroundSlate700.js";
import getBackgroundSlate800 from "./getBackgroundSlate800.js";
import getBackgroundSlate900 from "./getBackgroundSlate900.js";
import getBackgroundStone100 from "./getBackgroundStone100.js";
import getBackgroundStone200 from "./getBackgroundStone200.js";
import getBackgroundStone300 from "./getBackgroundStone300.js";
import getBackgroundStone400 from "./getBackgroundStone400.js";
import getBackgroundStone50 from "./getBackgroundStone50.js";
import getBackgroundStone500 from "./getBackgroundStone500.js";
import getBackgroundStone600 from "./getBackgroundStone600.js";
import getBackgroundStone700 from "./getBackgroundStone700.js";
import getBackgroundStone800 from "./getBackgroundStone800.js";
import getBackgroundStone900 from "./getBackgroundStone900.js";
import getBackgroundTeal100 from "./getBackgroundTeal100.js";
import getBackgroundTeal200 from "./getBackgroundTeal200.js";
import getBackgroundTeal300 from "./getBackgroundTeal300.js";
import getBackgroundTeal400 from "./getBackgroundTeal400.js";
import getBackgroundTeal50 from "./getBackgroundTeal50.js";
import getBackgroundTeal500 from "./getBackgroundTeal500.js";
import getBackgroundTeal600 from "./getBackgroundTeal600.js";
import getBackgroundTeal700 from "./getBackgroundTeal700.js";
import getBackgroundTeal800 from "./getBackgroundTeal800.js";
import getBackgroundTeal900 from "./getBackgroundTeal900.js";
import getBackgroundViolet100 from "./getBackgroundViolet100.js";
import getBackgroundViolet200 from "./getBackgroundViolet200.js";
import getBackgroundViolet300 from "./getBackgroundViolet300.js";
import getBackgroundViolet400 from "./getBackgroundViolet400.js";
import getBackgroundViolet50 from "./getBackgroundViolet50.js";
import getBackgroundViolet500 from "./getBackgroundViolet500.js";
import getBackgroundViolet600 from "./getBackgroundViolet600.js";
import getBackgroundViolet700 from "./getBackgroundViolet700.js";
import getBackgroundViolet800 from "./getBackgroundViolet800.js";
import getBackgroundViolet900 from "./getBackgroundViolet900.js";
import getBackgroundWhite from "./getBackgroundWhite.js";
import getBackgroundYellow100 from "./getBackgroundYellow100.js";
import getBackgroundYellow200 from "./getBackgroundYellow200.js";
import getBackgroundYellow300 from "./getBackgroundYellow300.js";
import getBackgroundYellow400 from "./getBackgroundYellow400.js";
import getBackgroundYellow50 from "./getBackgroundYellow50.js";
import getBackgroundYellow500 from "./getBackgroundYellow500.js";
import getBackgroundYellow600 from "./getBackgroundYellow600.js";
import getBackgroundYellow700 from "./getBackgroundYellow700.js";
import getBackgroundYellow800 from "./getBackgroundYellow800.js";
import getBackgroundYellow900 from "./getBackgroundYellow900.js";
import getBackgroundZinc100 from "./getBackgroundZinc100.js";
import getBackgroundZinc200 from "./getBackgroundZinc200.js";
import getBackgroundZinc300 from "./getBackgroundZinc300.js";
import getBackgroundZinc400 from "./getBackgroundZinc400.js";
import getBackgroundZinc50 from "./getBackgroundZinc50.js";
import getBackgroundZinc500 from "./getBackgroundZinc500.js";
import getBackgroundZinc600 from "./getBackgroundZinc600.js";
import getBackgroundZinc700 from "./getBackgroundZinc700.js";
import getBackgroundZinc800 from "./getBackgroundZinc800.js";
import getBackgroundZinc900 from "./getBackgroundZinc900.js";
import parseColorNameValue from "./parseColorNameValue.js";

export default function getBackgroundColor(color, theme, opacity, isFrom) {
  const [name, value] = parseColorNameValue(color, theme);

  switch (`${name}${value ? `-${value}` : ""}`) {
    case COLOR_WHITE:
      return getBackgroundWhite(opacity, isFrom);
    case COLOR_BLACK:
      return getBackgroundBlack(opacity, isFrom);
    case "gray-900":
      return getBackgroundGray900(opacity, isFrom);
    case "gray-800":
      return getBackgroundGray800(opacity, isFrom);
    case "gray-700":
      return getBackgroundGray700(opacity, isFrom);
    case "gray-600":
      return getBackgroundGray600(opacity, isFrom);
    case "gray-500":
      return getBackgroundGray500(opacity, isFrom);
    case "gray-400":
      return getBackgroundGray400(opacity, isFrom);
    case "gray-300":
      return getBackgroundGray300(opacity, isFrom);
    case "gray-200":
      return getBackgroundGray200(opacity, isFrom);
    case "gray-100":
      return getBackgroundGray100(opacity, isFrom);
    case "gray-50":
      return getBackgroundGray50(opacity, isFrom);
    case "slate-900":
      return getBackgroundSlate900(opacity, isFrom);
    case "slate-800":
      return getBackgroundSlate800(opacity, isFrom);
    case "slate-700":
      return getBackgroundSlate700(opacity, isFrom);
    case "slate-600":
      return getBackgroundSlate600(opacity, isFrom);
    case "slate-500":
      return getBackgroundSlate500(opacity, isFrom);
    case "slate-400":
      return getBackgroundSlate400(opacity, isFrom);
    case "slate-300":
      return getBackgroundSlate300(opacity, isFrom);
    case "slate-200":
      return getBackgroundSlate200(opacity, isFrom);
    case "slate-100":
      return getBackgroundSlate100(opacity, isFrom);
    case "slate-50":
      return getBackgroundSlate50(opacity, isFrom);
    case "zinc-900":
      return getBackgroundZinc900(opacity, isFrom);
    case "zinc-800":
      return getBackgroundZinc800(opacity, isFrom);
    case "zinc-700":
      return getBackgroundZinc700(opacity, isFrom);
    case "zinc-600":
      return getBackgroundZinc600(opacity, isFrom);
    case "zinc-500":
      return getBackgroundZinc500(opacity, isFrom);
    case "zinc-400":
      return getBackgroundZinc400(opacity, isFrom);
    case "zinc-300":
      return getBackgroundZinc300(opacity, isFrom);
    case "zinc-200":
      return getBackgroundZinc200(opacity, isFrom);
    case "zinc-100":
      return getBackgroundZinc100(opacity, isFrom);
    case "zinc-50":
      return getBackgroundZinc50(opacity, isFrom);
    case "neutral-900":
      return getBackgroundNeutral900(opacity, isFrom);
    case "neutral-800":
      return getBackgroundNeutral800(opacity, isFrom);
    case "neutral-700":
      return getBackgroundNeutral700(opacity, isFrom);
    case "neutral-600":
      return getBackgroundNeutral600(opacity, isFrom);
    case "neutral-500":
      return getBackgroundNeutral500(opacity, isFrom);
    case "neutral-400":
      return getBackgroundNeutral400(opacity, isFrom);
    case "neutral-300":
      return getBackgroundNeutral300(opacity, isFrom);
    case "neutral-200":
      return getBackgroundNeutral200(opacity, isFrom);
    case "neutral-100":
      return getBackgroundNeutral100(opacity, isFrom);
    case "neutral-50":
      return getBackgroundNeutral50(opacity, isFrom);
    case "stone-900":
      return getBackgroundStone900(opacity, isFrom);
    case "stone-800":
      return getBackgroundStone800(opacity, isFrom);
    case "stone-700":
      return getBackgroundStone700(opacity, isFrom);
    case "stone-600":
      return getBackgroundStone600(opacity, isFrom);
    case "stone-500":
      return getBackgroundStone500(opacity, isFrom);
    case "stone-400":
      return getBackgroundStone400(opacity, isFrom);
    case "stone-300":
      return getBackgroundStone300(opacity, isFrom);
    case "stone-200":
      return getBackgroundStone200(opacity, isFrom);
    case "stone-100":
      return getBackgroundStone100(opacity, isFrom);
    case "stone-50":
      return getBackgroundStone50(opacity, isFrom);
    case "red-900":
      return getBackgroundRed900(opacity, isFrom);
    case "red-800":
      return getBackgroundRed800(opacity, isFrom);
    case "red-700":
      return getBackgroundRed700(opacity, isFrom);
    case "red-600":
      return getBackgroundRed600(opacity, isFrom);
    case "red-500":
      return getBackgroundRed500(opacity, isFrom);
    case "red-400":
      return getBackgroundRed400(opacity, isFrom);
    case "red-300":
      return getBackgroundRed300(opacity, isFrom);
    case "red-200":
      return getBackgroundRed200(opacity, isFrom);
    case "red-100":
      return getBackgroundRed100(opacity, isFrom);
    case "red-50":
      return getBackgroundRed50(opacity, isFrom);
    case "orange-900":
      return getBackgroundOrange900(opacity, isFrom);
    case "orange-800":
      return getBackgroundOrange800(opacity, isFrom);
    case "orange-700":
      return getBackgroundOrange700(opacity, isFrom);
    case "orange-600":
      return getBackgroundOrange600(opacity, isFrom);
    case "orange-500":
      return getBackgroundOrange500(opacity, isFrom);
    case "orange-400":
      return getBackgroundOrange400(opacity, isFrom);
    case "orange-300":
      return getBackgroundOrange300(opacity, isFrom);
    case "orange-200":
      return getBackgroundOrange200(opacity, isFrom);
    case "orange-100":
      return getBackgroundOrange100(opacity, isFrom);
    case "orange-50":
      return getBackgroundOrange50(opacity, isFrom);
    case "yellow-900":
      return getBackgroundYellow900(opacity, isFrom);
    case "yellow-800":
      return getBackgroundYellow800(opacity, isFrom);
    case "yellow-700":
      return getBackgroundYellow700(opacity, isFrom);
    case "yellow-600":
      return getBackgroundYellow600(opacity, isFrom);
    case "yellow-500":
      return getBackgroundYellow500(opacity, isFrom);
    case "yellow-400":
      return getBackgroundYellow400(opacity, isFrom);
    case "yellow-300":
      return getBackgroundYellow300(opacity, isFrom);
    case "yellow-200":
      return getBackgroundYellow200(opacity, isFrom);
    case "yellow-100":
      return getBackgroundYellow100(opacity, isFrom);
    case "yellow-50":
      return getBackgroundYellow50(opacity, isFrom);
    case "amber-900":
      return getBackgroundAmber900(opacity, isFrom);
    case "amber-800":
      return getBackgroundAmber800(opacity, isFrom);
    case "amber-700":
      return getBackgroundAmber700(opacity, isFrom);
    case "amber-600":
      return getBackgroundAmber600(opacity, isFrom);
    case "amber-500":
      return getBackgroundAmber500(opacity, isFrom);
    case "amber-400":
      return getBackgroundAmber400(opacity, isFrom);
    case "amber-300":
      return getBackgroundAmber300(opacity, isFrom);
    case "amber-200":
      return getBackgroundAmber200(opacity, isFrom);
    case "amber-100":
      return getBackgroundAmber100(opacity, isFrom);
    case "amber-50":
      return getBackgroundAmber50(opacity, isFrom);
    case "lime-900":
      return getBackgroundLime900(opacity, isFrom);
    case "lime-800":
      return getBackgroundLime800(opacity, isFrom);
    case "lime-700":
      return getBackgroundLime700(opacity, isFrom);
    case "lime-600":
      return getBackgroundLime600(opacity, isFrom);
    case "lime-500":
      return getBackgroundLime500(opacity, isFrom);
    case "lime-400":
      return getBackgroundLime400(opacity, isFrom);
    case "lime-300":
      return getBackgroundLime300(opacity, isFrom);
    case "lime-200":
      return getBackgroundLime200(opacity, isFrom);
    case "lime-100":
      return getBackgroundLime100(opacity, isFrom);
    case "lime-50":
      return getBackgroundLime50(opacity, isFrom);
    case "green-900":
      return getBackgroundGreen900(opacity, isFrom);
    case "green-800":
      return getBackgroundGreen800(opacity, isFrom);
    case "green-700":
      return getBackgroundGreen700(opacity, isFrom);
    case "green-600":
      return getBackgroundGreen600(opacity, isFrom);
    case "green-500":
      return getBackgroundGreen500(opacity, isFrom);
    case "green-400":
      return getBackgroundGreen400(opacity, isFrom);
    case "green-300":
      return getBackgroundGreen300(opacity, isFrom);
    case "green-200":
      return getBackgroundGreen200(opacity, isFrom);
    case "green-100":
      return getBackgroundGreen100(opacity, isFrom);
    case "green-50":
      return getBackgroundGreen50(opacity, isFrom);
    case "emerald-900":
      return getBackgroundEmerald900(opacity, isFrom);
    case "emerald-800":
      return getBackgroundEmerald800(opacity, isFrom);
    case "emerald-700":
      return getBackgroundEmerald700(opacity, isFrom);
    case "emerald-600":
      return getBackgroundEmerald600(opacity, isFrom);
    case "emerald-500":
      return getBackgroundEmerald500(opacity, isFrom);
    case "emerald-400":
      return getBackgroundEmerald400(opacity, isFrom);
    case "emerald-300":
      return getBackgroundEmerald300(opacity, isFrom);
    case "emerald-200":
      return getBackgroundEmerald200(opacity, isFrom);
    case "emerald-100":
      return getBackgroundEmerald100(opacity, isFrom);
    case "emerald-50":
      return getBackgroundEmerald50(opacity, isFrom);
    case "teal-900":
      return getBackgroundTeal900(opacity, isFrom);
    case "teal-800":
      return getBackgroundTeal800(opacity, isFrom);
    case "teal-700":
      return getBackgroundTeal700(opacity, isFrom);
    case "teal-600":
      return getBackgroundTeal600(opacity, isFrom);
    case "teal-500":
      return getBackgroundTeal500(opacity, isFrom);
    case "teal-400":
      return getBackgroundTeal400(opacity, isFrom);
    case "teal-300":
      return getBackgroundTeal300(opacity, isFrom);
    case "teal-200":
      return getBackgroundTeal200(opacity, isFrom);
    case "teal-100":
      return getBackgroundTeal100(opacity, isFrom);
    case "teal-50":
      return getBackgroundTeal50(opacity, isFrom);
    case "cyan-900":
      return getBackgroundCyan900(opacity, isFrom);
    case "cyan-800":
      return getBackgroundCyan800(opacity, isFrom);
    case "cyan-700":
      return getBackgroundCyan700(opacity, isFrom);
    case "cyan-600":
      return getBackgroundCyan600(opacity, isFrom);
    case "cyan-500":
      return getBackgroundCyan500(opacity, isFrom);
    case "cyan-400":
      return getBackgroundCyan400(opacity, isFrom);
    case "cyan-300":
      return getBackgroundCyan300(opacity, isFrom);
    case "cyan-200":
      return getBackgroundCyan200(opacity, isFrom);
    case "cyan-100":
      return getBackgroundCyan100(opacity, isFrom);
    case "cyan-50":
      return getBackgroundCyan50(opacity, isFrom);
    case "sky-900":
      return getBackgroundSky900(opacity, isFrom);
    case "sky-800":
      return getBackgroundSky800(opacity, isFrom);
    case "sky-700":
      return getBackgroundSky700(opacity, isFrom);
    case "sky-600":
      return getBackgroundSky600(opacity, isFrom);
    case "sky-500":
      return getBackgroundSky500(opacity, isFrom);
    case "sky-400":
      return getBackgroundSky400(opacity, isFrom);
    case "sky-300":
      return getBackgroundSky300(opacity, isFrom);
    case "sky-200":
      return getBackgroundSky200(opacity, isFrom);
    case "sky-100":
      return getBackgroundSky100(opacity, isFrom);
    case "sky-50":
      return getBackgroundSky50(opacity, isFrom);
    case "blue-900":
      return getBackgroundBlue900(opacity, isFrom);
    case "blue-800":
      return getBackgroundBlue800(opacity, isFrom);
    case "blue-700":
      return getBackgroundBlue700(opacity, isFrom);
    case "blue-600":
      return getBackgroundBlue600(opacity, isFrom);
    case "blue-500":
      return getBackgroundBlue500(opacity, isFrom);
    case "blue-400":
      return getBackgroundBlue400(opacity, isFrom);
    case "blue-300":
      return getBackgroundBlue300(opacity, isFrom);
    case "blue-200":
      return getBackgroundBlue200(opacity, isFrom);
    case "blue-100":
      return getBackgroundBlue100(opacity, isFrom);
    case "blue-50":
      return getBackgroundBlue50(opacity, isFrom);
    case "indigo-900":
      return getBackgroundIndigo900(opacity, isFrom);
    case "indigo-800":
      return getBackgroundIndigo800(opacity, isFrom);
    case "indigo-700":
      return getBackgroundIndigo700(opacity, isFrom);
    case "indigo-600":
      return getBackgroundIndigo600(opacity, isFrom);
    case "indigo-500":
      return getBackgroundIndigo500(opacity, isFrom);
    case "indigo-400":
      return getBackgroundIndigo400(opacity, isFrom);
    case "indigo-300":
      return getBackgroundIndigo300(opacity, isFrom);
    case "indigo-200":
      return getBackgroundIndigo200(opacity, isFrom);
    case "indigo-100":
      return getBackgroundIndigo100(opacity, isFrom);
    case "indigo-50":
      return getBackgroundIndigo50(opacity, isFrom);
    case "violet-900":
      return getBackgroundViolet900(opacity, isFrom);
    case "violet-800":
      return getBackgroundViolet800(opacity, isFrom);
    case "violet-700":
      return getBackgroundViolet700(opacity, isFrom);
    case "violet-600":
      return getBackgroundViolet600(opacity, isFrom);
    case "violet-500":
      return getBackgroundViolet500(opacity, isFrom);
    case "violet-400":
      return getBackgroundViolet400(opacity, isFrom);
    case "violet-300":
      return getBackgroundViolet300(opacity, isFrom);
    case "violet-200":
      return getBackgroundViolet200(opacity, isFrom);
    case "violet-100":
      return getBackgroundViolet100(opacity, isFrom);
    case "violet-50":
      return getBackgroundViolet50(opacity, isFrom);
    case "purple-900":
      return getBackgroundPurple900(opacity, isFrom);
    case "purple-800":
      return getBackgroundPurple800(opacity, isFrom);
    case "purple-700":
      return getBackgroundPurple700(opacity, isFrom);
    case "purple-600":
      return getBackgroundPurple600(opacity, isFrom);
    case "purple-500":
      return getBackgroundPurple500(opacity, isFrom);
    case "purple-400":
      return getBackgroundPurple400(opacity, isFrom);
    case "purple-300":
      return getBackgroundPurple300(opacity, isFrom);
    case "purple-200":
      return getBackgroundPurple200(opacity, isFrom);
    case "purple-100":
      return getBackgroundPurple100(opacity, isFrom);
    case "purple-50":
      return getBackgroundPurple50(opacity, isFrom);
    case "fuchsia-900":
      return getBackgroundFuchsia900(opacity, isFrom);
    case "fuchsia-800":
      return getBackgroundFuchsia800(opacity, isFrom);
    case "fuchsia-700":
      return getBackgroundFuchsia700(opacity, isFrom);
    case "fuchsia-600":
      return getBackgroundFuchsia600(opacity, isFrom);
    case "fuchsia-500":
      return getBackgroundFuchsia500(opacity, isFrom);
    case "fuchsia-400":
      return getBackgroundFuchsia400(opacity, isFrom);
    case "fuchsia-300":
      return getBackgroundFuchsia300(opacity, isFrom);
    case "fuchsia-200":
      return getBackgroundFuchsia200(opacity, isFrom);
    case "fuchsia-100":
      return getBackgroundFuchsia100(opacity, isFrom);
    case "fuchsia-50":
      return getBackgroundFuchsia50(opacity, isFrom);
    case "pink-900":
      return getBackgroundPink900(opacity, isFrom);
    case "pink-800":
      return getBackgroundPink800(opacity, isFrom);
    case "pink-700":
      return getBackgroundPink700(opacity, isFrom);
    case "pink-600":
      return getBackgroundPink600(opacity, isFrom);
    case "pink-500":
      return getBackgroundPink500(opacity, isFrom);
    case "pink-400":
      return getBackgroundPink400(opacity, isFrom);
    case "pink-300":
      return getBackgroundPink300(opacity, isFrom);
    case "pink-200":
      return getBackgroundPink200(opacity, isFrom);
    case "pink-100":
      return getBackgroundPink100(opacity, isFrom);
    case "pink-50":
      return getBackgroundPink50(opacity, isFrom);
    case "rose-900":
      return getBackgroundRose900(opacity, isFrom);
    case "rose-800":
      return getBackgroundRose800(opacity, isFrom);
    case "rose-700":
      return getBackgroundRose700(opacity, isFrom);
    case "rose-600":
      return getBackgroundRose600(opacity, isFrom);
    case "rose-500":
      return getBackgroundRose500(opacity, isFrom);
    case "rose-400":
      return getBackgroundRose400(opacity, isFrom);
    case "rose-300":
      return getBackgroundRose300(opacity, isFrom);
    case "rose-200":
      return getBackgroundRose200(opacity, isFrom);
    case "rose-100":
      return getBackgroundRose100(opacity, isFrom);
    case "rose-50":
      return getBackgroundRose50(opacity, isFrom);
  }
}
