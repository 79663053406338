"use client";

import { CLIENT_CONSOLE, CLIENT_MOBILE, CLIENT_POS } from "@easybiz/utils";
import { FormattedMessage } from "react-intl";

function PlatformClientTitle({ client }) {
  switch (client) {
    case CLIENT_POS:
      return <FormattedMessage id="pos" tagName="span" defaultMessage="POS" />;
    case CLIENT_MOBILE:
      return <FormattedMessage id="mobile.app" tagName="span" defaultMessage="Mobile App" />;
    case CLIENT_CONSOLE:
      return <FormattedMessage id="admin.console" tagName="span" defaultMessage="Admin Console" />;
    default:
      return null;
  }
}

export default PlatformClientTitle;
