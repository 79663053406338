import { CloseOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { useCustomerAddressEditPanel } from "@easybiz/component";
import { GoogleMapJsLoader } from "@easybiz/web-ui";
import { Alert, Button, Checkbox, Form, Input, Space } from "antd";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import GooglePlaceAutoComplete from "../GooglePlaceAutoComplete";

export default function CustomerAddressDialog({ customerId, editAddress, onComplete, onCancel }) {
  const unitNoRef = useRef();
  const { loading, onOk, address, onChange, emptyPrompt, line1, line2, defaultCheckBox } = useCustomerAddressEditPanel(
    customerId,
    editAddress,
    onComplete
  );

  return (
    <>
      <Space direction="vertical" className="full-width">
        <GoogleMapJsLoader>
          <GooglePlaceAutoComplete
            open={open}
            onSelect={(address) => {
              onChange(address);
              unitNoRef && unitNoRef.current && unitNoRef.current.focus && unitNoRef.current.focus();
            }}
            disabled={loading}
            requrePostalCode
          />
        </GoogleMapJsLoader>
        {!address && <Alert type="info" icon={<EnvironmentOutlined />} showIcon message={emptyPrompt} />}
        <Form layout="horizontal">
          <Form.Item label={line1.title}>
            <Input.TextArea
              disabled={loading || !address}
              placeholder={line1.placeholder}
              value={address?.formatted}
              onChange={(e) => onChange({ ...address, formatted: e.target.value })}
            />
          </Form.Item>
          <Form.Item label={line2.title}>
            <Input
              disabled={loading || !address}
              placeholder={line2.placeholder}
              value={address?.unitNo}
              ref={unitNoRef}
              onChange={(e) => onChange({ ...address, unitNo: e.target.value })}
            />
          </Form.Item>
        </Form>
      </Space>
      <div className="flex flex-row flex-space-between flex-align-center">
        <Checkbox
          checked={address?.isDefault}
          disabled={loading}
          onChange={(e) => onChange({ ...address, isDefault: e.target.checked })}
        >
          {defaultCheckBox}
        </Checkbox>
        <Space>
          {onCancel && (
            <Button icon={<CloseOutlined />} onClick={onCancel}>
              <FormattedMessage tagName={"span"} defaultMessage={"Cancel"} />
            </Button>
          )}
          <Button type="primary" loading={loading} onClick={onOk}>
            {editAddress ? (
              <FormattedMessage defaultMessage={"Update address"} />
            ) : (
              <FormattedMessage defaultMessage={"Add address"} />
            )}
          </Button>
        </Space>
      </div>
    </>
  );
}
