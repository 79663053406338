const OPTIONS = {
  8: "lg:max-h-8",
  12: "lg:max-h-12",
  16: "lg:max-h-16",
  20: "lg:max-h-20",
  24: "lg:max-h-24",
  28: "lg:max-h-28",
  32: "lg:max-h-32",
  36: "lg:max-h-36",
  40: "lg:max-h-40",
  44: "lg:max-h-44",
  48: "lg:max-h-48",
  52: "lg:max-h-52",
  56: "lg:max-h-56",
  60: "lg:max-h-60",
  64: "lg:max-h-64",
  72: "lg:max-h-72",
  80: "lg:max-h-80",
  96: "lg:max-h-96",
};

export default function getMaxHeightLg(height) {
  return OPTIONS[height];
}
