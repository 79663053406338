"use client";

import {
  ACTION_ACK_PRICE,
  ACTION_AGREE_PRICE,
  ACTION_COLLECT,
  ACTION_CONFIRM_PRICE,
  ACTION_DELIVER,
  ACTION_PACK,
  ACTION_PAYMENT,
  ACTION_PICK_UP,
  ACTION_PLANT_IN,
  ACTION_PLANT_OUT,
  ACTION_SCHEDULE_DELIVER,
  ACTION_SCHEDULE_PICKUP,
  ACTION_SERVICE,
  ACTION_STORE_IN,
} from "@easybiz/utils";
import React from "react";
import { FormattedMessage } from "react-intl";

function OrderProgressTitle({ action, ing }) {
  switch (action) {
    case ACTION_SCHEDULE_PICKUP:
      if (ing) {
        return <FormattedMessage id="pending.pickup.schedule" defaultMessage="Pending pick up schedule" />;
      } else {
        return <FormattedMessage id="pickup.schedule" defaultMessage="Pick-up schedule" />;
      }
    case ACTION_PICK_UP:
      if (ing) {
        return <FormattedMessage id="pending.pickup" defaultMessage="Pending pick up" />;
      } else {
        return <FormattedMessage id="pickup" defaultMessage="Pick up" />;
      }
    case ACTION_PLANT_IN:
      if (ing) {
        return <FormattedMessage id="pending.plant.checkin" defaultMessage="Pending service center check in" />;
      } else {
        return <FormattedMessage id="plant.checkin" defaultMessage="Service center check in" />;
      }
    case ACTION_CONFIRM_PRICE:
      if (ing) {
        return <FormattedMessage id="pending.confirm.price" defaultMessage="Preparing your itemised receipt" />;
      } else {
        return <FormattedMessage id="confirm.price" defaultMessage="Confirm order total" />;
      }
    case ACTION_AGREE_PRICE:
      if (ing) {
        return (
          <FormattedMessage
            id="bill.is.ready.pending.confirmation"
            defaultMessage="Bill is ready, pending on your confirmation"
          />
        );
      } else {
        return <FormattedMessage id="customer.confirmation" defaultMessage="Customer confirmation" />;
      }
    case ACTION_ACK_PRICE:
    case ACTION_SERVICE:
    case ACTION_PACK:
      if (ing) {
        return <FormattedMessage id="service.in.progress" defaultMessage="Service in progress" />;
      } else {
        return <FormattedMessage id="servicing" defaultMessage="Servicing" />;
      }
    case ACTION_PAYMENT:
      if (ing) {
        return <FormattedMessage id="pending.payment" defaultMessage="Pending payment" />;
      } else {
        return <FormattedMessage id="payment" defaultMessage="Payment" />;
      }
    case ACTION_SCHEDULE_DELIVER:
      if (ing) {
        return <FormattedMessage id="pending.delivery.schedule" defaultMessage="Pending delivery schedule" />;
      } else {
        return <FormattedMessage id="delivery.schedule" defaultMessage="Delivery schedule" />;
      }
    case ACTION_PLANT_OUT:
      if (ing) {
        return <FormattedMessage id="delivery.otw" defaultMessage="Delivery OTW" />;
      } else {
        return <FormattedMessage id="service.center.checkout" defaultMessage="Service center check out" />;
      }
    case ACTION_DELIVER:
      if (ing) {
        return <FormattedMessage id="pending.delivery" defaultMessage="Pending delivery" />;
      } else {
        return <FormattedMessage id="delivery" defaultMessage="Delivery" />;
      }
    case ACTION_STORE_IN:
      if (ing) {
        return <FormattedMessage id="sending.back.to.store" defaultMessage="Sending back to store" />;
      } else {
        return <FormattedMessage id="back.to.store" defaultMessage="Back to store" />;
      }
    case ACTION_COLLECT:
      if (ing) {
        return <FormattedMessage id="pending.collection" defaultMessage="Pending collection" />;
      } else {
        return <FormattedMessage id="collection" defaultMessage="Collection" />;
      }
    default:
      return null;
  }
}

export default OrderProgressTitle;
