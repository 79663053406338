const values = {
  0: "bottom-0",
  1: "bottom-1",
  2: "bottom-2",
  3: "bottom-3",
  4: "bottom-4",
  5: "bottom-5",
  6: "bottom-6",
  7: "bottom-7",
  8: "bottom-8",
  9: "bottom-9",
  10: "bottom-10",
  11: "bottom-11",
  12: "bottom-12",
  14: "bottom-14",
  16: "bottom-16",
  20: "bottom-20",
  "1/2": "bottom-1/2",
  full: "bottom-full",
};

export default function getAbsoluteBottom(value) {
  return values[value];
}
