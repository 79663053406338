const options = {
  5: "bg-cyan-500/5",
  10: "bg-cyan-500/10",
  25: "bg-cyan-500/25",
  50: "bg-cyan-500/50",
  75: "bg-cyan-500/75",
  90: "bg-cyan-500/90",
  100: "bg-cyan-500",
};

const from = {
  5: "from-cyan-500/5",
  10: "from-cyan-500/10",
  25: "from-cyan-500/25",
  50: "from-cyan-500/50",
  75: "from-cyan-500/75",
  90: "from-cyan-500/90",
  100: "from-cyan-500",
};

export default function getBackgroundCyan500(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
