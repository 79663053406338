export const ORDER_ACTION_CREATE = "create";
export const ORDER_ACTION_REDO = "redo";
export const ORDER_ACTION_ASSIGN_SUPPLIER = "assign_supplier";
export const ORDER_ACTION_COLLECTION_RESCHEDULE = "collection_reschedule";
export const ORDER_ACTION_UPDATE_CUSTOMER_CONTACT = "update_customer_contact";
export const ORDER_ACTION_JOB_ADD = "job_add";
export const ORDER_ACTION_STORAGE = "storage";
export const ORDER_ACTION_PAYMENT_METHOD_UPDATE = "payment_method_update";
export const ORDER_ACTION_LINE_ITEM_EDIT = "line_item_edit";
export const ORDER_ACTION_NOTE = "note";
export const ORDER_ACTION_TWO_STEP_PRICE_CONTINUE = "two_step_price_continue";
export const ORDER_ACTION_PAYMENT = "payment";
export const ORDER_ACTION_REFUND = "refund";
export const ORDER_ACTION_PACK = "pack";
export const ORDER_ACTION_PACK_FOR_DELIVERY = "pack_for_delivery";
export const ORDER_ACTION_PACK_CANCEL = "pack_cancel";
export const ORDER_ACTION_COMPLETE = "complete";
export const ORDER_ACTION_CANCEL = "cancel";
export const ORDER_ACTION_PLANT_INFO_EDIT = "plant_info_edit";
export const ORDER_ACTION_RECURRING_BOOKING = "recurring_booking";
export const ORDER_ACTION_SALES_RETURN = "sales_return";
export const ORDER_ACTION_REFRESH_RECEIPT = "refresh_receipt";
export const ORDER_ACTION_ITEM_PROCESSED = "item_processed";
export const ORDER_ACTION_SUB_OPTION_COMPLETED = "sub_option_completed";
export const ORDER_ACTION_COMMENT = "comment";
export const ORDER_ACTION_RATING = "rating";
export const ORDER_ACTION_FETCH_PROGRESS = "fetch_progress";
export const ORDER_ACTION_ADJUST_TAX_INFO = "adjust_tax_info";
// Procedures
export const ORDER_ACTION_STORE_PACK = "store_pack";
export const ORDER_ACTION_DISCHARGE = "procedure_discharge";
export const ORDER_ACTION_SKIP_PICKUP = "procedure_skip_up";

export const ORDER_TAB_RECEIPT = "receipt";
export const ORDER_TAB_LABEL = "label";
export const ORDER_TAB_PHOTO = "photo";
export const ORDER_TAB_PAYMENT = "payment";
export const ORDER_TAB_COLLECTION = "collection";
export const ORDER_TAB_LOGISTIC = "logistic";
export const ORDER_TAB_PLANT_EDIT = "plant";
export const ORDER_TAB_PROGRESS = "progress";
export const ORDER_TAB_HISTORY = "history";

export const LINE_ITEM_TYPE_ITEM = "item";
export const LINE_ITEM_TYPE_SERVICE_TYPE = "service_type";
export const LINE_ITEM_TYPE_SUBTOTAL = "subtotal";
export const LINE_ITEM_TYPE_EXPRESS_CHARGE = "express_charge";
export const LINE_ITEM_TYPE_MINIMUM_CHARGE = "minimum_charge";
export const LINE_ITEM_TYPE_PROMOTION = "promotion";
export const LINE_ITEM_TYPE_ADJUSTMENTS = "adjustments";
export const LINE_ITEM_TYPE_GRAND_TOTAL = "grand_total";
export const LINE_ITEM_TYPE_GST = "gst";
export const LINE_ITEM_TYPE_PAYABLE = "payable";
export const LINE_ITEM_TYPE_ROUNDING = "rounding";
export const LINE_ITEM_TYPE_CREDIT_NOTE = "credit_note";

export const STEP_SELECT_AGENT = "agent";
export const STEP_SELECT_APP = "app";
export const STEP_SELECT_BUSINESS = "business";
export const STEP_SELECT_CUSTOMER = "customer";
export const STEP_SELECT_ADDRESS = "address";
export const STEP_SELECT_PUD = "pud";
export const STEP_SELECT_PICKUP = "pickup";
export const STEP_SELECT_DELIVERY = "delivery";
export const STEP_SELECT_SERVICE = "service";
export const STEP_SELECT_SCHEDULE_TYPE = "Schedule type";
export const STEP_SELECT_COLLECTION_PLACE = "collection place";
export const STEP_SELECT_COLLECTION = "collection";
export const STEP_SELECT_ITEMS = "items";
export const STEP_SELECT_NOTE = "note";
export const STEP_SELECT_DISCHARGE = "discharge";
export const STEP_REDO_ITEMS = "redo items";
export const STEP_TAKE_PHOTO = "take photo";
export const STEP_SALES_RETURN_METHOD = "sales return method";
export const STEP_SALES_RETURN_ITEMS = "sales return items";
export const STEP_SALES_RETURN_AMOUNT = "sales return amount";
export const STEP_PREVIEW_ITEMS = "preview items";
export const STEP_SELECT_PAYMENT = "payment";
export const STEP_DEPOSIT_PAYMENT = "deposit";
export const STEP_SIGNATURE = "signature";
export const STEP_QR_PICKUP = "qr pickup";
export const STEP_QR_DELIVERY = "qr delivery";
export const STEP_OVERVIEW = "overview";
export const STEP_SELECT_SERVICE_TYPE = "service_type";
export const STEP_SELECT_SUB_OPTION = "sub_option";
export const STEP_ITEM_PREVIEW = "item_preview";
export const STEP_PRODUCT_NAME = "product_name";
export const STEP_FREQUENCY = "frequency";
export const STEP_ASSIGN_WORKER = "assign worker";
export const STEP_LOCATE_ADDRESS = "locate address";
export const STEP_EDIT_ADDRESS = "edit address";
export const STEP_UNIT_QTY = "unit qty";
export const STEP_UNIT_PRICE = "unit price";
export const STEP_DISCOUNT = "discount";
export const STEP_PCS = "pcs";
export const STEP_SQFT_METHOD = "sqft method";
export const STEP_SQFT_WIDTH = "sqft width";
export const STEP_SQFT_HEIGHT = "sqft height";
export const STEP_SQFT_RADIUS = "sqft radius";
export const STEP_TRANSACTION_TYPE = "transaction type";
export const STEP_TOP_UP_OPTION = "top up option";
export const STEP_AMOUNT = "amount";
export const STEP_SELECT_STORE_APP = "store app";