
const options = {
  normal: "bg-blend-normal",
  multiply: "bg-blend-multiply",
  screen: "bg-blend-screen",
  overlay: "bg-blend-overlay",
  darken: "bg-blend-darken",
  lighten: "bg-blend-lighten",
  colorDodge: "bg-blend-color-dodge",
  colorBurn: "bg-blend-color-burn",
  hardLight: "bg-blend-hard-light",
  softLight: "bg-blend-soft-light",
  difference: "bg-blend-difference",
  exclusion: "bg-blend-exclusion",
  hue: "bg-blend-hue",
  saturation: "bg-blend-saturation",
  color: "bg-blend-color",
  luminosity: "bg-blend-luminosity",
};

export default function getBackgroundBlend(mode) {
  return options[mode];
}