const values = {
  none: "md:rounded-none",
  md: "md:rounded-md",
  lg: "md:rounded-lg",
  xl: "md:rounded-xl",
  "2xl": "md:rounded-2xl",
  "3xl": "md:rounded-3xl",
  full: "md:rounded-full",
};

export default function getRoundedMd(value) {
  return values[value];
}
