import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast } from "@easybiz/shell";
import { OPERATION_GROUP_POS, POS_ACTION_CLOCK_OUT } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function usePOSClockOut(callback) {
  const toast = useFeedbackToast();
  const intl = useIntl();

  const onSubmit = useCallback(() => {
    return [OPERATION_GROUP_POS, POS_ACTION_CLOCK_OUT];
  }, []);

  return useActionCallback(onSubmit, callback, (request, response) => {
    if (response?.checkOutTime) {
      toast.success(intl.formatMessage({ defaultMessage: "Clocked out at {time}" }, { time: response?.checkOutTime }));
    }
  });
}

export default usePOSClockOut;
