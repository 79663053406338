"use client";

import {
  PAIRING_MODE_CUSTOMER_SCREEN,
  PAIRING_MODE_MOBILE_OPERATION,
  PAIRING_MODE_PAYMENT_TERMINAL,
} from "@easybiz/utils";
import { FormattedMessage } from "react-intl";

function DevicePairingModeDisplay({ pairingMode }) {
  switch (pairingMode) {
    case PAIRING_MODE_CUSTOMER_SCREEN:
      return <FormattedMessage id="customer.screen" defaultMessage={"Customer screen"} />;
    case PAIRING_MODE_MOBILE_OPERATION:
      return <FormattedMessage id="mobile.operation" defaultMessage={"Mobile operation"} />;
    case PAIRING_MODE_PAYMENT_TERMINAL:
      return <FormattedMessage id="payment.terminal" defaultMessage={"Payment terminal"} />;
    default:
      return null;
  }
}

export default DevicePairingModeDisplay;
