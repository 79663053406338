import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function formatTime(date, timeZone, format) {
  const dateObj = dayjs.isDayjs(date)
    ? date
    : Number.isInteger(date)
    ? dayjs.unix(date).tz(timeZone)
    : date
    ? dayjs(date)
    : null;

  if (dateObj?.isValid()) {
    return dateObj.format((typeof format === "string") ? format : "HH:mm");
  }
}
