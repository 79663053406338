const OPTIONS = {
  8: "md:max-h-8",
  12: "md:max-h-12",
  16: "md:max-h-16",
  20: "md:max-h-20",
  24: "md:max-h-24",
  28: "md:max-h-28",
  32: "md:max-h-32",
  36: "md:max-h-36",
  40: "md:max-h-40",
  44: "md:max-h-44",
  48: "md:max-h-48",
  52: "md:max-h-52",
  56: "md:max-h-56",
  60: "md:max-h-60",
  64: "md:max-h-64",
  72: "md:max-h-72",
  80: "md:max-h-80",
  96: "md:max-h-96",
};

export default function getMaxHeightMd(height) {
  return OPTIONS[height];
}
