const options = {
  visible: "md:overflow-x-visible",
  hidden: "md:overflow-x-hidden",
  auto: "md:overflow-x-auto",
  clip: "md:overflow-x-clip",
};

export default function getOverflowXMd(value) {
  return options[value];
}
