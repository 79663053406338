export const INVOICE_STATUS_OPEN = "open";
export const INVOICE_STATUS_PAID = "paid";
export const INVOICE_STATUS_DRAFT = "draft";
export const INVOICE_STATUS_UNCOLLECTIBLE = "uncollectible";
export const INVOICE_STATUS_VOID = "void";

export const SUBSCRIPTION_STATUS_ACTIVE = "active";
export const SUBSCRIPTION_STATUS_PAST_DUE = "past_due";
export const SUBSCRIPTION_STATUS_UNPAID = "unpaid";
export const SUBSCRIPTION_STATUS_CANCELLED = "canceled";
export const SUBSCRIPTION_STATUS_INCOMPLETE = "incomplete";
export const SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED = "incomplete_expired";
export const SUBSCRIPTION_STATUS_TRIALING = "trialing";
export const SUBSCRIPTION_STATUS_PAUSED = "paused";

export const SUBSCRIPTION_ACTION_PAYMENT_SETUP_START = "payment_setup_start";
export const SUBSCRIPTION_ACTION_PAYMENT_SETUP_FINISHED = "payment_setup_finished";
export const SUBSCRIPTION_ACTION_BILLING_SETTING_EDIT = "edit_billing_company";
export const SUBSCRIPTION_ACTION_SUBSCRIPTION_ITEM_REMOVE = "subscription_item_remove";
export const SUBSCRIPTION_ACTION_SUBSCRIPTION_ITEM_ADD_ONLINE_STORE = "subscription_item_add_online_store";
export const SUBSCRIPTION_ACTION_SUBSCRIPTION_ITEM_ADD_POS = "subscription_item_add_pos";
export const SUBSCRIPTION_ACTION_SUBSCRIPTION_ITEM_ADD_APP = "subscription_item_add_app";
export const SUBSCRIPTION_ACTION_SUBSCRIPTION_ITEM_ADD_WORKFLOW_ACCOUNT = "subscription_item_add_workflow_account";
export const SUBSCRIPTION_ACTION_SUBSCRIPTION_ITEM_ADD_PLANT = "subscription_item_add_plant";
export const SUBSCRIPTION_ACTION_CANCEL_SUBSCRIPTION = "cancel_subscription";
export const SUBSCRIPTION_ACTION_PREVIEW_INVOICE = "preview_invoice";
