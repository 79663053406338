import {
  PLACEHOLDER_COMPANY_ADDRESS,
  PLACEHOLDER_COMPANY_BRAND_NAME,
  PLACEHOLDER_COMPANY_EMAIL,
  PLACEHOLDER_COMPANY_LEGAL_NAME,
  PLACEHOLDER_COMPANY_PHONE_NUMBER,
  PLACEHOLDER_COMPANY_REGISTRATION_NUMBER,
  PLACEHOLDER_COMPANY_WEBSITE,
  PLACEHOLDER_CREDIT_BALANCE,
  PLACEHOLDER_CURRENT_YEAR,
  PLACEHOLDER_CUSTOMER_ADDRESS,
  PLACEHOLDER_CUSTOMER_EMAIL,
  PLACEHOLDER_CUSTOMER_INDEX,
  PLACEHOLDER_CUSTOMER_NAME,
  PLACEHOLDER_CUSTOMER_PHONE_NUMBER,
  PLACEHOLDER_CUSTOMER_PRIVATE_NOTE,
  PLACEHOLDER_JOB_ADDRESS,
  PLACEHOLDER_JOB_NOTE,
  PLACEHOLDER_JOB_SERVICE_TYPES,
  PLACEHOLDER_JOB_TIME,
  PLACEHOLDER_JOB_TYPE,
  PLACEHOLDER_JOB_WORKER,
  PLACEHOLDER_MEMBER_BENEFITS,
  PLACEHOLDER_MEMBER_EXPIRY_DATE,
  PLACEHOLDER_MEMBER_FEE,
  PLACEHOLDER_MEMBER_JOIN_DATE,
  PLACEHOLDER_MEMBER_TITLE,
  PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_AMOUNT,
  PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_DESCRIPTION,
  PLACEHOLDER_ORDER_AMOUNT_DUE,
  PLACEHOLDER_ORDER_AMOUNT_PAID,
  PLACEHOLDER_ORDER_BOOKING_SURCHARGE,
  PLACEHOLDER_ORDER_BUSINESS_UNIT_CODE,
  PLACEHOLDER_ORDER_BUSINESS_UNIT_NAME,
  PLACEHOLDER_ORDER_CONFIRMED_AT,
  PLACEHOLDER_ORDER_CONFIRMED_BY,
  PLACEHOLDER_ORDER_DELIVERY_ADDRESS,
  PLACEHOLDER_ORDER_DELIVERY_COMPLETE_AT,
  PLACEHOLDER_ORDER_DELIVERY_DATE,
  PLACEHOLDER_ORDER_DELIVERY_LEAD_TIME,
  PLACEHOLDER_ORDER_DELIVERY_NOTE,
  PLACEHOLDER_ORDER_DELIVERY_OPTION_NAME,
  PLACEHOLDER_ORDER_DELIVERY_TIME,
  PLACEHOLDER_ORDER_DELIVERY_WEEKDAY,
  PLACEHOLDER_ORDER_DELIVERY_WORKER,
  PLACEHOLDER_ORDER_DUE_DATE,
  PLACEHOLDER_ORDER_ID,
  PLACEHOLDER_ORDER_INVOICE_DATE,
  PLACEHOLDER_ORDER_LAST_PAYMENT_DATE,
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORIES,
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_CODES,
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_NAMES,
  PLACEHOLDER_ORDER_LINE_ITEM_ADD_ON_CODES,
  PLACEHOLDER_ORDER_LINE_ITEM_ADD_ON_NAMES,
  PLACEHOLDER_ORDER_LINE_ITEM_ADD_ONS,
  PLACEHOLDER_ORDER_LINE_ITEM_COMPLETION_DATE,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CONTENT,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_INDEXES,
  PLACEHOLDER_ORDER_LINE_ITEM_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_PHOTO,
  PLACEHOLDER_ORDER_LINE_ITEM_QTY,
  PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_SET_PCS_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_DISCOUNT,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_PRICE,
  PLACEHOLDER_ORDER_LINE_ITEM_TOTAL,
  PLACEHOLDER_ORDER_LINE_ITEM_TOTAL_PCS_PER_SET,
  PLACEHOLDER_ORDER_LINE_ITEM_UNIT,
  PLACEHOLDER_ORDER_LINE_ITEM_UNIT_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_UNIT_PRICE,
  PLACEHOLDER_ORDER_MADE_BY,
  PLACEHOLDER_ORDER_MADE_DATE,
  PLACEHOLDER_ORDER_MADE_TIME,
  PLACEHOLDER_ORDER_MADE_WEEKDAY,
  PLACEHOLDER_ORDER_MADE_WEEKDAY_INDEX,
  PLACEHOLDER_ORDER_NO,
  PLACEHOLDER_ORDER_NOTE,
  PLACEHOLDER_ORDER_PACKAGE_DATE,
  PLACEHOLDER_ORDER_PACKAGE_INDEX,
  PLACEHOLDER_ORDER_PAYMENTS,
  PLACEHOLDER_ORDER_PICKUP_ADDRESS,
  PLACEHOLDER_ORDER_PICKUP_COMPLETE_AT,
  PLACEHOLDER_ORDER_PICKUP_DATE,
  PLACEHOLDER_ORDER_PICKUP_LEAD_TIME,
  PLACEHOLDER_ORDER_PICKUP_NOTE,
  PLACEHOLDER_ORDER_PICKUP_OPTION_NAME,
  PLACEHOLDER_ORDER_PICKUP_STORE_ADDRESS,
  PLACEHOLDER_ORDER_PICKUP_STORE_NAME,
  PLACEHOLDER_ORDER_PICKUP_STORE_OPENING_HOURS,
  PLACEHOLDER_ORDER_PICKUP_STORE_PHONE_NUMBER,
  PLACEHOLDER_ORDER_PICKUP_TIME,
  PLACEHOLDER_ORDER_PICKUP_WEEKDAY,
  PLACEHOLDER_ORDER_PICKUP_WORKER,
  PLACEHOLDER_ORDER_REDO_ORDER_NO,
  PLACEHOLDER_ORDER_RETURN_TYPE,
  PLACEHOLDER_ORDER_STATUS,
  PLACEHOLDER_ORDER_STORE_PICKUP_DATE,
  PLACEHOLDER_ORDER_STORE_PICKUP_WEEKDAY,
  PLACEHOLDER_ORDER_SUBTOTAL,
  PLACEHOLDER_ORDER_TAX_AMOUNT,
  PLACEHOLDER_ORDER_TAX_RATE,
  PLACEHOLDER_ORDER_TAX_TYPE,
  PLACEHOLDER_ORDER_TOTAL,
  PLACEHOLDER_ORDER_TOTAL_PACKAGES,
  PLACEHOLDER_ORDER_TOTAL_PCS,
  PLACEHOLDER_ORDER_TOTAL_UNITS,
  PLACEHOLDER_ORDER_TOTAL_WITH_OUT_TAX,
  PLACEHOLDER_OTP,
  PLACEHOLDER_OTP_PREFIX,
  PLACEHOLDER_PAGE_NUMBER,
  PLACEHOLDER_REWARD_POINTS_BALANCE,
  PLACEHOLDER_SALES_CHANNEL_ADDRESS,
  PLACEHOLDER_SALES_CHANNEL_CODE,
  PLACEHOLDER_SALES_CHANNEL_DOMAIN,
  PLACEHOLDER_SALES_CHANNEL_NAME,
  PLACEHOLDER_SALES_CHANNEL_OPENING_HOURS,
  PLACEHOLDER_SALES_CHANNEL_PHONE_NUMBER,
  PLACEHOLDER_TOTAL_PAGES,
  PLACEHOLDER_TRANSACTION_AMOUNT,
  PLACEHOLDER_TRANSACTION_BALANCE_AFTER,
  PLACEHOLDER_TRANSACTION_BALANCE_BEFORE,
  PLACEHOLDER_TRANSACTION_DATE,
  PLACEHOLDER_TRANSACTION_DESCRIPTION,
  PLACEHOLDER_TRANSACTION_ID,
  PLACEHOLDER_TRANSACTION_MADE_BY,
  PLACEHOLDER_TRANSACTION_METHOD,
  PLACEHOLDER_TRANSACTION_NOTE,
  PLACEHOLDER_TRANSACTION_TIME,
} from "./constants/content.js";

export default function formatPlaceholderName(placeholderId, intl, shortForm) {
  // Placeholder display text
  switch (placeholderId) {
    case PLACEHOLDER_OTP:
      return intl.formatMessage({
        id: "OTP",
        defaultMessage: "OTP",
      });
    case PLACEHOLDER_OTP_PREFIX:
      return shortForm
        ? `XXXX`
        : intl.formatMessage({
            id: "OTP.prefix",
            defaultMessage: "OTP Prefix",
          });
    case PLACEHOLDER_SALES_CHANNEL_NAME:
      return intl.formatMessage({
        id: "sales.channel.name",
        defaultMessage: "Sales Channel Name",
      });
    case PLACEHOLDER_SALES_CHANNEL_CODE:
      return shortForm
        ? `SC`
        : intl.formatMessage({
            id: "sales.channel.code",
            defaultMessage: "Sales Channel Code",
          });
    case PLACEHOLDER_SALES_CHANNEL_ADDRESS:
      return intl.formatMessage({
        id: "sales.channel.address",
        defaultMessage: "Sales Channel Address",
      });
    case PLACEHOLDER_SALES_CHANNEL_PHONE_NUMBER:
      return shortForm
        ? "SC Phone Number"
        : intl.formatMessage({
            id: "sales.channel.phone.number",
            defaultMessage: "Sales Channel Phone Number",
          });
    case PLACEHOLDER_SALES_CHANNEL_OPENING_HOURS:
      return intl.formatMessage({
        id: "sales.channel.opening.hours",
        defaultMessage: "Sales Channel Opening Hours",
      });
    case PLACEHOLDER_SALES_CHANNEL_DOMAIN:
      return shortForm
        ? "SC Domain"
        : intl.formatMessage({
            id: "sales.channel.domain.address",
            defaultMessage: "Sales Channel Domain Address",
          });
    case "brand_name":
    case PLACEHOLDER_COMPANY_BRAND_NAME:
      return shortForm
        ? intl.formatMessage({
            id: "brand.name",
            defaultMessage: "Brand Name",
          })
        : intl.formatMessage({
            id: "business.name",
            defaultMessage: "Business Brand Name",
          });
    case "legal_name":
    case PLACEHOLDER_COMPANY_LEGAL_NAME:
      return shortForm
        ? intl.formatMessage({
            id: "legal.name",
            defaultMessage: "Legal Name",
          })
        : intl.formatMessage({
            id: "business.legal.name",
            defaultMessage: "Business Legal Name",
          });
    case PLACEHOLDER_COMPANY_REGISTRATION_NUMBER:
      return shortForm
        ? `Reg No.`
        : intl.formatMessage({
            id: "business.registration.number",
            defaultMessage: "Business Registration Number",
          });
    case PLACEHOLDER_COMPANY_ADDRESS:
      return intl.formatMessage({
        id: "business.address",
        defaultMessage: "Business Address",
      });
    case PLACEHOLDER_COMPANY_EMAIL:
      return intl.formatMessage({
        id: "business.contact.email",
        defaultMessage: "Business Email",
      });
    case PLACEHOLDER_COMPANY_WEBSITE:
      return intl.formatMessage({
        id: "business.website",
        defaultMessage: "Business Website",
      });
    case PLACEHOLDER_COMPANY_PHONE_NUMBER:
      return shortForm
        ? intl.formatMessage({
            id: "company.tel",
            defaultMessage: "Business Tel",
          })
        : intl.formatMessage({
            id: "company.phone.number",
            defaultMessage: "Business Phone Number",
          });
    case PLACEHOLDER_CUSTOMER_NAME:
      return intl.formatMessage({
        id: "customer.name",
        defaultMessage: "Customer Name",
      });
    case PLACEHOLDER_CUSTOMER_INDEX:
      return shortForm
        ? `C-IDX`
        : intl.formatMessage({
            id: "customer.index",
            defaultMessage: "Customer Index",
          });
    case PLACEHOLDER_CUSTOMER_EMAIL:
      return intl.formatMessage({
        id: "customer.email",
        defaultMessage: "Customer Email",
      });
    case PLACEHOLDER_CUSTOMER_PHONE_NUMBER:
      return shortForm
        ? intl.formatMessage({
            id: "c.phone.number",
            defaultMessage: "C Phone Number",
          })
        : intl.formatMessage({
            id: "customer.phone.number",
            defaultMessage: "Customer Phone Number",
          });
    case PLACEHOLDER_CUSTOMER_PRIVATE_NOTE:
      return intl.formatMessage({
        id: "customer.private.note",
        defaultMessage: "Customer Private Note",
      });
    case PLACEHOLDER_CUSTOMER_ADDRESS:
      return intl.formatMessage({
        id: "customer.address",
        defaultMessage: "Customer Address",
      });
    case PLACEHOLDER_JOB_ADDRESS:
      return intl.formatMessage({
        id: "job.address",
        defaultMessage: "Job Address",
      });
    case PLACEHOLDER_JOB_TYPE:
      return intl.formatMessage({
        id: "job.type",
        defaultMessage: "Job type",
      });
    case PLACEHOLDER_JOB_SERVICE_TYPES:
      return intl.formatMessage({
        id: "job.service.types",
        defaultMessage: "Job service types",
      });
    case PLACEHOLDER_JOB_NOTE:
      return intl.formatMessage({
        id: "job.note",
        defaultMessage: "Job note",
      });
    case PLACEHOLDER_JOB_WORKER:
      return intl.formatMessage({
        id: "job.worker",
        defaultMessage: "Job Worker",
      });
    case PLACEHOLDER_JOB_TIME:
      return intl.formatMessage({
        id: "job.time",
        defaultMessage: "Job Time",
      });
    case PLACEHOLDER_ORDER_BUSINESS_UNIT_NAME:
      return shortForm
        ? intl.formatMessage({
            id: "bu.name",
            defaultMessage: "BU Name",
          })
        : intl.formatMessage({
            id: "order.business.unit.name",
            defaultMessage: "Order Business Unit Name",
          });
    case PLACEHOLDER_ORDER_BUSINESS_UNIT_CODE:
      return shortForm
        ? `BU`
        : intl.formatMessage({
            id: "order.business.unit.code",
            defaultMessage: "Order Business Unit Code",
          });
    case PLACEHOLDER_ORDER_PICKUP_DATE:
      return shortForm
        ? `Date`
        : intl.formatMessage({
            id: "home.pickup.date",
            defaultMessage: "Home Pickup Date",
          });
    case PLACEHOLDER_ORDER_PICKUP_TIME:
      return shortForm
        ? `Time`
        : intl.formatMessage({
            id: "home.pickup.time",
            defaultMessage: "Home Pickup Time",
          });
    case PLACEHOLDER_ORDER_PICKUP_WEEKDAY:
      return shortForm
        ? `Weekday`
        : intl.formatMessage({
            id: "home.pickup.weekday",
            defaultMessage: "Home Pickup Weekday",
          });
    case PLACEHOLDER_ORDER_PICKUP_COMPLETE_AT:
      return intl.formatMessage({
        id: "home.pickup.complete.at",
        defaultMessage: "Home Pickup Complete At",
      });
    case PLACEHOLDER_ORDER_PICKUP_ADDRESS:
      return intl.formatMessage({
        id: "home.pickup.address",
        defaultMessage: "Home Pickup Address",
      });
    case PLACEHOLDER_ORDER_PICKUP_WORKER:
      return intl.formatMessage({
        id: "home.pickup.worker",
        defaultMessage: "Home Pickup Worker",
      });
    case PLACEHOLDER_ORDER_PICKUP_NOTE:
      return intl.formatMessage({
        id: "home.pickup.note",
        defaultMessage: "Home Pickup note",
      });
    case PLACEHOLDER_ORDER_DELIVERY_DATE:
      return intl.formatMessage({
        id: "home.delivery.date",
        defaultMessage: "Home Delivery Date",
      });
    case PLACEHOLDER_ORDER_DELIVERY_TIME:
      return intl.formatMessage({
        id: "home.delivery.time",
        defaultMessage: "Home Delivery Time",
      });
    case PLACEHOLDER_ORDER_DELIVERY_WEEKDAY:
      return intl.formatMessage({
        id: "home.delivery.weekday",
        defaultMessage: "Home Delivery Weekday",
      });
    case PLACEHOLDER_ORDER_DELIVERY_COMPLETE_AT:
      return intl.formatMessage({
        id: "home.delivery.complete.at",
        defaultMessage: "Home Delivery Complete At",
      });
    case PLACEHOLDER_ORDER_DELIVERY_ADDRESS:
      return intl.formatMessage({
        id: "home.delivery.address",
        defaultMessage: "Home Delivery Address",
      });
    case PLACEHOLDER_ORDER_DELIVERY_WORKER:
      return intl.formatMessage({
        id: "home.delivery.worker",
        defaultMessage: "Home Delivery Worker",
      });
    case PLACEHOLDER_ORDER_DELIVERY_NOTE:
      return intl.formatMessage({
        id: "home.delivery.note",
        defaultMessage: "Home Delivery Note",
      });
    case PLACEHOLDER_ORDER_MADE_DATE:
      return shortForm
        ? `Date`
        : intl.formatMessage({
            id: "order.made.date",
            defaultMessage: "Order Made Date",
          });
    case PLACEHOLDER_ORDER_MADE_TIME:
      return shortForm
        ? `Time`
        : intl.formatMessage({
            id: "order.made.time",
            defaultMessage: "Order Made Time",
          });
    case PLACEHOLDER_ORDER_MADE_WEEKDAY:
      return shortForm
        ? `Weekday`
        : intl.formatMessage({
            id: "order.made.weekday",
            defaultMessage: "Order Made Weekday",
          });
    case PLACEHOLDER_ORDER_MADE_WEEKDAY_INDEX:
      return shortForm
        ? `I`
        : intl.formatMessage({
            id: "order.made.weekday.index",
            defaultMessage: "Order Made Weekday Index",
          });
    case PLACEHOLDER_ORDER_MADE_BY:
      return shortForm
        ? `Made By`
        : intl.formatMessage({
            id: "order.made.by",
            defaultMessage: "Order Made By",
          });
    case PLACEHOLDER_ORDER_CONFIRMED_AT:
      return intl.formatMessage({
        id: "order.confirmed.at",
        defaultMessage: "Order Confirmed At",
      });
    case PLACEHOLDER_ORDER_CONFIRMED_BY:
      return intl.formatMessage({
        id: "order.confirmed.by",
        defaultMessage: "Order Confirmed By",
      });
    case PLACEHOLDER_ORDER_REDO_ORDER_NO:
      return intl.formatMessage({
        id: "order.redo.order.no",
        defaultMessage: "Order Redo Order No.",
      });
    case PLACEHOLDER_ORDER_INVOICE_DATE:
      return intl.formatMessage({
        id: "order.invoice.date",
        defaultMessage: "Order Invoice Date",
      });
    case PLACEHOLDER_ORDER_DUE_DATE:
      return intl.formatMessage({
        id: "order.due.date",
        defaultMessage: "Order Due Date",
      });
    case PLACEHOLDER_ORDER_RETURN_TYPE:
      return intl.formatMessage({
        id: "order.return.type",
        defaultMessage: "Order Return Type",
      });
    case PLACEHOLDER_TRANSACTION_ID:
      return intl.formatMessage({
        id: "transaction.id",
        defaultMessage: "Transaction ID",
      });
    case PLACEHOLDER_TRANSACTION_DATE:
      return intl.formatMessage({
        id: "transaction.date",
        defaultMessage: "Transaction Date",
      });
    case PLACEHOLDER_TRANSACTION_TIME:
      return intl.formatMessage({
        id: "transaction.time",
        defaultMessage: "Transaction Time",
      });
    case PLACEHOLDER_TRANSACTION_METHOD:
      return intl.formatMessage({
        id: "transaction.method",
        defaultMessage: "Transaction Method",
      });
    case PLACEHOLDER_TRANSACTION_DESCRIPTION:
      return intl.formatMessage({
        id: "transaction.description",
        defaultMessage: "Transaction Description",
      });
    case PLACEHOLDER_TRANSACTION_AMOUNT:
      return intl.formatMessage({
        id: "transaction.amount",
        defaultMessage: "Transaction Amount",
      });
    case PLACEHOLDER_TRANSACTION_MADE_BY:
      return intl.formatMessage({
        id: "transaction.made.by",
        defaultMessage: "Transaction Made By",
      });
    case PLACEHOLDER_TRANSACTION_NOTE:
      return intl.formatMessage({
        id: "transaction.note",
        defaultMessage: "Transaction Note",
      });
    case PLACEHOLDER_ORDER_STORE_PICKUP_DATE:
      return shortForm
        ? `Date`
        : intl.formatMessage({
            id: "store.pickup.date",
            defaultMessage: "Store Pickup Date",
          });
    case PLACEHOLDER_ORDER_STORE_PICKUP_WEEKDAY:
      return intl.formatMessage({
        id: "store.pickup.weekday",
        defaultMessage: "Store Pickup Weekday",
      });
    case PLACEHOLDER_ORDER_NO:
      return shortForm
        ? `00000`
        : intl.formatMessage({
            id: "order.no",
            defaultMessage: "Order No",
          });
    case PLACEHOLDER_ORDER_ID:
      return intl.formatMessage({
        id: "order.id",
        defaultMessage: "Order ID",
      });
    case PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_DESCRIPTION:
      return intl.formatMessage({
        id: "adjustment.item.description",
        defaultMessage: "Adjustment Item Description",
      });
    case PLACEHOLDER_ORDER_ADJUSTMENT_ITEM_AMOUNT:
      return shortForm
        ? `$Adjust`
        : intl.formatMessage({
            id: "adjustment.item.amount",
            defaultMessage: "Adjustment Item Amount",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_NAME:
      return shortForm
        ? `Item Name`
        : intl.formatMessage({
            id: "line.item.name",
            defaultMessage: "Line Item Name",
          });
    case PLACEHOLDER_ORDER_PACKAGE_INDEX:
      return shortForm
        ? `I`
        : intl.formatMessage({
            id: "order.package.index",
            defaultMessage: "Order Package Index",
          });
    case PLACEHOLDER_ORDER_PACKAGE_DATE:
      return shortForm
        ? `Date`
        : intl.formatMessage({
            id: "order.package.date",
            defaultMessage: "Order Package Date",
          });
    case PLACEHOLDER_ORDER_TOTAL_PACKAGES:
      return shortForm
        ? `T`
        : intl.formatMessage({
            id: "order.total.packages",
            defaultMessage: "Order Total Packages",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX:
      return shortForm
        ? `I`
        : intl.formatMessage({
            id: "line.item.pcs.index",
            defaultMessage: "Line Item pcs Index",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_UNIT_INDEX:
      return shortForm
        ? `I`
        : intl.formatMessage({
            id: "line.item.unit.index",
            defaultMessage: "Line Item Unit Index",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_TOTAL_PCS_PER_SET:
      return shortForm
        ? `T`
        : intl.formatMessage({
            id: "line.item.total.pcs.per.set",
            defaultMessage: "Line Item Total pcs Per Set",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_SET_PCS_INDEX:
      return shortForm
        ? `I`
        : intl.formatMessage({
            id: "line.item.set.pcs.index",
            defaultMessage: "Line Item Set pcs Index",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_INDEXES:
      return shortForm
        ? `#I`
        : intl.formatMessage({
            id: "line.item.indexes",
            defaultMessage: "Line Item Indexes",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_NAME:
      return shortForm
        ? `SO Name`
        : intl.formatMessage({
            id: "line.item.service.option.name",
            defaultMessage: "Line Item Service Option Name",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NAME:
      return shortForm
        ? `Sub-SO Name`
        : intl.formatMessage({
            id: "line.item.sub.service.option.name",
            defaultMessage: "Line Item Sub-service Option Name",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_CODE:
      return shortForm
        ? `SOC`
        : intl.formatMessage({
            id: "line.item.sub.service.option.code",
            defaultMessage: "Line Item Sub-service Option Code",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_PRICE:
      return shortForm
        ? `Price`
        : intl.formatMessage({
            id: "line.item.sub.service.option.price",
            defaultMessage: "Line Item Sub-service Option Price",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_DISCOUNT:
      return shortForm
        ? `Discount`
        : intl.formatMessage({
            id: "line.item.sub.service.option.discount",
            defaultMessage: "Line Item Sub-service Option Discount",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NOTE:
      return shortForm
        ? `Sub-SO Note`
        : intl.formatMessage({
            id: "line.item.sub.service.option.note",
            defaultMessage: "Line Item Sub-service Option Note",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_CODE:
      return shortForm
        ? `Code`
        : intl.formatMessage({
            id: "line.item.service.option.code",
            defaultMessage: "Line Item Service Option Code",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_QTY:
      return shortForm
        ? `Qty`
        : intl.formatMessage({
            id: "line.item.qty",
            defaultMessage: "Line Item Qty",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_UNIT:
      return shortForm
        ? `Unit`
        : intl.formatMessage({
            id: "line.item.unit",
            defaultMessage: "Line Item Unit",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_UNIT_PRICE:
      return shortForm
        ? `U.P.`
        : intl.formatMessage({
            id: "line.item.unit.price",
            defaultMessage: "Line Item Unit Price",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_TOTAL:
      return shortForm
        ? `Total`
        : intl.formatMessage({
            id: "line.item.total",
            defaultMessage: "Line Item Total",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NAME:
      return shortForm
        ? intl.formatMessage({
            id: "detail.name",
            defaultMessage: "Detail Name",
          })
        : intl.formatMessage({
            id: "line.item.detail.name",
            defaultMessage: "Line Item Detail Name",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CODE:
      return shortForm
        ? `CODE`
        : intl.formatMessage({
            id: "line.item.detail.code",
            defaultMessage: "Line Item Detail Code",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CONTENT:
      return shortForm
        ? intl.formatMessage({
            id: "item.detail",
            defaultMessage: "Item Detail",
          })
        : intl.formatMessage({
            id: "line.item.detail.content",
            defaultMessage: "Line Item Detail Content",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NOTE:
      return shortForm
        ? intl.formatMessage({
            id: "detail.note",
            defaultMessage: "Detail Note",
          })
        : intl.formatMessage({
            id: "line.item.detail.note",
            defaultMessage: "Line Item Detail Note",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_COMPLETION_DATE:
      return shortForm
        ? intl.formatMessage({
            id: "date",
            defaultMessage: "Date",
          })
        : intl.formatMessage({
            id: "line.item.completion.date",
            defaultMessage: "Line Item Completion Date",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_ADD_ON_NAMES:
      return shortForm
        ? `ADD-N`
        : intl.formatMessage({
            id: "line.item.add.on.names",
            defaultMessage: "Line Item Add-on Names",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_ADD_ON_CODES:
      return shortForm
        ? `C`
        : intl.formatMessage({
            id: "line.item.add.on.codes",
            defaultMessage: "Line Item Add-on Codes",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_ADD_ONS:
      return intl.formatMessage({
        id: "line.item.add.ons.summary",
        defaultMessage: "Line Item Add-ons Summary",
      });
    case PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_NAMES:
      return shortForm
        ? `ACC-N`
        : intl.formatMessage({
            id: "line.item.accessory.names",
            defaultMessage: "Line Item Accessory Names",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_CODES:
      return shortForm
        ? `C`
        : intl.formatMessage({
            id: "line.item.accessory.codes",
            defaultMessage: "Line Item Accessory Codes",
          });
    case PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORIES:
      return intl.formatMessage({
        id: "line.item.accessories.summary",
        defaultMessage: "Line Item Accessories Summary",
      });
    case PLACEHOLDER_ORDER_LINE_ITEM_PHOTO:
      return intl.formatMessage({
        id: "line.item.photo",
        defaultMessage: "Line Item Photo",
      });
    case PLACEHOLDER_ORDER_LINE_ITEM_NOTE:
      return intl.formatMessage({
        id: "line.item.note",
        defaultMessage: "Line Item Note",
      });
    case PLACEHOLDER_ORDER_PAYMENTS: {
      return intl.formatMessage({
        id: "order.payments",
        defaultMessage: "Order Payments",
      });
    }
    case PLACEHOLDER_ORDER_SUBTOTAL: {
      return shortForm
        ? intl.formatMessage({
            id: "order.subtotal",
            defaultMessage: "Subtotal",
          })
        : intl.formatMessage({
            id: "order.subtotal.amount",
            defaultMessage: "Order Subtotal Amount",
          });
    }
    case PLACEHOLDER_ORDER_TOTAL:
      return shortForm
        ? intl.formatMessage({
            id: "total",
            defaultMessage: "Total",
          })
        : intl.formatMessage({
            id: "order.total.amount",
            defaultMessage: "Order Total Amount",
          });
    case PLACEHOLDER_ORDER_TOTAL_WITH_OUT_TAX:
      return shortForm
        ? intl.formatMessage({
            id: "total.without.tax",
            defaultMessage: "Total(-Tax)",
          })
        : intl.formatMessage({
            id: "order.total.without.tax",
            defaultMessage: "Order Total Without Tax",
          });
    case PLACEHOLDER_ORDER_TAX_TYPE:
      return shortForm
        ? `TN`
        : intl.formatMessage({
            id: "order.tax.type",
            defaultMessage: "Order Tax Type",
          });
    case PLACEHOLDER_ORDER_TAX_RATE:
      return shortForm
        ? `TR`
        : intl.formatMessage({
            id: "order.tax.rate",
            defaultMessage: "Order Tax Rate",
          });
    case PLACEHOLDER_ORDER_TAX_AMOUNT:
      return shortForm
        ? `Tax`
        : intl.formatMessage({
            id: "order.tax.amount",
            defaultMessage: "Order Tax Amount",
          });
    case PLACEHOLDER_ORDER_TOTAL_UNITS:
      return shortForm
        ? `T`
        : intl.formatMessage({
            id: "order.total.units",
            defaultMessage: "Order Total Units",
          });
    case PLACEHOLDER_ORDER_TOTAL_PCS:
      return shortForm
        ? `T`
        : intl.formatMessage({
            id: "order.total.pcs",
            defaultMessage: "Order Total pcs",
          });
    case PLACEHOLDER_ORDER_NOTE:
      return intl.formatMessage({
        id: "order.note",
        defaultMessage: "Order Note",
      });
    case PLACEHOLDER_ORDER_STATUS:
      return shortForm
        ? `Status`
        : intl.formatMessage({
            id: "order.status",
            defaultMessage: "Order Status",
          });
    case PLACEHOLDER_ORDER_AMOUNT_PAID:
      return shortForm
        ? `$Paid`
        : intl.formatMessage({
            id: "order.amount.paid",
            defaultMessage: "Order Amount paid",
          });
    case PLACEHOLDER_ORDER_LAST_PAYMENT_DATE:
      return shortForm
        ? `Date`
        : intl.formatMessage({
            id: "order.last.payment.date",
            defaultMessage: "Order Last Payment Date",
          });
    case PLACEHOLDER_ORDER_PICKUP_LEAD_TIME:
      return shortForm
        ? "Lead"
        : intl.formatMessage({
            id: "order.pickup.lead.time",
            defaultMessage: "Order Pickup Lead Time",
          });
    case PLACEHOLDER_ORDER_PICKUP_OPTION_NAME:
      return shortForm
        ? `P/U Name`
        : intl.formatMessage({
            id: "order.pickup.option.name",
            defaultMessage: "Order Pickup Option Name",
          });
    case PLACEHOLDER_ORDER_DELIVERY_LEAD_TIME:
      return shortForm
        ? `Lead`
        : intl.formatMessage({
            id: "order.delivery.lead.time",
            defaultMessage: "Order Delivery Lead Time",
          });
    case PLACEHOLDER_ORDER_BOOKING_SURCHARGE:
      return shortForm
        ? `$Surcharge`
        : intl.formatMessage({
            id: "order.booking.surcharge",
            defaultMessage: "Order Booking Surcharge",
          });
    case PLACEHOLDER_ORDER_DELIVERY_OPTION_NAME:
      return shortForm
        ? `Delivery Name`
        : intl.formatMessage({
            id: "order.delivery.option.name",
            defaultMessage: "Order Delivery Option Name",
          });
    case PLACEHOLDER_ORDER_AMOUNT_DUE:
      return shortForm
        ? `$Due`
        : intl.formatMessage({
            id: "order.amount.due",
            defaultMessage: "Order Amount due",
          });
    case PLACEHOLDER_ORDER_PICKUP_STORE_NAME:
      return intl.formatMessage({
        id: "order.pickup.store.name",
        defaultMessage: "Order Pickup Store Name",
      });
    case PLACEHOLDER_ORDER_PICKUP_STORE_ADDRESS:
      return intl.formatMessage({
        id: "order.pickup.store.address",
        defaultMessage: "Order Pickup Store Address",
      });
    case PLACEHOLDER_ORDER_PICKUP_STORE_PHONE_NUMBER:
      return intl.formatMessage({
        id: "order.pickup.store.phone.number",
        defaultMessage: "Order Pickup Store Phone Number",
      });
    case PLACEHOLDER_ORDER_PICKUP_STORE_OPENING_HOURS:
      return intl.formatMessage({
        id: "order.pickup.store.opening.hours",
        defaultMessage: "Order Pickup Store Opening Hours",
      });
    case PLACEHOLDER_TRANSACTION_BALANCE_BEFORE:
      return intl.formatMessage({
        id: "transaction.balance.before",
        defaultMessage: "Credit Balance Before Transaction",
      });
    case PLACEHOLDER_TRANSACTION_BALANCE_AFTER:
      return intl.formatMessage({
        id: "transaction.balance.after",
        defaultMessage: "Credit Balance After Transaction",
      });
    case PLACEHOLDER_MEMBER_TITLE:
      return intl.formatMessage({
        id: "member.title",
        defaultMessage: "Member Title",
      });
    case PLACEHOLDER_MEMBER_BENEFITS:
      return intl.formatMessage({
        id: "member.benefits",
        defaultMessage: "Member Benefits",
      });
    case PLACEHOLDER_MEMBER_JOIN_DATE:
      return intl.formatMessage({
        id: "member.join.date",
        defaultMessage: "Member Join Date",
      });
    case PLACEHOLDER_MEMBER_EXPIRY_DATE:
      return intl.formatMessage({
        id: "member.expiry.date",
        defaultMessage: "Member Expiry Date",
      });
    case PLACEHOLDER_MEMBER_FEE:
      return shortForm
        ? `$Fee`
        : intl.formatMessage({
            id: "member.fee",
            defaultMessage: "Member Fee",
          });
    case PLACEHOLDER_CREDIT_BALANCE:
      return shortForm
        ? `$Credit`
        : intl.formatMessage({
            id: "credit.balance",
            defaultMessage: "Credit Balance",
          });
    case PLACEHOLDER_REWARD_POINTS_BALANCE:
      return shortForm
        ? `Points`
        : intl.formatMessage({
            id: "reward.points.balance",
            defaultMessage: "Reward Points Balance",
          });
    case PLACEHOLDER_PAGE_NUMBER:
      return intl.formatMessage({
        id: "page.number",
        defaultMessage: "Page Number",
      });
    case PLACEHOLDER_TOTAL_PAGES:
      return intl.formatMessage({
        id: "total.pages",
        defaultMessage: "Total Pages",
      });
    case PLACEHOLDER_CURRENT_YEAR:
      return shortForm
        ? `Year`
        : intl.formatMessage({
            id: "current.year",
            defaultMessage: "Current Year",
          });
    default:
      return placeholderId || "";
  }
}
