import { useActionCallback } from "@easybiz/hook";
import { JOB_ACTION_REVERSE, OPERATION_GROUP_JOB } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function useJobReverseStatus(callback) {
  const intl = useIntl();

  const onSubmit = useCallback(({ taskId }) => {
    return [
      OPERATION_GROUP_JOB,
      JOB_ACTION_REVERSE,
      {
        taskId,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Job status reversed" }));
}

export default useJobReverseStatus;
