import { BorderOutlined, CheckSquareOutlined } from "@ant-design/icons";
import { Badge, Button } from "antd";
import React from "react";
import { useIntl } from "react-intl";

export default ({ disabled, selects, sortIndex, onChange, salesReturn }) => {
  const selectIndex = (selects || []).indexOf(sortIndex);
  const selected = selectIndex >= 0;
  const intl = useIntl();

  return (
    <Badge count={salesReturn ? intl.formatMessage({ defaultMessage: "Sales returned" }) : null}>
      <Button
        disabled={disabled}
        icon={selected ? <CheckSquareOutlined /> : <BorderOutlined />}
        danger={salesReturn}
        type={selected ? "primary" : "default"}
        onClick={(e) => {
          e.stopPropagation();
          if (onChange) {
            if (selectIndex >= 0) {
              onChange([...selects.slice(0, selectIndex), ...selects.slice(selectIndex + 1)]);
            } else {
              onChange([...(selects || []), sortIndex]);
            }
          }
        }}
      >{`#${sortIndex}`}</Button>
    </Badge>
  );
};
