const options = {
  1: "lg:row-start-1",
  2: "lg:row-start-2",
  3: "lg:row-start-3",
  4: "lg:row-start-4",
};

export default function getGridRowStartLg(start) {
  return options[start];
}
