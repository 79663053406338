const options = {
  left: "md:text-left",
  center: "md:text-center",
  right: "md:text-right",
  justify: "md:text-justify",
};

export default function getTextAlignMd(align) {
  return options[align];
}
