import {
  AccountBookOutlined,
  CarOutlined,
  CheckOutlined,
  DesktopOutlined,
  DollarOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import {
  useCashDrawer,
  useClientState,
  useClientStateSetter,
  useClientTheme,
  useRealmIsLoaded,
  useRealmSupplierIds,
  useUserBusinessCode,
  useUserIsPendingSignIn,
  useUserPermissions,
  useUser,
} from "@easybiz/shell";
import {
  DISPLAY_MODE_CHECK_IN,
  DISPLAY_MODE_CHECK_OUT,
  DISPLAY_MODE_COLLECTION,
  DISPLAY_MODE_CONFIRM_ORDER,
  DISPLAY_MODE_PRODUCTION,
  DISPLAY_MODE_SALES,
  SALES_CHANNEL_TYPE_STORE,
} from "@easybiz/utils";
import {
  ApplicationLauncher,
  LocationMonitor,
  OfflineAlert,
  OrderDrawer,
  SystemNoticeBanner,
  WebCheckout,
} from "@easybiz/web-admin";
import { Space } from "antd";
import React from "react";
import CashDrawerButton from "./components/CashDrawerButton";
import CollectionOptionsLoader from "./components/CollectionOptionsLoader";
import POSDisplayModeButton from "./components/POSDisplayModeButton";
import POSHomeLeftPanel from "./components/POSHomeLeftPanel";
import POSHomeRightPanel from "./components/POSHomeRightPanel";
import POSSignInPanel from "./components/POSSignInPanel";

export default function POSWebApplication() {
  const businessCode = useUserBusinessCode();
  const pendingSignIn = useUserIsPendingSignIn();
  const permissions = useUserPermissions();
  const user = useUser();
  const cashDrawer = useCashDrawer();
  const realmReady = useRealmIsLoaded();
  const secondaryContainer = useClientTheme()?.surface;
  const setState = useClientStateSetter();
  const supplierIds = useRealmSupplierIds();
  const { date, openOrderId } = useClientState();

  return pendingSignIn ? (
    <POSSignInPanel />
  ) : Array.isArray(permissions) && realmReady ? (
    <WebCheckout newOrderType={SALES_CHANNEL_TYPE_STORE} businessCode={businessCode} date={date}>
      <div className="flex full-screen">
        <OfflineAlert />
        <SystemNoticeBanner />
        {user?.multiStation && <LocationMonitor />}
        <div className="flex flex-fill flex-row scroll-container">
          <div className="flex flex-fill">
            <POSHomeLeftPanel />
            <div className="flex flex-row flex-space-between flex-align-center padding-half">
              <Space>
                <POSDisplayModeButton targetMode={DISPLAY_MODE_CONFIRM_ORDER} icon={<AccountBookOutlined />} />
                <POSDisplayModeButton targetMode={DISPLAY_MODE_CHECK_OUT} icon={<ShopOutlined />} />
                <POSDisplayModeButton targetMode={DISPLAY_MODE_CHECK_IN} icon={<CarOutlined />} />
                <POSDisplayModeButton targetMode={DISPLAY_MODE_COLLECTION} icon={<CheckOutlined />} />
                {supplierIds?.length > 0 && (
                  <POSDisplayModeButton targetMode={DISPLAY_MODE_PRODUCTION} icon={<DesktopOutlined />} />
                )}
              </Space>
              <Space>
                <POSDisplayModeButton targetMode={DISPLAY_MODE_SALES} icon={<DollarOutlined />} />
                <CashDrawerButton />
              </Space>
            </div>
          </div>
          <div
            className="flex"
            style={{ width: 470, minWidth: 470, padding: "8px 8px 0px 8px", background: secondaryContainer }}
          >
            <POSHomeRightPanel />
          </div>
          <OrderDrawer
            cashDrawer={cashDrawer}
            placement={"right"}
            orderId={openOrderId}
            open={Boolean(openOrderId)}
            onClose={() => setState({ openOrderId: null })}
          />
          <CollectionOptionsLoader />
        </div>
      </div>
    </WebCheckout>
  ) : (
    <ApplicationLauncher />
  );
}
