"use client";

import { formatPrice } from "@easybiz/utils";
import { FormattedMessage } from "react-intl";
import AmountDisplay from "../AmountDisplay";

function useOrderPaymentPanel(order) {
  const { totalBalance, depositAmount, netSales, totalPaid, totalWaived, voided, paid } = order?.data() || {};
  const overPaidAmount = order ? formatPrice((totalPaid || 0) - Math.max(0, (netSales || 0) - (totalWaived || 0))) : 0;

  return {
    ...(!voided &&
      !paid &&
      typeof depositAmount === "number" &&
      depositAmount > totalPaid && {
        depositAlert: {
          title: (
            <FormattedMessage
              id="pending.deposit.payment.x"
              defaultMessage={"Pending deposit payment of {amount}"}
              values={{
                amount: <AmountDisplay value={depositAmount} />,
              }}
            />
          ),
          description: <FormattedMessage id="click.to.pay.deposit" defaultMessage={"Click to pay deposit"} />,
        },
      }),
    ...(order &&
      !voided && {
        ...(totalBalance > 0 && {
          totalBalance,
        }),
        ...(overPaidAmount > 0 && {
          overPaidAmount,
        }),
        ...(order.get("totalBalance") > 0 && {
          paymentLink: true,
        }),
      }),
  };
}

export default useOrderPaymentPanel;
