const options = {
  center: "items-center",
  end: "items-end",
  start: "items-start",
  stretch: "items-stretch",
  baseline: "items-baseline",
};

export default function getAlignItems(align) {
  return options[align];
}
