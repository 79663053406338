const options = {
  5: "bg-white/5",
  10: "bg-white/10",
  25: "bg-white/25",
  50: "bg-white/50",
  75: "bg-white/75",
  90: "bg-white/90",
  100: "bg-white",
};

const from = {
  5: "from-white/5",
  10: "from-white/10",
  25: "from-white/25",
  50: "from-white/50",
  75: "from-white/75",
  90: "from-white/90",
  100: "from-white",
};

export default function getBackgroundWhite(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
