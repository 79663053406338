const values = {
  tighter: "tracking-tighter", // -0.05em;
  tight: "tracking-tight", // -0.025em;
  normal: "tracking-normal", // 0;
  wide: "tracking-wide", // 0.025em;
  wider: "tracking-wider", // 0.05em;
};

export default function getTracking(tracking) {
  return values[tracking];
}
