import { COLOR_TRANSPARENT, getColorHex } from "@easybiz/utils";

export default function getLinearGradient(attrs, theme) {
  return `linear-gradient(${[
    attrs.bgAngle,
    [getColorHex(attrs.bgStart, theme), attrs.bgStartPosition ? `${attrs.bgStartPosition}%` : null]
      .filter(Boolean)
      .join(" "),
    ...(attrs.bgStops || []).map(({ color, position }) =>
      [color ? getColorHex(color, theme) : null, position ? `${position}%` : null].filter(Boolean).join(" ")
    ),
    [getColorHex(attrs.bgEnd || COLOR_TRANSPARENT, theme), attrs.bgEndPosition ? `${attrs.bgEndPosition}%` : null]
      .filter(Boolean)
      .join(" "),
  ]
    .filter(Boolean)
    .join(", ")})`;
}
