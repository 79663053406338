const options = {
  0: "sm:border-l-0",
  1: "sm:border-l",
  2: "sm:border-l-2",
  4: "sm:border-l-4",
  8: "sm:border-l-8",
};

export default function getBorderLeftSm(width) {
  return options[width];
}
