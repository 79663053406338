const values = {
  static: "static",
  relative: "relative",
  fixed: "fixed",
  absolute: "absolute",
  sticky: "sticky",
};

export default function getPosition(position) {
  return values[position];
}
