import dayjs from "dayjs";
import calculateUnitIndex from "./calculateUnitIndex.js";
import { AGENT_SORTING_CODE, INTERNAL_NAME, RECEIVING_DAY_INDEX } from "./constants/label.js";
import { toItemQR } from "./qrcode.js";
import renderLabelContent from "./renderLabelContent.js";
import translate from "./translate.js";

export default function (order, labelId, context, accessory, labelSettings) {
  const orderData = (typeof order?.data === "function" && order?.data()) || order || {};
  const { labels, items, sortingCode, timestamp, business, redoOrderNo } = orderData;
  const businessCode = business?.id;
  const sortIndex = (labels || []).indexOf(labelId) + 1;
  const itemIndex = (items || []).findIndex(({ labelIndexes }) => (labelIndexes || []).indexOf(sortIndex) >= 0);
  const item = items ? items[itemIndex] : null;
  // Calculate unit index
  const { accessories, addons, product, date: itemDate, labelIndexes, notes } = item || {};
  const { unitIndex, unitTotalPcs, unitPcsIndex } = calculateUnitIndex(items, sortIndex);
  const lineItemLabelIndex = Array.isArray(labelIndexes) ? labelIndexes.indexOf(sortIndex) : -1;
  const businessCodes = context?.businessCodes;
  const {
    title,
    description,
    info,
    appendix,
    businessCodeMap,
    fixedHeight,
    fixedWidth,
    qrSize,
    qrEcc,
    singleQr,
    qrCode,
    labelPrinterShift,
    labelDensity,
  } = labelSettings || {};
  const totalAccessory =
    Array.isArray(accessories) && [0, ...accessories.map((accs) => accs.qty || 0)].reduce((a, b) => a + b);
  const titleSuffix = !accessory && Array.isArray(accessories) ? `(*${totalAccessory})` : "";

  let leftIndex = businessCodes && businessCodes[businessCode];
  let rightIndex = businessCodes && businessCodes[businessCode];

  const smallLabel = typeof fixedHeight !== "number" || fixedHeight <= 10;

  if (businessCodeMap && businessCodeMap[businessCode]) {
    const datas = (
      Array.isArray(businessCodeMap[businessCode]) ? businessCodeMap[businessCode] : [businessCodeMap[businessCode]]
    )
      .map((data) => {
        switch (data) {
          case RECEIVING_DAY_INDEX: {
            const receive = dayjs.unix(timestamp);
            return receive.isValid() ? `${receive.day() === 0 ? 7 : receive.day()}` : "";
          }
          case INTERNAL_NAME:
            return businessCodes && businessCodes[businessCode];
          case AGENT_SORTING_CODE:
            return sortingCode;
          default:
            return data || null;
        }
      })
      .filter((data) => data);

    leftIndex = datas.join("-");

    if (smallLabel) {
      rightIndex = datas.reverse().join("-");
    } else {
      rightIndex = leftIndex;
    }
  }

  const data = {
    ...orderData,
    ...item,
    ...(Array.isArray(notes) &&
      lineItemLabelIndex >= 0 &&
      typeof notes[lineItemLabelIndex] === "string" && { note: notes[lineItemLabelIndex] }),
    ...(itemDate && { delivery: { date: itemDate } }),
    businessCode,
    sortIndex,
    unitIndex,
    unitTotalPcs,
    unitPcsIndex,
  };

  return {
    sortIndex,
    unitIndex,
    ...(unitTotalPcs && {
      unitTotalPcs,
      unitPcsIndex,
    }),
    ...(qrCode !== false && { qrcode: toItemQR(labelId), singleQr, qrSize, qrEcc }),
    title: `${renderLabelContent(title || "", data, context)}${titleSuffix}`,
    reverseTitle: Boolean(redoOrderNo),
    description: `${
      accessory ? `** ${translate(accessory.title)} (${accessory.index}/${accessory.qty})\n` : ""
    }${renderLabelContent(description || "", data, context)}`,
    info: renderLabelContent(info || "", data, context),
    note: data.note,
    appendix: appendix && renderLabelContent(appendix, data, context),
    icons: [
      ...(product?.icons || []).filter((icon) => icon),
      ...(addons || []).map((addon) => addon.code).filter((code) => code),
    ],
    ...(fixedHeight && { fixedHeight }),
    ...(fixedWidth && { fixedWidth }),
    ...(leftIndex && { leftIndex }),
    ...(rightIndex && { rightIndex }),
    ...(accessory && { accessory: true }),
    ...(typeof labelPrinterShift === "number" && { labelPrinterShift }),
    ...(typeof labelDensity === "number" && { labelDensity }),
  };
}
