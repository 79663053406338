import { translate } from "@easybiz/utils";

function LineItemTitle({ item, showIndex }) {
  const { itemIndex, product, subType, unitQty, unitLabel } = item || {};

  return `${Number.isInteger(itemIndex) && showIndex ? `#${itemIndex + 1} ` : ""}${translate(product) || ""}${
    subType ? ` - ${translate(subType)}` : ""
  }${unitQty && unitLabel ? ` (${unitQty} ${unitLabel})` : ""}`;
}

export default LineItemTitle;
