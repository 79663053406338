import { CloseOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { usePOSCashDrawerOpen } from "@easybiz/api-pos";
import { usePrinter } from "@easybiz/hook";
import { useCashDrawer } from "@easybiz/shell";
import { IconButton } from "@easybiz/web-admin";
import { Button, Card } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import EndDrawerDialog from "./EndDrawerDialog";
import EndDrawerPanel from "./EndDrawerPanel";
import StartDrawerPanel from "./StartDrawerPanel";

export default function ({ onClose }) {
  const printer = usePrinter();
  const cashDrawer = useCashDrawer();
  const [endDrawerOpen, setEndDrawerOpen] = useState(false);
  const { onSubmit: onOpenDrawer } = usePOSCashDrawerOpen();

  return (
    <div className="flex flex-fill scroll-container">
      <div className="flex-fill scroll-body">
        <Card
          title={<FormattedMessage defaultMessage={"Cash Drawer"} />}
          extra={<IconButton icon={<CloseOutlined />} onClick={onClose} />}
        >
          {cashDrawer?.startTime ? (
            <EndDrawerPanel onEndDrawer={() => setEndDrawerOpen(true)} />
          ) : (
            <StartDrawerPanel onStarted={onClose} />
          )}
        </Card>
        <EndDrawerDialog
          cashDrawer={cashDrawer}
          open={endDrawerOpen}
          onCancel={() => setEndDrawerOpen(false)}
          onEnded={() => {
            setEndDrawerOpen(false);
            onClose(false);
          }}
        />
      </div>
      <div className="flex flex-row padding-vertical-half">
        <Button
          size="large"
          onClick={() => {
            printer.openDrawer();
            // Record
            onOpenDrawer && onOpenDrawer();
          }}
          icon={<FolderOpenOutlined />}
        >
          <FormattedMessage tagName="span" defaultMessage="Open cash drawer" />
        </Button>
      </div>
    </div>
  );
}
