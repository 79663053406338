"use client";

import {
  useActionClientFirestore,
  useActionServerFunction,
  useAppDrawer,
  useClientInstallId,
  useClientType,
  useClientVersion,
  useFeedbackToast,
} from "@easybiz/shell";
import { useCallback, useState } from "react";

export default function useServerAction(actionName) {
  const toast = useFeedbackToast();
  const client = useClientType();
  const clientVersion = useClientVersion();
  const installId = useClientInstallId();
  const httpsFunction = useActionServerFunction();
  const drawer = useAppDrawer();
  const { doc, setDoc, arrayUnion, getFirestore } = useActionClientFirestore();
  const [request, setRequest] = useState();
  const [response, setResponse] = useState();

  const onCall = useCallback(
    async (params, ...args) => {
      const onSucceed = args?.find((arg) => typeof arg === "function");

      try {
        setRequest(params || {});

        const response = await httpsFunction(
          {
            ...params,
            client,
            clientVersion,
            installId,
            ...(process.env.NODE_ENV === "development" && { isDevMode: true }),
          },
          actionName // Action name is Firebase function name
        );

        setResponse(response.data || {});

        if (typeof response.data?.success === "string") {
          toast.success(response.data.success);
        } else if (typeof response.data?.info === "string") {
          toast.info(response.data.info);
        } else {
          const successMessage = args?.find((arg) => typeof arg === "string");
          if (successMessage) {
            toast.success(successMessage);
          }
        }

        if (drawer?.guidePath) {
          setDoc(
            doc(getFirestore(), drawer.guidePath),
            {
              completes: arrayUnion(actionName),
            },
            { merge: true }
          );
        }

        if (onSucceed) {
          onSucceed(response.data || {});
        }
      } catch (error) {
        console.info(`%c[${actionName} failed]`, `color: red`, error.message, JSON.stringify(error.details));

        setResponse({
          error: {
            ...error.details,
            message: error.message,
          },
        });

        if (error.message) {
          toast?.error(error.message, error.details);
        }
      } finally {
        setRequest(null);
      }
    },
    [client, installId, clientVersion, actionName, drawer]
  );

  const onClear = useCallback(() => setResponse(null), []);

  return {
    request,
    response,
    loading: Boolean(request),
    succeed: Boolean(response && !response.error),
    onCall,
    setResponse,
    onAdjust: (updates) => setResponse((response) => ({ ...response, ...updates })), // TODO: remove
    onClear,
  };
}
