import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { AmountDisplay, DateDisplay } from "@easybiz/component";
import { useCashDrawer } from "@easybiz/shell";
import {
  DRAWER_TRANSACTION_TYPE_CASH_IN,
  DRAWER_TRANSACTION_TYPE_PAYMENT,
  DRAWER_TRANSACTION_TYPE_REFUND,
} from "@easybiz/utils";
import { Title } from "@easybiz/web-admin";
import { Button, Col, List, Row } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import CashInOutDrawer from "./CashInOutDrawer";
import DrawerHistoryDrawer from "./DrawerHistoryDrawer";

export default ({ onEndDrawer }) => {
  const cashDrawer = useCashDrawer();
  const [isCashIn, setIsCashIn] = useState(null);
  const [selectType, setSelectType] = useState();

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List
          header={
            <FormattedMessage
              defaultMessage={"Start of Drawer {date}"}
              values={{
                date: <DateDisplay timestamp={cashDrawer?.startTime} />,
              }}
            />
          }
          dataSource={[
            {
              title: <FormattedMessage defaultMessage={"Starting Cash"} />,
              amount: cashDrawer?.startCash,
            },
            {
              title: (
                <span>
                  <FormattedMessage defaultMessage={"Cash Payments"} /> ({cashDrawer?.cashPaymentsCount || 0})
                </span>
              ),
              amount: cashDrawer?.cashPayments,
              type: "success",
              onClick: () => setSelectType(DRAWER_TRANSACTION_TYPE_PAYMENT),
            },
            {
              title: (
                <span>
                  <FormattedMessage defaultMessage={"Cash Refunds"} /> ({cashDrawer?.cashRefundsCount || 0})
                </span>
              ),
              amount: cashDrawer?.cashRefunds,
              type: "danger",
              onClick: () => setSelectType(DRAWER_TRANSACTION_TYPE_REFUND),
            },
            {
              title: (
                <span>
                  <FormattedMessage defaultMessage={"Cash In/Out"} /> ({cashDrawer?.cashInOutCount || 0})
                </span>
              ),
              amount: cashDrawer?.cashInOut,
              type: cashDrawer?.cashInOut < 0 ? "danger" : cashDrawer?.cashInOut > 0 ? "success" : null,
              onClick: () => setSelectType(DRAWER_TRANSACTION_TYPE_CASH_IN),
            },
            {
              title: <FormattedMessage defaultMessage={"Expected in Drawer"} />,
              amount: cashDrawer?.balance,
            },
          ]}
          renderItem={({ title, amount, type, onClick }) => {
            const negitive = type === "danger";

            return (
              <List.Item
                extra={
                  <Title type={type}>
                    {negitive ? `(` : ""}
                    <AmountDisplay value={Math.abs(amount || 0)} />
                    {negitive ? `)` : ""}
                  </Title>
                }
                className={onClick ? "selectable" : null}
                onClick={onClick}
              >
                <List.Item.Meta title={title} />
              </List.Item>
            );
          }}
        />
      </Col>
      <Col span={12}>
        <Button size="large" icon={<PlusOutlined />} ghost type="primary" block onClick={() => setIsCashIn(true)}>
          <FormattedMessage tagName={"span"} defaultMessage={"Cash in"} />
        </Button>
      </Col>
      <Col span={12}>
        <Button size="large" icon={<MinusOutlined />} danger block onClick={() => setIsCashIn(false)}>
          <FormattedMessage tagName={"span"} defaultMessage={"Cash out"} />
        </Button>
      </Col>
      <Col span={24}>
        <Button size="large" type="primary" block onClick={onEndDrawer}>
          <FormattedMessage tagName={"span"} defaultMessage={"End drawer"} />
        </Button>
      </Col>
      <CashInOutDrawer isCashIn={isCashIn} open={typeof isCashIn === "boolean"} onClose={() => setIsCashIn(null)} />
      <DrawerHistoryDrawer
        startTime={cashDrawer?.startTime}
        type={selectType}
        open={Boolean(selectType)}
        onCancel={() => setSelectType(null)}
      />
    </Row>
  );
};
