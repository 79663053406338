const values = {
  0: "lg:left-0",
  1: "lg:left-1",
  2: "lg:left-2",
  3: "lg:left-3",
  4: "lg:left-4",
  5: "lg:left-5",
  6: "lg:left-6",
  7: "lg:left-7",
  8: "lg:left-8",
  9: "lg:left-9",
  10: "lg:left-10",
  11: "lg:left-11",
  12: "lg:left-12",
  14: "lg:left-14",
  16: "lg:left-16",
  20: "lg:left-20",
  "1/2": "lg:left-1/2",
};

export default function getAbsoluteLeftLg(value) {
  return values[value];
}
