"use client";

import { useRealmContext } from "@easybiz/shell";
import { BUSINESS_ID_FACTORY, toDisplayName } from "@easybiz/utils";
import { FormattedMessage } from "react-intl";

function NameDisplay({ nameKey, showKey, fallback }) {
  const context = useRealmContext();

  if (nameKey === BUSINESS_ID_FACTORY) {
    return <FormattedMessage id="plant" defaultMessage={"Plant"} />;
  } else {
    return toDisplayName(nameKey, context) || (showKey ? nameKey || null : null) || fallback;
  }
}

export default NameDisplay;
