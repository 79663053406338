import { PAYMENT_ID_POINTS } from "./constants/index.js";
import formatPrice from "./formatPrice.js";

export default function formatPointsPayment(pointToMoneyExRate, points, totalPrice) {
  if (
    typeof totalPrice === "number" &&
    totalPrice > 0 &&
    typeof pointToMoneyExRate === "number" &&
    pointToMoneyExRate > 0 &&
    typeof points === "number" &&
    points > 0
  ) {
    const totalBalance = formatPrice(points / pointToMoneyExRate);
    const payableAmount = totalBalance >= totalPrice ? totalPrice : Math.floor(totalBalance);

    if (payableAmount > 0) {
      return {
        id: PAYMENT_ID_POINTS,
        option: PAYMENT_ID_POINTS,
        amount: payableAmount,
        points: Math.floor(payableAmount * pointToMoneyExRate),
        maximumPayable: totalBalance,
        totalPoints: points,
        lockRate: pointToMoneyExRate,
      };
    } else {
      return {
        id: PAYMENT_ID_POINTS,
        option: PAYMENT_ID_POINTS,
        amount: payableAmount,
        maximumPayable: totalBalance,
        disabled: true,
        totalPoints: points,
      };
    }
  }
}
