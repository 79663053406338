const options = {
  auto: "sm:aspect-auto",
  square: "sm:aspect-square",
  video: "sm:aspect-video",
  none: "sm:aspect-none",
  "16/1": "sm:aspect-[16/1]",
  "16/2": "sm:aspect-[16/2]",
  "16/3": "sm:aspect-[16/3]",
  "16/4": "sm:aspect-[16/4]",
  "16/5": "sm:aspect-[16/5]",
  "16/6": "sm:aspect-[16/6]",
  "16/7": "sm:aspect-[16/7]",
  "16/8": "sm:aspect-[16/8]",
  "16/9": "sm:aspect-[16/9]",
  "16/10": "sm:aspect-[16/10]",
  "16/11": "sm:aspect-[16/11]",
  "16/12": "sm:aspect-[16/12]",
  "16/13": "sm:aspect-[16/13]",
  "16/14": "sm:aspect-[16/14]",
  "16/15": "sm:aspect-[16/15]",
  "16/16": "sm:aspect-[16/16]",
  "15/1": "sm:aspect-[15/1]",
  "15/2": "sm:aspect-[15/2]",
  "15/3": "sm:aspect-[15/3]",
  "15/4": "sm:aspect-[15/4]",
  "15/5": "sm:aspect-[15/5]",
  "15/6": "sm:aspect-[15/6]",
  "15/7": "sm:aspect-[15/7]",
  "15/8": "sm:aspect-[15/8]",
  "15/9": "sm:aspect-[15/9]",
  "15/10": "sm:aspect-[15/10]",
  "15/11": "sm:aspect-[15/11]",
  "15/12": "sm:aspect-[15/12]",
  "15/13": "sm:aspect-[15/13]",
  "15/14": "sm:aspect-[15/14]",
  "15/15": "sm:aspect-[15/15]",
  "15/16": "sm:aspect-[15/16]",
  "14/1": "sm:aspect-[14/1]",
  "14/2": "sm:aspect-[14/2]",
  "14/3": "sm:aspect-[14/3]",
  "14/4": "sm:aspect-[14/4]",
  "14/5": "sm:aspect-[14/5]",
  "14/6": "sm:aspect-[14/6]",
  "14/7": "sm:aspect-[14/7]",
  "14/8": "sm:aspect-[14/8]",
  "14/9": "sm:aspect-[14/9]",
  "14/10": "sm:aspect-[14/10]",
  "14/11": "sm:aspect-[14/11]",
  "14/12": "sm:aspect-[14/12]",
  "14/13": "sm:aspect-[14/13]",
  "14/14": "sm:aspect-[14/14]",
  "14/15": "sm:aspect-[14/15]",
  "14/16": "sm:aspect-[14/16]",
  "13/1": "sm:aspect-[13/1]",
  "13/2": "sm:aspect-[13/2]",
  "13/3": "sm:aspect-[13/3]",
  "13/4": "sm:aspect-[13/4]",
  "13/5": "sm:aspect-[13/5]",
  "13/6": "sm:aspect-[13/6]",
  "13/7": "sm:aspect-[13/7]",
  "13/8": "sm:aspect-[13/8]",
  "13/9": "sm:aspect-[13/9]",
  "13/10": "sm:aspect-[13/10]",
  "13/11": "sm:aspect-[13/11]",
  "13/12": "sm:aspect-[13/12]",
  "13/13": "sm:aspect-[13/13]",
  "13/14": "sm:aspect-[13/14]",
  "13/15": "sm:aspect-[13/15]",
  "13/16": "sm:aspect-[13/16]",
  "12/1": "sm:aspect-[12/1]",
  "12/2": "sm:aspect-[12/2]",
  "12/3": "sm:aspect-[12/3]",
  "12/4": "sm:aspect-[12/4]",
  "12/5": "sm:aspect-[12/5]",
  "12/6": "sm:aspect-[12/6]",
  "12/7": "sm:aspect-[12/7]",
  "12/8": "sm:aspect-[12/8]",
  "12/9": "sm:aspect-[12/9]",
  "12/10": "sm:aspect-[12/10]",
  "12/11": "sm:aspect-[12/11]",
  "12/12": "sm:aspect-[12/12]",
  "12/13": "sm:aspect-[12/13]",
  "12/14": "sm:aspect-[12/14]",
  "12/15": "sm:aspect-[12/15]",
  "12/16": "sm:aspect-[12/16]",
  "11/1": "sm:aspect-[11/1]",
  "11/2": "sm:aspect-[11/2]",
  "11/3": "sm:aspect-[11/3]",
  "11/4": "sm:aspect-[11/4]",
  "11/5": "sm:aspect-[11/5]",
  "11/6": "sm:aspect-[11/6]",
  "11/7": "sm:aspect-[11/7]",
  "11/8": "sm:aspect-[11/8]",
  "11/9": "sm:aspect-[11/9]",
  "11/10": "sm:aspect-[11/10]",
  "11/11": "sm:aspect-[11/11]",
  "11/12": "sm:aspect-[11/12]",
  "11/13": "sm:aspect-[11/13]",
  "11/14": "sm:aspect-[11/14]",
  "11/15": "sm:aspect-[11/15]",
  "11/16": "sm:aspect-[11/16]",
  "10/1": "sm:aspect-[10/1]",
  "10/2": "sm:aspect-[10/2]",
  "10/3": "sm:aspect-[10/3]",
  "10/4": "sm:aspect-[10/4]",
  "10/5": "sm:aspect-[10/5]",
  "10/6": "sm:aspect-[10/6]",
  "10/7": "sm:aspect-[10/7]",
  "10/8": "sm:aspect-[10/8]",
  "10/9": "sm:aspect-[10/9]",
  "10/10": "sm:aspect-[10/10]",
  "10/11": "sm:aspect-[10/11]",
  "10/12": "sm:aspect-[10/12]",
  "10/13": "sm:aspect-[10/13]",
  "10/14": "sm:aspect-[10/14]",
  "10/15": "sm:aspect-[10/15]",
  "10/16": "sm:aspect-[10/16]",
  "9/1": "sm:aspect-[9/1]",
  "9/2": "sm:aspect-[9/2]",
  "9/3": "sm:aspect-[9/3]",
  "9/4": "sm:aspect-[9/4]",
  "9/5": "sm:aspect-[9/5]",
  "9/6": "sm:aspect-[9/6]",
  "9/7": "sm:aspect-[9/7]",
  "9/8": "sm:aspect-[9/8]",
  "9/9": "sm:aspect-[9/9]",
  "9/10": "sm:aspect-[9/10]",
  "9/11": "sm:aspect-[9/11]",
  "9/12": "sm:aspect-[9/12]",
  "9/13": "sm:aspect-[9/13]",
  "9/14": "sm:aspect-[9/14]",
  "9/15": "sm:aspect-[9/15]",
  "9/16": "sm:aspect-[9/16]",
  "8/1": "sm:aspect-[8/1]",
  "8/2": "sm:aspect-[8/2]",
  "8/3": "sm:aspect-[8/3]",
  "8/4": "sm:aspect-[8/4]",
  "8/5": "sm:aspect-[8/5]",
  "8/6": "sm:aspect-[8/6]",
  "8/7": "sm:aspect-[8/7]",
  "8/8": "sm:aspect-[8/8]",
  "8/9": "sm:aspect-[8/9]",
  "8/10": "sm:aspect-[8/10]",
  "8/11": "sm:aspect-[8/11]",
  "8/12": "sm:aspect-[8/12]",
  "8/13": "sm:aspect-[8/13]",
  "8/14": "sm:aspect-[8/14]",
  "8/15": "sm:aspect-[8/15]",
  "8/16": "sm:aspect-[8/16]",
  "7/1": "sm:aspect-[7/1]",
  "7/2": "sm:aspect-[7/2]",
  "7/3": "sm:aspect-[7/3]",
  "7/4": "sm:aspect-[7/4]",
  "7/5": "sm:aspect-[7/5]",
  "7/6": "sm:aspect-[7/6]",
  "7/7": "sm:aspect-[7/7]",
  "7/8": "sm:aspect-[7/8]",
  "7/9": "sm:aspect-[7/9]",
  "7/10": "sm:aspect-[7/10]",
  "7/11": "sm:aspect-[7/11]",
  "7/12": "sm:aspect-[7/12]",
  "7/13": "sm:aspect-[7/13]",
  "7/14": "sm:aspect-[7/14]",
  "7/15": "sm:aspect-[7/15]",
  "7/16": "sm:aspect-[7/16]",
  "6/1": "sm:aspect-[6/1]",
  "6/2": "sm:aspect-[6/2]",
  "6/3": "sm:aspect-[6/3]",
  "6/4": "sm:aspect-[6/4]",
  "6/5": "sm:aspect-[6/5]",
  "6/6": "sm:aspect-[6/6]",
  "6/7": "sm:aspect-[6/7]",
  "6/8": "sm:aspect-[6/8]",
  "6/9": "sm:aspect-[6/9]",
  "6/10": "sm:aspect-[6/10]",
  "6/11": "sm:aspect-[6/11]",
  "6/12": "sm:aspect-[6/12]",
  "6/13": "sm:aspect-[6/13]",
  "6/14": "sm:aspect-[6/14]",
  "6/15": "sm:aspect-[6/15]",
  "6/16": "sm:aspect-[6/16]",
  "5/1": "sm:aspect-[5/1]",
  "5/2": "sm:aspect-[5/2]",
  "5/3": "sm:aspect-[5/3]",
  "5/4": "sm:aspect-[5/4]",
  "5/5": "sm:aspect-[5/5]",
  "5/6": "sm:aspect-[5/6]",
  "5/7": "sm:aspect-[5/7]",
  "5/8": "sm:aspect-[5/8]",
  "5/9": "sm:aspect-[5/9]",
  "5/10": "sm:aspect-[5/10]",
  "5/11": "sm:aspect-[5/11]",
  "5/12": "sm:aspect-[5/12]",
  "5/13": "sm:aspect-[5/13]",
  "5/14": "sm:aspect-[5/14]",
  "5/15": "sm:aspect-[5/15]",
  "5/16": "sm:aspect-[5/16]",
  "4/1": "sm:aspect-[4/1]",
  "4/2": "sm:aspect-[4/2]",
  "4/3": "sm:aspect-[4/3]",
  "4/4": "sm:aspect-[4/4]",
  "4/5": "sm:aspect-[4/5]",
  "4/6": "sm:aspect-[4/6]",
  "4/7": "sm:aspect-[4/7]",
  "4/8": "sm:aspect-[4/8]",
  "4/9": "sm:aspect-[4/9]",
  "4/10": "sm:aspect-[4/10]",
  "4/11": "sm:aspect-[4/11]",
  "4/12": "sm:aspect-[4/12]",
  "4/13": "sm:aspect-[4/13]",
  "4/14": "sm:aspect-[4/14]",
  "4/15": "sm:aspect-[4/15]",
  "4/16": "sm:aspect-[4/16]",
  "3/1": "sm:aspect-[3/1]",
  "3/2": "sm:aspect-[3/2]",
  "3/3": "sm:aspect-[3/3]",
  "3/4": "sm:aspect-[3/4]",
  "3/5": "sm:aspect-[3/5]",
  "3/6": "sm:aspect-[3/6]",
  "3/7": "sm:aspect-[3/7]",
  "3/8": "sm:aspect-[3/8]",
  "3/9": "sm:aspect-[3/9]",
  "3/10": "sm:aspect-[3/10]",
  "3/11": "sm:aspect-[3/11]",
  "3/12": "sm:aspect-[3/12]",
  "3/13": "sm:aspect-[3/13]",
  "3/14": "sm:aspect-[3/14]",
  "3/15": "sm:aspect-[3/15]",
  "3/16": "sm:aspect-[3/16]",
  "2/1": "sm:aspect-[2/1]",
  "2/2": "sm:aspect-[2/2]",
  "2/3": "sm:aspect-[2/3]",
  "2/4": "sm:aspect-[2/4]",
  "2/5": "sm:aspect-[2/5]",
  "2/6": "sm:aspect-[2/6]",
  "2/7": "sm:aspect-[2/7]",
  "2/8": "sm:aspect-[2/8]",
  "2/9": "sm:aspect-[2/9]",
  "2/10": "sm:aspect-[2/10]",
  "2/11": "sm:aspect-[2/11]",
  "2/12": "sm:aspect-[2/12]",
  "2/13": "sm:aspect-[2/13]",
  "2/14": "sm:aspect-[2/14]",
  "2/15": "sm:aspect-[2/15]",
  "2/16": "sm:aspect-[2/16]",
  "1/1": "sm:aspect-[1/1]",
  "1/2": "sm:aspect-[1/2]",
  "1/3": "sm:aspect-[1/3]",
  "1/4": "sm:aspect-[1/4]",
  "1/5": "sm:aspect-[1/5]",
  "1/6": "sm:aspect-[1/6]",
  "1/7": "sm:aspect-[1/7]",
  "1/8": "sm:aspect-[1/8]",
  "1/9": "sm:aspect-[1/9]",
  "1/10": "sm:aspect-[1/10]",
  "1/11": "sm:aspect-[1/11]",
  "1/12": "sm:aspect-[1/12]",
  "1/13": "sm:aspect-[1/13]",
  "1/14": "sm:aspect-[1/14]",
  "1/15": "sm:aspect-[1/15]",
  "1/16": "sm:aspect-[1/16]",
};

export default function getAspectRatioSm(attrs) {
  const { aspectHSm, aspectWSm } = attrs || {};
  return options[aspectWSm] || options[aspectHSm] || options[`${aspectWSm}/${aspectHSm}`];
}
