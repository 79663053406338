import {
  COLOR_ACCENT,
  COLOR_BLACK,
  COLOR_GRAY,
  COLOR_PRIMARY,
  COLOR_THIRD,
  COLOR_WHITE,
} from "./constants/template.js";

function getDarkValue(value) {
  return value > 500 ? 1000 - value : value;
}

export default function parseColorNameValue(color, theme, dark) {
  let name, value;

  if (typeof color === "string") {
    const parts = color.split("-");

    if (parts.length === 2) {
      name = parts[0];
      value = !Number.isNaN(parts[1]) ? parseInt(parts[1]) : parts[1];
    } else if (parts.length === 1) {
      if (isNaN(parts[0])) {
        name = parts[0];
      } else {
        name = COLOR_GRAY;
        value = parseInt(parts[0]);
      }
    }
  }

  switch (name) {
    case COLOR_PRIMARY: {
      const primary = value || theme?.value;
      return [theme?.name, dark ? getDarkValue(primary) : primary];
    }
    case COLOR_ACCENT: {
      const accent = value || theme?.accent?.value;
      return [theme?.accent?.name, dark ? getDarkValue(accent) : accent];
    }
    case COLOR_THIRD: {
      const third = value || theme?.third?.value;
      return [theme?.third?.name, dark ? getDarkValue(third) : third];
    }
    default:
      if (dark && (name === COLOR_BLACK || (name === COLOR_GRAY && value === 900))) {
        return [COLOR_WHITE];
      } else {
        return [name, dark ? getDarkValue(value) : value];
      }
  }
}
