const options = {
  0: "border-r-0",
  1: "border-r",
  2: "border-r-2",
  4: "border-r-4",
  8: "border-r-8",
};

export default function getBorderRight(width) {
  return options[width];
}
