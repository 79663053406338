import { COLOR_BLACK } from "./constants/template.js";
import parseColorNameValue from "./parseColorNameValue.js";

function isValidHexColorCode(colorCode) {
  // Define a regular expression for a valid hex color code
  const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  // Test the input string against the regular expression
  return hexColorRegex.test(colorCode);
}

export default function isDarkTone(bgColor, theme) {
  const [name, value] = parseColorNameValue(bgColor, theme);

  if ([COLOR_BLACK].includes(name) || value >= 400) {
    return true;
  }

  if (bgColor && isValidHexColorCode(bgColor)) {
    // Convert hex to RGB
    const hexToRgb = (hex) =>
      hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => "#" + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));

    const [r, g, b] = hexToRgb(bgColor);

    // Calculate relative luminance
    const luminance = (0.299 * r) / 255 + (0.587 * g) / 255 + (0.114 * b) / 255;

    // Use a luminance threshold of 0.5
    return luminance <= 0.5;
  }

  return false;
}
