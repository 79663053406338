import { useUserBusinessCode } from "@easybiz/shell";
import { useMemo } from "react";

function saveToMap(dataMap, key, c, w) {
  if (dataMap[key]) {
    if (c) {
      dataMap[key].complete += c;
      dataMap[key].total += c;
    }

    if (w) {
      dataMap[key].total += w;
    }
  } else {
    dataMap[key] = {
      total: (c || 0) + (w || 0),
      complete: c || 0,
    };
  }
}

function useDateProductionStatus(statusDoc, selects) {
  const businessCode = useUserBusinessCode();

  return useMemo(() => {
    if (statusDoc?.get("data")) {
      const orders = statusDoc.get("data");
      // const isSupplier = supplierId === businessCode;

      let total = 0,
        complete = 0,
        optionMap = {};

      for (const orderId in orders) {
        if (orders[orderId]) {
          const { b, it } = orders[orderId];
          // if (bySupplier || isSupplier || b === businessCode) {
          if (Array.isArray(it)) {
            it.forEach(({ o, c, w, sos }) => {
              if (c) {
                total += c;
                complete += c;
              }

              if (w) {
                total += w;
              }

              if (Array.isArray(sos)) {
                sos.forEach(({ c, n }) => {
                  saveToMap(optionMap, n, c ? 1 : 0, c ? 0 : 1);
                });
              } else if (o) {
                saveToMap(optionMap, o, c, w);
              }
            });
          }
          // }
        }
      }

      let options = Object.keys(optionMap)
        .map((id) => ({
          id,
          total: optionMap[id].total,
          complete: optionMap[id].complete,
          completed: optionMap[id].total === optionMap[id].complete,
        }))
        .sort((a, b) => b.total - a.total);

      if (Array.isArray(selects)) {
        options = [
          ...options
            .filter((option) => selects.find((select) => select.code === option.id))
            .map((option) => ({ ...option, strong: true })),
          ...options.filter((option) => !selects.find((select) => select.code === option.id)),
        ];
      }

      return {
        completed: complete === total,
        complete,
        total,
        options,
        document: statusDoc,
      };
    } else if (statusDoc) {
      return false;
    }
  }, [statusDoc, selects, businessCode]);
}

export default useDateProductionStatus;
