import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_POS, POS_ACTION_DEVICE_REGISTER } from "@easybiz/utils";
import { useCallback } from "react";

function usePOSDeviceRegister(callback) {
  const onSubmit = useCallback((deviceInfo) => {
    return [OPERATION_GROUP_POS, POS_ACTION_DEVICE_REGISTER, deviceInfo];
  }, []);

  return useActionCallback(onSubmit, callback, null, true);
}

export default usePOSDeviceRegister;
