import { formatPrice, UNIT_PCS } from "@easybiz/utils";
import { useMemo } from "react";

function useOrderAvailableReturnItems(order, isRedo) {
  return useMemo(() => {
    return (order?.get("items") || [])
      .map(({ returnQty, returnLabels, redoLabels, qty, labelIndexes, ...item }, index) => {
        const availableQty = isRedo
          ? (labelIndexes?.length || 0) - (redoLabels?.length || 0)
          : formatPrice(qty - (typeof returnQty === "number" ? returnQty : 0));

        if (availableQty > 0) {
          return {
            ...item,
            orderItemIndex: index,
            qty: 0,
            totalQty: availableQty,
            ...(isRedo && { unitPrice: 0, unit: UNIT_PCS }),
            ...(Array.isArray(labelIndexes) && {
              labelIndexes: Array.isArray(isRedo ? redoLabels : returnLabels)
                ? labelIndexes.filter((index) => (isRedo ? redoLabels : returnLabels).indexOf(index) < 0)
                : labelIndexes,
            }),
          };
        }
      })
      .filter((item) => item);
  }, [order, isRedo]);
}

export default useOrderAvailableReturnItems;
