import useActionCallback from "@easybiz/hook/src/useActionCallback";
import { CUSTOMER_ACTION_PAYMENT_INTENT_CANCEL, OPERATION_GROUP_CUSTOMER } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useCustomerPaymentCancelIntent(callback) {
  const intl = useIntl();

  return useActionCallback(
    ({ paymentIntent }) => {
      return [
        OPERATION_GROUP_CUSTOMER,
        CUSTOMER_ACTION_PAYMENT_INTENT_CANCEL,
        {
          customerId: paymentIntent.metadata.customer_id,
          paymentIntentId: paymentIntent.id,
          stripeAccount: paymentIntent.account,
        },
      ];
    },
    callback,
    intl.formatMessage({
      id: "payment.cancelled",
      defaultMessage: "Payment cancelled",
    })
  );
}

export default useCustomerPaymentCancelIntent;
