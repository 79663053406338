import TextContent from "../components/TextContent";
import Node from "../Node";

export default function LabelText(props) {
  const position = props.context?.positions?.find((p) => p.id === props.id);
  const style = {
    whiteSpace: "pre-wrap",
    ...(props.attrs?.invertMode && { backgroundColor: "black", color: "white" }),
  };

  return (
    <Node {...props}>
      {position && !props.draft ? (
        <p style={style}>{position.text?.lines?.join("\n")}</p>
      ) : (
        <TextContent
          {...props.content}
          context={props.context}
          isEditMode={Boolean(props.draft)}
          intl={props.intl}
          invertMode={props.attrs?.invertMode}
          Tooltip={props.draft?.Tooltip}
          style={style}
        />
      )}
    </Node>
  );
}
