"use client";

import {
  JOB_STATUS_CANCELLED,
  JOB_STATUS_COMPLETE,
  JOB_STATUS_FAILED,
  JOB_STATUS_PENDING,
  JOB_STATUS_SCHEDULED,
} from "@easybiz/utils";
import React from "react";
import { FormattedMessage } from "react-intl";

function LogisticStatusDisplay({ status }) {
  switch (status) {
    case JOB_STATUS_PENDING:
      return <FormattedMessage id="pending" defaultMessage="Pending" />;
    case JOB_STATUS_SCHEDULED:
      return <FormattedMessage id="scheduled" defaultMessage="Scheduled" />;
    case JOB_STATUS_FAILED:
      return <FormattedMessage id="failed" defaultMessage="Failed" />;
    case JOB_STATUS_COMPLETE:
      return <FormattedMessage id="completed" defaultMessage="Completed" />;
    case JOB_STATUS_CANCELLED:
      return <FormattedMessage id="cancelled" defaultMessage="Cancelled" />;
    default:
      return null;
  }
}

export default LogisticStatusDisplay;
