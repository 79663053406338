const options = {
  1: "sm:h-1",
  2: "sm:h-2",
  3: "sm:h-3",
  4: "sm:h-4",
  5: "sm:h-5",
  6: "sm:h-6",
  7: "sm:h-7",
  8: "sm:h-8",
  9: "sm:h-9",
  10: "sm:h-10",
  11: "sm:h-11",
  12: "sm:h-12",
  14: "sm:h-14",
  16: "sm:h-16",
  20: "sm:h-20",
  24: "sm:h-24",
  28: "sm:h-28",
  32: "sm:h-32",
  36: "sm:h-36",
  40: "sm:h-40",
  44: "sm:h-44",
  48: "sm:h-48",
  52: "sm:h-52",
  56: "sm:h-56",
  60: "sm:h-60",
  64: "sm:h-64",
  72: "sm:h-72",
  80: "sm:h-80",
  96: "sm:h-96",
  112: "sm:h-[28rem]",
  128: "sm:h-[32rem]",
  "1/2": "sm:h-1/2",
  "1/3": "sm:h-1/3",
  "2/3": "sm:h-2/3",
  "1/5": "sm:h-1/5",
  "2/5": "sm:h-2/5",
  "3/5": "sm:h-3/5",
  "4/5": "sm:h-4/5",
  px: "sm:h-px",
  screen: "sm:h-screen",
  fill: "sm:grow",
  full: "sm:h-full",
  ratio: "sm:h-auto",
  auto: "sm:h-auto",
};

export default function getHeightSm(size, parentDisplay) {
  return size === "fill" && !["col", "col-reverse"].includes(parentDisplay || "col") ? "" : options[size];
}
