export default function getMarginBottom(value) {
  switch (value) {
    case -44:
      return "-mb-44";
    case -40:
      return "-mb-40";
    case -36:
      return "-mb-36";
    case -32:
      return "-mb-32";
    case -28:
      return "-mb-28";
    case -24:
      return "-mb-24";
    case -20:
      return "-mb-20";
    case -16:
      return "-mb-16";
    case -12:
      return "-mb-12";
    case -8:
      return "-mb-8";
    case -4:
      return "-mb-4";
    case "-px":
      return "-mb-px";
    case 0:
      return "mb-0";
    case 1:
      return "mb-1";
    case 2:
      return "mb-2";
    case 3:
      return "mb-3";
    case 4:
      return "mb-4";
    case 5:
      return "mb-5";
    case 6:
      return "mb-6";
    case 7:
      return "mb-7";
    case 8:
      return "mb-8";
    case 9:
      return "mb-9";
    case 10:
      return "mb-10";
    case 12:
      return "mb-12";
    case 14:
      return "mb-14";
    case 16:
      return "mb-16";
    case 20:
      return "mb-20";
    case 24:
      return "mb-24";
    default:
      return "";
  }
}
