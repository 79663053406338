import getLinearGradient from "./getLinearGradient";

export default function getNodeStyle(attrs, theme, nodeStyle) {
  const hasBgSize = attrs?.bgWidth && attrs?.bgHeight;
  const isCustomBgColor = attrs?.bgColor && `${attrs?.bgColor}`.startsWith("#");

  const style = {
    ...nodeStyle,
    ...(isCustomBgColor && { backgroundColor: attrs?.bgColor }),
    ...((attrs?.bgImage || attrs?.bgStart) && {
      backgroundImage: [
        attrs?.bgStart ? getLinearGradient(attrs, theme) : null,
        attrs?.bgImage ? `url(${attrs?.bgImage.url})` : null,
      ]
        .filter(Boolean)
        .join(", "),
    }),
    ...(hasBgSize && {
      backgroundSize: `${attrs?.bgWidth}px ${attrs?.bgHeight}px`,
    }),
    ...(attrs?.styleWidth && { width: attrs?.styleWidth }),
    ...(attrs?.styleGap && { gap: attrs?.styleGap }),
  };

  if (Object.keys(style).length) {
    return style;
  }
}
