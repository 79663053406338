export default function formatNumber(value, currency, accounting, currencyDisplay = "narrowSymbol") {
  if (typeof value !== "number") return "";

  const options = currency
    ? {
        style: "currency",
        currency,
        ...(["code", "symbol", "narrowSymbol", "name"].includes(currencyDisplay) && {
          currencyDisplay,
        }),
        ...(accounting && { currencySign: "accounting" }),
      }
    : {
        minimumFractionDigits: Number.isInteger(accounting) ? accounting : 2,
      };

  const formatter = new Intl.NumberFormat("en-US-POSIX", options);

  if (currencyDisplay === false || currencyDisplay === "none") {
    // Exclude the currency symbol
    const parts = formatter.formatToParts(value);

    const formattedNumber = parts
      .filter((part) => part.type !== "currency")
      .map((part) => part.value)
      .join("");

    return formattedNumber.replace(/\u00A0/g, " ");
  } else {
    // Include the currency symbol as usual
    return formatter.format(value).replace(/\u00A0/g, " ");
  }
}
