import { escapeRegExp } from "@easybiz/utils";
import { useMemo } from "react";

function saveToMap(dataMap, key, { i, c, w }, { id, b, no }, itemName) {
  if (dataMap[key]) {
    if (c) {
      dataMap[key].complete += c;
      dataMap[key].total += c;
    }
    if (w) {
      dataMap[key].total += w;
    }
  } else {
    dataMap[key] = {
      complete: c || 0,
      total: (w || 0) + (c || 0),
      orders: [],
    };
  }

  if (c || w) {
    const completed = !w;
    const total = (c || 0) + (w || 0);

    dataMap[key].orders.push({
      id,
      index: i,
      name: itemName,
      orderNo: no,
      businessCode: b,
      complete: c || 0,
      waiting: w || 0,
      ...(total > 1 && { total }),
      completed,
    });
  }
}

function useProductionMonitorData(dataDoc, filterBusiness, selectBusinessCode, search) {
  return useMemo(() => {
    const orderMap = dataDoc?.get("data") || {};
    const regX = search && new RegExp(escapeRegExp(search), "i");

    let businessTotal = {},
      businessComplete = {},
      completeItem = 0,
      totalItem = 0,
      orders = [],
      serviceTypeMap = {},
      productMap = {};

    for (const orderId in orderMap) {
      let order = orderMap[orderId];
      order.id = orderId;

      const { b, no, it } = order;

      if (filterBusiness && b !== filterBusiness) {
        continue;
      }

      if (regX && !regX.test(no)) {
        continue;
      }

      const selected = !selectBusinessCode || selectBusinessCode === b;
      let total = 0,
        complete = 0;

      it.forEach((item) => {
        const { i, p, o, c, w, sos } = item;

        if (c) {
          total += c;
          complete += c;
        }

        if (w) {
          total += w;
        }

        if (selected) {
          if (p) {
            saveToMap(productMap, p, item, order, o);
          }

          if (Array.isArray(sos)) {
            sos.forEach(({ n, c }) => {
              saveToMap(serviceTypeMap, n, { i, c: c ? 1 : 0, w: c ? 0 : 1 }, order, p);
            });
          } else if (o) {
            saveToMap(serviceTypeMap, o, item, order, p);
          }
        }
      });

      if (selected) {
        orders.push({
          id: orderId,
          orderNo: no,
          businessCode: b,
          completed: total === complete,
          total,
          complete,
          waiting: total - complete,
          items: it.sort((a, b) => (b.w || 0) - (a.w || 0)),
        });
      }

      totalItem += total;
      completeItem += complete;

      if (!filterBusiness) {
        businessTotal[b] = (businessTotal[b] || 0) + total;
        businessComplete[b] = (businessComplete[b] || 0) + complete;
      }
    }

    const completeCount = orders.filter((order) => order.completed).length;

    return {
      businesses: !filterBusiness
        ? Object.keys(businessTotal)
            .map((key) => ({
              key,
              total: businessTotal[key],
              complete: businessComplete[key],
              pending: businessTotal[key] - businessComplete[key],
            }))
            .sort((a, b) => b.total - a.total)
        : null,
      completeItem,
      totalItem,
      percent: totalItem === 0 ? 0 : Math.floor((completeItem / totalItem) * 100),
      orders: orders.sort((a, b) => b.waiting - a.waiting),
      completeCount,
      completed: completeCount === orders.length,
      serviceTypes: Object.keys(serviceTypeMap)
        .map((name) => {
          const { complete, total, orders } = serviceTypeMap[name];
          return {
            name,
            complete,
            total,
            waiting: total - complete,
            orders: orders.sort((a, b) => b.waiting - a.waiting),
          };
        })
        .sort((a, b) => b.waiting - a.waiting),
      products: Object.keys(productMap)
        .map((name) => {
          const { complete, total, orders } = productMap[name];
          return {
            name,
            complete,
            total,
            waiting: total - complete,
            orders: orders.sort((a, b) => b.waiting - a.waiting),
          };
        })
        .sort((a, b) => b.waiting - a.waiting),
    };
  }, [dataDoc, filterBusiness, selectBusinessCode, search]);
}

export default useProductionMonitorData;