import { CUSTOMER_SCREEN_PAYMENT_INTENT } from "@easybiz/utils";
import { updateDoc } from "firebase/firestore";
import { Space } from "antd";
import React, { useEffect } from "react";
import PaymentIntentCancelButton from "../PaymentIntentCancelButton";
import PaymentIntentMonitorPanel from "../PaymentIntentMonitorPanel";
import { useClientCustomerScreen } from "@easybiz/shell";

export default function ({ paymentIntent, onSucceed, onCancel }) {
  const customerScreen = useClientCustomerScreen();

  useEffect(() => {
    if (customerScreen?.get("linking")) {
      updateDoc(customerScreen.ref, {
        mode: CUSTOMER_SCREEN_PAYMENT_INTENT,
        paymentIntent,
      });
    }
  }, [customerScreen?.get("linking")]);

  return (
    <Space direction="vertical" align="center" className="full-width" size={"large"}>
      <PaymentIntentMonitorPanel paymentIntent={paymentIntent} onSucceed={onSucceed} />
      <PaymentIntentCancelButton paymentIntent={paymentIntent} onCancelled={onCancel} />
    </Space>
  );
}
