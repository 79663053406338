"use client";

import { JOB_TYPE_DELIVERY, JOB_TYPE_PICKUP, JOB_TYPE_SERVICE } from "@easybiz/utils";
import React from "react";
import { FormattedMessage } from "react-intl";

function TaskTitle({ type, recurring }) {
  const recurringDisplay = recurring ? ` [${recurring.index + 1}/${recurring.total}]` : "";

  switch (type) {
    case JOB_TYPE_PICKUP:
      return <FormattedMessage id="pick.up" defaultMessage="Pick-up" />;
    case JOB_TYPE_SERVICE:
      return (
        <>
          <FormattedMessage id="service" defaultMessage="Service" />
          {recurringDisplay}
        </>
      );
    case JOB_TYPE_DELIVERY:
      return (
        <>
          <FormattedMessage id="delivery" defaultMessage="Delivery" />
          {recurringDisplay}
        </>
      );
    default:
      return null;
  }
}

export default TaskTitle;
