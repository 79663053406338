import { NameDisplay } from "@easybiz/component";
import { Table, Tag } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ loading, orders, selectOrderIds, isCheckOut, onSelect, onSelectChange }) => {
  return (
    <div className="padding-half">
      <Table
        rowKey="id"
        loading={!!loading}
        dataSource={orders || []}
        size="small"
        scroll={{ y: window.innerHeight - 200 }}
        pagination={false}
        columns={[
          {
            title: <FormattedMessage tagName="span" defaultMessage="Order No" />,
            key: "no",
            width: "25%",
            render: (order) => `#${order.no}`,
          },
          {
            title: isCheckOut ? (
              <FormattedMessage tagName="span" defaultMessage="Customer" />
            ) : (
              <FormattedMessage tagName="span" defaultMessage="Sales channel" />
            ),
            key: "cus",
            width: "25%",
            render: (order) => (isCheckOut ? order.cus : <NameDisplay nameKey={order.businessCode} />),
          },
          {
            title: <FormattedMessage tagName="span" defaultMessage="Pcs" />,
            key: "pcs",
            width: "25%",
            render: (order) => order.pcs,
          },
          {
            title: <FormattedMessage tagName="span" defaultMessage="Status" />,
            key: "status",
            width: "25%",
            render: (order) => (
              <Tag color={order.processed ? "success" : "processing"}>
                {order.processed ? (
                  <FormattedMessage defaultMessage={"Processed"} />
                ) : (
                  <FormattedMessage defaultMessage={"Pending"} />
                )}
              </Tag>
            ),
          },
        ]}
        rowSelection={{
          selectedRowKeys: selectOrderIds,
          getCheckboxProps: (order) => ({
            disabled: order.processed,
          }),
          onChange: onSelectChange,
        }}
        onRow={(order) => ({ onClick: () => onSelect(order) })}
        rowClassName="selectable"
      />
    </div>
  );
};
