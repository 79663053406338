import { ANIMATION_FADE_IN_BOTTOM, ANIMATION_STAGGER, COMPONENT_CONTAINER } from "@easybiz/utils";
import BackgroundDecorations from "./components/BackgroundDecorations";
import { FadeIn, FadeInStagger } from "./components/client/FadeIn";
import PageLink from "./components/PageLink";
import getNodeClassName from "./utils/getNodeClassName";
import getNodeStyle from "./utils/getNodeStyle";

function getAnimationNode(attrs) {
  switch (attrs?.animation) {
    case ANIMATION_STAGGER:
      return FadeInStagger;
    case ANIMATION_FADE_IN_BOTTOM:
      return FadeIn;
    default:
      return null;
  }
}

export default function Node(props) {
  const { id, as: propAs, nodeProps, className, context, attrs, content, draft, type, children } = props;
  const AnimationNode = draft?.editId ? null : getAnimationNode(attrs);
  // propAs and attrs?.ass will not be a
  const as = propAs || attrs?.as;
  const NodeTag = AnimationNode || as || "div";
  const { bgColor, theme } = context || {};
  const style = getNodeStyle(attrs, theme, props.style);

  let childNodes = undefined;

  if (!["img", "input", "textarea", "link"].includes(as)) {
    childNodes = (
      <>
        {type === COMPONENT_CONTAINER || children ? (
          <>
            {Array.isArray(attrs?.decorations) && (
              <BackgroundDecorations decorations={attrs.decorations} bgColor={bgColor} theme={theme} />
            )}
            {children}
            {draft?.highlights}
          </>
        ) : undefined}
        {content?.link && !draft && (
          <PageLink
            lang={context.lang}
            {...content?.link}
            siteMap={context.siteMap}
            isEditMode={Boolean(draft)}
            isEmbedMode={context.isEmbedMode}
            className="absolute inset-0"
            Link={context.Link}
          />
        )}
      </>
    );
  }

  const node = (
    <NodeTag
      id={id}
      {...(AnimationNode
        ? {
            draftRef: draft?.ref,
            as,
          }
        : {
            ref: draft?.ref,
          })}
      {...nodeProps}
      {...(style && { style })}
      {...draft?.floatingProps}
      {...(draft?.dndProps && style
        ? {
            ...draft?.dndProps,
            style: { ...draft.dndProps.style, ...style },
          }
        : draft?.dndProps)}
      onClick={draft?.onClick}
      className={getNodeClassName({
        className,
        context,
        attrs: attrs?.bgImageSm ? { ...attrs, displayLg: "hidden", displayXl: "hidden" } : attrs,
        content,
        draft,
        type,
        as,
      })}
    >
      {childNodes}
    </NodeTag>
  );

  if (attrs?.bgImageSm) {
    return (
      <>
        {node}
        <Node
          {...props}
          attrs={{
            ...attrs,
            bgImage: attrs.bgImageSm,
            bgRepeat: attrs.bgRepeatSm,
            bgPosition: attrs.bgPositionSm,
            bgSize: attrs.bgSizeSm,
            bgWidth: attrs.bgWidthSm,
            bgHeight: attrs.bgHeightSm,
            bgImageSm: null,
            display: "hidden",
            displaySm: "hidden",
            displayMd: "hidden",
            displayLg: attrs.displayLg || "col",
            displayXl: attrs.displayXl || "col",
          }}
        />
      </>
    );
  } else {
    return node;
  }
}
