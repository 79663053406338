"use client";

import {
  DISABLE_TYPE_CUT_OFF,
  DISABLE_TYPE_NON_WORKING_DAY,
  DISABLE_TYPE_NOT_AVAILABLE,
  DISABLE_TYPE_OFF_DAY,
  DISABLE_TYPE_PROCESSING_DAY,
} from "@easybiz/utils";
import dayjs from "dayjs";
import React from "react";
import { FormattedMessage } from "react-intl";

function ScheduleDisableDisplay({ disabledType, cutOffTime, dayIndex }) {
  switch (disabledType) {
    case DISABLE_TYPE_CUT_OFF:
      return (
        <FormattedMessage id="cut.off.day.x" defaultMessage="Cut off day ({cutOffTime})" values={{ cutOffTime }} />
      );
    case DISABLE_TYPE_OFF_DAY:
      return <FormattedMessage id="off.day" defaultMessage="Off day" />;
    case DISABLE_TYPE_NON_WORKING_DAY:
      return <FormattedMessage id="non.working.day" defaultMessage="Non-working day" />;
    case DISABLE_TYPE_PROCESSING_DAY:
      return (
        <FormattedMessage
          id="processing.day.x"
          defaultMessage="{index} processing day"
          values={{ index: dayjs().date(dayIndex).format("Do") }}
        />
      );
    case DISABLE_TYPE_NOT_AVAILABLE:
      return <FormattedMessage id="not.available" defaultMessage="Not available" />;
    default:
      return null;
  }
}

export default ScheduleDisableDisplay;
