import { CloseOutlined } from "@ant-design/icons";
import { useCustomerPaymentCancelIntent } from "@easybiz/api-customer";
import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function ({ paymentIntent, onCancelled }) {
  const { onSubmit, loading } = useCustomerPaymentCancelIntent((succeed) => {
    if (succeed) {
      onCancelled && onCancelled();
    }
  });

  return (
    <Button
      icon={<CloseOutlined />}
      type="primary"
      danger
      onClick={() => onSubmit({ paymentIntent })}
      loading={loading}
    >
      <FormattedMessage tagName="span" defaultMessage="Cancel payment" />
    </Button>
  );
}
