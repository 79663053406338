import { safeSearchRegExp, translate } from "@easybiz/utils";
import { useMemo } from "react";

function useServiceTypeOptionsWithSelects(serviceTypes, subOptions, search) {
  return useMemo(() => {
    let allOptions = [].concat.apply(
      [],
      (serviceTypes || []).map(({ options }) =>
        (options || []).map(({ price, ...option }) => {
          const optionIndex = Array.isArray(subOptions)
            ? subOptions.findIndex((select) => translate(select.title) === translate(option.title))
            : -1;
          if (optionIndex >= 0) {
            return {
              ...option,
              ...subOptions[optionIndex],
              ...(typeof price === "number" && {
                fixedPrice: price,
              }),
              optionIndex,
            };
          } else {
            return {
              ...option,
              ...(typeof price === "number" && {
                fixedPrice: price,
              }),
            };
          }
        })
      )
    );

    if (Array.isArray(subOptions)) {
      subOptions.forEach((option, index) => {
        if (!allOptions.find((added) => added.optionIndex === index)) {
          allOptions.unshift({ ...option, optionIndex: index });
        }
      });
    }

    if (search) {
      const regX = safeSearchRegExp(search);
      return allOptions.filter((option) => regX.test(option.code) || regX.test(option.title));
    } else {
      return allOptions;
    }
  }, [serviceTypes, subOptions, search]);
}

export default useServiceTypeOptionsWithSelects;
