import { useUserImageHost } from "@easybiz/shell";
import { Badge, Image } from "antd";
import React from "react";

export default ({ width, photoId, count, onClick }) => {
  const imageHost = useUserImageHost();

  return (
    <Badge count={count} showZero={false} offset={[-4, 4]}>
      <Image
        style={{ width: width, height: width }}
        fallback="/images/defualt-cloth.jpg"
        preview={false}
        src={photoId ? `${imageHost}/${photoId}` : "/images/defualt-cloth.jpg"}
        onClick={onClick}
      />
    </Badge>
  );
};
