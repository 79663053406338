import { ContainerOutlined } from "@ant-design/icons";
import { usePrinter } from "@easybiz/hook";
import {
  useCashDrawerIsEnabled,
  useCashDrawerIsPendingStart,
  useClientState,
  useClientStateSetter,
} from "@easybiz/shell";
import { DISPLAY_MODE_CASH_DRAWER } from "@easybiz/utils";
import { Badge, Button } from "antd";
import React from "react";
import { useIntl } from "react-intl";

export default () => {
  const printer = usePrinter();
  const pendingStart = useCashDrawerIsPendingStart();
  const cashManageEnabled = useCashDrawerIsEnabled();
  const { displayMode } = useClientState();
  const setState = useClientStateSetter();
  const intl = useIntl();

  return (
    <Badge count={pendingStart ? intl.formatMessage({ defaultMessage: "Not started" }) : null} offset={[-32, -4]}>
      <Button
        size="large"
        type={displayMode?.key === DISPLAY_MODE_CASH_DRAWER ? "primary" : "default"}
        danger={pendingStart}
        style={{ width: 64 }}
        onClick={() => {
          if (cashManageEnabled) {
            setState({
              displayMode: displayMode?.key === DISPLAY_MODE_CASH_DRAWER ? null : { key: DISPLAY_MODE_CASH_DRAWER },
            });
          } else {
            printer.openDrawer();
          }
        }}
        icon={<ContainerOutlined />}
      />
    </Badge>
  );
};
