import { useUpdateCheckoutItems } from "@easybiz/checkout";
import { AmountDisplay, UnitDisplay } from "@easybiz/component";
import { useCheckoutEditItemSetter } from "@easybiz/shell";
import { translate, UNIT_INCH, UNIT_KG, UNIT_SQFT } from "@easybiz/utils";
import React from "react";
import DescimalUnitInputButton from "../DescimalUnitInputButton";
import QuantityUpdateBar from "../QuantityUpdateBar";
import SQFTInputButton from "../SQFTInputButton";

export default ({ item }) => {
  const { unit, unitPrice, option, qty, pcs, measure, width, height, radius } = item;
  const updateItems = useUpdateCheckoutItems();
  const setEditItem = useCheckoutEditItemSetter();

  const onUpdateQty = (updates) => {
    const newItem = { ...item, ...updates };
    const itemIndex = updateItems(newItem);

    if (Number.isInteger(itemIndex)) {
      setEditItem({ ...newItem, itemIndex });
    }
  };

  switch (unit) {
    case UNIT_SQFT: {
      return (
        <SQFTInputButton
          value={qty}
          measure={measure}
          width={width}
          height={height}
          radius={radius}
          onChange={onUpdateQty}
        />
      );
    }
    case UNIT_KG:
    case UNIT_INCH: {
      return <DescimalUnitInputButton value={qty} pcs={pcs} unit={unit} onChange={onUpdateQty} />;
    }
    default:
      return (
        <QuantityUpdateBar
          title={
            <span>
              {translate(option)} <AmountDisplay value={unitPrice} />
              {unit ? (
                <span>
                  {" "}
                  / <UnitDisplay unit={unit} />
                </span>
              ) : null}
            </span>
          }
          value={qty}
          suffix={<UnitDisplay unit={unit} />}
          onChange={(newQty) => onUpdateQty({ qty: newQty })}
        />
      );
  }
};
