import { CameraOutlined, EditOutlined, HistoryOutlined, PrinterOutlined } from "@ant-design/icons";
import { usePrinter } from "@easybiz/hook";
import {
  getComponentProps,
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_ID,
  PLACEHOLDER_ORDER_LINE_ITEM_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX,
  TEMPLATE_CATEGORY_LABEL,
} from "@easybiz/utils";
import { LabelComponent } from "@easybiz/web-template";
import { List, Popover, Space, Tag, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../IconButton";
import LabelHistoryList from "../LabelHistoryList";
import LabelStatusTag from "../LabelStatusTag";
import OrderPhotoGallery from "../OrderPhotoGallery";
import Title from "../Title";

export default function LabelListItemNew({
  template,
  renderParams,
  item,
  order,
  onNote,
  onPhoto,
  disablePrint,
  disabled,
}) {
  const printer = usePrinter();
  const intl = useIntl();

  const onPrint = () => {
    printer.print({ encodeLabels: [item.command] });
  };

  return (
    <List.Item>
      <List.Item.Meta
        title={
          <Space direction="vertical" className="full-width">
            {item[PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX] ? (
              <Tag>Accessory</Tag>
            ) : (
              <LabelStatusTag order={order} sortIndex={item[PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX]} />
            )}
            <div
              style={{
                display: "inline-block",
                cursor: "pointer",
                boxShadow: `0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)`,
                backgroundColor: "white",
              }}
              onClick={onPrint}
            >
              <LabelComponent
                {...getComponentProps(template, intl, {
                  templateCategory: TEMPLATE_CATEGORY_LABEL,
                  positions: item.positions,
                  language: template?.content?.language,
                  renderParams: { ...renderParams, ...item },
                })}
                scale={
                  typeof template?.attrs?.mmWidth === "number" ? Math.min(436 / (template.attrs.mmWidth * 8), 1) : null
                }
              />
            </div>
          </Space>
        }
        description={
          item[PLACEHOLDER_ORDER_LINE_ITEM_NOTE] || !item[PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX] ? (
            <Space direction="vertical">
              {item[PLACEHOLDER_ORDER_LINE_ITEM_NOTE] ? (
                <Typography.Text type="warning" strong>
                  {`** `}
                  {item[PLACEHOLDER_ORDER_LINE_ITEM_NOTE]}
                </Typography.Text>
              ) : null}
              <OrderPhotoGallery order={order} itemIndex={item[PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX]} wrap />
              {!item[PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX] && (
                <Space>
                  {onNote ? (
                    <IconButton
                      onClick={() =>
                        onNote({
                          sortIndex: item[PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX],
                          note: item[PLACEHOLDER_ORDER_LINE_ITEM_NOTE],
                        })
                      }
                      icon={<EditOutlined />}
                      disabled={disabled}
                    />
                  ) : null}
                  {onPhoto ? (
                    <IconButton
                      onClick={() => onPhoto(item[PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX])}
                      icon={<CameraOutlined />}
                      disabled={disabled}
                    />
                  ) : null}
                  {disablePrint ? null : <IconButton onClick={onPrint} icon={<PrinterOutlined />} />}
                  <Popover
                    trigger="click"
                    placement="left"
                    title={
                      <Title>
                        <FormattedMessage defaultMessage={"Label History"} />
                      </Title>
                    }
                    content={<LabelHistoryList labelId={item[PLACEHOLDER_ORDER_LINE_ITEM_ID]} />}
                  >
                    <IconButton icon={<HistoryOutlined />} />
                  </Popover>
                </Space>
              )}
            </Space>
          ) : null
        }
      />
    </List.Item>
  );
}
