const options = {
  1: "xl:h-1",
  2: "xl:h-2",
  3: "xl:h-3",
  4: "xl:h-4",
  5: "xl:h-5",
  6: "xl:h-6",
  7: "xl:h-7",
  8: "xl:h-8",
  9: "xl:h-9",
  10: "xl:h-10",
  11: "xl:h-11",
  12: "xl:h-12",
  14: "xl:h-14",
  16: "xl:h-16",
  20: "xl:h-20",
  24: "xl:h-24",
  28: "xl:h-28",
  32: "xl:h-32",
  36: "xl:h-36",
  40: "xl:h-40",
  44: "xl:h-44",
  48: "xl:h-48",
  52: "xl:h-52",
  56: "xl:h-56",
  60: "xl:h-60",
  64: "xl:h-64",
  72: "xl:h-72",
  80: "xl:h-80",
  96: "xl:h-96",
  112: "xl:h-[28rem]",
  128: "xl:h-[32rem]",
  "1/2": "xl:h-1/2",
  "1/3": "xl:h-1/3",
  "2/3": "xl:h-2/3",
  "1/5": "xl:h-1/5",
  "2/5": "xl:h-2/5",
  "3/5": "xl:h-3/5",
  "4/5": "xl:h-4/5",
  px: "xl:h-px",
  screen: "xl:h-screen",
  fill: "xl:grow",
  full: "xl:h-full",
  ratio: "xl:h-auto",
  auto: "xl:h-auto",
};

export default function getHeightXl(size, parentDisplay) {
  return size === "fill" && !["col", "col-reverse"].includes(parentDisplay || "col") ? "" : options[size];
}
