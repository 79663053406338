const options = {
  5: "bg-violet-300/5",
  10: "bg-violet-300/10",
  25: "bg-violet-300/25",
  50: "bg-violet-300/50",
  75: "bg-violet-300/75",
  90: "bg-violet-300/90",
  100: "bg-violet-300",
};

const from = {
  5: "from-violet-300/5",
  10: "from-violet-300/10",
  25: "from-violet-300/25",
  50: "from-violet-300/50",
  75: "from-violet-300/75",
  90: "from-violet-300/90",
  100: "from-violet-300",
};

export default function getBackgroundViolet300(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
