"use client";

import { createContext, useContext, useMemo, useState } from "react";

const ActionContext = createContext();

export function ActionProvider({ callable, children, firestore }) {
  const [blockingRequest, setBlockingRequest] = useState(null);

  const context = useMemo(() => {
    return {
      callable,
      firestore,
      blockingRequest,
      setBlockingRequest,
    };
  }, [callable, blockingRequest]);

  return <ActionContext.Provider value={context}>{children}</ActionContext.Provider>;
}

export const useActionClientFirestore = () => useContext(ActionContext).firestore;
export const useActionServerFunction = () => useContext(ActionContext).callable;
export const useActionCallingRequest = () => useContext(ActionContext).blockingRequest;
export const useActionCallingRequestSetter = () => useContext(ActionContext).setBlockingRequest;
