import { AmountDisplay, UnitDisplay } from "@easybiz/component";
import { useClientTheme } from "@easybiz/shell";
import { List, Space, Typography } from "antd";
import React, { useEffect, useRef } from "react";

export default ({
  onClick,
  fontSize,
  strong,
  type,
  avatar,
  title,
  descriptions,
  amount,
  qty,
  unitPrice,
  extra,
  actions,
  unit,
  selected,
  disabled,
  children,
}) => {
  const ref = useRef();
  const backgroundColor = useClientTheme()?.secondaryContainer;
  const amountStyle = { fontSize: fontSize || 16, whiteSpace: "nowrap" };

  useEffect(() => {
    if (selected) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [selected]);

  return (
    <List.Item
      ref={ref}
      className={onClick ? "selectable" : null}
      style={{ backgroundColor: selected ? backgroundColor : null }}
      onClick={onClick}
      actions={actions}
    >
      <List.Item.Meta
        avatar={avatar}
        title={
          <div className="flex flex-row flex-space-between">
            <Typography.Text
              style={{ fontSize: fontSize || 16 }}
              strong={strong}
              delete={disabled}
              type={disabled ? "secondary" : type}
            >
              {title}
            </Typography.Text>
            <Space>
              {extra}
              {typeof unitPrice === "number" && (
                <Typography.Text style={amountStyle} delete={disabled} type={disabled ? "secondary" : type}>
                  <AmountDisplay value={unitPrice} accounting />
                </Typography.Text>
              )}
              {qty ? (
                <Typography.Text style={amountStyle} strong={strong}>
                  {typeof qty === "number" ? `x` : ""}
                  {qty} <UnitDisplay unit={unit} />
                </Typography.Text>
              ) : null}
              {typeof amount === "number" ? (
                <Typography.Text
                  style={amountStyle}
                  strong={strong}
                  delete={disabled}
                  type={disabled ? "secondary" : type}
                >
                  <AmountDisplay value={amount} accounting />
                </Typography.Text>
              ) : null}
            </Space>
          </div>
        }
        description={
          (Array.isArray(descriptions) || children) && (
            <div>
              {(descriptions || []).map(({ title, amount, isDate, type, isMinus }) => {
                return (
                  <div className="flex flex-row flex-space-between">
                    <Typography.Text type={type} strong={isDate}>
                      {title}
                    </Typography.Text>
                    {typeof amount === "number" ? (
                      <Typography.Text
                        style={{
                          fontSize: fontSize || 16,
                          whiteSpace: "nowrap",
                        }}
                        type={isMinus ? "success" : "secondary"}
                      >
                        <AmountDisplay value={amount} accounting />
                      </Typography.Text>
                    ) : null}
                  </div>
                );
              })}
              {children}
            </div>
          )
        }
      />
    </List.Item>
  );
};
