export default function calculateFlatPercentageWidth(width, fullWidth, remainingWidth) {
  if (Number.isInteger(width)) {
    // Width by character
    return Math.min(remainingWidth, width);
  } else if (width) {
    const [seg1, seg2] = `${width}`.split("/");

    if (seg1 && seg2 && !isNaN(seg1) && !isNaN(seg2)) {
      return Math.floor((fullWidth * seg1) / seg2);
    }
  }
}
