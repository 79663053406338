import { CameraOutlined, DollarOutlined, FormOutlined } from "@ant-design/icons";
import { AmountDisplay, DateDisplay, UnitDisplay } from "@easybiz/component";
import { translate } from "@easybiz/utils";
import { Avatar, List, Typography } from "antd";
import { useUserImageHost } from "@easybiz/shell";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import LabelStatusTag from "../LabelStatusTag";
import OrderPhotoGallery from "../OrderPhotoGallery";
import Title from "../Title";

export default ({ order, itemIndex }) => {
  const imageHost = useUserImageHost();

  const dataSource = useMemo(() => {
    if (order) {
      const { items, completes } = order.data() || {};
      const item = items && items[itemIndex];

      if (item) {
        const { photoId, product, option, lineTotal, selectSteps, subOptions, note, labelIndexes, qty, unit, pcs } =
          item;

        return [
          {
            avatar: photoId ? <Avatar src={`${imageHost}/${photoId}`} /> : null,
            title: translate(product),
            description: <FormattedMessage defaultMessage={"Product"} />,
            action: (
              <Title>
                <FormattedMessage
                  defaultMessage={"Line total: {amount}"}
                  values={{
                    amount: <AmountDisplay value={lineTotal} />,
                  }}
                />
              </Title>
            ),
          },
          ...(Array.isArray(subOptions)
            ? subOptions.map(({ title, code, price, note }) => {
                const complete = completes?.find(
                  (record) =>
                    record.sortIndex === itemIndex + 1 &&
                    (record.option ? record.option === translate(title) : record.optionCode === code)
                );

                return {
                  title: (
                    <span>
                      {translate(title) || code}
                      {note ? ` (${note})` : ""}
                    </span>
                  ),
                  description: (
                    <Typography.Text type={complete ? "success" : "warning"} strong>
                      {complete ? (
                        <FormattedMessage
                          defaultMessage={"Completed by {name} at {time}"}
                          values={{
                            name: complete.o,
                            time: <DateDisplay timestamp={complete.t} />,
                          }}
                        />
                      ) : (
                        <FormattedMessage defaultMessage={"Pending"} />
                      )}
                    </Typography.Text>
                  ),
                  avatar: <DollarOutlined />,
                  action: <AmountDisplay value={price} />,
                };
              })
            : translate(option)
            ? [
                {
                  title: translate(option),
                  description: <FormattedMessage defaultMessage={"Service Type"} />,
                  action: (
                    <Title>
                      {`x ${qty}`}
                      <UnitDisplay unit={unit} />
                      {pcs ? (
                        <span>
                          {" "}
                          (
                          <FormattedMessage defaultMessage={"{count} pcs"} values={{ count: pcs }} />)
                        </span>
                      ) : null}
                    </Title>
                  ),
                },
              ]
            : []),
          ...(Array.isArray(selectSteps)
            ? selectSteps.map(({ title, value }) => {
                return {
                  avatar: <FormOutlined />,
                  title: Array.isArray(value) ? value.map(({ code }) => code).join("; ") : String(value),
                  description: translate(title),
                };
              })
            : []),
          ...(note
            ? [
                {
                  avatar: <FormOutlined />,
                  title: <b>{note}</b>,
                  description: <FormattedMessage defaultMessage={"Note"} />,
                },
              ]
            : []),
          ...(Array.isArray(labelIndexes)
            ? labelIndexes.map((sortIndex) => {
                return {
                  avatar: <CameraOutlined />,
                  title: (
                    <span>
                      {`#${sortIndex}`} <LabelStatusTag order={order} sortIndex={sortIndex} />
                    </span>
                  ),
                  description: <OrderPhotoGallery order={order} itemIndex={sortIndex} wrap />,
                };
              })
            : []),
        ];
      }
    }
  }, [order]);

  return (
    <List
      size="small"
      loading={!order}
      dataSource={dataSource}
      renderItem={({ avatar, title, description, action }) => {
        return (
          <List.Item extra={action}>
            <List.Item.Meta title={title} avatar={avatar} description={description} />
          </List.Item>
        );
      }}
    />
  );
};
