const options = {
  1: "md:h-1",
  2: "md:h-2",
  3: "md:h-3",
  4: "md:h-4",
  5: "md:h-5",
  6: "md:h-6",
  7: "md:h-7",
  8: "md:h-8",
  9: "md:h-9",
  10: "md:h-10",
  11: "md:h-11",
  12: "md:h-12",
  14: "md:h-14",
  16: "md:h-16",
  20: "md:h-20",
  24: "md:h-24",
  28: "md:h-28",
  32: "md:h-32",
  36: "md:h-36",
  40: "md:h-40",
  44: "md:h-44",
  48: "md:h-48",
  52: "md:h-52",
  56: "md:h-56",
  60: "md:h-60",
  64: "md:h-64",
  72: "md:h-72",
  80: "md:h-80",
  96: "md:h-96",
  112: "md:h-[28rem]",
  128: "md:h-[32rem]",
  "1/2": "md:h-1/2",
  "1/3": "md:h-1/3",
  "2/3": "md:h-2/3",
  "1/5": "md:h-1/5",
  "2/5": "md:h-2/5",
  "3/5": "md:h-3/5",
  "4/5": "md:h-4/5",
  px: "md:h-px",
  screen: "md:h-screen",
  fill: "md:grow",
  full: "md:h-full",
  ratio: "md:h-auto",
  auto: "md:h-auto",
};

export default function getHeightMd(size, parentDisplay) {
  return size === "fill" && !["col", "col-reverse"].includes(parentDisplay || "col") ? "" : options[size];
}
