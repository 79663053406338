export const CUSTOMER_SCREEN_PROFILE_FILL_IN = "PROFILE_FILL_IN";
export const CUSTOMER_SCREEN_ORDER = "ORDER_SIGN";
export const CUSTOMER_SCREEN_TOP_UP_OPTIONS = "TOP_UP_OPTIONS";
export const CUSTOMER_SCREEN_TOP_UP = "TOP_UP_SIGN";
export const CUSTOMER_SCREEN_ITEM_DETAIL = "ITEM_DETAIL";
export const CUSTOMER_SCREEN_COLLECTION = "COLLECTION_SIGN";
export const CUSTOMER_SCREEN_DATE_TIME_OPTIONS = "DATE_TIME_OPTIONS";
export const CUSTOMER_SCREEN_ARTICLE = "ARTICLE";
export const CUSTOMER_SCREEN_PAYMENT_INTENT = "PAYMENT_NEXT_ACTION";

export const CUSTOMER_ACTION_SIGN_IN = "sign_in";
export const CUSTOMER_ACTION_SIGN_UP = "sign_up";
export const CUSTOMER_ACTION_PROFILE_SAVE = "profile_update"; // TODO: remove
export const CUSTOMER_ACTION_PROMO_CODE_CHECK = "promo_code_check";
export const CUSTOMER_ACTION_BUSINESS_ZONE_CHECK = "business_zone_check";
export const CUSTOMER_ACTION_TIME_SLOT_OPTIONS = "time_slot_options";
export const CUSTOMER_ACTION_ACCOUNT_INSTALL = "account_install";
export const CUSTOMER_ACTION_OTP_SEND = "otp_send";
export const CUSTOMER_ACTION_OTP_VERIFY = "otp_verify";
export const CUSTOMER_ACTION_MARKETING_ONBOARD = "marketing_onboard";
export const CUSTOMER_ACTION_CREDIT_TOP_UP = "credit_top_up";
export const CUSTOMER_ACTION_PAYMENT_METHOD_SETUP = "payment_method_setup";
export const CUSTOMER_ACTION_PAYMENT_METHOD_REMOVE = "payment_method_remove";
export const CUSTOMER_ACTION_PAYMENT_METHOD_FETCH = "payment_method_fetch";
export const CUSTOMER_ACTION_PAYMENT_INTENT_CANCEL = "payment_intent_cancel";
export const CUSTOMER_ACTION_ADDRESS_EDIT = "address_edit";
export const CUSTOMER_ACTION_NOTIFICATION_MARK_ALL_READ = "notification_mark_all_read";
export const CUSTOMER_ACTION_RECURRING_EDIT = "recurring_edit";
export const CUSTOMER_ACTION_ARTICLE_READ = "article_read";
export const CUSTOMER_ACTION_FETCH_ORDER_DETAIL = "fetch_order_detail";
export const CUSTOMER_ACTION_CONFIRMATION = "confirmation";
export const CUSTOMER_ACTION_PREPARE_CASHIER_PAY_ORDER = "prepare_cashier_pay_order";
export const CUSTOMER_ACTION_PREPARE_CASHIER_TOP_UP = "prepare_cashier_top_up";
export const CUSTOMER_ACTION_PREPARE_CASHIER_JOIN_MEMBER = "prepare_cashier_join_member";
export const CUSTOMER_ACTION_GET_AVAILABLE_COUPONS = "get_available_coupons";
export const CUSTOMER_ACTION_COUPON_REDEEM = "coupon_redeem";
export const CUSTOMER_ACTION_SCHEDULE_PREVIEW = "schedule_preview";
export const CUSTOMER_ACTION_PAY_ORDER = "pay_order";
export const CUSTOMER_ACTION_PAY_TOP_UP = "pay_top_up";
export const CUSTOMER_ACTION_PAY_MEMBER = "pay_member";
export const CUSTOMER_ACTION_RESCHEDULE = "reschedule";
export const CUSTOMER_ACTION_QUICK_STATUS_PREVIEW = "quick_status_preview";
export const CUSTOMER_ACTION_ADDRESS_CHECK = "address_check";
export const CUSTOMER_ACTION_ORDER_HISTORY_LIST = "order_history_list";
export const CUSTOMER_ACTION_GET_REFERRAL_CODE = "get_referral_code";
export const CUSTOMER_ACTION_SIGN_OUT = "sign_out";
export const CUSTOMER_ACTION_INQUIRY_SUBMIT = "inquiry_submit";
export const CUSTOMER_ACTION_CANCEL_PROFILE_UPDATE = "cancel_profile_update";
export const CUSTOMER_ACTION_EMAIL_PREFERENCES_EDIT = "email_preferences_edit";
export const CUSTOMER_ACTION_REQUEST_BOOKING_OPTIONS = "request_booking_options";

export const ACCOUNT_FIELD_NAME = "name";
export const ACCOUNT_FIELD_PHONE_NUMBER = "phoneNumber";
export const ACCOUNT_FIELD_EMAIL = "email";
export const ACCOUNT_FIELD_ADDRESS = "address";
export const ACCOUNT_FIELD_BIRTHDAY = "birthday";
export const ACCOUNT_FIELD_PRIVATE_NOTE = "private_note";
export const ACCOUNT_FIELD_REFERRAL_SOURCE = "source";

export const CUSTOMER_INTENT_PAYMENT = "payment";
export const CUSTOMER_INTENT_SETUP_PAYMENT_METHOD = "setup_payment_method";
export const CUSTOMER_INTENT_CHECKOUT = "checkout";
export const CUSTOMER_INTENT_JOIN_MEMBER = "join_member";
export const CUSTOMER_INTENT_TOP_UP = "top_up";
