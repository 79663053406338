import { DisconnectOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import { FormattedMessage } from "react-intl";

export default function ({ techName }) {
  return (
    <Alert
      showIcon
      icon={<DisconnectOutlined />}
      type="error"
      message={
        <FormattedMessage
          defaultMessage="To enable web USB printing, please switch to <chrome>Chrome</chrome> or <edge>Edge</edge> as your browser does not support {techName} technology."
          values={{
            techName,
            edge: (t) => (
              <a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noreferrer">
                {t}
              </a>
            ),
            chrome: (t) => (
              <a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer">
                {t}
              </a>
            ),
          }}
        />
      }
    />
  );
}
