const values = {
  0: "sm:pt-0",
  0.5: "sm:pt-0.5",
  1: "sm:pt-1",
  1.5: "sm:pt-1.5",
  2: "sm:pt-2",
  2.5: "sm:pt-2.5",
  3: "sm:pt-3",
  3.5: "sm:pt-3.5",
  4: "sm:pt-4",
  5: "sm:pt-5",
  6: "sm:pt-6",
  7: "sm:pt-7",
  8: "sm:pt-8",
  9: "sm:pt-9",
  10: "sm:pt-10",
  12: "sm:pt-12",
  14: "sm:pt-14",
  16: "sm:pt-16",
  20: "sm:pt-20",
  24: "sm:pt-24",
  28: "sm:pt-28",
  32: "sm:pt-32",
  36: "sm:pt-36",
  40: "sm:pt-40",
  44: "sm:pt-44",
  48: "sm:pt-48",
  52: "sm:pt-52",
  56: "sm:pt-56",
  60: "sm:pt-60",
  64: "sm:pt-64",
  72: "sm:pt-72",
  80: "sm:pt-80",
  96: "sm:pt-96",
};

export default function getPaddingTopSm(value) {
  return values[value];
}
