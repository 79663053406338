import { Alert, InputNumber, Space } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import NumericEditor from "../NumericEditor";

export default ({ settings, onChange }) => {
  const intl = useIntl();
  const { lineCharacter, rowGap, qrSize, feedLines, codeTable } = settings || {};

  return (
    <Space direction="vertical" size={"middle"} className="full-width">
      <div>
        <div className="flex flex-row flex-space-between flex-align-center padding-bottom-half">
          <FormattedMessage tagName={"span"} defaultMessage="Characters per line" />
          <NumericEditor
            unit={intl.formatMessage({ defaultMessage: "characters" })}
            offset={2}
            value={typeof lineCharacter === "number" ? lineCharacter : 48}
            min={10}
            max={100}
            onChange={(lineCharacter) => onChange({ ...settings, lineCharacter })}
          />
        </div>
        <Alert
          type="info"
          message={
            <FormattedMessage defaultMessage="Normal font to print per line, 80mm receipt printer default to 48 characters, you only need to change this setting if your printer have alignment issue." />
          }
        />
      </div>
      <div>
        <div className="flex flex-row flex-space-between flex-align-center padding-bottom-half">
          <FormattedMessage tagName={"span"} defaultMessage="Line gap" />
          <NumericEditor
            unit={intl.formatMessage({ defaultMessage: "dots" })}
            offset={5}
            value={typeof rowGap === "number" ? rowGap : 40}
            min={10}
            max={100}
            onChange={(rowGap) => onChange({ ...settings, rowGap })}
          />
        </div>
        <Alert
          type="info"
          message={
            <FormattedMessage defaultMessage="Gap in dots (printing unit) between each lines, default to 40 dots." />
          }
        />
      </div>
      <div>
        <div className="flex flex-row flex-space-between flex-align-center padding-bottom-half">
          <FormattedMessage tagName={"span"} defaultMessage="Line Feed After Receipt" />
          <NumericEditor
            value={typeof feedLines === "number" ? feedLines : 7}
            min={0}
            max={50}
            onChange={(feedLines) => onChange({ ...settings, feedLines })}
            unit="lines"
          />
        </div>
        <Alert
          type="info"
          message={<FormattedMessage defaultMessage="Set the number of extra lines to feed after printing a receipt to ensure proper cutting." />}
        />
      </div>
      <div>
        <div className="flex flex-row flex-space-between flex-align-center padding-bottom-half">
          <FormattedMessage tagName={"span"} defaultMessage="QR code size" />
          <NumericEditor
            value={typeof qrSize === "number" ? qrSize : 6}
            min={1}
            max={8}
            onChange={(qrSize) => onChange({ ...settings, qrSize })}
          />
        </div>
        <Alert
          type="info"
          message={<FormattedMessage defaultMessage="QR code size, can set from 1 ~ 8, default to 6." />}
        />
      </div>
      <div>
        <div className="flex flex-row flex-space-between flex-align-center padding-bottom-half">
          <FormattedMessage tagName={"span"} defaultMessage="Code page (Code table)" />
          <InputNumber value={codeTable} onChange={(codeTable) => onChange({ ...settings, codeTable })} min={0}
            placeholder="0 ~ 255" />
        </div>
        <Alert
          type="info"
          message={
            <FormattedMessage defaultMessage="For multilingual printing, please check your printer code page list and select the correct code page for your language" />
          }
        />
      </div>
    </Space>
  );
};
