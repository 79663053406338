import { PrinterOutlined } from "@ant-design/icons";
import { usePrinter } from "@easybiz/hook";
import { useOrderLabelDisplay } from "@easybiz/query";
import { useUserBusinessCode, useUserRealmId } from "@easybiz/shell";
import {
  LINE_ALIGN_CENTER,
  LINE_TYPE_TITLE,
  PRINTER_TYPE_LABEL,
  PRINTER_TYPE_RECEIPT,
  SALES_CHANNEL_TYPE_STORE,
} from "@easybiz/utils";
import { textToImageData } from "@easybiz/web-component";
import { Button, message } from "antd";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

export default function TestPrintButton({ printerType, device, printArgs, settings }) {
  const realmId = useUserRealmId();
  const [printing, setPrinting] = useState(false);
  const [latestOrder, setLatestOrder] = useState();
  const printer = usePrinter();
  const businessCode = useUserBusinessCode();
  const labelDisplay = useOrderLabelDisplay(
    { onSnapshot, doc, getFirestore, getDoc, getDocs, query, where, limit, collection },
    latestOrder,
    settings,
    {
      textToImageData,
    }
  );

  useEffect(() => {
    if (realmId && businessCode) {
      return onSnapshot(
        query(
          collection(getFirestore(), `realms/${realmId}/orders`),
          where("type", "==", SALES_CHANNEL_TYPE_STORE),
          where("business.id", "==", businessCode),
          orderBy("timestamp", "desc"),
          limit(1)
        ),
        (snapshot) => {
          setLatestOrder(snapshot.docs[0]);
        }
      );
    }
  }, [realmId, businessCode]);

  const onTestPrint = async () => {
    try {
      setPrinting(true);

      let args = printArgs;

      if (!args) {
        // Default testing prinit content
        switch (printerType) {
          case PRINTER_TYPE_RECEIPT:
            if (latestOrder) {
              args = {
                lines: [
                  {
                    type: LINE_TYPE_TITLE,
                    align: LINE_ALIGN_CENTER,
                    raw: "Test title",
                  },
                ],
              };
            } else {
              const receipt = await getDoc(doc(getFirestore(), `realms/${realmId}/receipts/${latestOrder.id}`));
              args = receipt.data();
            }
            break;
          case PRINTER_TYPE_LABEL:
            if (labelDisplay?.labels) {
              args = {
                encodeLabels: (labelDisplay.labels || []).map(({ command }) => command),
              };
            } else if (latestOrder) {
              args = {
                labelOrder: latestOrder,
              };
            } else {
              args = {
                label: {
                  title: `Test title`,
                  description: "Test content",
                },
              };
            }
            break;
        }
      }

      await printer.print(args || {}, device, settings);
    } catch (err) {
      message.error(err.message);
    } finally {
      setPrinting(false);
    }
  };

  return (
    <Button disabled={!printer || printing} loading={printing} icon={<PrinterOutlined />} onClick={onTestPrint}>
      <FormattedMessage tagName="span" defaultMessage="Test print" />
    </Button>
  );
}
