import clsx from "clsx";
import getMaxWidth from "./getMaxWidth";
import getMaxWidthLg from "./getMaxWidthLg";
import getMaxWidthMd from "./getMaxWidthMd";
import getMaxWidthSm from "./getMaxWidthSm";
import getMaxWidthXl from "./getMaxWidthXl";

export default function getMaxWidths(attrs) {
  return clsx(
    getMaxWidth(attrs?.maxW),
    getMaxWidthSm(attrs?.maxWSm),
    getMaxWidthMd(attrs?.maxWMd),
    getMaxWidthLg(attrs?.maxWLg),
    getMaxWidthXl(attrs?.maxWXl)
  );
}
