import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_POS, POS_ACTION_CASH_DRAWER_END } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function usePOSCashDrawerEnd(callback) {
  const intl = useIntl();

  const onSubmit = useCallback(({ actualAmount }) => {
    if (typeof actualAmount !== "number") {
      return intl.formatMessage({
        defaultMessage: "Please enter actual amount",
      });
    }

    return [
      OPERATION_GROUP_POS,
      POS_ACTION_CASH_DRAWER_END,
      {
        actualAmount,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Cash drawer ended" }));
}

export default usePOSCashDrawerEnd;
