export default function getMarginTopXl(value) {
  switch (value) {
    case -44:
      return "xl:-mt-44";
    case -40:
      return "xl:-mt-40";
    case -36:
      return "xl:-mt-36";
    case -32:
      return "xl:-mt-32";
    case -28:
      return "xl:-mt-28";
    case -24:
      return "xl:-mt-24";
    case -20:
      return "xl:-mt-20";
    case -16:
      return "xl:-mt-16";
    case -12:
      return "xl:-mt-12";
    case -8:
      return "xl:-mt-8";
    case -4:
      return "xl:-mt-4";
    case 0:
      return "xl:mt-0";
    case 1:
      return "xl:mt-1";
    case 2:
      return "xl:mt-2";
    case 3:
      return "xl:mt-3";
    case 4:
      return "xl:mt-4";
    case 5:
      return "xl:mt-5";
    case 6:
      return "xl:mt-6";
    case 7:
      return "xl:mt-7";
    case 8:
      return "xl:mt-8";
    case 9:
      return "xl:mt-9";
    case 10:
      return "xl:mt-10";
    case 12:
      return "xl:mt-12";
    case 14:
      return "xl:mt-14";
    case 16:
      return "xl:mt-16";
    case 20:
      return "xl:mt-20";
    case 24:
      return "xl:mt-24";
    case 28:
      return "xl:mt-28";
    case 32:
      return "xl:mt-32";
    case 36:
      return "xl:mt-36";
    case 40:
      return "xl:mt-40";
    default:
      return "";
  }
}
