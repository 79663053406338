import { ShopFilled, ShopOutlined, SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import { useCheckoutCustomer, useClientStateSetter, useRealmPOSSettings, useUserBusinessCode, useUser } from "@easybiz/shell";
import { ACTION_PAYMENT } from "@easybiz/utils";
import { useHaveNotBusinessOrder } from "@easybiz/web-firebase";
import { Checkbox, Segmented, Typography } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import OrderLoadMoreQueryList from "./OrderLoadMoreQueryList";
import { PERMISSION_ACCESS_ORDER_DATABASE } from "@easybiz/constants";

export default function MainActionOrderHistoryPanel({ search, orderCount }) {
  const [outstandingAssend, setOutstandingAssend] = useState(null);
  const [viewOther, setViewOther] = useState(false);
  const clients = useUser()?.clients;
  const allOrder = useRealmPOSSettings().permissions?.find((p) => p.id === PERMISSION_ACCESS_ORDER_DATABASE)?.allOrder;
  const customer = useCheckoutCustomer();
  const businessCode = useUserBusinessCode();
  const haveOtherOrder = useHaveNotBusinessOrder(
    (allOrder || clients?.length > 0) && (customer || search) ? businessCode : null,
    customer?.id,
    search
  );

  const queryParams = useMemo(() => {
    let queryParams = {};

    if (customer?.id) {
      queryParams.customerId = customer.id;
    } else if (search) {
      queryParams.search = search;
    } else if (typeof outstandingAssend === "boolean") {
      queryParams = {
        awaitAction: ACTION_PAYMENT,
        businessCode,
        assend: outstandingAssend,
      };
    }

    if (viewOther) {
      if (!allOrder) {
        queryParams.businessCodes = clients;
      }
    } else {
      queryParams.businessCode = businessCode;
    }

    return queryParams;
  }, [customer?.id, businessCode, allOrder, viewOther, search, outstandingAssend, clients]);

  const setState = useClientStateSetter();

  const onSelectOrder = useCallback(
    (order) => {
      setState({ openOrderId: order.id });
    },
    [setState]
  );

  return (
    <div className="padding-half">
      <Typography.Title level={5}>
        {customer ? (
          <FormattedMessage
            defaultMessage={"{customerName}'s order history (Total {count} orders)"}
            values={{ customerName: customer.name, count: orderCount }}
          />
        ) : search ? (
          <FormattedMessage
            defaultMessage={"Order history by searching {search}"}
            values={{ search: <b>{search}</b> }}
          />
        ) : (
          <div className="flex flex-row flex-space-between flex-align-center">
            <FormattedMessage defaultMessage={"Search orders"} />
            <Checkbox
              checked={typeof outstandingAssend === "boolean"}
              onChange={(e) => setOutstandingAssend(e.target.checked ? false : null)}
            >
              <FormattedMessage defaultMessage={"Store outstanding orders"} />
            </Checkbox>
          </div>
        )}
      </Typography.Title>
      {typeof outstandingAssend === "boolean" && !search ? (
        <Segmented
          size="large"
          value={outstandingAssend}
          className="margin-bottom-half"
          block
          options={[
            {
              icon: <SortAscendingOutlined />,
              value: false,
              label: <FormattedMessage defaultMessage={"Most Recent First"} />,
            },
            {
              icon: <SortDescendingOutlined />,
              value: true,
              label: <FormattedMessage defaultMessage={"Earliest First"} />,
            },
          ]}
          onChange={setOutstandingAssend}
        />
      ) : haveOtherOrder ? (
        <Segmented
          size="large"
          value={viewOther}
          className="margin-bottom-half"
          block
          options={[
            { icon: <ShopFilled />, value: false, label: <FormattedMessage defaultMessage={"Own store order"} /> },
            {
              icon: <ShopOutlined />,
              value: true,
              label: <FormattedMessage defaultMessage={"Include other Store/App"} />,
            },
          ]}
          onChange={setViewOther}
        />
      ) : null}
      <OrderLoadMoreQueryList onSelect={onSelectOrder} queryParams={queryParams} />
    </div>
  );
}
