import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_SKIP_PICKUP } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useOrderSkipPickUp(callback) {
  const intl = useIntl();

  return useActionCallback(
    ({ orderId }) => {
      return [
        OPERATION_GROUP_ORDER,
        ORDER_ACTION_SKIP_PICKUP,
        {
          orderId,
        },
      ];
    },
    callback,
    intl.formatMessage({
      id: "pick.up.skipped",
      defaultMessage: "Pick-up skipped",
    })
  );
}

export default useOrderSkipPickUp;
