import { CreditCardOutlined, DollarOutlined, GiftOutlined, WalletOutlined } from "@ant-design/icons";
import { AmountDisplay, PaymentMethodDisplay } from "@easybiz/component";
import { useUserImageHost } from "@easybiz/shell";
import { PAYMENT_ID_CASH, PAYMENT_ID_CREDIT, PAYMENT_ID_POINTS, PAYMENT_ID_STRIPE } from "@easybiz/utils";
import { Avatar, Button } from "antd";
import React from "react";

export default ({ disabled, paymentMethod, onSelect, select }) => {
  const imageHost = useUserImageHost();

  const selected = select?.id === paymentMethod.id;

  let icon;

  switch (paymentMethod.id) {
    case PAYMENT_ID_CREDIT:
      icon = <WalletOutlined />;
      break;
    case PAYMENT_ID_POINTS:
      icon = <GiftOutlined />;
      break;
    case PAYMENT_ID_CASH:
      icon = <DollarOutlined />;
      break;
    case PAYMENT_ID_STRIPE:
      icon = <CreditCardOutlined />;
      break;
  }

  return (
    <Button
      id={paymentMethod.id}
      shape="round"
      disabled={disabled || paymentMethod.disabled}
      style={{ position: "relative" }}
      onClick={onSelect ? () => onSelect(paymentMethod) : null}
      type={selected ? "primary" : "default"}
      icon={icon}
    >
      {paymentMethod.photoId && !icon ? (
        <Avatar
          style={{ position: "absolute", top: 7, left: 15 }}
          size={24}
          src={`${imageHost}/${paymentMethod.photoId}`}
          shape="square"
        />
      ) : null}
      {paymentMethod.photoId && !icon ? <span style={{ width: 24 }} /> : null}
      <span>{paymentMethod.title || <PaymentMethodDisplay option={paymentMethod.option} />}</span>
      {typeof paymentMethod.totalCredits === "number" && (
        <span>
          {" "}
          (<AmountDisplay value={paymentMethod.totalCredits} />)
        </span>
      )}
      {typeof paymentMethod.totalPoints === "number" && (
        <span>
          {" "}
          (<AmountDisplay value={paymentMethod.totalPoints} minimumFractionDigits={0} pure />)
        </span>
      )}
    </Button>
  );
};
