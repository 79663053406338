"use client";

import { useUserGoogleMapKey } from "@easybiz/shell";
import { useJsApiLoader } from "@react-google-maps/api";
import Skeleton from "./Skeleton";

const libraries = ["places"];

export default function GoogleMapJsLoader({ children, height }) {
  const googleMapsApiKey = useUserGoogleMapKey();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    libraries,
    googleMapsApiKey,
  });

  return isLoaded ? children : <Skeleton height={height || "h-9"} />;
}
