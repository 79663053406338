import { useRealmToDisplayName } from "@easybiz/shell";
import { Tag } from "antd";
import { useIntl } from "react-intl";

export default ({ store, total }) => {
  const intl = useIntl();
  const toDisplayName = useRealmToDisplayName();

  let display, color;

  if (store === null) {
    display = "?";
    color = "warning";
  } else if (store === false) {
    display = intl.formatMessage({ defaultMessage: "Driver collected" });
    color = "processing";
  } else if (store === true) {
    display = intl.formatMessage({ defaultMessage: "Delivered to customer" });
    color = "success";
  } else if (typeof store === "string" && store.includes("::")) {
    const [code, storeId] = store.split("::");
    display = [toDisplayName(code) || code, storeId].filter(Boolean).join("-");
    color = "processing";
  } else {
    display = toDisplayName(store) || store;
    color = "processing";
  }

  return (
    <Tag color={color}>
      <b>
        {display}
        {total ? ` x ${total}` : ""}
      </b>
    </Tag>
  );
};
