import { useAsyncCollectionOptions } from "@easybiz/component";
import {
  useCheckoutEditOrder,
  useCheckoutService,
  useCheckoutServiceTypes,
  useClientState,
  useClientStateSetter,
  useUserBusinessCode,
} from "@easybiz/shell";
import { useEffect } from "react";

export default function () {
  const service = useCheckoutService();
  const businessCode = useUserBusinessCode();
  const editOrder = useCheckoutEditOrder();
  const { date } = useClientState();
  const serviceTypes = useCheckoutServiceTypes();
  const setState = useClientStateSetter();
  const { options } = useAsyncCollectionOptions({
    serviceId: service?.id,
    businessCode,
    date,
    orderId: editOrder?.id,
    serviceTypes,
    combine: true,
  });

  useEffect(() => {
    setState({ collectionOptions: options });
  }, [options]);
}
