const options = {
  0: "lg:border-t-0",
  1: "lg:border-t",
  2: "lg:border-t-2",
  4: "lg:border-t-4",
  8: "lg:border-t-8",
};

export default function getBorderTopLg(width) {
  return options[width];
}
