const COLOR_50 = {
  slate: "ring-slate-50",
  gray: "ring-gray-50",
  zinc: "ring-zinc-50",
  neutral: "ring-neutral-50",
  stone: "ring-stone-50",
  red: "ring-red-50",
  orange: "ring-orange-50",
  amber: "ring-amber-50",
  yellow: "ring-yellow-50",
  lime: "ring-lime-50",
  green: "ring-green-50",
  emerald: "ring-emerald-50",
  teal: "ring-teal-50",
  cyan: "ring-cyan-50",
  sky: "ring-sky-50",
  blue: "ring-blue-50",
  indigo: "ring-indigo-50",
  violet: "ring-violet-50",
  purple: "ring-purple-50",
  fuchsia: "ring-fuchsia-50",
  pink: "ring-pink-50",
  rose: "ring-rose-50",
};

const COLOR_200 = {
  slate: "ring-slate-200",
  gray: "ring-gray-200",
  zinc: "ring-zinc-200",
  neutral: "ring-neutral-200",
  stone: "ring-stone-200",
  red: "ring-red-200",
  orange: "ring-orange-200",
  amber: "ring-amber-200",
  yellow: "ring-yellow-200",
  lime: "ring-lime-200",
  green: "ring-green-200",
  emerald: "ring-emerald-200",
  teal: "ring-teal-200",
  cyan: "ring-cyan-200",
  sky: "ring-sky-200",
  blue: "ring-blue-200",
  indigo: "ring-indigo-200",
  violet: "ring-violet-200",
  purple: "ring-purple-200",
  fuchsia: "ring-fuchsia-200",
  pink: "ring-pink-200",
  rose: "ring-rose-200",
};

const COLOR_300 = {
  slate: "ring-slate-300",
  gray: "ring-gray-300",
  zinc: "ring-zinc-300",
  neutral: "ring-neutral-300",
  stone: "ring-stone-300",
  red: "ring-red-300",
  orange: "ring-orange-300",
  amber: "ring-amber-300",
  yellow: "ring-yellow-300",
  lime: "ring-lime-300",
  green: "ring-green-300",
  emerald: "ring-emerald-300",
  teal: "ring-teal-300",
  cyan: "ring-cyan-300",
  sky: "ring-sky-300",
  blue: "ring-blue-300",
  indigo: "ring-indigo-300",
  violet: "ring-violet-300",
  purple: "ring-purple-300",
  fuchsia: "ring-fuchsia-300",
  pink: "ring-pink-300",
  rose: "ring-rose-300",
};

const COLOR_400 = {
  slate: "ring-slate-400",
  gray: "ring-gray-400",
  zinc: "ring-zinc-400",
  neutral: "ring-neutral-400",
  stone: "ring-stone-400",
  red: "ring-red-400",
  orange: "ring-orange-400",
  amber: "ring-amber-400",
  yellow: "ring-yellow-400",
  lime: "ring-lime-400",
  green: "ring-green-400",
  emerald: "ring-emerald-400",
  teal: "ring-teal-400",
  cyan: "ring-cyan-400",
  sky: "ring-sky-400",
  blue: "ring-blue-400",
  indigo: "ring-indigo-400",
  violet: "ring-violet-400",
  purple: "ring-purple-400",
  fuchsia: "ring-fuchsia-400",
  pink: "ring-pink-400",
  rose: "ring-rose-400",
};

const COLOR_500 = {
  slate: "ring-slate-500",
  gray: "ring-gray-500",
  zinc: "ring-zinc-500",
  neutral: "ring-neutral-500",
  stone: "ring-stone-500",
  red: "ring-red-500",
  orange: "ring-orange-500",
  amber: "ring-amber-500",
  yellow: "ring-yellow-500",
  lime: "ring-lime-500",
  green: "ring-green-500",
  emerald: "ring-emerald-500",
  teal: "ring-teal-500",
  cyan: "ring-cyan-500",
  sky: "ring-sky-500",
  blue: "ring-blue-500",
  indigo: "ring-indigo-500",
  violet: "ring-violet-500",
  purple: "ring-purple-500",
  fuchsia: "ring-fuchsia-500",
  pink: "ring-pink-500",
  rose: "ring-rose-500",
};

const COLOR_600 = {
  slate: "ring-slate-600",
  gray: "ring-gray-600",
  zinc: "ring-zinc-600",
  neutral: "ring-neutral-600",
  stone: "ring-stone-600",
  red: "ring-red-600",
  orange: "ring-orange-600",
  amber: "ring-amber-600",
  yellow: "ring-yellow-600",
  lime: "ring-lime-600",
  green: "ring-green-600",
  emerald: "ring-emerald-600",
  teal: "ring-teal-600",
  cyan: "ring-cyan-600",
  sky: "ring-sky-600",
  blue: "ring-blue-600",
  indigo: "ring-indigo-600",
  violet: "ring-violet-600",
  purple: "ring-purple-600",
  fuchsia: "ring-fuchsia-600",
  pink: "ring-pink-600",
  rose: "ring-rose-600",
};

const COLOR_700 = {
  slate: "ring-slate-700",
  gray: "ring-gray-700",
  zinc: "ring-zinc-700",
  neutral: "ring-neutral-700",
  stone: "ring-stone-700",
  red: "ring-red-700",
  orange: "ring-orange-700",
  amber: "ring-amber-700",
  yellow: "ring-yellow-700",
  lime: "ring-lime-700",
  green: "ring-green-700",
  emerald: "ring-emerald-700",
  teal: "ring-teal-700",
  cyan: "ring-cyan-700",
  sky: "ring-sky-700",
  blue: "ring-blue-700",
  indigo: "ring-indigo-700",
  violet: "ring-violet-700",
  purple: "ring-purple-700",
  fuchsia: "ring-fuchsia-700",
  pink: "ring-pink-700",
  rose: "ring-rose-700",
};

const COLOR_800 = {
  slate: "ring-slate-800",
  gray: "ring-gray-800",
  zinc: "ring-zinc-800",
  neutral: "ring-neutral-800",
  stone: "ring-stone-800",
  red: "ring-red-800",
  orange: "ring-orange-800",
  amber: "ring-amber-800",
  yellow: "ring-yellow-800",
  lime: "ring-lime-800",
  green: "ring-green-800",
  emerald: "ring-emerald-800",
  teal: "ring-teal-800",
  cyan: "ring-cyan-800",
  sky: "ring-sky-800",
  blue: "ring-blue-800",
  indigo: "ring-indigo-800",
  violet: "ring-violet-800",
  purple: "ring-purple-800",
  fuchsia: "ring-fuchsia-800",
  pink: "ring-pink-800",
  rose: "ring-rose-800",
};

export default function getRingColor(theme) {
  const { name = "teal", value } = theme || {};

  switch (value) {
    case 50:
      return COLOR_50[name];
    case 200:
      return COLOR_200[name];
    case 300:
      return COLOR_300[name];
    case 400:
      return COLOR_400[name];
    case 500:
      return COLOR_500[name];
    case 600:
      return COLOR_600[name];
    case 700:
      return COLOR_700[name];
    case 800:
      return COLOR_800[name];
  }
}
