import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_POS, POS_ACTION_CLOSE_OF_DAY_WITHDRAW } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function usePOSCloseOfDayWithdraw(callback) {
  const intl = useIntl();

  const onSubmit = useCallback(({ note, date }) => {
    if (!note) {
      return intl.formatMessage({
        defaultMessage: "Please enter the reason of withdraw",
      });
    }

    return [
      OPERATION_GROUP_POS,
      POS_ACTION_CLOSE_OF_DAY_WITHDRAW,
      {
        note,
        date,
      },
    ];
  }, []);

  return useActionCallback(
    onSubmit,
    callback,
    intl.formatMessage({
      defaultMessage: "Close of day report withdrawn",
    })
  );
}

export default usePOSCloseOfDayWithdraw;
