const options = {
  normal: "sm:justify-normal",
  center: "sm:justify-center",
  end: "sm:justify-end",
  start: "sm:justify-start",
  between: "sm:justify-between",
  around: "sm:justify-around",
  evenly: "sm:justify-evenly",
};

export default function getJustifyItemsSm(justify) {
  return options[justify];
}
