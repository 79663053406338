import {
  COMPONENT_ITEM_QR_CODE,
  COMPONENT_LABEL,
  COMPONENT_LOGO,
  COMPONENT_PACKAGE_QR_CODE,
  COMPONENT_TEXT,
  getChildProps,
  getLabelBlockStyle,
} from "@easybiz/utils";
import LabelLogo from "./labels/LabelLogo";
import LabelQRCode from "./labels/LabelQRCode";
import LabelText from "./labels/LabelText";
import PreviewLabel from "./labels/PreviewLabel";
import Node from "./Node";

export default function LabelComponent(props) {
  const style = getLabelBlockStyle(props);

  switch (props.attrs?.subType || props.type) {
    case COMPONENT_LABEL:
      return <PreviewLabel {...props} />;
    case COMPONENT_TEXT:
      return <LabelText {...props} style={style} />;
    case COMPONENT_ITEM_QR_CODE:
    case COMPONENT_PACKAGE_QR_CODE:
      return <LabelQRCode {...props} style={style} />;
    case COMPONENT_LOGO:
      return <LabelLogo {...props} style={style} />;
    default: {
      if (!props.child) return null;

      const ComponentTag = props.draft?.Component || LabelComponent;

      return (
        <Node {...props} style={style}>
          {props.child.keys?.map((key) => {
            return (
              <ComponentTag key={key} {...getChildProps(props, key)} {...(props.draft === true && { draft: true })} />
            );
          })}
        </Node>
      );
    }
  }
}
