const options = {
  center: "lg:items-center",
  end: "lg:items-end",
  start: "lg:items-start",
  stretch: "lg:items-stretch",
  baseline: "lg:items-baseline",
};

export default function getAlignItemsLg(align) {
  return options[align];
}
