const values = {
  center: "justify-self-center",
  end: "justify-self-end",
  start: "justify-self-start",
  stretch: "justify-self-stretch",
  auto: "justify-self-auto",
};

export default function getJustifySelf(align) {
  return values[align];
}
