"use client";

import { PROMO_TYPE_FLAT, PROMO_TYPE_PERCENT } from "@easybiz/utils";
import LocaleCurrency from "../LocaleCurrency";

function FlatPercentageDisplay({ type, value, prefix }) {
  switch (type) {
    case PROMO_TYPE_PERCENT:
      return `${prefix || ""}${value}%`;
    case PROMO_TYPE_FLAT:
      return (
        <>
          {prefix || ""}
          <LocaleCurrency value={value} />
        </>
      );
    default:
      return null;
  }
}

export default FlatPercentageDisplay;
