import { CreditCardOutlined, EditOutlined, RollbackOutlined } from "@ant-design/icons";
import { useOrderPaymentPanel } from "@easybiz/component";
import { useCheckoutEditOrderSetter, useClientStateSetter } from "@easybiz/shell";
import { Alert, Button, Card, Col, Row, Space } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import OrderPayableStatusList from "../OrderPayableStatusList";
import OrderPaymentIntentEditDialog from "../OrderPaymentIntentEditDialog";
import OrderPricingList from "../OrderPricingList";
import PaymentDrawer from "../PaymentDrawer";
import RefundDialog from "../RefundDialog";
import SalesReturnButton from "../SalesReturnButton";
import CopyPaymentLinkButton from "./CopyPaymentLinkButton";

export default function OrderPaymentPanel({ cashDrawer, orderId, order, placement, permissions }) {
  const { depositAlert, totalBalance, overPaidAmount, paymentLink } = useOrderPaymentPanel(order);
  const setEditOrder = useCheckoutEditOrderSetter();
  const setState = useClientStateSetter();
  const [paymentVisible, setPaymentVisible] = useState(false);
  const [depositVisible, setDepositVisible] = useState(false);
  const [refundVisible, setRefundVisible] = useState();
  const [refundPayment, setRefundPayment] = useState();
  const [paymentIntentVisible, setPaymentIntentVisible] = useState(false);

  return (
    <>
      <Space direction="vertical" className="full-width" size={"middle"}>
        {depositAlert && (
          <Alert
            banner
            type="info"
            message={depositAlert.title}
            description={depositAlert.description}
            action={
              <Button
                icon={<CreditCardOutlined />}
                disabled={!permissions?.canPayment}
                type="primary"
                onClick={() => setDepositVisible(true)}
              >
                <FormattedMessage tagName={"span"} defaultMessage="Pay deposit" />
              </Button>
            }
          />
        )}
        <Card bodyStyle={{ padding: 8 }}>
          <OrderPayableStatusList
            order={order}
            canCancelPayment={permissions?.canCancelPayment}
            onRefund={setRefundPayment}
            onEditPaymentIntent={() => setPaymentIntentVisible(true)}
          />
        </Card>
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              {paymentLink && (
                <Col span={24}>
                  <CopyPaymentLinkButton order={order} />
                </Col>
              )}
              {((permissions?.canRefundOverpaid && overPaidAmount) || permissions?.canRefund) && (
                <Col span={24}>
                  <Button
                    type={overPaidAmount ? "primary" : "link"}
                    ghost
                    block
                    danger={!overPaidAmount}
                    icon={<RollbackOutlined />}
                    onClick={() => setRefundVisible(true)}
                  >
                    {overPaidAmount ? (
                      <FormattedMessage tagName={"span"} defaultMessage="Refund overpaid" />
                    ) : (
                      <FormattedMessage tagName={"span"} defaultMessage={"Refund"} />
                    )}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={12}>
            {totalBalance && (
              <Button
                icon={<CreditCardOutlined />}
                type="primary"
                block
                onClick={() => setPaymentVisible(true)}
                disabled={!permissions?.canPayment}
              >
                <FormattedMessage tagName={"span"} defaultMessage="Make payment" />
              </Button>
            )}
          </Col>
        </Row>
        <Card title={<FormattedMessage defaultMessage={"Line items detail"} />} bodyStyle={{ padding: 8 }}>
          <OrderPricingList pricing={order?.data()} />
        </Card>
        <Row gutter={16}>
          <Col span={12}>
            <SalesReturnButton placement={placement} order={order} disabled={!permissions?.canSalesReturn} />
          </Col>
          <Col span={12}>
            <Button
              block
              disabled={!permissions?.canEdit}
              icon={<EditOutlined />}
              onClick={() => {
                setEditOrder(order);
                setState({ displayMode: null, openOrderId: null });
              }}
            >
              <FormattedMessage tagName={"span"} defaultMessage={"Edit line items"} />
            </Button>
          </Col>
        </Row>
      </Space>
      <br />
      <PaymentDrawer
        placement={placement}
        orderId={orderId}
        customer={order?.get("customer")}
        fullAmount={depositVisible ? order?.get("depositAmount") : totalBalance}
        isDeposit={depositVisible}
        enablePartial={!depositVisible}
        open={depositVisible || paymentVisible}
        onCancel={() => {
          setPaymentVisible(false);
          setDepositVisible(false);
        }}
        onSucceed={() => {
          setPaymentVisible(false);
          setDepositVisible(false);
        }}
      />
      <RefundDialog
        cashDrawer={cashDrawer}
        orderId={orderId}
        open={refundVisible || Boolean(refundPayment)}
        payment={refundPayment}
        amount={overPaidAmount}
        onClose={() => {
          setRefundVisible(false);
          setRefundPayment(null);
        }}
      />
      <OrderPaymentIntentEditDialog
        order={order}
        open={paymentIntentVisible}
        onCancel={() => setPaymentIntentVisible(false)}
      />
    </>
  );
}
