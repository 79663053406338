import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_POS, POS_ACTION_CASH_DRAWER_CASH_IN_OUT } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function usePOSCashDrawerCashInOut(callback) {
  const intl = useIntl();

  const onSubmit = useCallback(({ note, amount }) => {
    if (typeof amount !== "number") {
      return intl.formatMessage({
        defaultMessage: "Please enter cash in/out amount",
      });
    }
    if (amount === 0) {
      return intl.formatMessage({
        defaultMessage: "Cash in/out amount can not be 0",
      });
    }
    if (!note) {
      return intl.formatMessage({
        defaultMessage: "Please leave a note",
      });
    }

    return [
      OPERATION_GROUP_POS,
      POS_ACTION_CASH_DRAWER_CASH_IN_OUT,
      {
        amount,
        note,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, (request) =>
    request.amount > 0
      ? intl.formatMessage({ defaultMessage: "Cash in succeed" })
      : intl.formatMessage({ defaultMessage: "Cash out succeed" })
  );
}

export default usePOSCashDrawerCashInOut;
