const options = {
  normal: "md:justify-normal",
  center: "md:justify-center",
  end: "md:justify-end",
  start: "md:justify-start",
  between: "md:justify-between",
  around: "md:justify-around",
  evenly: "md:justify-evenly",
};

export default function getJustifyItemsMd(justify) {
  return options[justify];
}
