"use client";

import { createContext, useContext } from "react";

const PriceListContext = createContext();

export function PriceListProvider({ pricingData, children }) {
  return <PriceListContext.Provider value={pricingData}>{children}</PriceListContext.Provider>;
}

export const usePriceListProducts = () => useContext(PriceListContext).products;
export const usePriceListCategories = () => useContext(PriceListContext).categories;
export const usePriceListProduct = (productId) => {
  const { categories, products } = useContext(PriceListContext);

  if (Array.isArray(categories)) {
    for (const category of categories) {
      const product = category.products?.find((product) => product.key === productId);
      if (product) {
        return product;
      }
    }
  } else {
    return products?.find((product) => product.key === productId);
  }
};
export const usePriceListProductServiceTypes = (rawPricingData, productId) => {
  const { serviceTypes } = useContext(PriceListContext);
  const product = rawPricingData?.products?.find((product) => product.id === productId);

  return Array.isArray(product?.serviceTypes) && product?.serviceTypes.length > 0
    ? product?.serviceTypes
    : serviceTypes;
};
