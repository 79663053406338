"use client";

import { useUserRealmId } from "@easybiz/shell";
import {
  collection,
  endBefore,
  getFirestore,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";

export default ({ customerId, startDate, endDate, startAfter: startDoc, endBefore: endDoc, limit: limitCount }) => {
  const realmId = useUserRealmId();
  const [records, setRecords] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRecords(null);
    if (realmId) {
      let operation = query(collection(getFirestore(), `realms/${realmId}/points_records`));

      if (customerId) {
        operation = query(operation, where("customer.id", "==", customerId));
      }

      if (startDate && endDate) {
        operation = query(
          operation,
          where("createDate.date", ">=", startDate),
          where("createDate.date", "<=", endDate),
          orderBy("createDate.date", "desc"),
          orderBy("createDate.raw", "desc")
        );
      } else {
        operation = query(operation, orderBy("createDate", "desc"));
      }

      if (startDoc) {
        operation = query(operation, startAfter(startDoc), limit(limitCount || 10));
      } else if (endDoc) {
        operation = query(operation, endBefore(endDoc), limitToLast(limitCount || 10));
      } else {
        operation = query(operation, limit(limitCount || 10));
      }

      setLoading(true);
      return onSnapshot(operation, (result) => {
        setRecords(result.docs);
        setLoading(false);
      });
    }
  }, [realmId, customerId, startDate, endDate, startDoc, endDoc, limitCount]);

  return [records, loading];
};
