import { getBackgroundColor, getColorParams100, getRingColor, getShadowColor } from "@easybiz/utils";
import clsx from "clsx";

export default function BackgroundShadowSlashLeft({ bgColor, theme }) {
  return (
    <>
      <div
        className={`absolute left-1/2 top-0 pointer-events-none h-[50rem] w-[90rem] -translate-x-1/2 bg-[radial-gradient(50%_100%_at_top,theme(${getColorParams100(
          theme
        )}),white)] opacity-20 lg:left-36`}
      />
      <div
        className={clsx(
          "absolute inset-y-0 right-1/2 pointer-events-none mr-12 w-[150vw] origin-bottom-left skew-x-[-30deg] shadow-xl ring-1 sm:mr-20 md:mr-0 lg:right-full lg:-mr-36 lg:origin-center",
          getBackgroundColor(bgColor, theme),
          getRingColor({ ...theme, value: 50 }),
          getShadowColor(theme),
        )}
      />
    </>
  );
}
