// TODO: DELETE ALL OLD EMAIL TEMPLATES (SENDGRID)
export const EMAIL_TEMPLATE_OTP = `otp`;
export const EMAIL_TEMPLATE_GENERAL = `general`;
export const EMAIL_TEMPLATE_PLATFORM_WELCOME = `verification`;
export const EMAIL_TEMPLATE_FORGOT_PASSWORD = `forgot_password`;
export const EMAIL_TEMPLATE_SIGN_IN_ALERT = `sign_in_alert`;
export const EMAIL_TEMPLATE_REALM_DELETED = `realm_deleted`;
export const EMAIL_TEMPLATE_FORM_SUBMIT_ACK = `form_submit_ack`;
export const EMAIL_TEMPLATE_RECEIPT = `receipt`;

export const EMAIL_STATUS_ERROR = "error";
export const EMAIL_STATUS_PROCESSED = "processed";
export const EMAIL_STATUS_DROPPED = "dropped";
export const EMAIL_STATUS_DEFERRED = "deferred";
export const EMAIL_STATUS_BOUNCE = "bounce";
export const EMAIL_STATUS_SPAM = "spam report";
export const EMAIL_STATUS_DELIVERED = "delivered";
export const EMAIL_STATUS_OPEN = "open";
export const EMAIL_STATUS_CLICK = "click";
export const EMAIL_STATUS_SENT = "sent";

export const EMAIL_COMPONENT_BODY = "body";
export const EMAIL_COMPONENT_HEAD = "head";
export const EMAIL_COMPONENT_BUTTON = "button";
export const EMAIL_COMPONENT_CONTAINER = "container";
export const EMAIL_COMPONENT_CODE_BLOCK = "code_block";
export const EMAIL_COMPONENT_CODE_INLINE = "code_inline";
export const EMAIL_COMPONENT_FONT = "font";
export const EMAIL_COMPONENT_HR = "hr";
export const EMAIL_COMPONENT_IMAGE = "image";
export const EMAIL_COMPONENT_LINK = "link";
export const EMAIL_COMPONENT_PREVIEW = "preview";
export const EMAIL_COMPONENT_TAILWIND = "tailwind";
export const EMAIL_COMPONENT_TEXT = "text";
export const EMAIL_COMPONENT_UNSUBSCRIBE = "unsubscribe";
export const EMAIL_COMPONENT_UNSUBSCRIBE_PREFERENCES = "unsubscribe_preferences";

export const EMAIL_GROUP_RECEIPT = "receipt";
export const EMAIL_GROUP_PROMOTION = "promotion";
export const EMAIL_GROUP_ORDER_REMINDER = "order_reminder";
export const EMAIL_GROUP_FEEDBACK = "feedback";

// B2C
export const EMAIL_TYPE_ORDER_STATUS_UPDATES = "order_status_updates";
export const EMAIL_TYPE_CUSTOMER_FEEDBACK_REQUESTS = "customer_feedback_requests";
export const EMAIL_TYPE_PROMOTIONAL = "promotional";
export const EMAIL_TYPE_NEW_PRODUCT_SERVICES = "new_product_services";
export const EMAIL_TYPE_LOCAL_EVENTS = "local_events";
export const EMAIL_TYPE_COMPANY_NEWS = "company_news";
// B2B
export const EMAIL_TYPE_PRODUCT_UPDATES = "product_updates";
export const EMAIL_TYPE_EDUCATIONAL = "educational";
export const EMAIL_TYPE_TECHNICAL_MAINTENANCE = "technical_maintenance";
export const EMAIL_TYPE_NEWSLETTER = "newsletter";

export const FORM_AUTO_REPLY_SUBJECT = "Thank you for contacting us";
export const FORM_AUTO_REPLY_BODY = `Hi {{firstName}},

Thank you for contacting {{brandName}}. We have received your inquiry and will get back to you as soon as possible.

We’ll be in touch.

The {{brandName}} Team
`;
