import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast } from "@easybiz/shell";
import { CUSTOMER_ACTION_ADDRESS_EDIT, OPERATION_GROUP_CUSTOMER } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function useCustomerAddressEdit(callback) {
  const intl = useIntl();
  const toast = useFeedbackToast();

  const onSubmit = useCallback(({ customerId, address, addressId, deleted, isDefault }, requireLine2) => {
    if (!address && !deleted)
      return intl.formatMessage({
        id: "please.search.and.select.address",
        defaultMessage: `Please search and select address`,
      });
    if (requireLine2 && !address.unitNo)
      return intl.formatMessage({
        id: "please.enter.your.address.line.2",
        defaultMessage: "Please enter your address line 2",
      });

    return [
      OPERATION_GROUP_CUSTOMER,
      CUSTOMER_ACTION_ADDRESS_EDIT,
      {
        customerId,
        ...(addressId && { addressId }),
        ...(address && { address }),
        ...(deleted && { deleted: true }),
        ...(isDefault && { isDefault: true }),
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, (request) => {
    if (request.deleted) {
      toast.success(
        intl.formatMessage({
          id: "address.deleted",
          defaultMessage: "Address deleted",
        })
      );
    } else if (request.addressId) {
      toast.success(
        intl.formatMessage({
          id: "address.updated",
          defaultMessage: "Address updated",
        })
      );
    } else {
      toast.success(
        intl.formatMessage({
          id: "address.added",
          defaultMessage: "Address added",
        })
      );
    }
  });
}

export default useCustomerAddressEdit;
