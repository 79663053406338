const values = {
  center: "self-center",
  end: "self-end",
  start: "self-start",
  none: "self-auto",
  // ESC/POS Support
  left: "self-start",
  right: "self-end",
};

export default function getAlignSelf(align) {
  return values[align];
}
