const options = {
  5: "bg-neutral-500/5",
  10: "bg-neutral-500/10",
  25: "bg-neutral-500/25",
  50: "bg-neutral-500/50",
  75: "bg-neutral-500/75",
  90: "bg-neutral-500/90",
  100: "bg-neutral-500",
};

const from = {
  5: "from-neutral-500/5",
  10: "from-neutral-500/10",
  25: "from-neutral-500/25",
  50: "from-neutral-500/50",
  75: "from-neutral-500/75",
  90: "from-neutral-500/90",
  100: "from-neutral-500",
};

export default function getBackgroundNeutral500(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
