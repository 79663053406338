import { COMPONENT_TEXT } from "./constants/template.js";
import formatMMPaddings from "./formatMMPaddings.js";

function getLetterSpacing(fontSizeMM) {
  switch (fontSizeMM) {
    case 1.5:
      return "2.8px";
    case 3:
      return "1.55px";
    case 4:
      return "4.9px";
    case 5:
      return "4.2px";
    case 6:
      return "7.2px";
    case 7:
      return "5.5px";
    case 8:
      return "10.5px";
    case 10:
      return "7.3px";
    case 12:
      return "12.5px";
  }
}

export default function getLabelBlockStyle({ id, type, attrs, context }, nativeEnv) {
  const { fontSizeMM, borderWidth } = attrs || {};
  const dotsPerMM = Number.isInteger(context?.dotsPerMM) ? context.dotsPerMM : 8;
  const fontSize = fontSizeMM * dotsPerMM;
  const position = context?.positions?.find((block) => block.id === id);

  return {
    ...(!context?.previewMode && {
      position: "absolute",
    }),
    ...(type === COMPONENT_TEXT && {
      letterSpacing: context?.language ? 0 : getLetterSpacing(fontSizeMM),
      fontSize: nativeEnv ? fontSize : `${fontSize}px`,
      lineHeight: nativeEnv ? fontSize : `${fontSize}px`,
    }),
    ...(position && {
      top: position.top,
      left: position.left,
      width: position.width,
      height: position.height === 0 ? undefined : position.height,
    }),
    ...(borderWidth && {
      outlineWidth: borderWidth,
      outlineStyle: "solid",
      outlineColor: "#000",
    }),
    ...formatMMPaddings(attrs, dotsPerMM),
    ...(attrs?.rotation &&
      position && {
        transform: `translateX(${position.height}px) rotate(${attrs.rotation}deg)`,
        transformOrigin: "top left",
      }),
  };
}
