"use client";

import dayjs from "dayjs";
import { createContext, useContext, useMemo } from "react";
import { useClientType } from "./ClientContext";

const LicenseContext = createContext();

export function LicenseProvider({ children, license }) {
  const client = useClientType();

  const context = useMemo(() => {
    return {
      license,
      expired: license?.valid == false,
      clientAuthDeviceId: license?.[client]?.id,
      clientAuthTime: license?.[client]?.authTime,
      multiStation: license?.multiStation,
      closeOfDay: license?.closeOfDay,
      ...(license?.trialing && {
        trialEndTimestamp: license.expireUTCTimetamp,
      }),
      ...(license?.valid &&
        license.expired && {
          expiringTimestamp: dayjs
            .unix(license.expireUTCTimetamp)
            .add(license.warningPeriod || 0, "day")
            .unix(),
        }),
    };
  }, [license, client]);

  return <LicenseContext.Provider value={context}>{children}</LicenseContext.Provider>;
}

export const useLicense = () => useContext(LicenseContext).license;
export const useLicenseExpired = () => useContext(LicenseContext).expired;
export const useLicenseExpiringTimestamp = () => useContext(LicenseContext).expiringTimestamp;
export const useLicenseTrailEndTimestamp = () => useContext(LicenseContext).trialEndTimestamp;
export const useLicenseClientAuthTimestamp = () => useContext(LicenseContext).clientAuthTime;
export const useLicenseClientAuthDeviceId = () => useContext(LicenseContext).clientAuthDeviceId;
export const useLicenseCloseOfDay = () => useContext(LicenseContext).closeOfDay;
