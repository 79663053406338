import { PlusOutlined } from "@ant-design/icons";
import { useStandardLineItemSelectPanel } from "@easybiz/checkout";
import { Button, Divider, Empty, List, Space } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import LaundryLineItemEditor from "../LaundryLineItemEditor";
import ProductBadgeImage from "../ProductBadgeImage";
import ProductSubTypeSelectPanel from "../ProductSubTypeSelectPanel";
import Title from "../Title";

export default function SubTypeProductSelectPanel({ productId, subTypeId, selected, onClose, onAddOther }) {
  const { select, subTypes, setSelectSubTypeId, prices } = useStandardLineItemSelectPanel(
    productId,
    subTypeId,
    selected
  );

  return (
    <div className="flex flex-fill scroll-container">
      <div className="flex flex-row flex-space-between flex-align-center">
        <Title>
          <FormattedMessage defaultMessage={"Add to Order"} />
        </Title>
        <Button onClick={onClose} style={{ minWidth: 120 }}>
          <FormattedMessage tagName="span" defaultMessage="Close" />
        </Button>
      </div>
      <Divider style={{ margin: "8px 0px" }} />
      <div className={`flex flex-fill scroll-body${subTypes ? `` : " padding-top-half"}`}>
        {subTypes && (
          <ProductSubTypeSelectPanel subTypes={subTypes} select={select?.id} onSelect={setSelectSubTypeId} />
        )}
        <Space>
          <ProductBadgeImage width={50} photoId={select?.photoId} count={select?.qty} />
          <Title fontSize={22}>{select?.title}</Title>
        </Space>
        <List
          rowKey={(item) =>
            Number.isInteger(item.itemIndex) ? `${item.itemIndex}` : `${item.optionId}${item.unitPrice}`
          }
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<FormattedMessage defaultMessage={"No preset pricing options"} />}
              >
                <Button icon={<PlusOutlined />} onClick={() => onAddOther({ productId, product: select?.title })}>
                  <FormattedMessage tagName={"span"} defaultMessage={"Add"} />
                </Button>
              </Empty>
            ),
          }}
          dataSource={prices}
          renderItem={(item) => {
            return <LaundryLineItemEditor item={item} />;
          }}
        />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
