const values = {
  none: "lg:rounded-none",
  md: "lg:rounded-md",
  lg: "lg:rounded-lg",
  xl: "lg:rounded-xl",
  "2xl": "lg:rounded-2xl",
  "3xl": "lg:rounded-3xl",
  full: "lg:rounded-full",
};

export default function getRoundedLg(value) {
  return values[value];
}
