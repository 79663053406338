import clsx from "clsx";

function Wave({ fill, rotate }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 381 52"
      xmlSpace="preserve"
      className={clsx("h-5 flex-shrink-0 -ml-px", rotate ? "transform rotate-180" : "")}
    >
      <path fill={fill} d="M381.5,52V-0.2C290.6-0.2, 281, 42, 181.3,42S85.9-0.2-0.3-0.2V52H381.5z" />
    </svg>
  );
}

export default function Pattern({ className, fill }) {
  const rotate = className?.includes("top-full") || className?.includes("top-0");

  return (
    <div className={clsx(className, "overflow-x-clip flex pointer-events-none z-10")}>
      {Array.from({ length: 10 }).map((_, i) => (
        <Wave key={i} fill={fill} rotate={rotate} />
      ))}
    </div>
  );
}
