import { HistoryOutlined } from "@ant-design/icons";
import { DateDisplay, NameDisplay } from "@easybiz/component";
import { useClientStateSetter, useUserBusinessCode, useUserRealmId } from "@easybiz/shell";
import {
  ACTION_STORE_OUT,
  PROCEDURE_STORE_CHECK_IN,
  PROCEDURE_STORE_CHECK_OUT,
  STATUSES_KEY_ORDER_STATE,
} from "@easybiz/utils";
import { IconButton, ReceiptView } from "@easybiz/web-admin";
import { Drawer, List, Tabs } from "antd";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import HandoverButton from "./HandoverButton";
import HandoverHistoryDrawer from "./HandoverHistoryDrawer";
import HandoverPanel from "./HandoverPanel";
import PanelHeader from "./PanelHeader";

function sortOrders(orders) {
  orders.sort((a, b) => `${b.no}`.localeCompare(a.no));

  return orders.some((order) => order.processed)
    ? [...orders.filter((order) => !order.processed), ...orders.filter((order) => order.processed)]
    : orders;
}

export default ({ displayMode, onChange, isCheckOut, onClose }) => {
  const [historyVisible, setHistoryVisible] = useState(false);
  const [receiptLiens, setReceiptLines] = useState();
  const [orders, setOrders] = useState();
  const realmId = useUserRealmId();
  const businessCode = useUserBusinessCode();
  const setState = useClientStateSetter();
  const dateString = displayMode.date?.format("YYYY-MM-DD");
  const selects = displayMode?.selects;

  useEffect(() => {
    if (realmId && businessCode && dateString) {
      if (isCheckOut) {
        return onSnapshot(
          doc(
            getFirestore(),
            `realms/${realmId}/statuses/${STATUSES_KEY_ORDER_STATE}/keys/${dateString}-${businessCode}`
          ),
          (snapshot) => {
            setOrders(
              sortOrders(
                Object.keys(snapshot.get("data") || {}).map((id) => ({
                  ...snapshot.get(`data.${id}`),
                  processed: !snapshot.get(`data.${id}.awaits`)?.includes(ACTION_STORE_OUT),
                  id,
                }))
              )
            );
          }
        );
      } else {
        return onSnapshot(
          doc(getFirestore(), `realms/${realmId}/statuses/collect_${businessCode}/keys/${dateString}`),
          (snapshot) => {
            setOrders(
              sortOrders(
                Object.keys(snapshot.get("data") || {}).map((id) => {
                  const { no, bu, c, w, b, r } = snapshot.get(`data.${id}`) || {};

                  return {
                    id,
                    no,
                    bu,
                    pcs: c + w,
                    businessCode: b,
                    processed: r || (c > 0 && w === 0),
                  };
                })
              )
            );
          }
        );
      }
    }
  }, [realmId, businessCode, dateString, isCheckOut]);

  const orderServices = (orders?.map((order) => order.bu) || []).filter(
    (id, index, self) => self.indexOf(id) === index
  );

  return (
    <div className="flex flex-fill scroll-container">
      <PanelHeader
        title={
          <span>
            {isCheckOut ? (
              <FormattedMessage id="store.checkout" defaultMessage={"Store Check Out"} />
            ) : (
              <FormattedMessage id="store.checkin" defaultMessage={"Store Check In"} />
            )}
            {` `}(
            {dateString &&
              (isCheckOut ? (
                <FormattedMessage
                  defaultMessage={"Received: {date}"}
                  values={{
                    date: <DateDisplay date={dateString} hideYear />,
                  }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage={"Collection: {date}"}
                  values={{
                    date: <DateDisplay date={dateString} hideYear />,
                  }}
                />
              ))}
            )
          </span>
        }
        onClose={onClose}
      />
      {orders && orders.length > 0 ? (
        <Tabs
          tabBarStyle={{ margin: 0 }}
          type="card"
          tabBarExtraContent={
            <div
              className="padding-horizontal-half flex flex-row"
              style={{
                gap: 8,
                paddingBottom: 8,
              }}
            >
              <IconButton icon={<HistoryOutlined />} onClick={() => setHistoryVisible(true)} />
              <HandoverButton
                selects={selects}
                procedure={isCheckOut ? PROCEDURE_STORE_CHECK_OUT : PROCEDURE_STORE_CHECK_IN}
                onComplete={(receipeLines) => {
                  onChange({ ...displayMode, selects: null });
                  setReceiptLines(receipeLines);
                }}
              />
            </div>
          }
        >
          {orderServices.map((serviceId) => {
            const serviceOrders = orders?.filter((order) => order.bu === serviceId) || [];

            return (
              <Tabs.TabPane
                key={serviceId}
                tab={
                  <span>
                    <NameDisplay nameKey={serviceId} /> ({serviceOrders?.length || 0})
                  </span>
                }
              >
                <HandoverPanel
                  serviceId={serviceId}
                  orders={serviceOrders}
                  selectOrderIds={selects?.map((select) => select.id)}
                  isCheckOut={isCheckOut}
                  onComplete={setReceiptLines}
                  onSelect={(order) => setState({ openOrderId: order.id })}
                  onSelectChange={(selectIds) => {
                    onChange({
                      ...displayMode,
                      selects: [
                        ...(displayMode.selects || []).filter(
                          (select) => !serviceOrders.some((order) => order.id === select.id)
                        ),
                        ...selectIds.map((id) => ({ id, date: dateString })),
                      ],
                    });
                  }}
                />
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      ) : (
        <List loading={!orders} dataSource={[]} />
      )}
      <HandoverHistoryDrawer isCheckOut={isCheckOut} open={historyVisible} onClose={() => setHistoryVisible(false)} />
      <Drawer
        width={470}
        placement="right"
        title={<FormattedMessage tagName="span" defaultMessage="Handover Receipt" />}
        open={Boolean(receiptLiens)}
        onClose={() => setReceiptLines(null)}
      >
        {receiptLiens && <ReceiptView lines={receiptLiens} printing />}
      </Drawer>
    </div>
  );
};
