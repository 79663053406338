import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_POS, POS_ACTION_CLOSE_OF_DAY_PREVIEW } from "@easybiz/utils";
import { useCallback } from "react";

function usePOSCloseOfDayPreview(callback) {
  const onSubmit = useCallback(({ date }) => {
    return [
      OPERATION_GROUP_POS,
      POS_ACTION_CLOSE_OF_DAY_PREVIEW,
      {
        date,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback);
}

export default usePOSCloseOfDayPreview;
