"use client";

import { useUserRealmId } from "@easybiz/shell";
import { formatAddon } from "@easybiz/utils";
import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (serviceId, formatData) => {
  const realmId = useUserRealmId();
  const [addons, setAddons] = useState(null);

  useEffect(() => {
    setAddons(null);
    if (realmId && serviceId) {
      return onSnapshot(
        query(collection(getFirestore(), `realms/${realmId}/services/${serviceId}/addons`), orderBy("sortIndex")),
        (result) => setAddons(formatData ? result.docs.map(formatAddon) : result.docs)
      );
    }
  }, [realmId, serviceId]);

  return addons;
};
