"use client";

import { collection, doc, getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";

export default (realmId, file, metadata, photoId, onProgress) => {
  const uploadPhotoId = photoId || doc(collection(getFirestore(), `photos`)).id;
  const fileRef = ref(getStorage(), `${uploadPhotoId}`);
  const customMetadata = {
    ...metadata,
    ...(realmId && { realmId }),
    photoId: uploadPhotoId,
    newUpload: "yes",
  };

  if (onProgress) {
    const task = uploadBytesResumable(fileRef, file, {
      customMetadata,
    });

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    task.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        onProgress(null, parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
      },
      (error) => {
        // Handle unsuccessful uploads
        onProgress(null, null, error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        onProgress(uploadPhotoId, 100);
      }
    );
  } else {
    return uploadBytes(fileRef, file, customMetadata).then(() => uploadPhotoId);
  }
};
