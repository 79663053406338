const options = {
  visible: "lg:overflow-x-visible",
  hidden: "lg:overflow-x-hidden",
  auto: "lg:overflow-x-auto",
  clip: "lg:overflow-x-clip",
};

export default function getOverflowXLg(value) {
  return options[value];
}
