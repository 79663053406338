export default function getMarginLeft(value) {
  switch (value) {
    case -44:
      return "-ml-44";
    case -40:
      return "-ml-40";
    case -36:
      return "-ml-36";
    case -32:
      return "-ml-32";
    case -28:
      return "-ml-28";
    case -24:
      return "-ml-24";
    case -20:
      return "-ml-20";
    case -16:
      return "-ml-16";
    case -12:
      return "-ml-12";
    case -8:
      return "-ml-8";
    case -4:
      return "-ml-4";
    case -3:
      return "-ml-3";
    case -2:
      return "-ml-2";
    case -1:
      return "-ml-1";
    case 0:
      return "ml-0";
    case 1:
      return "ml-1";
    case 2:
      return "ml-2";
    case 3:
      return "ml-3";
    case 4:
      return "ml-4";
    case 5:
      return "ml-5";
    case 6:
      return "ml-6";
    case 7:
      return "ml-7";
    case 8:
      return "ml-8";
    case 9:
      return "ml-9";
    case 10:
      return "ml-10";
    case 12:
      return "ml-12";
    case 14:
      return "ml-14";
    case 16:
      return "ml-16";
    case 20:
      return "ml-20";
    case 24:
      return "ml-24";
    default:
      return "";
  }
}
