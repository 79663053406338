const values = {
  center: "xl:self-center",
  end: "xl:self-end",
  start: "xl:self-start",
  none: "xl:self-auto",
};

export default function getAlignSelfXl(align) {
  return values[align];
}
