export default function textToImageData(lines, canvasWidth, canvasHeight, fontSize = 26, fontWeight) {
  // Create canvas
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Set canvas size
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  // Set font and text settings
  context.font = `${
    fontWeight ? `${fontWeight} ` : ""
  }${fontSize}px ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`;
  context.textBaseline = "top";
  context.fillStyle = "white";
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.fillStyle = "black";

  // Draw text
  let currentY = 0;
  const lineHeight = fontSize;
  lines.forEach((line) => {
    context.fillText(line, 0, currentY);
    currentY += lineHeight;
  });

  // Get image data
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

  return imageData;
}
