import {
  PROCEDURE_DISCHARGED,
  PROCEDURE_DRIVER_COLLECTED,
  PROCEDURE_PENDING_COLLECTION,
  PROCEDURE_PLANT_CHECK_IN,
  PROCEDURE_PLANT_CHECK_OUT,
  PROCEDURE_PLANT_CONFIRM_ORDER,
  PROCEDURE_PLANT_LABELING,
  PROCEDURE_PLANT_PACKING,
  PROCEDURE_PLANT_SERVICE_COMPLETE,
  PROCEDURE_PLANT_SERVICING,
  PROCEDURE_PRODUCTION_CALENDAR,
  PROCEDURE_STORAGE,
  PROCEDURE_STORE_CHECK_IN,
  PROCEDURE_STORE_CHECK_OUT,
  PROCEDURE_STORE_RECEIVED,
} from "@easybiz/utils";
import { useIntl } from "react-intl";

function useProcedureTitle(procedure, isAgent, processing) {
  const intl = useIntl();

  switch (procedure) {
    case PROCEDURE_PENDING_COLLECTION:
      return intl.formatMessage({
        id: "pending.collect",
        defaultMessage: "Pending collect",
      });
    case PROCEDURE_DRIVER_COLLECTED:
      return intl.formatMessage({
        id: "collected",
        defaultMessage: "Collected",
      });
    case PROCEDURE_STORE_RECEIVED:
      if (processing) {
        return intl.formatMessage({
          id: "store.checkin",
          defaultMessage: "Store check in",
        });
      } else {
        return intl.formatMessage({
          id: "store.received",
          defaultMessage: "Store received",
        });
      }
    case PROCEDURE_PLANT_LABELING:
      if (processing) {
        return intl.formatMessage({
          id: "label",
          defaultMessage: "Label",
        });
      } else {
        return intl.formatMessage({
          id: "labeled",
          defaultMessage: "Labeled",
        });
      }
    case PROCEDURE_STORE_CHECK_OUT:
      if (processing) {
        return intl.formatMessage({
          id: "store.checkout",
          defaultMessage: "Store check out",
        });
      } else {
        return intl.formatMessage({
          id: "store.checkedout",
          defaultMessage: "Store checked out",
        });
      }
    case PROCEDURE_PLANT_CHECK_IN:
      return intl.formatMessage({
        id: "production.check.in",
        defaultMessage: "Production check in",
      });
    case PROCEDURE_PLANT_CONFIRM_ORDER:
      return intl.formatMessage({
        id: "confirm.order",
        defaultMessage: "Confirm order",
      });
    case PROCEDURE_PLANT_SERVICING:
      return intl.formatMessage({
        id: "servicing",
        defaultMessage: "Servicing",
      });
    case PROCEDURE_PLANT_SERVICE_COMPLETE:
      return intl.formatMessage({
        id: "service.complete",
        defaultMessage: "Service complete",
      });
    case PROCEDURE_PLANT_PACKING:
      return intl.formatMessage({
        id: "packing",
        defaultMessage: "Packing",
      });
    case PROCEDURE_PLANT_CHECK_OUT:
      return intl.formatMessage({
        id: "production.check.out",
        defaultMessage: "Production check out",
      });
    case PROCEDURE_STORE_CHECK_IN:
      return intl.formatMessage({
        id: "store.checkin",
        defaultMessage: "Store check in",
      });
    case PROCEDURE_DISCHARGED:
      if (processing) {
        return intl.formatMessage({
          id: "discharge",
          defaultMessage: "Discharge",
        });
      } else {
        return intl.formatMessage({
          id: "discharged",
          defaultMessage: "Discharged",
        });
      }
    case PROCEDURE_STORAGE:
      return intl.formatMessage({
        id: "storage",
        defaultMessage: "Storage",
      });
    case PROCEDURE_PRODUCTION_CALENDAR:
      return intl.formatMessage({
        id: "production.calendar",
        defaultMessage: "Production calendar",
      });
    default:
      return (
        procedure ||
        intl.formatMessage({
          id: "unknown",
          defaultMessage: "Unknown",
        })
      );
  }
}

export default useProcedureTitle;
