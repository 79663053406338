const options = {
  "bottom": "bg-bottom",
  "center": "bg-center",
  "left": "bg-left",
  "left-bottom": "bg-left-bottom",
  "left-top": "bg-left-top",
  "right": "bg-right",
  "right-bottom": "bg-right-bottom",
  "right-top": "bg-right-top",
  "top": "bg-top",
};

export default function getBgImagePosition(bgPosition) {
  return options[bgPosition];
}
