const values = {
  "-20": "-z-20",
  "-10": "-z-10",
  0: "z-0",
  10: "z-10",
  20: "z-20",
  30: "z-30",
  40: "z-40",
  50: "z-50",
};

export default function getZIndex(value) {
  return values[value];
}
