import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_REFRESH_RECEIPT } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useOrderReceiptRefresh(callback) {
  const intl = useIntl();

  return useActionCallback(
    ({ orderId }) => {
      return [
        OPERATION_GROUP_ORDER,
        ORDER_ACTION_REFRESH_RECEIPT,
        {
          orderId,
        },
      ];
    },
    callback,
    intl.formatMessage({
      id: "receipt.refreshed",
      defaultMessage: "Receipt refreshed",
    })
  );
}

export default useOrderReceiptRefresh;
