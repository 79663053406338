const options = {
  left: "lg:text-left",
  center: "lg:text-center",
  right: "lg:text-right",
  justify: "lg:text-justify",
};

export default function getTextAlignLg(align) {
  return options[align];
}
