import { useActionCallback } from "@easybiz/hook";
import { JOB_ACTION_FAILED, OPERATION_GROUP_JOB } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function useJobFailed(callback) {
  const intl = useIntl();

  const onSubmit = useCallback(({ taskId, note }) => {
    return [
      OPERATION_GROUP_JOB,
      JOB_ACTION_FAILED,
      {
        taskId,
        ...(note && { note }),
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Job failed" }));
}

export default useJobFailed;
