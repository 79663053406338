const values = {
  first: "sm:order-first",
  last: "sm:order-last",
  none: "sm:order-none",
  1: "sm:order-1",
  2: "sm:order-2",
  3: "sm:order-3",
  4: "sm:order-4",
  5: "sm:order-5",
  6: "sm:order-6",
  7: "sm:order-7",
  8: "sm:order-8",
  9: "sm:order-9",
  10: "sm:order-10",
  11: "sm:order-11",
  12: "sm:order-12",
};

export default function getOrderSm(order) {
  return values[order];
}
