import { CloseOutlined } from "@ant-design/icons";
import { useUpdateCheckoutItems } from "@easybiz/checkout";
import { UnitDisplay } from "@easybiz/component";
import { useCheckoutEditItemSetter, useCheckoutService, useUserRealmId } from "@easybiz/shell";
import { translate } from "@easybiz/utils";
import { Button, Card, Col, Form, Input, message, Row, Space } from "antd";
import { collection, doc, getFirestore } from "firebase/firestore";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../IconButton";
import PcsPerUnitEditorButton from "../PcsPerUnitEditorButton";
import ServiceTypeSelecter from "../ServiceTypeSelecter";
import UnitPriceButton from "../UnitPriceButton";
import UnitSelector from "../UnitSelector";

export default ({ initProduct, onClose }) => {
  const realmId = useUserRealmId();
  const service = useCheckoutService();
  const updateItems = useUpdateCheckoutItems();
  const setEidtItem = useCheckoutEditItemSetter();
  const [name, setName] = useState(initProduct?.product || "");
  const [unitInfo, setUnitInfo] = useState();
  const [price, setPrice] = useState(0);
  const [option, setOption] = useState();
  const intl = useIntl();

  const onAdd = () => {
    if (!initProduct?.productId) {
      if (!name || !`${name}`.trim())
        return message.info(intl.formatMessage({ defaultMessage: "Please enter item name" }));
      if (!unitInfo) return message.info(intl.formatMessage({ defaultMessage: "Please select unit label" }));
    }

    if (!option) return message.info(intl.formatMessage({ defaultMessage: "Please select service type" }));
    if (typeof price !== "number")
      return message.info(intl.formatMessage({ defaultMessage: "Please enter unit price" }));

    const newItem = {
      ...(initProduct?.productId
        ? initProduct
        : {
            ...unitInfo,
            productId: doc(collection(getFirestore(), `realms/${realmId}/products`)).id,
            product: `${name}`.trim(),
          }),
      optionId: option.id,
      option: translate(option.title),
      unitPrice: price,
      qty: 1,
    };

    const itemIndex = updateItems(newItem);
    if (Number.isInteger(itemIndex)) {
      setEidtItem({ ...newItem, itemIndex });
    }

    onClose();
  };

  return (
    <Card
      title={
        initProduct?.product ? (
          <FormattedMessage
            defaultMessage={"Add {productName}"}
            values={{ productName: translate(initProduct.product) }}
          />
        ) : (
          <FormattedMessage tagName="span" defaultMessage="Add Other Item" />
        )
      }
      extra={
        <Space>
          <Button type="primary" onClick={onAdd}>
            <FormattedMessage defaultMessage={"Add to order"} />
          </Button>
          <IconButton icon={<CloseOutlined />} onClick={onClose} />
        </Space>
      }
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label={<FormattedMessage tagName="span" defaultMessage="Name" />}>
              <Input
                placeholder={intl.formatMessage({
                  defaultMessage: "Describe your item",
                })}
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<FormattedMessage tagName="span" defaultMessage="Unit price" />}>
              <UnitPriceButton unit={unitInfo?.unit} unitPrice={price} onChange={setPrice} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Space>
              <Form.Item label={<FormattedMessage tagName="span" defaultMessage="Unit label" />}>
                <UnitSelector sector={service?.sector} value={unitInfo} onChange={setUnitInfo} radio autoSelect />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    <FormattedMessage tagName="span" defaultMessage="Pcs per unit" />{" "}
                    <b>
                      (<UnitDisplay unit={unitInfo?.unit} />)
                    </b>
                  </span>
                }
              >
                <PcsPerUnitEditorButton unitInfo={unitInfo} onChange={setUnitInfo} />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Form.Item label={<FormattedMessage tagName="span" defaultMessage="Service option" />}>
          <ServiceTypeSelecter option={option} onChange={setOption} />
        </Form.Item>
      </Form>
    </Card>
  );
};
