import calculateIncludedTax from "./calculateIncludedTax.js";

export default function getOrderTaxAmount(order) {
  if (order.priceList) {
    if (order.priceList.taxRate) {
      return calculateIncludedTax(order)?.amount;
    }
  } else if (typeof order.gstAmount === "number") {
    return order.gstAmount;
  }
}
