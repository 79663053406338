const options = {
  1: "xl:row-start-1",
  2: "xl:row-start-2",
  3: "xl:row-start-3",
  4: "xl:row-start-4",
};

export default function getGridRowStartXl(start) {
  return options[start];
}
