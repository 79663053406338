import { AccountBookOutlined } from "@ant-design/icons";
import { ReceiptView } from "@easybiz/web-admin";
import { usePOSTopUpReport } from "@easybiz/api-pos";
import { Button, Drawer } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default () => {
  const intl = useIntl();
  const [dateVisible, setDateVisible] = useState(false);
  const { loading, response, onSubmit } = usePOSTopUpReport();

  useEffect(() => {
    if (dateVisible) {
      onSubmit({
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      });
    }
  }, [dateVisible]);

  return (
    <>
      <Button size="large" block icon={<AccountBookOutlined />} onClick={() => setDateVisible(true)}>
        <FormattedMessage tagName="span" defaultMessage="Top up report" />
      </Button>
      <Drawer
        width={480}
        title={<FormattedMessage tagName="span" defaultMessage="Top up report" />}
        placement="right"
        open={dateVisible}
        onClose={() => setDateVisible(false)}
      >
        <ReceiptView
          lines={response?.lines}
          printing
          loading={
            loading
              ? {
                  tip: intl.formatMessage({
                    defaultMessage: `Generating top up report...`,
                  }),
                }
              : false
          }
        />
      </Drawer>
    </>
  );
};
