export const ERROR_400_BROWSER_NO_WEB_USB = "no_web_usb";

// TODO: Improve
export const ERROR_CODE_CLOSE_OF_DAY_DATA_CHANGED = "closed_of_day_data_changed";
export const ERROR_CODE_BROWSER_NOT_SUPPORT = "browser_not_support";

export const ERROR_CODE_SINGIN_OTHER_DEVICE = "signin_other_device";
export const ERROR_CODE_MULTI_STATION_WRONG_ADDRESS = "multi_station_wrong_address";
export const ERROR_CODE_PASSWORD_CHANGED = "password_changed";
export const ERROR_CODE_ACCOUNT_DELETED = "account_deleted";
export const ERROR_CODE_DEVICE_BLOCKED = "device_blocked";
export const ERROR_CODE_NOT_WHITELISTED = "not_whitelisted";
export const ERROR_CODE_LOGGED_OUT = "logged_out";
export const ERROR_CODE_MOCK_TIMEOUT = "mock_timeout";
export const ERROR_CODE_LICENSE_EXPIRED = "license_expired";
export const ERROR_CODE_USE_NATIVE_POS = "use_native_pos";

export const ERROR_CODE_PRINTER_NOT_PAIRED = "printer_not_paired";
export const ERROR_CODE_PRINTING_ERROR = "printing_error";
export const ERROR_CODE_PRINT_CONNECT_TIMEOUT = "print_connect_timeout";
export const ERROR_CODE_PRINT_TIMEOUT = "print_connect_timeout";

export const ERROR_CODE_RESCHEDULE_BLOCKED = "reschedule_blocked";
export const ERROR_CODE_RESCHEDULE_LIMITED = "reschedule_limited";
export const ERROR_CODE_SCHEDULE_NO_PICKUP = "schedule_no_pickup";
export const ERROR_CODE_ADDRESS_NOT_IN_SERVICE_ZONE = "address_not_in_service_zone";
export const ERROR_CODE_BOOK_SLOT_FULL = "book_slot_full";
export const ERROR_CODE_BOOKING_TIME_EXPIRED = "booking_time_expired";
export const ERROR_CODE_CARD_PENDING_PAYMENT = "pending_card_payment";

export const ERROR_CODE_PROMO_CODE_EXPIRED = "promo_code_expired";
export const ERROR_CODE_PROMO_CODE_WRONG_CODE = "promo_code_wrong_code";
export const ERROR_CODE_PROMO_CODE_ALREADY_USED = "promo_code_used";
export const ERROR_CODE_PROMO_CODE_APP_LIMITED = "promo_code_app_limited";
export const ERROR_CODE_PROMO_CODE_BUSINESS_LIMITED = "promo_code_business_limited";
export const ERROR_CODE_PROMO_CODE_TIMES_LIMITED = "promo_code_times_limited";
export const ERROR_CODE_FIRST_TIME_USE_ONLY = "first_time_use_only";

export const ERROR_CODE_STORE_APP_LIMITED = "store_app_limited";
export const ERROR_CODE_BUSINESS_LIMITED = "business_limited";
export const ERROR_CODE_USAGE_LIMITED = "usage_limited";
export const ERROR_CODE_BATCH_PAYMENT_OUTSTANDING_CHANGED = "batch_payment_outstanding_changed";

export const ERROR_ACCOUNT_NOT_FOUND = "account_not_found";
export const ERROR_PAYMENT_ALREADY_CANCELLED = "payment_already_cancelled";

// Device
export const ERROR_CODE_BLUETOOTH_NOT_ENABLED = "bluetooth_not_enabled";

export const ERROR_CODE_EMAIL_VERIFIED_WITH_OTHER_ACCOUNT = "email_binded_other_account";
export const ERROR_CODE_EMAIL_VERIFICATION_WRONG_ID = "email_verificaiton_wrong_id";
export const ERROR_CODE_EMAIL_VERIFICATION_EXPIRED = "email_verificaiton_expired";
export const ERROR_CODE_EMAIL_ALREADY_VERIFIED = "email_already_verified";
export const ERROR_CODE_EMAIL_ALREADY_REGISTERED = "email_already_registered";
export const ERROR_CODE_PHONE_VERIFIED_WITH_OTHER_ACCOUNT = "phone_binded_other_account";
export const ERROR_CODE_PHONE_ALREADY_EXISTS = "already_exists";
export const ERROR_CODE_INVALID_PHONE_NUMBER = "invalid_phone_number";
export const ERROR_CODE_SMS_DAILY_LIMIT_REACH = "sms_daily_limit_reach";
export const ERROR_CODE_SMS_SEND_INTERVAL_LIMTED = "sms_send_interval_limited";
export const ERROR_CODE_OTP_WRONG_OR_EXPIRED = "otp_wrong_or_expired";
export const ERROR_CODE_OTP_EXPIRED = "otp_expired";
export const ERROR_CODE_TIME_SLOT_EXPIRED = "time_slot_expired";

export const ERROR_CODE_INVALID_IMAGE_DATA = "invalid_image_data";
export const ERROR_CODE_ZONE_ID_ALREADY_IN_USE = "zone_id_already_in_use";
export const ERROR_CODE_DUPLICA_ZONE_TIME_SETTING = "duplicate_zone_time_setting";

export const ERROR_CODE_CANCELLATION_BLOCKED = "cancellation_blocked";
export const ERROR_CODE_CANCELLATION_LIMITED = "cancellation_limited";
export const ERROR_CODE_CANCELLATION_OTHER_STORE = "cancellation_other_store";
export const ERROR_CODE_CANCELLATION_ALREADY_COMPLETED = "cancellation_already_completed";

export const ERROR_CODE_NOT_REGISTERED = "not_registered";
export const ERROR_CODE_DELIVERY_LOADING_WRONG_ORDER = "delivery_loading_wrong_order";
export const ERROR_CODE_DUPLICATED_COLLECTION_POINT = "duplicated_collection_point";
export const ERROR_CODE_HANDOVER_BY_BAG = "handover_by_bag";
export const ERROR_CODE_NO_PENDING_HANDOVER = "no_pending_handover";

export const ERROR_CODE_ORDER_ALREADY_PAID = "order_already_paid";
export const ERROR_CODE_ORDER_PAYMENT_EXCEEDED = "order_payment_exceeded";

// Credit
export const ERROR_CODE_TOP_UP_CREDIT_CHANGED = "top_up_credit_changed";
export const ERROR_AUTH_INVALID_OTP = "auth/invalid-otp";
