const values = {
  "-32": "-top-32",
  "-12": "-top-12",
  "-11": "-top-11",
  "-10": "-top-10",
  "-9": "-top-9",
  "-8": "-top-8",
  "-7": "-top-7",
  "-6": "-top-6",
  "-5": "-top-5",
  "-4": "-top-4",
  "-3": "-top-3",
  "-2": "-top-2",
  "-1": "-top-1",
  0: "top-0",
  1: "top-1",
  2: "top-2",
  3: "top-3",
  4: "top-4",
  5: "top-5",
  6: "top-6",
  7: "top-7",
  8: "top-8",
  9: "top-9",
  10: "top-10",
  11: "top-11",
  12: "top-12",
  14: "top-14",
  16: "top-16",
  20: "top-20",
  "1/2": "top-1/2",
  "full": "top-full",
};

export default function getAbsoluteTop(value) {
  return values[value];
}
