const options = {
  center: "md:items-center",
  end: "md:items-end",
  start: "md:items-start",
  stretch: "md:items-stretch",
  baseline: "md:items-baseline",
};

export default function getAlignItemsMd(align) {
  return options[align];
}
