import { UsbOutlined, WifiOutlined } from "@ant-design/icons";
import { PrinterTypeDisplay } from "@easybiz/component";
import { useConnectedPrinter } from "@easybiz/hook";
import { useClientState, useClientStateSetter, usePrintingBluetoothWrite, usePrintingUsbWrite } from "@easybiz/shell";
import { PRINTER_IO_BLUETOOTH } from "@easybiz/utils";
import { getDeviceInfo } from "@easybiz/web-component";
import { Alert, Space, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import BluetoothPrinterConnectButton from "../BluetoothPrinterConnectButton";
import Dialog from "../Dialog";
import PrinterStatusBanner from "../PrinterStatusBanner";
import USBDeviceList from "../USBDeviceList";
import WebAPINotSupportAlert from "../WebAPINotSupportAlert";
import WebUSBInstallDrawerAlert from "../WebUSBInstallDrawerAlert";

export default function PrinterPairingDialog() {
  const { printerPairing } = useClientState();
  const setState = useClientStateSetter();
  const printerType = printerPairing?.printerType;
  const device = getDeviceInfo();
  const usbIO = usePrintingUsbWrite();
  const bluetoothIO = usePrintingBluetoothWrite();
  const printer = useConnectedPrinter(printerType);

  return (
    <Dialog
      zIndex={1001}
      title={<PrinterTypeDisplay printerType={printerType} />}
      open={Boolean(printerPairing)}
      onCancel={() => setState({ printerPairing: null })}
      onOk={() => setState({ printerPairing: null })}
      okType="default"
      okText={<FormattedMessage tagName={"span"} defaultMessage="Close" />}
    >
      <div className="padding-bottom">
        <PrinterStatusBanner printerType={printerType} />
      </div>
      <Tabs
        type="card"
        defaultActiveKey={printer?.io === PRINTER_IO_BLUETOOTH ? "bluetooth" : "usb"}
        items={[
          {
            key: "usb",
            label: (
              <span>
                <UsbOutlined /> <FormattedMessage defaultMessage={"USB"} /> (
                <FormattedMessage defaultMessage={"recommended"} />)
              </span>
            ),
            children: usbIO ? (
              <USBDeviceList printerType={printerType} commands={printerPairing?.commands} />
            ) : (
              <WebAPINotSupportAlert techName={<FormattedMessage defaultMessage="Web USB" />} />
            ),
          },
          {
            key: "bluetooth",
            label: (
              <span>
                <WifiOutlined /> <FormattedMessage defaultMessage={"Bluetooth"} />
              </span>
            ),
            children: bluetoothIO ? (
              <Space direction="vertical" size={"middle"}>
                <Alert
                  type="info"
                  message={
                    <FormattedMessage defaultMessage={`Reconnect required every time you restart the POS App`} />
                  }
                  description={
                    <FormattedMessage
                      defaultMessage={
                        "Due to the limitations of web Bluetooth technology, closing this POS page will result in the loss of the current Bluetooth connection. Therefore, whenever you restart the POS system, you will need to manually select and reconnect the Bluetooth printer."
                      }
                    />
                  }
                />
                <BluetoothPrinterConnectButton printerType={printerType} />
              </Space>
            ) : (
              <WebAPINotSupportAlert techName={<FormattedMessage defaultMessage="Web Bluetooth" />} />
            ),
          },
        ]}
      />
      {device.isWindows && <WebUSBInstallDrawerAlert />}
    </Dialog>
  );
}
