"use client";

import { PLATFORM_TEMPLATE_REALM_ID, SERVER_ACTION_DEVICE_REGISTRATION } from "@easybiz/constants";
import { useServerAction } from "@easybiz/hook";
import {
  useClientInstallId,
  useClientStateSetter,
  useClientType,
  useClientVersion,
  useUser,
  useUserRealmId,
  useUserUID,
} from "@easybiz/shell";
import { useEffect } from "react";

// Init device register (Grant permissions)
function DeviceRegister({ getDeviceInfo, signInWithCustomToken, getAuth }) {
  const user = useUser();
  // Reregister device if password updated
  const passwordUpdatedAt = user?.passwordUpdatedAt;
  const uid = useUserUID();
  const realmId = useUserRealmId();
  const installId = useClientInstallId();
  const setAppState = useClientStateSetter();
  const client = useClientType();
  const clientVersion = useClientVersion();
  const { onCall } = useServerAction(SERVER_ACTION_DEVICE_REGISTRATION);

  useEffect(() => {
    if (installId && uid && realmId && realmId !== PLATFORM_TEMPLATE_REALM_ID) {
      onCall(
        {
          ...getDeviceInfo(),
          installId,
          client,
          clientVersion,
          betaRelease: Boolean(process.env.NEXT_PUBLIC_partial_release),
        },
        (response) => {
          const { token, notice, intercom } = response;

          if (token) {
            signInWithCustomToken(getAuth(), token);
          }

          if (notice) {
            setAppState({ notice });
          }

          // TODO: REMOVE INTERCOM
          if (intercom && typeof window !== "undefined" && window.Intercom) {
            window.Intercom("boot", {
              ...intercom,
              custom_launcher_selector: `#intercomLauncher`,
              hide_default_launcher: true,
            });
          }
        }
      );
    }
  }, [client, clientVersion, uid, realmId, installId, passwordUpdatedAt]);

  return null;
}

export default DeviceRegister;
