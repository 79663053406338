const values = {
  0: "lg:right-0",
  1: "lg:right-1",
  2: "lg:right-2",
  3: "lg:right-3",
  4: "lg:right-4",
  5: "lg:right-5",
  6: "lg:right-6",
  7: "lg:right-7",
  8: "lg:right-8",
  9: "lg:right-9",
  10: "lg:right-10",
  11: "lg:right-11",
  12: "lg:right-12",
  14: "lg:right-14",
  16: "lg:right-16",
  20: "lg:right-20",
  "1/2": "lg:right-1/2",
};

export default function getAbsoluteRightLg(value) {
  return values[value];
}
