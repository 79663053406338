const options = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
  justify: "text-justify",
};

export default function getTextAlign(align) {
  return options[align];
}
