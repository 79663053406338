import clsx from "clsx";
import getShadowLg from "./getShadowLg";
import getShadowMd from "./getShadowMd";
import getShadowSm from "./getShadowSm";
import getShadowXl from "./getShadowXl";

const values = {
  none: "shadow-none",
  sm: "shadow-sm",
  md: "shadow-md",
  lg: "shadow-lg",
  xl: "shadow-xl",
  "2xl": "shadow-2xl",
  inner: "shadow-inner",
};

export default function getShadow(attrs) {
  return clsx(
    values[attrs?.shadow],
    getShadowSm(attrs?.shadowSm),
    getShadowMd(attrs?.shadowMd),
    getShadowLg(attrs?.shadowLg),
    getShadowXl(attrs?.shadowXl)
  );
}
