import { Drawer } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import CustomerForm from "./CustomerForm";

export default ({ open, width, height, placement, customerId, onSaved, onClose }) => {
  return (
    <Drawer
      title={
        customerId ? (
          <FormattedMessage defaultMessage={"Update Customer"} />
        ) : (
          <FormattedMessage defaultMessage={"New Customer"} />
        )
      }
      placement={placement || "right"}
      width={placement !== "bottom" ? width || 470 : null}
      height={height}
      open={open}
      onClose={onClose}
      bodyStyle={{ padding: "16px 0px 0px 16px" }}
      destroyOnClose
    >
      <CustomerForm customerId={customerId} onSaved={onSaved || onClose} />
    </Drawer>
  );
};
