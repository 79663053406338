import clsx from "clsx";
import getOverflowXLg from "./getOverflowXLg";
import getOverflowXMd from "./getOverflowXMd";
import getOverflowXSm from "./getOverflowXSm";
import getOverflowXXl from "./getOverflowXXl";
import getOverflowYLg from "./getOverflowYLg";
import getOverflowYMd from "./getOverflowYMd";
import getOverflowYSm from "./getOverflowYSm";
import getOverflowYXl from "./getOverflowYXl";

const overflowX = {
  hidden: "overflow-x-hidden",
  visible: "overflow-x-visible",
  auto: "overflow-x-auto",
  clip: "overflow-x-clip",
};

const overflowY = {
  hidden: "overflow-y-hidden",
  visible: "overflow-y-visible",
  auto: "overflow-y-auto",
  clip: "overflow-y-clip",
};

export default function getOverflow(attrs) {
  return clsx(
    ...(attrs?.rounded && attrs.rounded !== "none"
      ? ["overflow-clip"]
      : [overflowX[attrs?.overflowX], overflowY[attrs?.overflowY]]),
    ...(attrs?.roundedSm && attrs.roundedSm !== "none"
      ? ["sm:overflow-clip"]
      : [getOverflowXSm(attrs?.overflowXSm), getOverflowYSm(attrs?.overflowYSm)]),
    ...(attrs?.roundedMd && attrs.roundedMd !== "none"
      ? ["md:overflow-clip"]
      : [getOverflowXMd(attrs?.overflowXMd), getOverflowYMd(attrs?.overflowYMd)]),
    ...(attrs?.roundedLg && attrs.roundedLg !== "none"
      ? ["lg:overflow-clip"]
      : [getOverflowXLg(attrs?.overflowXLg), getOverflowYLg(attrs?.overflowYLg)]),
    ...(attrs?.roundedXl && attrs.roundedXl !== "none"
      ? ["xl:overflow-clip"]
      : [getOverflowXXl(attrs?.overflowXXl), getOverflowYXl(attrs?.overflowYXl)])
  );
}
