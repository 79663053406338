import { CheckOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { LogisticStatusDisplay } from "@easybiz/component";
import { useJobComplete, useJobFailed, useJobReverseStatus } from "@easybiz/api-job";
import {
  getJobStatusColor,
  JOB_ACTION_COMPLETE,
  JOB_ACTION_FAILED,
  JOB_ACTION_REVERSE,
  JOB_STATUS_COMPLETE,
  JOB_STATUS_FAILED,
} from "@easybiz/utils";
import { Menu, Popover, Tag } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

export default function ({ taskId, orderId, customerId, status, placement }) {
  const [open, setOpen] = useState(false);
  const { onSubmit: onComplete, loading: completing } = useJobComplete();
  const { onSubmit: onFailed, loading: failding } = useJobFailed();
  const { onSubmit: onReverse, loading: reversing } = useJobReverseStatus();

  return (
    <Popover
      trigger={"click"}
      placement={placement || "left"}
      open={open}
      onOpenChange={setOpen}
      title={<FormattedMessage defaultMessage={"Update job status"} />}
      content={
        <Menu
          items={
            status === JOB_STATUS_COMPLETE
              ? [
                  {
                    key: JOB_ACTION_REVERSE,
                    icon: <CheckOutlined />,
                    label: <FormattedMessage defaultMessage={"Reverse to pending"} />,
                    onClick: () => {
                      setOpen(false);
                      onReverse({
                        orderId,
                        taskId,
                        customerId,
                      });
                    },
                  },
                ]
              : [
                  {
                    key: JOB_ACTION_COMPLETE,
                    icon: <CheckOutlined />,
                    label: <FormattedMessage defaultMessage={"Complete job"} />,
                    onClick: () => {
                      setOpen(false);
                      onComplete({
                        orderId,
                        taskId,
                        customerId,
                      });
                    },
                  },
                  ...(status !== JOB_STATUS_FAILED
                    ? [
                        {
                          type: "divider",
                        },
                        {
                          key: JOB_ACTION_FAILED,
                          icon: <CloseOutlined />,
                          label: <FormattedMessage defaultMessage={"Failed job"} />,
                          danger: true,
                          onClick: () => {
                            setOpen(false);
                            onFailed({
                              orderId,
                              taskId,
                              customerId,
                            });
                          },
                        },
                      ]
                    : []),
                ]
          }
        />
      }
    >
      <Tag
        className="selectable"
        color={getJobStatusColor(status)}
        icon={completing || failding || reversing ? <LoadingOutlined /> : null}
      >
        <LogisticStatusDisplay status={status} />
      </Tag>
    </Popover>
  );
}
