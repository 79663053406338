import { COLOR_BLACK, COLOR_WHITE } from "./constants/template.js";
import getBorderWhite from "./getBorderWhite.js";
import parseColorNameValue from "./parseColorNameValue.js";

const COLOR_100 = {
  slate: "border-slate-100",
  gray: "border-gray-100",
  zinc: "border-zinc-100",
  neutral: "border-neutral-100",
  stone: "border-stone-100",
  red: "border-red-100",
  orange: "border-orange-100",
  amber: "border-amber-100",
  yellow: "border-yellow-100",
  lime: "border-lime-100",
  green: "border-green-100",
  emerald: "border-emerald-100",
  teal: "border-teal-100",
  cyan: "border-cyan-100",
  sky: "border-sky-100",
  blue: "border-blue-100",
  indigo: "border-indigo-100",
  violet: "border-violet-100",
  purple: "border-purple-100",
  fuchsia: "border-fuchsia-100",
  pink: "border-pink-100",
  rose: "border-rose-100",
};

const COLOR_200 = {
  slate: "border-slate-200",
  gray: "border-gray-200",
  zinc: "border-zinc-200",
  neutral: "border-neutral-200",
  stone: "border-stone-200",
  red: "border-red-200",
  orange: "border-orange-200",
  amber: "border-amber-200",
  yellow: "border-yellow-200",
  lime: "border-lime-200",
  green: "border-green-200",
  emerald: "border-emerald-200",
  teal: "border-teal-200",
  cyan: "border-cyan-200",
  sky: "border-sky-200",
  blue: "border-blue-200",
  indigo: "border-indigo-200",
  violet: "border-violet-200",
  purple: "border-purple-200",
  fuchsia: "border-fuchsia-200",
  pink: "border-pink-200",
  rose: "border-rose-200",
};

const COLOR_300 = {
  slate: "border-slate-300",
  gray: "border-gray-300",
  zinc: "border-zinc-300",
  neutral: "border-neutral-300",
  stone: "border-stone-300",
  red: "border-red-300",
  orange: "border-orange-300",
  amber: "border-amber-300",
  yellow: "border-yellow-300",
  lime: "border-lime-300",
  green: "border-green-300",
  emerald: "border-emerald-300",
  teal: "border-teal-300",
  cyan: "border-cyan-300",
  sky: "border-sky-300",
  blue: "border-blue-300",
  indigo: "border-indigo-300",
  violet: "border-violet-300",
  purple: "border-purple-300",
  fuchsia: "border-fuchsia-300",
  pink: "border-pink-300",
  rose: "border-rose-300",
};

const COLOR_400 = {
  slate: "border-slate-400",
  gray: "border-gray-400",
  zinc: "border-zinc-400",
  neutral: "border-neutral-400",
  stone: "border-stone-400",
  red: "border-red-400",
  orange: "border-orange-400",
  amber: "border-amber-400",
  yellow: "border-yellow-400",
  lime: "border-lime-400",
  green: "border-green-400",
  emerald: "border-emerald-400",
  teal: "border-teal-400",
  cyan: "border-cyan-400",
  sky: "border-sky-400",
  blue: "border-blue-400",
  indigo: "border-indigo-400",
  violet: "border-violet-400",
  purple: "border-purple-400",
  fuchsia: "border-fuchsia-400",
  pink: "border-pink-400",
  rose: "border-rose-400",
};

const COLOR_500 = {
  slate: "border-slate-500",
  gray: "border-gray-500",
  zinc: "border-zinc-500",
  neutral: "border-neutral-500",
  stone: "border-stone-500",
  red: "border-red-500",
  orange: "border-orange-500",
  amber: "border-amber-500",
  yellow: "border-yellow-500",
  lime: "border-lime-500",
  green: "border-green-500",
  emerald: "border-emerald-500",
  teal: "border-teal-500",
  cyan: "border-cyan-500",
  sky: "border-sky-500",
  blue: "border-blue-500",
  indigo: "border-indigo-500",
  violet: "border-violet-500",
  purple: "border-purple-500",
  fuchsia: "border-fuchsia-500",
  pink: "border-pink-500",
  rose: "border-rose-500",
};

const COLOR_600 = {
  slate: "border-slate-600",
  gray: "border-gray-600",
  zinc: "border-zinc-600",
  neutral: "border-neutral-600",
  stone: "border-stone-600",
  red: "border-red-600",
  orange: "border-orange-600",
  amber: "border-amber-600",
  yellow: "border-yellow-600",
  lime: "border-lime-600",
  green: "border-green-600",
  emerald: "border-emerald-600",
  teal: "border-teal-600",
  cyan: "border-cyan-600",
  sky: "border-sky-600",
  blue: "border-blue-600",
  indigo: "border-indigo-600",
  violet: "border-violet-600",
  purple: "border-purple-600",
  fuchsia: "border-fuchsia-600",
  pink: "border-pink-600",
  rose: "border-rose-600",
};

const COLOR_700 = {
  slate: "border-slate-700",
  gray: "border-gray-700",
  zinc: "border-zinc-700",
  neutral: "border-neutral-700",
  stone: "border-stone-700",
  red: "border-red-700",
  orange: "border-orange-700",
  amber: "border-amber-700",
  yellow: "border-yellow-700",
  lime: "border-lime-700",
  green: "border-green-700",
  emerald: "border-emerald-700",
  teal: "border-teal-700",
  cyan: "border-cyan-700",
  sky: "border-sky-700",
  blue: "border-blue-700",
  indigo: "border-indigo-700",
  violet: "border-violet-700",
  purple: "border-purple-700",
  fuchsia: "border-fuchsia-700",
  pink: "border-pink-700",
  rose: "border-rose-700",
};

const COLOR_800 = {
  slate: "border-slate-800",
  gray: "border-gray-800",
  zinc: "border-zinc-800",
  neutral: "border-neutral-800",
  stone: "border-stone-800",
  red: "border-red-800",
  orange: "border-orange-800",
  amber: "border-amber-800",
  yellow: "border-yellow-800",
  lime: "border-lime-800",
  green: "border-green-800",
  emerald: "border-emerald-800",
  teal: "border-teal-800",
  cyan: "border-cyan-800",
  sky: "border-sky-800",
  blue: "border-blue-800",
  indigo: "border-indigo-800",
  violet: "border-violet-800",
  purple: "border-purple-800",
  fuchsia: "border-fuchsia-800",
  pink: "border-pink-800",
  rose: "border-rose-800",
};

export default function getBorderColor(color, theme, opacity) {
  const [name, value] = parseColorNameValue(color, theme);

  switch (name) {
    case COLOR_BLACK:
      return "border-black";
    case COLOR_WHITE:
      return getBorderWhite(opacity);
    default:
      switch (value) {
        case 100:
          return COLOR_100[name];
        case 200:
          return COLOR_200[name];
        case 300:
          return COLOR_300[name];
        case 400:
          return COLOR_400[name];
        case 500:
          return COLOR_500[name];
        case 600:
          return COLOR_600[name];
        case 700:
          return COLOR_700[name];
        case 800:
          return COLOR_800[name];
      }
  }
}
