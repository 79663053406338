const options = {
  5: "bg-gray-200/5",
  10: "bg-gray-200/10",
  25: "bg-gray-200/25",
  50: "bg-gray-200/50",
  75: "bg-gray-200/75",
  90: "bg-gray-200/90",
  100: "bg-gray-200",
};

const from = {
  5: "from-gray-200/5",
  10: "from-gray-200/10",
  25: "from-gray-200/25",
  50: "from-gray-200/50",
  75: "from-gray-200/75",
  90: "from-gray-200/90",
  100: "from-gray-200",
};

export default function getBackgroundGray200(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
