"use client";

import { useCustomerAddressEdit } from "@easybiz/api-customer";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

function useCustomerAddressEditPanel(customerId, editAddress, onComplete, initDefault) {
  const [address, setAddress] = useState();
  const [isDefault, setIsDefault] = useState(false);
  const intl = useIntl();
  const { onSubmit, loading } = useCustomerAddressEdit((succeed, response) => {
    if (succeed) {
      onComplete(response.address);
    }
  });

  useEffect(() => {
    setIsDefault(initDefault || false);

    if (editAddress) {
      const { customer, sortIndex, ...address } = editAddress;
      setAddress(address);
    } else {
      setAddress(null);
    }
  }, [editAddress]);

  return {
    address,
    onChange: setAddress,
    title: editAddress ? (
      <FormattedMessage id="edit.address" defaultMessage="Edit Address" />
    ) : (
      <FormattedMessage id="new.address" defaultMessage="New Address" />
    ),
    onOk: () => {
      onSubmit({
        customerId,
        addressId: editAddress?.id,
        isDefault,
        address,
      });
    },
    loading,
    emptyPrompt: <FormattedMessage id="search.and.select.address" defaultMessage="Search and select address" />,
    line1: {
      title: <FormattedMessage id="address.line.1" defaultMessage="Address Line 1" />,
      placeholder: intl.formatMessage({
        id: "street.block",
        defaultMessage: "Street/Block",
      }),
    },
    line2: {
      title: <FormattedMessage id="address.line.2" defaultMessage="Address Line 2" />,
      placeholder: intl.formatMessage({
        id: "floor.unit.no",
        defaultMessage: "Unit no",
      }),
    },
    defaultCheckBox: <FormattedMessage id="set.as.default.address" defaultMessage="Set as default address" />,
  };
}

export default useCustomerAddressEditPanel;
