import { formatPrice } from "@easybiz/utils";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

function useUnpaidOrdersPanelState(orders) {
  const [orderIds, setOrderIds] = useState([]);

  useEffect(() => {
    if (
      Array.isArray(orderIds) &&
      Array.isArray(orders) &&
      orderIds.find((orderId) => !orders.find((order) => order.id === orderId))
    ) {
      setOrderIds(orderIds.filter((orderId) => orders.find((order) => order.id === orderId)));
    }
  }, [orders]);

  return useMemo(() => {
    let orderMonthMap = {};

    orders?.forEach((order) => {
      const month = dayjs.unix(order.get("timestamp")).format("YYYY-MM");

      if (orderMonthMap[month]) {
        orderMonthMap[month].push(order);
      } else {
        orderMonthMap[month] = [order];
      }
    });

    let totalSelectedBalance;

    if (Array.isArray(orders) && orders.length > 0 && Array.isArray(orderIds)) {
      totalSelectedBalance = formatPrice(
        orders.map((order) => (orderIds.indexOf(order.id) >= 0 ? order.get("totalBalance") : 0)).reduce((a, b) => a + b)
      );
    }

    const allChecked = orderIds.length > 0 && orderIds?.length === orders?.length;

    return {
      totalSelectedBalance,
      allChecked,
      months: Object.keys(orderMonthMap)
        .sort()
        .map((month) => {
          const checked = !orderMonthMap[month].find(
            (order) => !Array.isArray(orderIds) || orderIds.indexOf(order.id) < 0
          );

          return {
            month,
            checked,
            indeterminate:
              !checked &&
              !!orderMonthMap[month].find((order) => Array.isArray(orderIds) && orderIds.indexOf(order.id) >= 0),
            count: orderMonthMap[month].length,
            onChange: () => {
              if (!checked) {
                const newSelects = [...(orderIds || []), ...orderMonthMap[month].map((order) => order.id)];
                setOrderIds(newSelects.filter((orderId, index) => newSelects.indexOf(orderId) === index));
              } else {
                setOrderIds(
                  (orderIds || []).filter((orderId) => !orderMonthMap[month].find((other) => other.id === orderId))
                );
              }
            },
          };
        }),
      orderIds,
      onSelect: (order) => {
        const index = orderIds ? orderIds.indexOf(order.id) : -1;

        if (index >= 0) {
          setOrderIds([...orderIds.slice(0, index), ...orderIds.slice(index + 1)]);
        } else {
          setOrderIds([...(orderIds || []), order.id]);
        }
      },
      onSelectAll: () => {
        if (allChecked) {
          setOrderIds([]);
        } else {
          setOrderIds((orders || []).map((order) => order.id));
        }
      },
      onClear: () => setOrderIds([]),
    };
  }, [orders, orderIds]);
}

export default useUnpaidOrdersPanelState;
