import clsx from "clsx";
import getMarginBottom from "./getMarginBottom";
import getMarginBottomLg from "./getMarginBottomLg";
import getMarginBottomSm from "./getMarginBottomSm";
import getMarginLeft from "./getMarginLeft";
import getMarginLeftLg from "./getMarginLeftLg";
import getMarginLeftSm from "./getMarginLeftSm";
import getMarginRight from "./getMarginRight";
import getMarginRightLg from "./getMarginRightLg";
import getMarginRightSm from "./getMarginRightSm";
import getMarginTop from "./getMarginTop";
import getMarginTopLg from "./getMarginTopLg";
import getMarginTopSm from "./getMarginTopSm";
import getMarginTopXl from "./getMarginTopXl";

export default function getMargins(attrs) {
  return clsx(
    getMarginTop(attrs?.mt),
    getMarginBottom(attrs?.mb),
    getMarginLeft(attrs?.ml),
    getMarginRight(attrs?.mr),
    getMarginTopSm(attrs?.mtSm),
    getMarginBottomSm(attrs?.mbSm),
    getMarginLeftSm(attrs?.mlSm),
    getMarginRightSm(attrs?.mrSm),
    getMarginTopLg(attrs?.mtLg),
    getMarginBottomLg(attrs?.mbLg),
    getMarginLeftLg(attrs?.mlLg),
    getMarginRightLg(attrs?.mrLg),
    getMarginTopXl(attrs?.mtXl)
  );
}
