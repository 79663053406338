import { AmountDisplay, DateDisplay, PaymentMethodDisplay } from "@easybiz/component";
import { useOrderCancel } from "@easybiz/api-order";
import { Input, List, Space } from "antd";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from "../Dialog";
import Title from "../Title";

export default function ({ order, open, onCancel }) {
  const [note, setNote] = useState("");
  const intl = useIntl();
  const { onSubmit, loading } = useOrderCancel((success) => success && onCancel());

  const refundablePayments = useMemo(() => {
    if (open && order) {
      return (order.get("payments") || []).filter(
        (payment) => payment.v > 0 && !order.get("payments").find((other) => other.pt === payment.t)
      );
    }
  }, [order, open]);

  return (
    <Dialog
      open={open}
      onCancel={onCancel}
      confirmLoading={loading}
      title={
        order && (
          <FormattedMessage
            tagName="span"
            defaultMessage="Cancel Order {orderNo}?"
            values={{ orderNo: `#${order.get("orderNo")}` }}
          />
        )
      }
      onOk={() => {
        onSubmit({
          orderId: order.id,
          note,
        });
      }}
      okText={<FormattedMessage defaultMessage={"Cancel order"} />}
      okDanger
    >
      <Space direction="vertical" className="full-width">
        {refundablePayments?.length > 0 && (
          <List
            header={<FormattedMessage tagName={"b"} defaultMessage={"Payment made below will be refunded"} />}
            size="small"
            dataSource={refundablePayments}
            renderItem={(payment) => {
              const { v, m, t, o } = payment;

              return (
                <List.Item
                  actions={[
                    <Title type="danger">
                      <FormattedMessage defaultMessage="Refund" />
                    </Title>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <Title type={"success"}>
                        <FormattedMessage
                          defaultMessage={"{amount} paid by {option}"}
                          values={{
                            amount: <AmountDisplay value={v} />,
                            option: <PaymentMethodDisplay option={m} />,
                          }}
                        />
                      </Title>
                    }
                    description={
                      <span>
                        <DateDisplay timestamp={t} /> {o}
                      </span>
                    }
                  />
                </List.Item>
              );
            }}
          />
        )}
        <Input.TextArea
          autoFocus
          placeholder={intl.formatMessage({
            defaultMessage: "Cancellation note",
          })}
          rows={2}
          disabled={loading}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </Space>
    </Dialog>
  );
}
