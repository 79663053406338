import { useClientType } from "@easybiz/shell";
import { CLIENT_POS } from "@easybiz/utils";
import { Button, Popconfirm } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import Title from "../Title";

export default function ({ type, disabled, danger, children, icon, block, loading, confirmTitle, onConfirm }) {
  const client = useClientType();

  return (
    <Popconfirm
      title={<Title>{confirmTitle}</Title>}
      onConfirm={onConfirm}
      okText={<FormattedMessage defaultMessage={"Confirm"} />}
      {...(client === CLIENT_POS && {
        okButtonProps: { size: "large" },
        cancelButtonProps: { size: "large" },
      })}
    >
      <Button block={block} type={type} disabled={disabled} loading={loading} icon={icon} danger={danger}>
        {children}
      </Button>
    </Popconfirm>
  );
}
