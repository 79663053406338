const values = {
  3: "leading-3",
  4: "leading-4",
  5: "leading-5",
  6: "leading-6",
  7: "leading-7",
  8: "leading-8",
  9: "leading-9",
  10: "leading-10",
  none: "leading-none",
};

export default function getLeading(height) {
  return values[height];
}
