const breakpoints = ["Xl", "Lg", "Md", "Sm", ""];

export default function getAttr(attrs, key, breakpoint) {
  const value =
    attrs?.[`${key}${breakpoint}`] ??
    attrs?.[
      `${key}${
        breakpoints.find((other, index) => index > breakpoints.indexOf(breakpoint) && attrs?.[`${key}${other}`]) || ""
      }`
    ];

  return value;
}
