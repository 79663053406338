const values = {
  0: "lg:pb-0",
  0.5: "lg:pb-0.5",
  1: "lg:pb-1",
  1.5: "lg:pb-1.5",
  2: "lg:pb-2",
  2.5: "lg:pb-2.5",
  3: "lg:pb-3",
  3.5: "lg:pb-3.5",
  4: "lg:pb-4",
  5: "lg:pb-5",
  6: "lg:pb-6",
  7: "lg:pb-7",
  8: "lg:pb-8",
  9: "lg:pb-9",
  10: "lg:pb-10",
  12: "lg:pb-12",
  14: "lg:pb-14",
  16: "lg:pb-16",
  20: "lg:pb-20",
  24: "lg:pb-24",
  28: "lg:pb-28",
  32: "lg:pb-32",
  36: "lg:pb-36",
  40: "lg:pb-40",
  44: "lg:pb-44",
  48: "lg:pb-48",
  52: "lg:pb-52",
  56: "lg:pb-56",
  60: "lg:pb-60",
  64: "lg:pb-64",
  72: "lg:pb-72",
  80: "lg:pb-80",
  96: "lg:pb-96",
};

export default function getPaddingBottomLg(value) {
  return values[value];
}
