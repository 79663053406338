const options = {
  0: "lg:translate-x-0",
  8: "lg:translate-x-8 transform",
  "1/2": "lg:translate-x-1/2 transform",
  "-1/2": "lg:-translate-x-1/2 transform",
};

export default function getTranslateXLg(value) {
  return options[value];
}
