import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast, useRealmToDisplayName } from "@easybiz/shell";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_COLLECTION_RESCHEDULE } from "@easybiz/utils";
import { useIntl } from "react-intl";
function useOrderCollectionUpdate(callback) {
  const toast = useFeedbackToast();
  const intl = useIntl();
  const toDisplayName = useRealmToDisplayName();

  return useActionCallback(
    ({ date, option, orderId, itemIds, collectionPlace, note }) => {
      return [
        OPERATION_GROUP_ORDER,
        ORDER_ACTION_COLLECTION_RESCHEDULE,
        {
          orderId,
          ...(date && { date }),
          ...(note && { note }),
          ...(option && { option }),
          ...(collectionPlace && { collectionPlace }),
          ...(itemIds && { itemIds }),
        },
      ];
    },
    callback,
    (request) => {
      if (request.date) {
        toast.success(
          intl.formatMessage(
            {
              id: "collection.rescheduled.to..date.x",
              defaultMessage: "Collection rescheduled to {date}",
            },
            { date: intl.formatDate(request.date) }
          )
        );
      } else if (request.collectionPlace) {
        toast.success(
          intl.formatMessage(
            {
              id: "collection.place.changed.to.x",
              defaultMessage: "Collection place changed to {placeName}",
            },
            { placeName: toDisplayName(request.collectionPlace) || request.collectionPlace }
          )
        );
      }
    }
  );
}

export default useOrderCollectionUpdate;
