function LocaleDate({ lang, timestamp, date, hour, minute, month, weekday }) {
  // Options for date formatting
  const options = {
    year: "numeric",
    month: month || "long",
    day: "numeric",
    hour: hour || (timestamp ? "2-digit" : undefined),
    minute: minute || (timestamp ? "2-digit" : undefined),
    weekday,
    // Add additional options based on your requirements (e.g., weekday)
  };

  // Get the formatted date string based on the locale
  return new Date(timestamp ? timestamp * 1000 : date).toLocaleDateString(lang, options);
}

export default LocaleDate;
