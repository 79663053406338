const values = {
  "-32": "lg:-top-32",
  "-12": "lg:-top-12",
  "-11": "lg:-top-11",
  "-10": "lg:-top-10",
  "-9": "lg:-top-9",
  "-8": "lg:-top-8",
  "-7": "lg:-top-7",
  "-6": "lg:-top-6",
  "-5": "lg:-top-5",
  "-4": "lg:-top-4",
  "-3": "lg:-top-3",
  "-2": "lg:-top-2",
  "-1": "lg:-top-1",
  0: "lg:top-0",
  1: "lg:top-1",
  2: "lg:top-2",
  3: "lg:top-3",
  4: "lg:top-4",
  5: "lg:top-5",
  6: "lg:top-6",
  7: "lg:top-7",
  8: "lg:top-8",
  9: "lg:top-9",
  10: "lg:top-10",
  11: "lg:top-11",
  12: "lg:top-12",
  14: "lg:top-14",
  16: "lg:top-16",
  20: "lg:top-20",
  "1/2": "lg:top-1/2",
};

export default function getAbsoluteTopLg(value) {
  return values[value];
}
