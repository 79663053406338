const options = {
  0: "border-t-0",
  1: "border-t",
  2: "border-t-2",
  4: "border-t-4",
  8: "border-t-8",
};

export default function getBorderTop(width) {
  return options[width];
}
