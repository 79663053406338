const options = {
  left: "sm:text-left",
  center: "sm:text-center",
  right: "sm:text-right",
  justify: "sm:text-justify",
};

export default function getTextAlignSm(align) {
  return options[align];
}
