"use client";

import { useUserCurrency, useClientLang } from "@easybiz/shell";

function LocaleCurrency({ value, accounting }) {
  const lang = useClientLang();
  const currency = useUserCurrency();

  if (typeof value === "number" || (typeof value === "string" && !isNaN(value))) {
    return currency
      ? parseFloat(value).toLocaleString(lang, {
          style: "currency",
          currency,
          currencyDisplay: "narrowSymbol",
          ...(accounting && {
            currencySign: "accounting",
          }),
        })
      : value;
  } else {
    return null;
  }
}

export default LocaleCurrency;
