const options = {
  5: "bg-neutral-50/5",
  10: "bg-neutral-50/10",
  25: "bg-neutral-50/25",
  50: "bg-neutral-50/50",
  75: "bg-neutral-50/75",
  90: "bg-neutral-50/90",
  100: "bg-neutral-50",
};

const from = {
  5: "from-neutral-50/5",
  10: "from-neutral-50/10",
  25: "from-neutral-50/25",
  50: "from-neutral-50/50",
  75: "from-neutral-50/75",
  90: "from-neutral-50/90",
  100: "from-neutral-50",
};

export default function getBackgroundNeutral50(opacity, isFrom) {
  return (isFrom ? from : options)[opacity || 100];
}
