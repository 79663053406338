export const PRINTER_TYPE_RECEIPT = "receipt";
export const PRINTER_TYPE_LABEL = "label";
export const PRINTER_TYPE_STICKER = "sticker";

export const PRINTER_IO_BLUETOOTH = 'bluetooth';
export const PRINTER_IO_USB = 'usb';

export const LINE_ALIGN_CENTER = "center";
export const LINE_ALIGN_LEFT = "left";
export const LINE_ALIGN_RIGHT = "right";

export const LINE_TYPE_IMAGE = "image";
export const LINE_TYPE_TITLE = "title";
export const LINE_TYPE_PARAGRAPH = "paragraph";
export const LINE_TYPE_TERMS = "terms";
export const LINE_TYPE_QRCODE = "qrcode";
export const LINE_TYPE_BARCODE = "barcode";
export const LINE_TYPE_CUT = "cut";

export const CONTENT_DIVIDER = "[divider]";
export const CONTENT_FENCE = "[fence]";
export const CONTENT_BREAK = "[break]";
export const CONTENT_BOLD = "[bold]";
export const CONTENT_SIGN_HERE = "[sign]";
export const CONTENT_CUT = '[cut]';

export const QR_CODE_TYPE_ORDER = "O";
export const QR_CODE_TYPE_ITEM = "I";
export const QR_CODE_TYPE_PACK = "P";
export const QR_CODE_TYPE_PICKUP_BAG = "BAG";
export const QR_CODE_TYPE_STORAGE = "STORE";
export const QR_CODE_TYPE_WORKFLOW = "W";
export const QR_CODE_TYPE_CLOUD_APP = "CA";
export const CODE_TYPE_MEMBER_CARD = "CARD"; // TODO: improve this
export const CODE_DIVIDER = "::";

export const PRINT_FONT_SIZE_SMALL = "small";
export const PRINT_FONT_SIZE_NORMAL = "normal";
export const PRINT_FONT_SIZE_LARGE = "large";