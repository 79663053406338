const options = {
  normal: "justify-normal",
  center: "justify-center",
  end: "justify-end",
  start: "justify-start",
  between: "justify-between",
  around: "justify-around",
  evenly: "justify-evenly",
  stretch: "justify-stretch",
};

export default function getJustifyItems(justify) {
  return options[justify];
}
