const values = {
  none: "md:shadow-none",
  sm: "md:shadow-sm",
  md: "md:shadow-md",
  lg: "md:shadow-md",
  xl: "md:shadow-xl",
  "2xl": "md:shadow-2xl",
  inner: "md:shadow-inner",
};

export default function getShadowMd(value) {
  return values[value];
}
