const options = {
  1: "lg:w-1",
  2: "lg:w-2",
  3: "lg:w-3",
  4: "lg:w-4",
  5: "lg:w-5",
  6: "lg:w-6",
  7: "lg:w-7",
  8: "lg:w-8",
  9: "lg:w-9",
  10: "lg:w-10",
  11: "lg:w-11",
  12: "lg:w-12",
  14: "lg:w-14",
  16: "lg:w-16",
  20: "lg:w-20",
  24: "lg:w-24",
  28: "lg:w-28",
  32: "lg:w-32",
  36: "lg:w-36",
  40: "lg:w-40",
  44: "lg:w-44",
  48: "lg:w-48",
  52: "lg:w-52",
  56: "lg:w-56",
  60: "lg:w-60",
  64: "lg:w-64",
  72: "lg:w-72",
  80: "lg:w-80",
  96: "lg:w-96",
  112: "lg:w-[28rem]",
  128: "lg:w-[32rem]",
  160: "lg:w-[40rem]",
  192: "lg:w-[48rem]",
  "1/2": "lg:w-1/2",
  "1/3": "lg:w-1/3",
  "2/3": "lg:w-2/3",
  "1/5": "lg:w-1/5",
  "2/5": "lg:w-2/5",
  "3/5": "lg:w-3/5",
  "4/5": "lg:w-4/5",
  px: "lg:w-px",
  screen: "lg:w-screen",
  grow: "lg:grow",
  fill: "lg:flex-1",
  full: "lg:w-full",
  ratio: "lg:w-auto",
  auto: "lg:w-auto",
};

export default function getWidthLg(size, parentDisplay) {
  return size === "fill" && !["row", "row-reverse"].includes(parentDisplay) ? "" : options[size];
}
