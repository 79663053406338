const options = {
  0: "sm:translate-x-0",
  8: "sm:translate-x-8 transform",
  "1/2": "sm:translate-x-1/2 transform",
  "-1/2": "sm:-translate-x-1/2 transform",
};

export default function getTranslateXSm(value) {
  return options[value];
}
