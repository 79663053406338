const values = {
  "-32": "sm:-top-32",
  "-12": "sm:-top-12",
  "-11": "sm:-top-11",
  "-10": "sm:-top-10",
  "-9": "sm:-top-9",
  "-8": "sm:-top-8",
  "-7": "sm:-top-7",
  "-6": "sm:-top-6",
  "-5": "sm:-top-5",
  "-4": "sm:-top-4",
  "-3": "sm:-top-3",
  "-2": "sm:-top-2",
  "-1": "sm:-top-1",
  0: "sm:top-0",
  1: "sm:top-1",
  2: "sm:top-2",
  3: "sm:top-3",
  4: "sm:top-4",
  5: "sm:top-5",
  6: "sm:top-6",
  7: "sm:top-7",
  8: "sm:top-8",
  9: "sm:top-9",
  10: "sm:top-10",
  11: "sm:top-11",
  12: "sm:top-12",
  14: "sm:top-14",
  16: "sm:top-16",
  20: "sm:top-20",
  "1/2": "sm:top-1/2",
};

export default function getAbsoluteTopSm(value) {
  return values[value];
}
